<div class="row">
    <div class="col-md-12 heading">
        <h2 class="pull-left">Master Template - {{ master?.title }}</h2>
        <button routerLink="../../{{ master?.category?.slug }}" type="button" class="btn-admin">
            <i class="icon feather icon-arrow-left"></i><span>Back</span>
        </button>
    </div>
</div>
<div class="edit-box">
    <div class="row">
        <div class="col-md-12">
            <ul class="nav nav-tabs" id="globalSetting" role="tablist">
                <li class="nav-item" role="presentation">
                <button class="nav-link active" id="global-template-tab" data-bs-toggle="tab" data-bs-target="#global-template" type="button" role="tab" aria-controls="global-template" aria-selected="false">Global Settings</button>
                </li>
                <li class="nav-item" role="presentation">
                <button class="nav-link" id="setting-group-tab" data-bs-toggle="tab" data-bs-target="#setting-group" type="button" role="tab">Setting Groups</button>
                </li>
            </ul>
            <div class="tab-content" id="globalSettingContent">
                <div class="tab-pane fade show active" id="global-template" role="tabpanel" aria-labelledby="global-template-tab">
                    <div>
                        <div class="col mb-3 div-add-setting">
                            <span class="c-pointer btn-admin btn-admin-primary" (click)="modalActionEvent('add_setting', 'show')"><i class="icon feather icon-plus"></i> Add Setting</span>
                        </div>
                        <form [formGroup]="globalTemplateForm" (ngSubmit)="storeGlobalTemplateSetting()">
                            <ng-container formArrayName="data"> <!-- Data -->
                                <ng-container *ngFor="let data of settingsData().controls; let data_index = index; let first_index = first; let last_index = last" [formGroupName]="data_index">
                                    <div class="row g-2 mb-3">
                                        <div class="col arrow-dir-vertical" [ngClass]="data.value.group_id ? 'row-setting-group' : 'row-setting-nongroup'">
                                            <div class="dropdown">
                                                <span class="c-pointer" data-bs-toggle="dropdown"><i class="icon feather icon-more-vertical"></i></span>
                                                <ul class="dropdown-menu dropdown-elm-action">
                                                    <li class="c-pointer" *ngIf="!first_index" (click)="moveRowSetting(data_index, 0)"><span class="dropdown-item"><i class="icon feather icon-chevron-up"></i>Move Up</span></li>
                                                    <li class="c-pointer" *ngIf="!last_index" (click)="moveRowSetting(data_index, 1)"><span class="dropdown-item"><i class="icon feather icon-chevron-down"></i>Move Down</span></li>
                                                    <li class="c-pointer" (click)="addNewRowTemplateSetting(data_index, 0)"><span class="dropdown-item"><i class="icon feather icon-plus-square"></i>Insert Above</span></li>
                                                    <li class="c-pointer" (click)="addNewRowTemplateSetting(data_index, 1)"><span class="dropdown-item"><i class="icon feather icon-plus-square"></i>Insert Below</span></li>
                                                    <li><hr class="dropdown-divider"></li>
                                                    <li class="c-pointer" (click)="removeRowTemplateSetting(data_index)" *ngIf="globalTemplateForm.value.data.length >= 1 && hasValidIdInItems(data_index)">
                                                        <span class="dropdown-item remove-row"><i class="icon feather icon-trash-2"></i>Delete</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div *ngIf="data.value.group_id; then groupSetting else singleSetting"></div>
                                        <ng-template #groupSetting>
                                            <div class="col-md">
                                                <div class="card text-dark bg-light mb-3">
                                                    <div class="card-header">
                                                        <span>{{ data.value.group_name }}</span>
                                                    </div>
                                                    <div class="card-body">
                                                        <ng-container formArrayName="items"> <!-- Items -->
                                                            <div class="row mb-3 g-2" *ngFor="let item of settingsItems(data_index).controls; let item_index = index; let first_item_index = first; let last_item_index = last" [formGroupName]="item_index">
                                                                <div class="col arrow-dir-vertical" [ngClass]="data.value.group_id ? 'row-setting-group' : 'row-setting-nongroup'">
                                                                    <div class="dropdown">
                                                                        <span class="c-pointer" data-bs-toggle="dropdown"><i class="icon feather icon-more-vertical"></i></span>
                                                                        <ul class="dropdown-menu dropdown-elm-action">
                                                                            <li class="c-pointer" (click)="moveGroupedSettingItem(data_index, item_index, 0)" *ngIf="!first_item_index"><span class="dropdown-item"><i class="icon feather icon-chevron-up"></i>Move Up</span></li>
                                                                            <li class="c-pointer" (click)="moveGroupedSettingItem(data_index, item_index, 1)" *ngIf="!last_item_index"><span class="dropdown-item"><i class="icon feather icon-chevron-down"></i>Move Down</span></li>
                                                                            <li class="c-pointer" (click)="addGroupSettingItem(data_index, item_index, data.value.group_id, 0)"><span class="dropdown-item"><i class="icon feather icon-plus-square"></i>Insert Above</span></li>
                                                                            <li class="c-pointer" (click)="addGroupSettingItem(data_index, item_index, data.value.group_id, 1)"><span class="dropdown-item"><i class="icon feather icon-plus-square"></i>Insert Below</span></li>
                                                                            <li *ngIf="data.value.items.length > 1"><hr class="dropdown-divider"></li>
                                                                            <li class="c-pointer" *ngIf="data.value.items.length > 1" (click)="removeItemFromGroupedSetting(data_index, item_index)">
                                                                                <span class="dropdown-item remove-row"><i class="icon feather icon-trash-2"></i>Delete</span>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md">
                                                                    <div>
                                                                        <label><small>Type</small></label>
                                                                        <select [attr.disabled]="item.value.id != null ? 'disabled' : null" formControlName="settings_type" class="form-select" (change)="onChangeSettingsType(item, $event)">
                                                                            <option value="0">Dropdown</option>
                                                                            <option value="1">Toggle</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md">
                                                                    <div>
                                                                        <label><small>Title</small></label>
                                                                        <input type="text" formControlName="title" class="form-control">
                                                                    </div>
                                                                </div>
                                                                <div class="col-md">
                                                                    <div>
                                                                        <label><small>Description</small></label>
                                                                        <input type="text" formControlName="description" class="form-control">
                                                                    </div>
                                                                </div>
                                                                <div class="col-md">
                                                                    <div>
                                                                        <label><small>Class Name</small></label>
                                                                        <input type="text" formControlName="class_name" class="form-control" [readonly]="item.value.settings_type === 0 ? true : false">
                                                                    </div>
                                                                </div>
                                                                <ng-container formArrayName="options"> <!-- Options -->
                                                                    <div class="row m-3 mb-0" *ngFor="let option of settingsOptions(data_index, item_index).controls; let option_index = index; let option_first = first" [formGroupName]="option_index">
                                                                        <div class="col arrow-dir-vertical">
                                                                            <div class="dropdown">
                                                                                <span class="c-pointer" data-bs-toggle="dropdown"><i class="icon feather icon-more-vertical"></i></span>
                                                                                <ul class="dropdown-menu dropdown-elm-action">
                                                                                    <li class="c-pointer" (click)="addSettingsOptions(data_index, item_index, option_index, item.id)"><span class="dropdown-item"><i class="icon feather icon-plus-square"></i>Add Option</span></li>
                                                                                    <li class="c-pointer" *ngIf="data.value.items[item_index].options.length > 1" (click)="removeSettingsOption(data_index, item_index, option_index)"><span class="dropdown-item"><i class="icon feather icon-trash-2"></i>Remove Option</span></li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md">
                                                                            <div>
                                                                                <label><small>Option[{{ option_index+1 }}] Title</small></label>
                                                                                <input type="text" formControlName="title" class="form-control">
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md">
                                                                            <div>
                                                                                <label><small>Option[{{ option_index+1 }}] Description</small></label>
                                                                                <input type="text" formControlName="description" class="form-control">
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md">
                                                                            <div>
                                                                                <label><small>Option[{{ option_index+1 }}] Class name</small></label>
                                                                                <input type="text" formControlName="class_name" class="form-control">
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </ng-container>
                                                            </div>
                                                        </ng-container>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-template>
                                        <ng-template #singleSetting>
                                            <div class="col-md">
                                                <ng-container formArrayName="items">
                                                    <div class="row g-2" *ngFor="let item of settingsItems(data_index).controls; let item_index = index; let first_row_setting_idx = first" [formGroupName]="item_index">
                                                        <div class="col-md">
                                                            <div>
                                                                <label><small>Type</small></label>
                                                                <select formControlName="settings_type" [attr.disabled]="item.value.id ? 'disabled' : null" class="form-select" (change)="onChangeSettingsType(item,$event)">
                                                                    <option value="0">Dropdown</option>
                                                                    <option value="1">Toggle</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="col-md">
                                                            <div>
                                                                <label><small>Title</small></label>
                                                                <input type="text" formControlName="title" class="form-control">
                                                            </div>
                                                        </div>
                                                        <div class="col-md">
                                                            <div>
                                                                <label><small>Description</small></label>
                                                                <input type="text" formControlName="description" class="form-control">
                                                            </div>
                                                        </div>
                                                        <div class="col-md">
                                                            <div>
                                                                <label><small>Class Name</small></label>
                                                                <input type="text" formControlName="class_name" class="form-control" [readonly]="item.value.settings_type == 0">
                                                            </div>
                                                        </div>
                                                        <ng-container formArrayName="options"> <!-- Options -->
                                                            <div class="row m-3 mb-0" *ngFor="let option of settingsOptions(data_index, item_index).controls; let option_index = index; let option_first = first" [formGroupName]="option_index">
                                                                <div class="col arrow-dir-vertical">
                                                                    <div class="dropdown">
                                                                        <span class="c-pointer" data-bs-toggle="dropdown"><i class="icon feather icon-more-vertical"></i></span>
                                                                        <ul class="dropdown-menu dropdown-elm-action">
                                                                            <li class="c-pointer" (click)="addSettingsOptions(data_index, item_index, option_index, item.id)"><span class="dropdown-item"><i class="icon feather icon-plus-square"></i>Add Option</span></li>
                                                                            <li class="c-pointer" *ngIf="item.value.options.length > 1" (click)="removeSettingsOption(data_index, item_index, option_index)"><span class="dropdown-item"><i class="icon feather icon-trash-2"></i>Remove Option</span></li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md">
                                                                    <div>
                                                                        <label><small>Option[{{ option_index+1 }}] Title</small></label>
                                                                        <input type="text" formControlName="title" class="form-control">
                                                                    </div>
                                                                </div>
                                                                <div class="col-md">
                                                                    <div>
                                                                        <label><small>Option[{{ option_index+1 }}] Description</small></label>
                                                                        <input type="text" formControlName="description" class="form-control">
                                                                    </div>
                                                                </div>
                                                                <div class="col-md">
                                                                    <div>
                                                                        <label><small>Option[{{ option_index+1 }}] Class Name</small></label>
                                                                        <input type="text" formControlName="class_name" class="form-control">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ng-container>
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </ng-template>
                                    </div>
                                </ng-container>
                            </ng-container>
                            <div class="col mt-3">
                                <button class="btn-admin btn-admin-primary btn-save-global-setting">Save</button>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="tab-pane fade" id="setting-group" role="tabpanel" aria-labelledby="setting-group-tab">
                    <div>
                        <div class="col mb-3 div-add-setting">
                            <span class="btn-admin btn-admin-primary c-pointer" (click)="createNewFormGroup()"><i class="icon feather icon-plus"></i>Add Group</span>
                        </div>
                        <div class="setting-list-container">
                            <table class="table caption-top">
                                <caption>List of Groups</caption>
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Name</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let group of settingGroupList?.data">
                                    <td>{{ group.id }}</td>
                                    <td>{{ group.group_name }}</td>
                                    <td>
                                        <span class="btn-table c-pointer" (click)="updateTemplateGroup(group)"><i class="icon feather icon-edit"></i></span>
                                        <span class="btn-table c-pointer" (click)="deleteGroupData(group.id)"><i class="icon feather icon-trash-2"></i></span>
                                    </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
  
<div class="modal fade mt-5" #modalAddRowSetting>
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <form [formGroup]="newSettingForm" (ngSubmit)="insertNewSetting()">
                <div class="modal-header">
                    <h5 class="modal-title">Add setting</h5>
                    <span class="btn-close c-pointer" #modalCloseAddRowSetting (click)="modalActionEvent('add_setting', 'hide')"></span>
                </div>
                <div class="modal-body">
                    <div class="row mb-3">
                        <label class="col-sm-2 col-form-label">Settings Group</label>
                        <div class="col-sm-10">
                            <select class="form-select" formControlName="setting_group">
                                <option value="">No Group</option>
                                <option *ngFor="let template_group of settingGroupList?.data" value="{{ template_group.id }}">{{ template_group.group_name }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <label class="col-sm-2 col-form-label">Settings Type *</label>
                        <div class="col-sm-10">
                            <select class="form-select" formControlName="setting_type" (change)="onChangeNewSettingTypeForm($event.target.value)">
                                <option value="0">Dropdown</option>
                                <option value="1">Toggle</option>
                            </select>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <label class="col-sm-2 col-form-label">Settings Title *</label>
                        <div class="col-sm-10">
                            <input type="text" class="form-control" formControlName="setting_title">
                        </div>
                    </div>
                    <div class="row mb-3">
                        <label class="col-sm-2 col-form-label">Description</label>
                        <div class="col-sm-10">
                            <input type="text" class="form-control" formControlName="setting_description">
                        </div>
                    </div>
                    <div class="row mb-3">
                        <label class="col-sm-2 col-form-label">Class name</label>
                        <div class="col-sm-10">
                            <input type="text" class="form-control" formControlName="setting_class_name">
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="submit" class="btn btn-admin btn-admin-primary">Insert</button>
                </div>
            </form>
        </div>
    </div>
</div>

<ngx-loading [show]="loading"></ngx-loading>