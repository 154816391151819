import { Component, OnInit, OnDestroy, ViewChild, ElementRef, AfterViewInit, ChangeDetectorRef, HostListener } from '@angular/core';
import { FrontService } from '../../front.service';
import { Menu } from '../../../models/menu.model';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { QuickDesign } from '../../../models/quick-design.model';
import { QuickDesignService } from '../../../services/quick-deisgn.service';
import { Element } from '../../../models/element.model';
import { ElementService } from '../../../services/element.service';
import { StyleService } from '../../../services/style.service';
import { Style } from '../../../models/style.model';

import { TemplateService } from '../../../services/template.service';
import { Template } from '../../../models/template.model';

import { MyDesktopService } from '../../../services/mydesktop.service';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { Auth } from '../../../models/auth.model';
import { ClientService } from '../../../services/client.service';
import { StorageService } from '../../../services/storage.service';
import { Client } from '../../../models/client.model';
import * as juice from 'juice';
import * as moment from 'moment';

import * as beautify from 'beautify';

import { Select2OptionData } from 'ng2-select2';
import { ImageLibraryService } from '../../../shared/imagelibrary.service';
import { UserService } from '../../../services/user.service';
import { PasteService } from '../../../services/paste.service';
import { SupportService } from '../../../services/support.service';
import { Observable, Subject } from 'rxjs';
import { ParticaService } from '../../../services/partica.service';

import { environment } from '../../../../environments/environment';
import { VaultService } from '../../../services/vault.service';
import { CampaignService } from '../../../services/campaign.service';
import { DomainService } from '../../../services/domain.service';
import { ProgressService } from '../../../shared/progress-loader/progress.service';
import { CustomCategoryService } from '../../../services/customcategory.service';
import { ToastrService } from 'ngx-toastr';
import { ThirdPartyService } from '../../../services/thirdparty.service';
import { PricefinderService } from '../../../services/pricefinder.service';

import * as copy from 'copy-to-clipboard';
import { IdashboardService } from '../../../services/idashboard.service';
import { PropertySearchService } from '../../../shared/property-search/property-search.service';
import { GlobalService } from '../../../services/global.service';
import { User } from '../../../models/user.model';
import { MalihuScrollbarModule, MalihuScrollbarService } from 'ngx-malihu-scrollbar';
import { ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import { FeatureFlagService } from '../../../services/feature-flags.service';
import { TemplateElementSettingService } from '../../../services/template-element-setting.service';
import { TemplateGlobalSettingService } from '../../../services/template-global-setting.service';
import { Categories } from './../../../enum/categories.enum';
import { GlobalTemplate, GroupedGlobalSetting, RowGlobalSetting } from '../../../models/global-template.model';
import { async } from '@angular/core/testing';
import { CanComponentDeactivate } from '../../../services/unsaved-changes.guard';

declare var $: any;
declare var jquery: any;
declare var swal: any;
declare var CKEDITOR: any;
declare var introJs: any;
declare var _bugHerd: any;
declare var grapesjs: any;
declare var Tagify: any;
declare const bootstrap: any;
declare var window: any;  // WB-3447: Elevio helper

// import * as grapesjs from 'grapesjs';

@Component({
  selector: 'app-template-front-build',
  templateUrl: './template-front-build.component.html',
  styleUrls: ['./template-front-build.component.scss']
})
export class TemplateFrontBuildComponent implements OnInit, OnDestroy, AfterViewInit, CanComponentDeactivate {
  destroy$: Subject<boolean> = new Subject<boolean>();
  loading = false;
  subLoading = false;
  mainLoading = false;
  menus: Menu[] = [];
  id: number;
  category: string;
  template: Template;
  maxLimit: number;
  quickDesigns: QuickDesign[] = [];
  elements: Element[] = [];
  style: Style;

  s2CampaignValue;
  s2CampaignData: Array<Select2OptionData>;
  s2CampaignOptions: Select2Options;

  pdfButtonClicked = false;
  imgButtonClicked = false;
  qdtButtonClicked = false;
  qdoButtonClicked = false;
  fbButtonClicked = false;
  twitterButtonClicked = false;
  linkedinButtonClicked = false;
  viewBrowserButtonClicked = false;
  viewCodeButtonClicked = false;
  savePopupShow = true;

  editor: any;
  selectedFileHolder: any;
  selectedListingIDs = [];
  editorWidth = '900px';
  editorDevice = 'Desktop View';
  is_save = false;
  new_template_name = '';

  s2StatusValue = '';

  imageUrl = '';
  is_style_visible = false;

  libraryStorageForm: FormGroup;

  elementListingHtml: any[] = [];

  element_parent_id = 0;
  element_listing_limit = 0;
  element_listing_index = 0;

  element_file_limit = 1;

  is_help_box_open = false;

  elementArgGroupId;

  client: Client;

  // Start of Image Library related variables
  files = [];
  subFiles = [];
  tempFiles = [];
  images = [];
  selected_image = [];
  libraryType = 'owned';
  libraryPath = '';
  base_path = '';
  libraryFolder = '';
  sid: number;
  is_new_folder = false;
  is_new_image = false;
  is_on_level3 = false;
  level = 0;
  folder_name1 = '';
  folder_name2 = '';
  // End of Image Library related variables
  selectedListings = [];
  selectedFile = [];

  clientSetting: any;
  groupSetting: any;

  supportLink = '';

  authUser: Auth;
  clientAgents = [];
  templateAgent: any;

  inlineJS = [];

  plugins = [];

  enable_contenteditable = false;
  letZoom = false;
  ckeditor_active = false;


  is_highlightable = true;
  _tmp_selected_element: any;

  _sender: any;

  oldPosImages: any[];
  newPosImages: any[];

  init_colorpicker = false;
  color_selected_element: any;

  template_devices: any;

  template_custom_attribute: {
    name: 'custom-width';
    width: '10px';
    height: '10px';
  };

  image_editor: any;
  image_model: any;

  property_guide_skip = false;
  updateable_link_type = 'url';

  showScreenshot = false;
  showPartica = false;
  // showImageLibrary = false;


  isSendToVault = false;
  vaultTemplateId = 0;
  mainColor = '';

  isPreloaded = false;

  isMaster = false;

  customImageType = {
    isCustom: false,
    type: 'jpg',
  };

  campaigns = [];

  isPreloadedTemplate = false;
  isPreloadedCampaign = false;
  isPreloadedCampaignMethod = 'add';
  preloadedSaveType = 'template';

  vaultContentId = '';
  isVaultMail = false;
  isVaultPopulated = false;
  isVaultFirstLoad = false;
  isHighResPdf = false;

  isDesignlyMail = false;
  designlyMailId = '';
  isSendToDesignlyMail = false;
  isAddUpdateMaster = '';

  testEmailForm: FormGroup;
  testMailSent = false;

  uploadCabinateForm: FormGroup;
  pdfUploadPath = '';

  isInsert = false;
  isReplace = false;

  customCategories = [];
  customCategoryForm: FormGroup;
  customCategorySave = false;

  quickDesignGroups = [];
  quickDesignOptionLoaded = false;

  checkOwners = 0;
  checkTenants = 0;
  checkSalePrice = 0;
  checkInspections = 0;
  checkCustomFields = 0;
  checkRooms = 0;
  checkMapImage = 0;
  checkFeatures = 0;
  checkPropertyOffers = 0;
  checkPropertyAdvertising = 0;
  checkPropertySolicitor = 0;
  checkPropertyOfferConditions = 0;

  emailCategories = [1, 2, 3, 7, 11, 13];
  printableCategories = [4, 5, 8, 10];
  socialMediaCategories = [6];
  signboardCategories = [9];
  pricefinderCategories = [14];

  totalChanges = 0;
  isAutoSaved = false;

  templateListings = [];

  isMergedSaved = false;
  priceFinderMapValues: any;

  priceFinderProcessedChecklist = 0;
  priceFinderSuburbData = {
    suburb: <any>{},
    stats: <any>{},
    segmentations: <any>{},
    listings: <any>{},
    requestData: <any>{},
    queryData: <any>{},
  };

  sharePriceFinderLinkClicked = false;

  priceFinderPdfUrl = '';
  ckeditorFontName: any;
  ckeditorFontFamily: any;

  startDragging = false;

  preview = '';
  agentSearchFilter = '';
  selectedAgents: User[] = []; 

  cropImageUrl = '';
  croppedImage = '';
  cropImageModal: any;  
  defaultAspectRatio = 1/1;
  maintainAspectRatio = false;
  cropperImageTransform: ImageTransform = {
    rotate: 0,
    flipH: false,
    flipV: false,
    scale: 1
  };
  cropperCanvasRotation =  0;

  tagifyTemplateTags: any;

  saveAsTemplateState: any = {
    selectedTab: 'details-tab'
  };

  templateShareUsers: { ID: number, name: string, isSelected: false, isVisible: true }[] = [];
  vaultShareUsers: { ID: number, name: string, isSelected: boolean, isVisible: true, agent_id: number }[] = [];
  templateShareWith: 'agent' | 'office' = 'agent';
  
  templateVisibleInVault = 'no';
  
  insertPropertyButton: boolean = false;
  insertPropertyElement: boolean = false;
  templateCategoryId = Categories;
  ukStyleId  = [421, 422];

  selectedStaffMembers: number[] = [];
  defaultSelectedStaffMembers: number[] = [];
  isShareToAllStaffMembers = false;
  isShareStaffMembers = false;
  shareWithEveryone = false;
  isVaultTemplate = false;
  isHarcourtsAu = false;
  isUserHasChanges = false;
  elevioModuleRequestSupportId: number = 10;  // elevio request support module ID

  /* Filing Cabinet Folders */
  filingCabinetFolders: any = [];
  /* End of Filing Cabinet Folders */
  showInactiveFlag= false;

  @ViewChild('builderHtml', { static: true }) builder: ElementRef;
  @ViewChild('mdPropSearchModal', { static: true }) mdPropSearchModal: ElementRef;
  @ViewChild('mdPropSearchModalClose', { static: true }) mdPropSearchModalClose: ElementRef;
  
  @ViewChild('mdLibrarySearchModal', { static: true }) mdLibrarySearchModal: ElementRef;
  @ViewChild('mdLibrarySearchModalClose', { static: true }) mdLibrarySearchModalClose: ElementRef;
  
  @ViewChild('mdParticaSearchModal', { static: true }) mdParticaSearchModal: ElementRef;
  @ViewChild('mdParticaSearchModalClose', { static: true }) mdParticaSearchModalClose: ElementRef;
  
  @ViewChild('itemFile', { static: false }) itemFile: ElementRef;
  @ViewChild('customCategoryIcon', { static: true }) customCategoryIcon: ElementRef;
  
  @ViewChild('mdPricefinderSearchModal', { static: false }) mdPricefinderSearchModal: ElementRef;
  @ViewChild('mdPricefinderSearchModalClose', { static: false }) mdPricefinderSearchModalClose: ElementRef;
  @ViewChild('sendEmailModal', { static: false }) sendEmailModal: ElementRef;

  /* Start of Global & Element Settings variables */
  globalSettings = new FormArray([]);
  elementTemplateSettingList: any;
  elementPropertyIcons = [];
  elementPropertyForm: FormGroup;
  userGlobalSettings = [];
  tmpUserGlobalClass = []; //to reference class of id="wrapper"
  slideHideSpeed = 450;
  /* End of Global & Element Settings variables */

  currIframeWidth = '';
  constructor(
    public frontService: FrontService,
    private templateService: TemplateService,
    private quickDesignService: QuickDesignService,
    private elementService: ElementService,
    private styleService: StyleService,
    private myDesktopService: MyDesktopService,
    private clientService: ClientService,
    private storageService: StorageService,
    private route: ActivatedRoute,
    private router: Router,
    private imageService: ImageLibraryService,
    private userService: UserService,
    private pasteService: PasteService,
    private supportService: SupportService,
    private particaService: ParticaService,
    private ref: ChangeDetectorRef,
    private vaultService: VaultService,
    private campaignService: CampaignService,
    private domainService: DomainService,
    private progressService: ProgressService,
    private customCategoryService: CustomCategoryService,
    private toastr: ToastrService,
    private thirdPartyService: ThirdPartyService,
    private pricefinderService: PricefinderService,
    private idashboardService: IdashboardService,
    private propertySearchService: PropertySearchService,
    private globalService: GlobalService,
    private mScrollbarService: MalihuScrollbarService,
    private fb: FormBuilder,
    private FeatureFlagService: FeatureFlagService,
    private templateElementSettingService: TemplateElementSettingService,
    private templateGlobalSettingService: TemplateGlobalSettingService
  ) {
    const $this = this;

    window['onPdfCheck'] = function () {
      $this.onPdfDownloadCheck();
    };

    window['onModalLinkTypeChange'] = function () {
      $this.onModalLinkTypeChange();
    };

    window['onClickSend'] = function (link, val) {
      $this.onClickSend(link, val);
    };

    window['onAnchorPaste'] = function () {
      $this.onAnchorPaste();
    };

    window['onCheckAutoSave'] = function (event) {
      $this.onCheckAutoSave(event);
    };

    window['copyPriceFinderUrl'] = function (url: string) {
      copy(url);
    };

    const grapesJs = document.createElement('script');
    grapesJs.src = '/assets/grapesjs.0.14.25/grapes.js';
    document.getElementsByTagName('head')[0].appendChild(grapesJs);

    // Hide NPS Survey
    if(window.document.getElementsByClassName('sm-survey').length)
      window.document.getElementsByClassName('sm-survey')[0].remove();
  }

  ngOnInit() {

    this.elementPropertyForm = this.fb.group({
      icons: this.fb.array([
        this.fb.group({
          label: '',
          is_show: '',
          position: '',
          img: ''
        })
      ])
    });

    this.tagifyTemplateTags = new Tagify(document.querySelector("#templateTags"), {});

    this.authUser = this.frontService.authService.auth;
    this.clientAgents =  this.authUser.client.users;
    this.supportLink = this.frontService.supportLink;
    const $this = this;
    this.client = this.authUser.client;
    this.loading = true;
    this.frontService.fullPageContent = true;
    this.frontService.loadMenu(this.menus);
    this.isHarcourtsAu = this.FeatureFlagService.checkGroup('harcourts', this.authUser);

    this.clientSetting = this.frontService.authService.auth.client;
    this.groupSetting = this.clientSetting.group.master;
    this.propertySearchService.propertyInsertSource = null;

    this.templateAgent = this.authUser.user;
    this.vaultContentId = this.route.snapshot.queryParamMap.get('contentid');
    this.showInactiveFlag = this.frontService.globalEmitterService.getShowInactiveFlag();

    if (this.vaultContentId !== '' && this.vaultContentId !== null) {
      this.isVaultMail = true;
      this.isVaultFirstLoad = true;
    }
    this.isHighResPdf = false;
    this.designlyMailId = this.route.snapshot.queryParamMap.get('internal');
    if (this.designlyMailId === 'create') {
      this.isDesignlyMail = true;
    }

    if (this.authUser.brandid === '6') {
      this.mainColor = '#FFB914';
    } else if (this.frontService.appConfig.SKIN === 'raywhite') {
      this.mainColor = '#FFE512';
    } else if (this.frontService.appConfig.SKIN === 'prd') {
      this.mainColor = '#D80000';
    } else {
      this.mainColor = '#37aae1';
    }

    this.pasteService.paste_alert = this.authUser.user.paste_alert;

    if (this.authUser.provider === 'domain') {
      this.s2CampaignData = <any>[
        { id: 'Sales', text: 'Sales' },
        { id: 'Rental', text: 'Rental' },
        { id: 'Share', text: 'Share' },
        { id: 'Sold', text: 'Sold' },
        { id: 'NewHomes', text: 'New Homes' },
      ];
    } else if (this.authUser.provider === 'vaultre') {
      this.s2CampaignData = <any>[
        { id: 'Sales', text: 'Sales' },
        { id: 'Rental', text: 'Rental' },
        { id: 'Commercial', text: 'Commercial' },
        { id: 'Commercial Lease', text: 'Commercial Lease' },
        { id: 'Rural', text: 'Rural' },
        { id: 'Land', text: 'Land' },
        { id: 'Leased', text: 'Leased' },
        { id: 'Sold', text: 'Sold' },
        { id: 'Under Offer/Conditional', text: 'Under Offer/Conditional' },
        { id: 'Business', text: 'Business' },
      ];

      this.vaultService.httpGetBranches();
      this.vaultService.httpGetPropertyTypes();
      this.vaultService.httpGetPrecincts();
      this.vaultService.httpGetAccountRegions();
      this.vaultService.httpGetAccountBranches();
    } else {
      this.s2CampaignData = <any>[
        { id: 'Sales', text: 'Sales' },
        { id: 'Rental', text: 'Rental' },
        { id: 'Sold', text: 'Sold' },
      ];
    }

    this.s2CampaignOptions = {
      width: '100%',
      placeholder: 'Select a Campaign Type',
      minimumResultsForSearch: -1,
      multiple: true,
    };

    if (this.authUser.is_master) {
      this.isMaster = true;
    }

    if (this.authUser.agentid === '7357' && this.authUser.provider === 'vaultre') {
      this.isMaster = true;
    }
    // maintain flag even when refreshed on the builder
    if(localStorage.getItem('isDefaultHarcourts') && localStorage.getItem('isDefaultHarcourts') == 'yes') {
      this.quickDesignService.quickdesignCategoryLabels.printables = 'PDF';
    } 

    this.campaignService.campaignGetAll.takeUntil(this.destroy$).subscribe((response: any) => {
      if (typeof response !== 'undefined' && response.status === 'success') {
        this.campaigns = response.data;
        let campaignData = [];

        if (this.authUser.provider === 'domain') {
          campaignData = [
            { id: 'Sales', text: 'Sales' },
            { id: 'Rental', text: 'Rental' },
            { id: 'Share', text: 'Share' },
            { id: 'Sold', text: 'Sold' },
            { id: 'NewHomes', text: 'New Homes' },
          ];
        } else if (this.authUser.provider === 'vaultre') {
          campaignData = [
            { id: 'Sales', text: 'Sales' },
            { id: 'Rental', text: 'Rental' },
            { id: 'Commercial', text: 'Commercial' },
            { id: 'Commercial Lease', text: 'Commercial Lease' },
            { id: 'Rural', text: 'Rural' },
            { id: 'Land', text: 'Land' },
            { id: 'Leased', text: 'Leased' },
            { id: 'Sold', text: 'Sold' },
            { id: 'Under Offer/Conditional', text: 'Under Offer/Conditional' },
            { id: 'Business', text: 'Business' },
          ];
        } else {
          campaignData = [
            { id: 'Sales', text: 'Sales' },
            { id: 'Rental', text: 'Rental' },
            { id: 'Sold', text: 'Sold' },
          ];
        }

        this.campaigns.forEach((campaign) => {
          campaignData.push({ id: `custom_${campaign.ID}`, text: campaign.name });
        });

        const newCampaigns = campaignData.map((el) => {
          return { id: el.id, text: el.text };
        });

        this.s2CampaignData = <any>newCampaigns;
      }
    });

    this.campaignService.campaignPostTemplate.takeUntil(this.destroy$).subscribe((response: any) => {
      if (typeof response !== 'undefined' && response.status === 'success') {
        $this.saveHtml(this.editor);
        // swal(
        //   'Saved to Selected Categories',
        //   '',
        //   'success'
        // );
        this.s2CampaignValue = '';
        $('#selectCampaign').modal('hide');

        this.ref.detectChanges();
        this.isUserHasChanges = false;
      }
    });

    this.vaultService.onVaultGetMailoutData.takeUntil(this.destroy$).subscribe((response: any) => {
      if (typeof response !== 'undefined' && response.status === 'success') {
        this.isVaultMail = true;
        this.selectedListings = response.data;

        this.editor.runCommand('populate-listing');
      }
      if (typeof response !== 'undefined' && response.status === 'error') {
        swal({
          title: 'Vault Content Invalid',
          text: 'Contact VaultRE for more information',
          showConfirmButton: false,
          allowEscapeKey: false,
          allowOutsideClick: false,
          type: 'error',
        });
      }
    });

    const tempAgentUsers = this.authUser.client.users.slice(0);
    this.clientAgents = tempAgentUsers.sort(function (a, b) {
      const x = a.firstname.toLowerCase();
      const y = b.firstname.toLowerCase();
      return x < y ? -1 : x > y ? 1 : 0;
    });

    this.imageService.is_builder = true;

    this.s2CampaignOptions = {
      width: '100%',
      placeholder: 'Select a Campaign Type',
      minimumResultsForSearch: -1,
      multiple: true,
    };

    if (this.authUser.is_master) {
      this.isMaster = true;
    }

    if (this.authUser.agentid === '7357' && this.authUser.provider === 'vaultre') {
      this.isMaster = true;
    }



    this.route.params.subscribe((params: Params) => {
      this.id = this.storageService.template_id = +params['id'];
      this.category = params['type'];

      this.templateService.httpGetTemplate(this.id);
    });

    this.templateService.templateChanged.takeUntil(this.destroy$).subscribe((response: any) => {
      if (response && typeof response.status !== 'undefined') {
        if (response.status === 'success') {

          this.template = <Template>response.data;
          if (this.template) {
            if(this.template.quickdesign && Array.isArray(this.template.quickdesign) && this.template.quickdesign.length === 0){
              this.template.quickdesign = null;
            }
            if (this.template.category_id !== this.templateCategoryId.EMARKETING) {
              /* Get global template info */
              this.templateGlobalSettingService.getUserGlobalTemplate(this.template.ID, this.template.user_id);
              this.templateGlobalSettingService.getGlobalTemplate(this.template.master_id);
              /* End of Get global template info */
            }

            if (this.template.user_agent_id !== this.templateAgent.id) {
              const agentIndex = this.clientAgents.findIndex((c) => c.id === this.template.user_agent_id);
              if (typeof agentIndex !== 'undefined' && agentIndex !== -1) {
                this.templateAgent = this.clientAgents[agentIndex];
              }
            }
            
            this.customCategoryForm.patchValue({
              title: this.template.title
            });

            $this.uploadCabinateForm.patchValue({
              title: this.template.title,
            });

            if(this.template.master.puppeteer){
              $this.uploadCabinateForm.patchValue({
                resolutionFilter: 'for-print',
              });
            }

            if (this.template.media_attribute.size === 'custom') {
              // add 2.65mm or 10px
              const width = String(this.template.media_attribute.custom_width);
              let custom_width: any;
              if (width.split('px').length > 1) {
                const _width = width.split('px');
                // tslint:disable-next-line:radix
                custom_width = parseInt(_width[0]) + 10 + 'px';
              }

              if (width.split('mm').length > 1) {
                const _width = width.split('mm');
                // tslint:disable-next-line:radix
                custom_width = parseInt(_width[0]) + 2.65 + 'mm';
              }

              const height = String(this.template.media_attribute.custom_height);
              let custom_height: any;
              if (height.split('px').length > 1) {
                const _height = height.split('px');
                // tslint:disable-next-line:radix
                custom_height = parseInt(_height[0]) + 10 + 'px';
              }

              if (height.split('mm').length > 1) {
                const _height = height.split('mm');
                // tslint:disable-next-line:radix
                custom_height = parseInt(_height[0]) + 2.65 + 'mm';
              }

              this.template_custom_attribute = {
                name: this.template.media_attribute.slug,
                width: custom_width,
                height: custom_height,
              };
            }

            this.elementArgGroupId = this.template.group_id;
            if (this.template.master && this.template.group_id !== this.template.master.group_id) {
              this.elementArgGroupId = this.template.master.group_id;
            }

            const elementServiceArgs = {
              category_id: this.template.category_id,
              client_id: [0, this.client.ID],
              group_id: this.elementArgGroupId,
              media_attribute_id: this.template.media_attribute_id,
              department_id: this.template.department_id,
              is_active: 1,
              provider: ['all', this.authUser.provider],
              group_sub_group_id: this.clientSetting.group_sub_group_id,
            };

            if (this.template.master.show_element === 1) {
              elementServiceArgs.client_id = [0];
            } else if (this.template.master.show_element === 2) {
              elementServiceArgs.client_id = [this.client.ID];
            }

            this.campaignService.httpGetCampaigns({
              type: 'custom',
            });
            this.elementService.httpGetElements(elementServiceArgs);

            this.isPreloadedCampaign = this.template.preloadedCampaign;
            this.isPreloadedTemplate = this.template.preloadedTemplate;

            this.template.listings.forEach((listing) => {
              const _listing = {};

              _listing['listing_id'] = listing.listing_id;

              if (typeof listing.query_params !== 'undefined' && listing.query_params) {
                _listing['query_params'] = JSON.parse(listing.query_params);
              }

              this.templateListings.push(_listing);
            });

          }

          if (typeof this.template.campaign_categories !== 'undefined' && this.template.campaign_categories) {
            this.s2CampaignValue = this.template.campaign_categories.map((el) => el.category);
          }
        }
        if (response.status === 'error') {
          swal('Template not found', '', 'error');
          return this.router.navigateByUrl('/templates');
        }
      }
    });

    this.imageService.fileChanged.takeUntil(this.destroy$).subscribe((file: any) => {
      const imageLibraryModel = this.editor.getSelected();
      const imagePath = this.getLink(file);
      imageLibraryModel.set('src', imagePath);

      setTimeout(() => {
        this.mdLibrarySearchModalClose.nativeElement.click();
        // this.showImageLibrary = false;
        this.subLoading = false;

        this.ref.detectChanges();
      }, 200);
    });

    this.imageService.onUnsplashInsert.takeUntil(this.destroy$).subscribe((links: any) => {
      if (typeof links.download_location !== 'undefined' && links.download_location) {
        this.thirdPartyService.httpUnsplashDownload({
          url: links.download_location,
        });
        this.loading = true;
      }
    });

    this.thirdPartyService.onUnsplashDownload.takeUntil(this.destroy$).subscribe((response: any) => {
      this.loading = false;
      if (typeof response !== 'undefined' && response.status === 'success') {
        const imageLibraryModel = this.editor.getSelected();
        const emailCategories = [1, 2, 3, 7, 11, 13];

        let insertUnsplashUrl = `${response.data.url}`;

        if (emailCategories.includes(this.template.category_id)) {
          insertUnsplashUrl = `${response.data.url}&w=800`;
          imageLibraryModel.set('src', insertUnsplashUrl);

          setTimeout(() => {
            this.mdLibrarySearchModalClose.nativeElement.click();
            // this.showImageLibrary = false;
            this.subLoading = false;

            this.ref.detectChanges();
          }, 200);
        }

        if (!emailCategories.includes(this.template.category_id)) {
          swal({
            title: 'Image Resolution to Insert',
            type: 'info',
            showCancelButton: true,
            focusConfirm: false,
            confirmButtonText: 'Default 1080px',
            confirmButtonAriaLabel: 'Default 1080px',
            cancelButtonText: 'Raw',
            cancelButtonAriaLabel: 'Raw',
          })
            .then((result) => {
              if (result) {
                insertUnsplashUrl = `${response.data.url}&w=1080`;
                imageLibraryModel.set('src', insertUnsplashUrl);

                setTimeout(() => {
                  this.mdLibrarySearchModalClose.nativeElement.click();
                  // this.showImageLibrary = false;
                  this.subLoading = false;

                  this.ref.detectChanges();
                }, 200);
              }
            })
            .catch((result) => {
              imageLibraryModel.set('src', response.data.url);

              setTimeout(() => {
                this.mdLibrarySearchModalClose.nativeElement.click();
                // this.showImageLibrary = false;
                this.subLoading = false;

                this.ref.detectChanges();
              }, 200);
            });
        }
      }
      if (typeof response !== 'undefined' && response.status === 'error') {
        swal('Third Party Service Unavailable', 'Contact Designly', 'error');
      }
    });

    this.storageService.storageMigrateToS3.takeUntil(this.destroy$).subscribe((response: any) => {
      if (typeof response !== 'undefined' && response.status === 'success') {
        this.image_model.set('src', response.data.new_url);
        this.editor.Modal.close();

        swal(
          'Image Saved',
          // tslint:disable-next-line:max-line-length
          'Your image has been edited and saved but the image may still be loading, you can save your template and continue while the image is still loading.',
          'success'
        );
        $('.tui-editor-backdrop').css('display', 'none');

        this.ref.detectChanges();
      }
    });

    this.elementService.elementsChanged.takeUntil(this.destroy$).subscribe((elements: Element[]) => {
      if (typeof elements !== 'undefined' && elements.length > 0) {
        this.elements = elements;
        this.styleService.httpGetStyles({
          category_id: this.template.category_id,
          group_id: this.elementArgGroupId,
          media_attribute_id: this.template.media_attribute_id,
          department_id: this.template.department_id,
          client_id: [0, this.template.client_id],
        });

        this.loading = false;
      }
    });

    this.elementService.elementListingHtml.takeUntil(this.destroy$).subscribe((htmls: any) => {
      if (typeof htmls !== 'undefined' && htmls.status === 'success') {
        this.elementListingHtml = htmls.data;

        this.loading = false;
        this.subLoading = false;
        this.mainLoading = false;

        if (this.isVaultMail) {
          this.onSelectListings(true);
          this.isVaultPopulated = true;
        }

        this.ref.detectChanges();
      }
    });

    this.styleService.stylesChanged.takeUntil(this.destroy$).subscribe((styles: any) => {
      if (typeof styles !== 'undefined' && styles.status === 'success') {
        if (styles.data.length > 0) {
          styles = styles.data;

          let styleIndex = styles.findIndex((styl) => styl.client_id === this.template.client_id);

          if (styleIndex === -1) {
            styleIndex = 0;
          }

          if (styles[styleIndex].ckeditor_font_name && styles[styleIndex].ckeditor_font_family) {
            this.ckeditorFontName = styles[styleIndex].ckeditor_font_name.split('\n');
            this.ckeditorFontFamily = styles[styleIndex].ckeditor_font_family.split('\n');
          }

          if (this.template.master.enable_ckeditor === 1) {
            this.plugins = ['grapesjs-tui-editor', 'gjs-plugin-ckeditor'];
            this.ckeditor_active = true;
          } else {
            this.plugins = ['grapesjs-tui-editor'];
          }

          if (this.template.master.enable_contenteditable === 1) {
            this.enable_contenteditable = true;
          }

          const grapesScripts = [
            '/assets/grapesjs-tui-image-editor/grapesjs-tui-image-editor.min.js',
            '/assets/grapesjs-plugin-ckeditor/grapesjs-plugin-ckeditor.min.js',
            '/assets/ckeditor/ckeditor.js?v1.0.0'
          ];

          grapesScripts.forEach((script: string, i: number) => {
            const grapeScript = document.createElement('script');
            grapeScript.src = script;
            document.getElementsByTagName('head')[0].appendChild(grapeScript);
          });

          setTimeout(() => {
            this.initEditor();
            this.style = styles[styleIndex];
            this.initBuild();
            this.initStyle();

            if (this.isVaultMail) {
              this.loading = true;

              let currentHtml = this.editor.getHtml();
              let checkInspections = 0;
              currentHtml = $(currentHtml);

              if (currentHtml.has('.md-prop-field-opentime').length || currentHtml.has('.md-prop-field-multiple-opentimes').length ||
              currentHtml.has('.md-prop-field-opentimes-title').length ||
              currentHtml.has('.md-prop-field-multiple-opentimes-title').length) {
                checkInspections = 1;
              } else {
                checkInspections = 0;
              }

              this.vaultService.httpGeMailOutData(this.vaultContentId, {
                checkInspections: checkInspections,
              });
            }
          }, 1000);
        } else {
          let contactSupport = '';

          if (this.frontService.authService.getApp() === 'MyDesign3') {
            contactSupport = 'Contact MyDesign Support';
          } else {
            contactSupport = 'Contact Designly Support';
          }

          swal('Missing Builder Style', contactSupport, 'error');
        }
      }
    });

    this.templateService.templateBuild.takeUntil(this.destroy$).subscribe((template: any) => {
      if (typeof template.ID !== 'undefined') {
        this.loading = false;

        if (this.isSendToVault) {
          if (this.isShareToAllStaffMembers) {
            this.httpSendService();
          } else {
            const accessByIds: { id: number }[] = this.createAccessByIds(this.selectedStaffMembers);
            this.httpSendService(accessByIds);
          }

          this.isSendToVault = false;
        } else if (this.isPreloaded) {
          if (this.preloadedSaveType === 'template') {
            const msg = !this.isPreloadedTemplate ? 'Removed' : 'Added';

            swal(msg + ' as Preloaded Template', '', 'success');
          } else {
            const msg = !this.isPreloadedCampaign ? 'Removed' : 'Added';

            swal(msg + ' as Preloaded Campaign Template', '', 'success');
          }

          this.isPreloaded = false;
        } else if (this.isSendToDesignlyMail) {
          // tslint:disable-next-line: max-line-length
          return (window.location.href = `${this.frontService.appConfig.DESIGNLYMAIL_URL}/emarketing/campaigns/create?templateid=${this.template.ID}`);
        } else if (this.testMailSent) {
          this.templateService.httpTestEmail(this.template.ID, this.testEmailForm.value);

          this.testMailSent = false;
          this.loading = true;
        } else if (this.customCategorySave) {
          swal('Design saved as Template', 'This design can now be used within the <b>Use Template</b> section, also this design has been moved into the template folder for further use.', 'success');

          this.customCategorySave = false;
        } else if (this.viewBrowserButtonClicked) {
          window.open(`${this.frontService.appConfig.S3_URL}${this.authUser.provider}-html/${this.template.ID}/main.html`, '_blank');
          this.viewBrowserButtonClicked = false;
        } else if (this.viewCodeButtonClicked) {
          window.open(`${this.frontService.appConfig.S3_URL}${this.authUser.provider}-html/${this.template.ID}/source.html`, '_blank');
          this.viewCodeButtonClicked = false;
        } else {
          let swalTitle = 'Saved!';
          let swalContent = '';
          let serviceProvider = 'VaultRE';

          if (this.frontService.authService.isUk()) {
            serviceProvider = 'VaultEA';
          }

          if (this.template.category.slug === 'property-match') {
            swalContent = 'Your template has been saved and is available to generate PDF Flyers in ' + serviceProvider;
          } else if (this.template.category_id === 2 || this.template.category_id === 11) {
            swalTitle = 'Sent!';
            swalContent = 'Your ' + this.template.category.title + ' template is now available in ' + serviceProvider + ' to use.';
          } else if (this.isAutoSaved) {
            this.isAutoSaved = false;
            return this.toastr.success('Completed', 'Auto Saving', {
              timeOut: 2000,
              extendedTimeOut: 100,
            });
          } else {
            if (this.is_save) {
              return this.toastr.success('Your template has been saved.', 'Success', {
                timeOut: 2000,
                extendedTimeOut: 100,
              });
            } else {
              if (this.is_save) {
                this.is_save = false;
                return this.toastr.success('Your template has been saved.', 'Success', {
                  timeOut: 2000,
                  extendedTimeOut: 100,
                });
              } else {
                swalContent = 'Your template has been saved.';
              }
            }
          }

          if ($this.sharePriceFinderLinkClicked) {
            const priceFinderPdfUrl = $this.frontService.appConfig.S3_URL + template.additional_data.pdf_path;

              let defaultPriceFinderDisclaimer = 'Try pasting in an email';
              let sendButtonHtml = '';
              if (this.template.group.pricefinder_template_id) {
                defaultPriceFinderDisclaimer = 'Try pasting in an email or you can send with an email by clicking the button below';
                // tslint:disable-next-line: max-line-length
                sendButtonHtml = '<br/><h2 class="swal2-title" id="swal2-title">Send via email</h2><p>Create a simple email in Designly with share link button</p><button type="button" id="pf-send-email-btn" class="btn btn-primary">Create Email</button>';
              }

              return swal({
                title: 'Share Link',
                html: `<p>Anyone with the link can view this PDF</p>
                       <p class="sharelink-input">
                        <input type="text" class="form-control" value="${priceFinderPdfUrl}">
                        <a onclick="swal.clickConfirm();" class="btn btn-primary">COPY</a>
                       </p>
                       ${sendButtonHtml}
                       `,
                type: 'success',
                showConfirmButton: false,
                onBeforeOpen: () => {
                  if (this.template.group.pricefinder_template_id) {
                    const sendEmailBtn = document.getElementById('pf-send-email-btn');

                    sendEmailBtn.addEventListener('click', () => {
                      this.onPriceFinderSendEmail(priceFinderPdfUrl);
                      swal.clickConfirm();
                    });
                  }
                }
              }).then(() => {
                copy(priceFinderPdfUrl);
              })
              .catch(swal.noop);
          }

          let imageExtension = 'jpg';

          if (template.category_id === 6 || template.category_id === 7) {
            imageExtension = 'png';
          }
          if (this.pdfButtonClicked === true) {

            const body = this.editor.Canvas.getBody();
            let customWrapper = $(body).find('#wrapper > #customWrapper').html();
            /* Remove custom wrapper if it is existing after saving it in s3 */
            if (typeof customWrapper !== 'undefined' && customWrapper !== false) {
              $(body).find('#wrapper > #customWrapper > .md-box').unwrap();
            }

            const pdf_url = $this.frontService.appConfig.CACHED_S3_BUCKET_URL + template.additional_data.pdf_path;

            const link = document.createElement('a');
            link.href = pdf_url;
            link.dispatchEvent(new MouseEvent('click'));
            link.remove();

            this.pdfButtonClicked = false;
            
            swalTitle = 'Downloaded';
            swalContent = `Your template has been downloaded. <br>
              When printing PDF make sure to select the \'Fit to Page\' or \'Scale to Fit\' options to ensure print quality
              `;
            return this.toastr.success(swalContent, swalTitle, {
              enableHtml: true,
              timeOut: 4000,
              extendedTimeOut: 3000,
            });
          }

          if (this.imgButtonClicked === true) {
            const pdf_url = $this.frontService.appConfig.CACHED_S3_BUCKET_URL + template.additional_data.image_path;

            const link = document.createElement('a');
            link.href = pdf_url;
            link.dispatchEvent(new MouseEvent('click'));
            link.remove();

            this.imgButtonClicked = false;

            swalTitle = 'Downloaded';
            swalContent = 'Your template has been downloaded.';
            return this.toastr.success(swalContent, swalTitle, {
              enableHtml: true,
              timeOut: 4000,
              extendedTimeOut: 300,
            });
          }

          if (this.fbButtonClicked === true) {
            this.imageUrl = $this.frontService.appConfig.S3_URL + template.additional_data.image_path;

            window.open('https://www.facebook.com/sharer/sharer.php?u=' + this.imageUrl + '?_=' + new Date().getTime(), 'sharer', 'toolbar=0,status=0,width=626,height=436');
            this.fbButtonClicked = false;

            return true;
          }

          if (this.twitterButtonClicked === true) {
            this.imageUrl = $this.frontService.appConfig.S3_URL + template.additional_data.image_path;

            window.open('https://twitter.com/home?status=' + this.imageUrl + '?_=' + new Date().getTime(), 'sharer', 'toolbar=0,status=0,width=626,height=436');
            this.twitterButtonClicked = false;

            return true;
          }

          if (this.linkedinButtonClicked === true) {
            this.imageUrl = $this.frontService.appConfig.S3_URL + template.additional_data.image_path;

            window.open('https://www.linkedin.com/shareArticle?mini=true&url=' + this.imageUrl + '?_=' + new Date().getTime(), 'sharer', 'toolbar=0,status=0,width=626,height=436');
            this.linkedinButtonClicked = false;

            return true;
          }

          if (this.loading === false && this.savePopupShow === true) {
            swal({
              title: swalTitle,
              html: swalContent,
              type: 'success',
            }).catch(swal.noop);

            if (this.isVaultMail) {
              // tslint:disable-next-line: max-line-length
              const sendLink = `/cgi-bin/clientvault/emarketing/bulk.cgi?messagetype=designlyedm&jsoncontentid=${this.vaultContentId}&contenturl=${template.htmlPath}`;

              if (this.frontService.authService.isUk()) {
                window.location.href = `https://login.vaultea.co.uk${sendLink}`;
              } else {
                window.location.href = `https://login.vaultre.com.au${sendLink}`;
              }
            }

            if (this.isDesignlyMail) {
              // tslint:disable-next-line: max-line-length
              window.location.href = `${$this.frontService.appConfig.DESIGNLYMAIL_URL}/emarketing/campaigns/create?templateid=${this.template.ID}`;
            }
          }
          this.savePopupShow = true;
        }
      }
    });

    this.vaultService.onVaultSendTemplate.takeUntil(this.destroy$).subscribe((vault: any) => {
      if (typeof vault !== 'undefined' && vault.status === 'success') {
        this.loading = false;
        let templateId = this.template.ID;
        let defaultPlatform = 'MRI Vault';
        let defaultLink = 'vaultre.com.au';

        if (this.frontService.authService.isUk()) {
          defaultLink = 'vaultea.co.uk';
          templateId = vault.data.id;
        }

        if (localStorage.getItem('is_corporate') === 'yes') {
          defaultLink = 'clientvault.com';
        }

        this.templateService.httpGetTemplate(this.id);
        this.httpGetVaultTemplate(vault.data.id);
        this.isVaultTemplate = true;

        swal({
          title: `Template has been added/saved to your ${defaultPlatform} account`,
          type: 'success',
          html:
            // tslint:disable-next-line:max-line-length
            `<a target="_blank" href="https://login.${defaultLink}/cgi-bin/clientvault/emarketing/bulk.cgi?messagetype=designly&designlytemplateid=${vault.data.id}" class="btn btn-primary">View</a>`,
          showCancelButton: false,
          showConfirmButton: false,
          focusConfirm: false,
        });
      }

      if (vault.status === 'error') {
        this.loading = false;
        swal('Error while sending template', '', 'error');
      }
    });


    this.myDesktopService
    .myDesktopCustomParams
    .takeUntil(this.destroy$).subscribe((dataCustomParams: any) => {
      if (!Array.isArray(dataCustomParams) && typeof dataCustomParams.id !== 'undefined') {
        this.onUpdateListing(dataCustomParams);
      }
      this.ref.detectChanges();
    });

    this.templateService.templateTestEmail.takeUntil(this.destroy$).subscribe((response: any) => {
      if (typeof response !== 'undefined' && response.status === 'success') {
        this.loading = false;

        $('#sendTestEmail').modal('hide');

        swal('Test Email Sent', '', 'success');
      }
      if (typeof response !== 'undefined' && response.status === 'error') {
        this.loading = false;
        swal('Error while sending test email', '', 'error');
      }
    });

    this.libraryStorageForm = new FormGroup({
      type: new FormControl('owned'),
    });

    this.supportService.onScreenshot.subscribe((response: boolean) => {
      if (response) {
        this.showScreenshot = true;
        $('#supportModal').modal('hide');
      }
    });

    this.supportService.onProcessed.subscribe((response: boolean) => {
      if (response) {
        this.loading = false;
      }
    });

    this.imageService.onCloseLibrary.takeUntil(this.destroy$).subscribe((response) => {
      if (response) {
        // this.showImageLibrary = false;
        this.mdLibrarySearchModalClose.nativeElement.click();

        this.ref.detectChanges();
      }
    });

    this.particaService.onBuilderModalClose.takeUntil(this.destroy$).subscribe((response) => {
      if (response) {
        this.mdParticaSearchModalClose.nativeElement.click();
        this.showPartica = false;

        this.ref.detectChanges();
      }
    });

    this.particaService.onBuilderInsertArticle.takeUntil(this.destroy$).subscribe((response: any) => {
      if (typeof response !== 'undefined') {
        this.onSelectedArticle(response);
        this.mdParticaSearchModalClose.nativeElement.click();
        this.showPartica = false;

        this.ref.detectChanges();
      }
    });

    this.vaultService.onVaultGetListing.takeUntil(this.destroy$).subscribe((response: any) => {
      if (typeof response !== 'undefined' && typeof response.status !== 'undefined') {
        if (typeof response.data.sync !== 'undefined' && response.data.sync) {
          this.onUpdateListing(response.data);
        }
        this.ref.detectChanges();
      }
    });

    this.domainService
    .onGetDomainListing
    .takeUntil(this.destroy$).subscribe((response: any) => {
      if (typeof response !== 'undefined' && typeof response.status !== 'undefined') {
        if (typeof response.data.sync !== 'undefined' && response.data.sync) {
          this.onUpdateListing(response.data);
        }

        this.ref.detectChanges();
      }
    });

    this.idashboardService
    .onGetListing
    .takeUntil(this.destroy$).subscribe((response: any) => {
      if (typeof response !== 'undefined' && typeof response.status !== 'undefined') {
        if (typeof response.data.sync !== 'undefined' && response.data.sync) {
          this.onUpdateListing(response.data);
        }

        this.ref.detectChanges();
      }
    });

    this.templateService.templatePreloaded.takeUntil(this.destroy$).subscribe((response: any) => {
      if (typeof response !== 'undefined' && response.status === 'success') {
        $this.saveHtml(this.editor);

        $('#selectCampaign').modal('hide');
        this.s2CampaignValue = '';
      }
      if (typeof response !== 'undefined' && response.status === 'error') {
        swal('Error adding as Preloaded Template', 'Contact Designly', 'error');
        this.loading = false;

        this.isPreloaded = false;
        this.s2CampaignValue = '';
      }
    });

    this.customCategoryService.httpGetAll();

    this.customCategoryService.onGetAll.takeUntil(this.destroy$).subscribe((response: any) => {
      if (response) {
        if (typeof response !== 'undefined' && response.status === 'success') {
          this.customCategories = response.data;
        }
        if (typeof response !== 'undefined' && response.status === 'error') {
          swal('Error loading custom categories', 'Contact Designly', 'error');
        }
      }
    });

    this.quickDesignService.onAdd.takeUntil(this.destroy$).subscribe((response: any) => {
      if (response) {
        this.loading = false;
        if (typeof response !== 'undefined' && response.status === 'success') {
          this.template.quickdesign = response.data;

          $('.html-template-title').text(this.customCategoryForm.get('title').value);
          this.new_template_name = this.customCategoryForm.get('title').value;

          this.saveHtml(this.editor, '', true);

          $('#saveAsTemplateModal').modal('hide');
        }
        if (typeof response !== 'undefined' && response.status === 'error') {
          swal('Error', response.message, 'error');
        }
      }
    });

    this.quickDesignService.onDelete.takeUntil(this.destroy$).subscribe((response: any) => {
      if (response) {
        this.loading = false;
        if (typeof response !== 'undefined' && response.status === 'success') {
          this.template.quickdesign = null;
          swal('Template removed from Template', '', 'success');
        }
        if (typeof response !== 'undefined' && response.status === 'error') {
          swal('Error removing template from Template', 'Contact Designly', 'error');
        }
      }
    });

    this.quickDesignService.onGetGroupsByCategory.takeUntil(this.destroy$).subscribe((response: any) => {
      if (response) {
        this.loading = false;
        if (typeof response !== 'undefined' && response.status === 'success') {
          this.quickDesignGroups = response.data;
        }
        if (typeof response !== 'undefined' && response.status === 'error') {
          swal('Internal Server Error', 'Contact Designly', 'error');
        }
      }
    });

    this.pricefinderService.onGenerateReport.takeUntil(this.destroy$).subscribe((response: any) => {
      if (response) {
        this.mdPricefinderSearchModalClose.nativeElement.click();
        this.generatePriceFinderReport(response);
      }
    });

    this.pricefinderService.onGenerateMap.takeUntil(this.destroy$).subscribe((response: any) => {
      if (response && typeof response.status !== 'undefined') {
        if (response.status === 'success') {
          this.priceFinderSuburbData.suburb['map'] = response.data.map_image;

          this.progressService.progress(4 / 4);
          this.onGeneratePricefinderReport(this.priceFinderSuburbData);
        }
      }
    });

    this.pricefinderService.onGetStats.takeUntil(this.destroy$).subscribe((response: any) => {
      if (response && typeof response.status !== 'undefined') {
        if (response.status === 'success') {
          this.priceFinderSuburbData.stats = response.data;
        }

        this.pricefinderService.httpGetSegmentations(this.priceFinderSuburbData.suburb.suburb.id, this.priceFinderSuburbData.requestData, this.priceFinderSuburbData.queryData);
        this.progressService.progress(1 / 4);
      }
    });

    this.pricefinderService.onGetSegmentations.takeUntil(this.destroy$).subscribe((response: any) => {
      if (response && typeof response.status !== 'undefined') {
        if (response.status === 'success') {
          this.priceFinderSuburbData.segmentations = response.data;
        }

        this.pricefinderService.httpGetStatsChart(this.priceFinderSuburbData.suburb.suburb.id, this.priceFinderSuburbData.requestData, this.priceFinderSuburbData.queryData);
        this.progressService.progress(2 / 4);
      }
    });

    this.pricefinderService.onGetStatsChart.takeUntil(this.destroy$).subscribe((response: any) => {
      if (response && typeof response.status !== 'undefined') {
        if (response.status === 'success') {
          this.priceFinderSuburbData.stats['chart'] = response.data;
        }

        const mapProperties = [];

        this.priceFinderSuburbData.listings.forEach((listing, index) => {
          mapProperties.push({
            location: listing.location,
            label: index + 1,
          });
        });

        this.pricefinderService.httpGenerateMap({
          map: this.priceFinderMapValues,
          suburb: `${this.priceFinderSuburbData.suburb.suburb.suburbName}, Australia`,
          center_location: this.priceFinderSuburbData.suburb.suburb.location,
          properties: mapProperties,
        });
        this.progressService.progress(3 / 4);
      }
    });

    this.templateService.templateDuplicate
    .takeUntil(this.destroy$)
    .subscribe(
      (template: Template) => {
        if (typeof template.ID !== 'undefined') {
          // Accomodate Replica Lag
          setTimeout(() => {
            this.loading = false;
            window.location.href = `/templates/${template.ID}/build?pricefinder_url=${this.priceFinderPdfUrl}`;
          }, 2000);
        }
      }
    );

    this.propertySearchService.onInsertListings
    .takeUntil(this.destroy$)
    .subscribe(
      (listings: []) => {
        this.selectedListings = listings;
        if (this.insertPropertyButton) {
          this.onSelectListingsInsertButton(true);  // insert listing using insert button for bayleys
        } else {
          this.onSelectListings(true);  // insert listing original functionality
        }
      }
    );

    this.propertySearchService.onS2Changed
    .takeUntil(this.destroy$)
    .subscribe((response: any) => {
      if (response) {
        if (typeof response.type !== 'undefined' && response.type) {
          if (response.type === 's2StatusValue') {
            this.s2StatusValue = response.value;
          }
        }
      }
    });

    
    this.storageService.onUploadBase64
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (response) {
          if (typeof response.status !== 'undefined')  {
            this.loading = false;
            if (response.status === 'success') {
              const selectedComponent = this.editor.getSelected();

              selectedComponent.set({
                src: response.data
              });
            } else {
              swal({
                title: 'Failed to crop',
                type: 'error'
              });
            }
          }
        }
      }
    );

    this.testEmailForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
    });

    this.uploadCabinateForm = new FormGroup({
      title: new FormControl('', [Validators.required]),
      folder: new FormControl('', [Validators.required]),
      resolutionFilter: new FormControl(''),
    });

    this.customCategoryForm = new FormGroup({
      'reference': new FormControl('', Validators.required),
      'quick_design_group_id': new FormControl(''),
      'description': new FormControl(''),
      'title': new FormControl('', Validators.required),
    });

    
    this.cropImageModal = new bootstrap.Modal(document.getElementById('cropImageModal'));

    this.templateGlobalSettingService.userGlobalTemplateChanged
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (response.data.classes) {
          (JSON.parse(response.data.classes)).forEach((obj) => {
            this.userGlobalSettings.push({
              id: obj.id,
              class: obj.class,
            });
            this.tmpUserGlobalClass.push(obj.class);
          });
        }
      }
    );
    
    this.templateGlobalSettingService.globalTemplateChanged
    .takeUntil(this.destroy$)
    .subscribe(
      (response: GlobalTemplate) => {
        this.userGlobalSettings = [];
        this.globalSettings = new FormArray([]);
        if (response.data.length > 0) {
          response.data.forEach((obj: RowGlobalSetting & GroupedGlobalSetting, index: number) => {
            let settingItem = new FormArray([]);
            if (obj.group_id != null) { //grouped global settings
              obj.data.forEach((grpItem: any, indx: number) => {
                let isClassExist = false;
                let dropdownOptions = new FormArray([]);
                //find class id in user_global_setting
                if (grpItem.settings_type == 0) { //check if dropdown option class is in user global setting
                  if (Array.isArray(grpItem.settings_dropdown_options)) {
                    grpItem.settings_dropdown_options.forEach((data: any, idx: number) => {
                      let optIdx = grpItem.settings_dropdown_options.findIndex((optVal) => {
                        let isInUsrGlbSttng = false;
                        this.userGlobalSettings.forEach((usrGblSttng) => {
                          if (grpItem.id === usrGblSttng.id && optVal.class_name == usrGblSttng.class) {
                            isInUsrGlbSttng = true;
                          }
                        });
                        return isInUsrGlbSttng;
                      });
                      dropdownOptions.push(
                        new FormGroup({
                          id: new FormControl(data.id),
                          title: new FormControl(data.title),
                          description: new FormControl(data.description),
                          class_name: new FormControl(data.class_name),
                          is_class_exists: new FormControl((optIdx >= 0 ? true : false))
                        })
                      );
                    });
                  }
                } else {// toggle setting 
                  let userIdxSetting = this.userGlobalSettings.findIndex((val) => {
                    return val.id == grpItem.id && val.class == grpItem.class_name ? true : false
                  });
                  isClassExist = userIdxSetting >= 0 ? true : isClassExist;
                }

                settingItem.push(new FormGroup({
                  id: new FormControl(grpItem.id),
                  title: new FormControl(grpItem.title),
                  description: new FormControl(grpItem.description),
                  settings_type: new FormControl(grpItem.settings_type),
                  class_name: new FormControl(grpItem.class_name),
                  is_class_exists: new FormControl(isClassExist),
                  dropdown_options: dropdownOptions
                }));
              });
              this.globalSettings.push(
                new FormGroup({
                  group_name: new FormControl(obj.group_name),
                  group_id: new FormControl(obj.group_id),
                  data: settingItem
                })
              );
            } else { //non-group global template settings
              let dropdownOptions = new FormArray([]);
              let isClassExist = false;
              if (obj.settings_type == 0) { //check if dropdown option class is in user global setting
                if (Array.isArray(obj.settings_dropdown_options)) {
                  obj.settings_dropdown_options.forEach((option: any, idx: number) => {
                    let optIdx = obj.settings_dropdown_options.findIndex((optVal) => {
                      let isInUsrGlbSttng = false;
                      this.userGlobalSettings.forEach((usrGblSttng) => {
                        if (obj.id === usrGblSttng.id && optVal.class_name == usrGblSttng.class) {
                          isInUsrGlbSttng = true;
                        }
                      });
                      return isInUsrGlbSttng;
                    });
                    dropdownOptions.push(
                      new FormGroup({
                        id: new FormControl(option.id),
                        title: new FormControl(option.title),
                        description: new FormControl(option.description),
                        class_name: new FormControl(option.class_name),
                        is_class_exists: new FormControl((optIdx >= 0 ? true : false))
                      })
                    );
                  });
                }

              } else {
                let userIdxSetting = this.userGlobalSettings.findIndex((val) => {
                  return val.id == obj.id && val.class == obj.class_name ? true : false
                });
                isClassExist = userIdxSetting >= 0 ? true : isClassExist;
              }

              this.globalSettings.push(new FormGroup({
                id: new FormControl(obj.id),
                title: new FormControl(obj.title),
                description: new FormControl(obj.description),
                settings_type: new FormControl(obj.settings_type),
                class_name: new FormControl(obj.class_name),
                dropdown_options: dropdownOptions,
                is_class_exists: new FormControl(isClassExist)
              }));
            }
          });
        }
      }
    );

    this.templateService.vaultDeleteTemplate.takeUntil(this.destroy$).subscribe({
      next: (response: any) => {
        switch (response.status) {
          case 'success':
            this.shareWithEveryone = false;

            swal({
              title: 'Template successfully removed from Vault.',
              type: 'success',
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              confirmButtonText: 'OK',
            })
              .then(() => {
                this.isVaultTemplate = false;
                this.selectedStaffMembers = [];
                this.defaultUserForSendVre(this.authUser.user.agent_id);
              })
              .catch(swal.noop);
            break;
          case 'error':
            swal(response.message, '', 'error');
            break;
          default:
            break;
        }

        this.loading = false;
      },
      error: () => {
        this.loading = false;
      },
    });

    this.templateService.vaultGetTemplate.takeUntil(this.destroy$).subscribe({
      next: (response: any) => {
        if (response.status === 'success' && response.data) {
          this.isVaultTemplate = true;

          if (response.data.accessBy.length === 0) {
            this.shareWithEveryone = true;
          } else {
            this.selectedStaffMembers = [];
            this.vaultShareUsers.forEach(users => {
              let isUserSelected = response.data.accessBy.find(accessbyUsers => accessbyUsers.id.toString() === users.agent_id);

              if (isUserSelected) {
                users.isSelected = true;
                this.selectedStaffMembers.push(users.agent_id);
              }
            });
          }
        } else if (response.status === 'success' && !response.data) {
          this.defaultUserForSendVre(this.authUser.user.agent_id)
        }
        
        this.loading = false;
      },
      error: () => {
        this.loading = false;
      },
    });

    this.vaultService.onPushToFileCabinate.takeUntil(this.destroy$).subscribe((response: any) => {
      if (response) {
        this.loading = false;
        if (typeof response !== 'undefined' && response.status === 'success') {
          swal({
            title: `Successfully uploaded the file to the cabinet.`,
            type: 'success',
            html: response.data ? `<a target="_blank" href="${response.data}" class="btn btn-primary">Go To Property</a>` : '',
            showCancelButton: false,
            showConfirmButton: response.data ? false : true,
            focusConfirm: false,
            showCloseButton: true
          });
        }
        if (typeof response !== 'undefined' && response.status === 'error') {
          swal('Something went wrong while uploading the file to the cabinet.', 'Contact Designly', 'error');
        }
      }
    });
  }

  @HostListener('window:beforeunload', ['$event'])
  showAlertForUnsaved($event: any) {
    if (this.isUserHasChanges) {
      $event.returnValue = true;
    }
  }

  canDeactivate(): boolean {
    if (this.isUserHasChanges) {
      return window.confirm('You have unsaved changes! Are you sure you want to leave?');
    }
    return true; 
  }

  initEditor() {
    const $this = this;

    this.template_devices = [
      {name: 'Desktop', width: '900px' },
      {name: 'Desktop view', width: '901px' },
      {name: 'Mobile portrait', width: '330px'},
      {name: 'A4 portrait', width: '800px'},
      {name: 'A4 landscape', width: '1104px'},
      {name: 'A3 portrait', width: '1096px'},
      //{name: 'A3 landscape', width: '97%'},
      {name: 'A3 landscape', width: '1550px'},
      {name: 'FB cover', width: '828px', height: '315px'},
      {name: 'FB post', width: '940px', height: '788px'},
      {name: 'TW post', width: '1024px', height: '512px'},
      {name: 'IG post', width: '1084px'},
      {name: 'DL card', width: '800px'},
      {name: 'Portrait 1200x1800', width: '197mm' },
      {name: 'Portrait 1200x2400', width: '1178px' },
      {name: 'Landscape 1800x1200', width: '1168px' },
      {name: 'Landscape 1750x1130', width: '1168px' },
      this.template_custom_attribute
    ];

    if ( this.template.master.puppeteer ) {
        this.template_devices = [
          {name: 'Desktop', width: '900px' },
          {name: 'Desktop view', width: '901px' },
          {name: 'Mobile portrait', width: '330px'},
          {name: 'A4 portrait', width: '800px', /* height: '1130px' */},
          {name: 'A4 landscape', width: '1130px', /* height: '799px' */ },
          {name: 'A3 portrait', width: '1121px', /* height: '1586px' */ },
          //{name: 'A3 landscape', width: '97%', /* height: '1120px' */ },
          {name: 'A3 landscape', width: '1550px', /* height: '1120px' */ },
          {name: 'FB cover', width: '828px', height: '315px'},
          {name: 'FB post', width: '940px', height: '788px'},
          {name: 'TW post', width: '1024px', height: '512px'},
          {name: 'IG post', width: '1084px'},
          {name: 'DL card', width: '800px'},
          {name: 'Portrait 1200x1800', width: '197mm' },
          {name: 'Portrait 1200x2400', width: '1178px' },
          {name: 'Landscape 1800x1200', width: '1168px' },
          {name: 'Landscape 1750x1130', width: '1168px' },
          this.template_custom_attribute
        ];
    }

    this.editor = grapesjs.init({
      container: '#builder-html',
      height: '100%',
      panels: {
        defaults: [],
      },
      deviceManager: {
        devices: this.template_devices,
      },
      storageManager: {
        type: 'remote',
        autosave: false,
        autoload: false,
      },
      autorender: false,
      plugins: this.plugins,
      pluginsOpts: {
        'grapesjs-tui-editor': {
          config: {
            includeUI: {
              menuBarPosition: 'top',
            },
          },
          labelApply: 'Insert',
          hideHeader: 1,
          onApply: function (imageEditor, imageModel) {
            $this.image_editor = imageEditor;
            $this.image_model = imageModel;

            const migrateData = [];
            migrateData['template_id'] = $this.template.ID;
            migrateData['client_id'] = $this.template.client_id;
            migrateData['file'] = imageEditor.toDataURL({
              format: 'png',
            });

            $this.storageService.httpPostMigrateToS3(migrateData);
            $('.tui-editor-backdrop').css('display', 'block');
          },
          apiUrl: this.frontService.appConfig.API_ENDPOINT,
        },
      },
      copyPaste: false,
      avoidInlineStyle: false,
      keepEmptyTextNodes: true,
      forceClass: false,
      showDevices: false,
    });

    if (this.ckeditor_active) {
      CKEDITOR.config.coreStyles_bold = { element: 'b', overrides: 'strong' };
      CKEDITOR.on('dialogDefinition', function (ev) {
        try {
          const dialogName = ev.data.name;
          const dialogDefinition = ev.data.definition;

          if (dialogName === 'link') {
            const informationTab = dialogDefinition.getContents('target');
            const targetField = informationTab.get('linkTargetType');
            targetField['default'] = '_blank';
          }
        } catch (exception) {
          alert('Error ' + ev.message);
        }
      });

      let defaultColors = ['1ABC9C', '2ECC71', '3498DB', '9B59B6', '4E5F70', 'F1C40F', '16A085', '27AE60', '2980B9', '8E44AD', '2C3E50', 'F39C12', 'E67E22', 'E74C3C', 'ECF0F1', '95A5A6', 'DDD', 'FFF', 'D35400', 'C0392B', 'BDC3C7', '7F8C8D', '999', '000'];

      if (typeof this.clientSetting.group.builder_ckeditor_font_and_background_color !== 'undefined') {
        if (this.clientSetting.group.master_client_id === this.clientSetting.ID && this.clientSetting.group.builder_ckeditor_font_and_background_color === 3) {
          defaultColors = [];
        }
        if (this.clientSetting.group.master_client_id !== this.clientSetting.ID && this.clientSetting.group.builder_ckeditor_font_and_background_color === 2) {
          defaultColors = [];
        }
        if (this.clientSetting.group.builder_ckeditor_font_and_background_color === 0) {
          defaultColors = [];
        }
      }

      let customColors = [];

      for (let i = 1; i < 11; i++) {
        if (this.clientSetting && typeof this.clientSetting !== 'undefined' && typeof this.clientSetting[`color_${i}`] !== 'undefined' && this.clientSetting[`color_${i}`]) {
          customColors.push(this.clientSetting[`color_${i}`].replace('#', ''));
        } else {
          if (this.groupSetting && typeof this.groupSetting !== 'undefined' && typeof this.groupSetting[`color_${i}`] !== 'undefined' && this.groupSetting[`color_${i}`]) {
            customColors.push(this.groupSetting[`color_${i}`].replace('#', ''));
          }
        }
      }

      customColors = customColors.filter((x, i, a) => a.indexOf(x) === i);
      const finalFontColors = [...customColors, ...defaultColors];

      CKEDITOR.config.colorButton_colors = finalFontColors.join(',');

      const defaultFontSize = ['8/8px', '9/9px', '10/10px', '11/11px', '12/12px', '14/14px', '16/16px', '18/18px', '20/20px', '22/22px', '24/24px', '26/26px', '28/28px', '36/36px', '48/48px', '72/72px', '84/84px', '96/96px', '128/128px'];

      CKEDITOR.config.fontSize_sizes = defaultFontSize.join(';');

      const defaultFontName = ['Arial/Arial, Helvetica, sans-serif', 'Comic Sans MS/Comic Sans MS, comic sans, sans-serif', 'Courier New/Courier New, Courier, monospace', 'Georgia/Georgia, serif', 'Lucida Sans Unicode/Lucida Sans Unicode, Lucida Grande, sans-serif', 'Tahoma/Tahoma, Geneva, sans-serif', 'Times New Roman/Times New Roman, Times, serif', 'Trebuchet MS/Trebuchet MS, Helvetica, sans-serif', 'Verdana/Verdana, Geneva, sans-serif'];
      let customFonts = [];

      if (this.ckeditorFontName && this.ckeditorFontFamily) {
        this.ckeditorFontFamily.map((fontFamily, index) => {
          if (this.ckeditorFontName[index]) {
            customFonts.push(this.ckeditorFontName[index] + '/' + fontFamily);
          } else {
            const fontName = fontFamily.split(',');
            customFonts.push(fontName[0] + '/' + fontFamily);
          }
        });
      }

      const finalFontName = [...customFonts , ...defaultFontName];

      CKEDITOR.config.font_names = finalFontName.join(';');

      CKEDITOR.config.forcePasteAsPlainText = true;
      CKEDITOR.config.pasteFromWordRemoveFontStyles = true;
      CKEDITOR.config.pasteFromWordRemoveStyles = true;
      CKEDITOR.config.removeFormatAttributes  = true;
      CKEDITOR.on('instanceReady', function (e) {
        const editor = e.editor;
        editor.on('paste', function (ev) {
          /* Slice a text from start till the max character count when do Ctrl + V */
          let maxCharacterCount = ev.editor.element.getAttribute('data-max-character-count');
          if (maxCharacterCount != null) {
            setTimeout(() => {
              let activeEditorText = this.getData();
              if (activeEditorText.length >= maxCharacterCount) {
                  this.setData(activeEditorText.slice(0, maxCharacterCount));
              }
            }, 100);
          }
        });

        if (editor.container.$.dataset.disableEditor == 1) {
          /* Hiding of element to prevent console error */
          document.getElementById(e.editor.id + '_top').style.display = 'none';
        }

        editor.disableAutoInline = true;

        editor.on('focus', (evt) => {
          let activeEditor = editor.getSelection().root.editor;
          /* Keypress to use the preventDefault when exceed with the limit */
          evt.editor.document.on('keypress', function (evnt) {
            $this.isUserHasChanges = true;
            let editorText = evnt.data.$.target.innerText;
            let maxCharacterCount = activeEditor.document.getActive().getAttribute('data-max-character-count');
            if (maxCharacterCount != null && editorText.length >= maxCharacterCount) {
              evnt.data.preventDefault();
            }
          });

          /* Undo and Redo command of ckeditor */
          editor.on('afterCommandExec', function (event) {
            let maxCharacterCount = event.editor.element.getAttribute('data-max-character-count');
            if (maxCharacterCount != null) {
              if (['undo','redo'].includes(event.data.name)) {
                let activeEditorText = this.getData();
                if (activeEditorText.length >= maxCharacterCount) {
                  this.setData(activeEditorText.slice(0, maxCharacterCount));
                }
              }
            }
          });
        });
      });
    }

    const comps = this.editor.DomComponents;

    const originalTable = comps.getType('table');
    const originalCell = comps.getType('cell');
    const originalRow = comps.getType('row');
    const originalTBody = comps.getType('tbody');

    const originalImage = comps.getType('image');
    const originalDefault = comps.getType('default');
    const originalText = comps.getType('text');
    const originalTextNode = comps.getType('textnode');

    const originalLink = comps.getType('link');

    comps.getWrapper().set({ badgable: false, highlightable: false, hoverable: false });

    comps.addType('comment', {
      model: originalTextNode.model.extend(
        {
          toHTML() {
            return `<!--${this.get('content')}-->`;
          },
        },
        {
          isComponent(el) {
            if (el.nodeType === 8) {
              return {
                tagName: 'NULL', // just need this to avoid some parser rule
                type: 'comment',
                content: el.textContent,
              };
            }
          },
        }
      ),
      view: originalTextNode.view.extend({
        render: function () {
          const content = String(this.model.attributes.content);

          if (content.includes('PIDLIST')) {
            return this;
          }

          this.el = document.createComment(this.model.attributes.content);
          return this;
        },
      }),
    });

    comps.addType('table', {
      model: originalTable.model.extend({
        defaults: Object.assign({}, originalTable.model.prototype.defaults, {
          draggable: false,
          droppable: false,
          removable: false,
          highlightable: false,
          copyable: false,
          badgable: false,
          stylable: false,
          hoverable: false,
          selectable: false,
          toolbar: null,
        }),
      }),
      view: originalTable.view,
    });

    comps.addType('tbody', {
      model: originalTBody.model.extend({
        defaults: Object.assign({}, originalTBody.model.prototype.defaults, {
          draggable: false,
          droppable: false,
          removable: false,
          highlightable: false,
          copyable: false,
          badgable: false,
          stylable: false,
          selectable: false,
          hoverable: false,
          toolbar: null,
        }),
      }),
      view: originalTBody.view,
    });

    comps.addType('row', {
      model: originalRow.model.extend({
        defaults: Object.assign({}, originalRow.model.prototype.defaults, {
          draggable: false,
          droppable: false,
          removable: false,
          highlightable: false,
          copyable: false,
          badgable: false,
          stylable: false,
          selectable: false,
          hoverable: false,
          toolbar: null,
        }),
      }),
      view: originalRow.view,
    });

    comps.addType('cell', {
      model: originalCell.model.extend({
        defaults: Object.assign({}, originalCell.model.prototype.defaults, {
          draggable: false,
          droppable: false,
          removable: false,
          highlightable: false,
          copyable: false,
          badgable: false,
          stylable: false,
          selectable: false,
          hoverable: false,
          toolbar: null,
        }),
      }),
      view: originalCell.view,
    });

    comps.addType('mdspacer', {
      model: originalDefault.model.extend(
        {
          defaults: Object.assign({}, originalDefault.model.prototype.defaults, {
            draggable: false,
            droppable: false,
            removable: false,
            highlightable: false,
            copyable: false,
            badgable: false,
            stylable: false,
            selectable: false,
            hoverable: false,
            toolbar: null,
          }),
        },
        {
          isComponent: function (el) {
            const elm = $(el);
            if (elm.hasClass('md-spacer')) {
              return { type: 'mdspacer' };
            }
          },
        }
      ),
      view: originalDefault.view,
    });

    comps.addType('dsgnly-undraggable', {
      model: originalDefault.model.extend(
        {
          defaults: Object.assign({}, originalDefault.model.prototype.defaults, {
            droppable: false,
            highlightable: false,
            layerable: true,
            badgable: false,
          }),
        },
        {
          isComponent: function (el) {
            const elm = $(el);
            if (elm.hasClass('dsgnly-undraggable')) {
              return { type: 'dsgnly-undraggable' };
            }
          },
        }
      ),
      view: originalDefault.view.extend({
        render: function() {
          originalDefault.view.prototype.render.apply(this, arguments);
          const model = this.model;
          model.addAttributes({
            selectable: true
          });
          return this;
        }
      })
    });

    comps.addType('dsgnly-drag-area', {
      model: originalDefault.model.extend(
        {
          defaults: Object.assign({}, originalDefault.model.prototype.defaults, {
            copyable: true,
          }),
        },
        {
          isComponent: function (el) {
            const elm = $(el);
            if (elm.hasClass('dsgnly-drag-area')) {
              return { type: 'default' };
            }
          },
        }
      ),
      view: originalDefault.view.extend({
        render: function() {
          originalDefault.view.prototype.render.apply(this, arguments);
          const model = this.model;
          model.addAttributes({
            draggable: '.dsgnly-drag-area',
            droppable: '.row'
          });
          return this;
        }
      })
    });

    comps.addType('md-removable', {
      model: originalDefault.model.extend(
        {
          defaults: Object.assign({}, originalDefault.model.prototype.defaults, {
            draggable: false,
            droppable: false,
            copyable: false,
            layerable: true,
          }),
        },
        {
          isComponent(el) {
            const elm = $(el);
            if (elm.hasClass('md-removable')) {
              return { type: 'md-removable' };
            }
          },
        }
      ),
      view: originalDefault.view,
    });

    comps.addType('text', {
      model: originalText.model.extend({
        defaults: Object.assign({}, originalText.model.prototype.defaults, {
          hoverable: false,
          editable: true,
          draggable: false,
          droppable: false,
          removable: true,
          copyable: false,
          badgable: false,
        }),
      }),
      view: originalText.view.extend({
        events: {
          click: 'handleClick',
          dblclick: 'handledblClick',
          onchange: 'handleChange',
        },
        handledblClick: function (e) {
          this.el.contentEditable = 'true';
          if (this.el.contentEditable === 'true' || this.el.contentEditable === 'inherit') {
            setTimeout(() => $this.editor.getSelected().view.enableEditing(), 0);
          }
        },
        handleClick: function (e) {
          // if (this.el.contentEditable === 'true' || this.el.contentEditable === 'inherit') {
          //   setTimeout(() => $this.editor.getSelected().view.enableEditing(), 0);
          // }

          try {
            const formattedElements = this.$el.find('b, u, i');
            formattedElements.each(function () {
              const isHighlightable = $(this).attr('data-highlightable');

              if (isHighlightable === '1') {
                $(this).removeAttr('data-gjs-type').removeAttr('data-highlightable');
              }
            });
          } catch (e) {
            console.log(e);
          }
          
          if (this.el.contentEditable === 'inherit') {
            $('head').append(
              $('<style id="ckeditor_toggle">').text(`
              .cke_inner {
                display: none !important;
              }
              `)
            );

            setTimeout(() => {
              $this.editor.getSelected().view.disableEditing();
              this.el.click();
              // this.el.contentEditable = 'true';
            }, 0);
          } else {
            $this.editor.trigger('canvasScroll');

            $('head').find('#ckeditor_toggle').remove();
            if (!$('.gjs-rte-toolbar').is(':visible')) { 
              setTimeout(() => $this.editor.getSelected().view.disableEditing(), 0);
            }
          }
        },
        handleChange: function (e) {
          try {
            if (this.$el.hasClass('md-content-alert')) {
              const elementChar = this.$el.text();
              const allowedCharCount = this.$el.attr('data-max-character')
              if (elementChar.length > allowedCharCount) {
                this.$el.addClass('allowed');
                this.$el.attr('title', 'Overflow text');
              } else {
                this.$el.removeClass('allowed');
                this.$el.removeAttr('title');
              }
            }
          } catch (e) {
            console.warn(e);
          }
        },
      }),
    });

    if (this.authUser.provider !== 'designly') {
      comps.addType('md-prop-search', {
        model: originalImage.model.extend(
          {
            defaults: Object.assign({}, originalImage.model.prototype.defaults, {
              draggable: false,
              droppable: false,
              removable: false,
              copyable: false,
              badgable: false,
              stylable: true,
              hoverable: false
            }),
          },
          {
            isComponent: function (el) {
              const elm = $(el);
              if (elm.hasClass('md-prop-search')) {
                return { type: 'md-prop-search' };
              }
            },
          }
        ),
        view: originalImage.view.extend({
          events: {
            click: 'handleClick',
          },
          handleClick: function (e) {
            const selectedComponent = $this.editor.getSelected();

            let selectedCid = '';
            if (typeof selectedComponent !== 'undefined' && typeof selectedComponent.view !== 'undefined') {
              selectedCid = selectedComponent.view.cid;
            }

            if (selectedCid !== this.cid) {
              $this.letZoom = false;
              $this.disableZoom();
            }

            $this.initTooltip();

            const parentEl = this.model.view.$el.parent();
            const iframeLeft = $('iframe').offset().left;
            const panelWidth = $('.gjs-pn-views-container').width();
            const iframeScrollTop = $('iframe').contents().find('body').scrollTop();
            const parentOffsetTop = iframeScrollTop + parentEl.offset().top + 2 + 'px';
            let parentOffsetLeft = iframeLeft - panelWidth + parentEl.offset().left - 8 + 'px';
            

            if ($this.frontService.authService.getApp() === 'Designly') {
              parentOffsetLeft = iframeLeft + parentEl.offset().left + 'px';
            }

            let defaultStyle =
              `
              <style id="tb_overwrite">
              .tb_overwrite {
                top:` +
              parentOffsetTop +
              ` !important;
                left: ` +
              parentOffsetLeft +
              ` !important;
              }
              </style>
            `;

            const iframeElement = $('iframe');
            if ($this.socialMediaCategories.includes($this.template.category_id) ||
              iframeElement.width() > iframeElement.height()) {
              defaultStyle =
                `
                <style id="tb_overwrite">
                .tb_overwrite {
                  left: ` +
                parentOffsetLeft +
                ` !important;
                }
                </style>
              `;
            }

            $('#tb_overwrite').remove();
            $('head').append(defaultStyle);

            try {
              setTimeout(() => {
                const iframe = $($this.builder.nativeElement).find('iframe').contents();
                let cssRules = iframe.find('#gjs-css-rules').html();

                cssRules = String(cssRules).replace('md-library-search', 'md-library-x-search').replace('md-prop-search', 'md-prop-x-search');

                iframe.find('#gjs-css-rules').html(cssRules);
              }, 0);
            } catch (e) {
              console.error(e);
            }
          },
          render: function () {
            originalImage.view.prototype.render.apply(this, arguments);

            const tb = this.model.get('toolbar');

            const editorTbExist = tb.find(function (tbi) {
              return tbi.command === 'tui-image-editor';
            });

            const cropToolbarIndex = tb.findIndex(tbi => tbi.command === 'open-crop-modal');

            if (cropToolbarIndex === -1) {
              tb.push({
                attributes: {
                  class: 'icon feather icon-crop tb-crop',
                  'data-tooltip': 'Crop Image',
                  'data-tooltip-pos': 'bottom',
                },
                command: 'open-crop-modal'
              });
            }

            const isImageExist = tb.find(function (tbi) {
              return tbi.command === 'md-el-image-gallery';
            });

            if (typeof isImageExist === 'undefined') {
              tb.push({
                attributes: {
                  class: 'icon feather icon-image',
                  // title: 'Image Gallery'
                  title: '',
                  'data-tooltip': 'Image Gallery',
                  'data-tooltip-pos': 'bottom',
                },
                command: 'md-el-image-gallery',
              });
            }

            if ($this.template.category_id !== 7 && $this.template.category_id !== 1 && $this.template.category_id !== 2 && $this.template.category_id !== 3) {
              const isExist2 = tb.find(function (tbi) {
                return tbi.command === 'md-el-image-fit';
              });

              if (typeof isExist2 === 'undefined') {
                tb.push({
                  attributes: {
                    class: 'icon feather icon-maximize-2',
                    // title: 'Fit Image',
                    title: '',
                    'data-tooltip': 'Fit Image',
                    'data-tooltip-pos': 'bottom',
                  },
                  command: 'md-el-image-fit',
                });
              }

              const isExistZoom = tb.find(function (tbi) {
                return tbi.command === 'md-el-image-zoom';
              });

              if (typeof isExistZoom === 'undefined') {
                tb.push({
                  attributes: {
                    class: 'icon feather icon-move',
                    // title: 'Zoom Image'
                    title: '',
                    'data-tooltip': 'Drag and Zoom',
                    'data-tooltip-pos': 'bottom',
                  },
                  command: 'md-el-image-zoom',
                });
              }
            }

            const isExistDelete = tb.find(function (tbi) {
              return tbi.command === 'md-el-prop-search-delete';
            });

            if (typeof isExistDelete === 'undefined') {
              tb.push({
                attributes: {
                  class: 'icon feather icon-trash-2',
                  // title: 'Property Search',
                  title: '',
                  'data-tooltip': 'Clear Design',
                  'data-tooltip-pos': 'bottom',
                },
                command: 'md-el-prop-search-delete',
              });
            }

            const isExist = tb.find(function (tbi) {
              return tbi.command === 'md-el-prop-search';
            });

            if (typeof isExist === 'undefined') {
              tb.push({
                attributes: {
                  class: 'icon feather icon-search',
                  // title: 'Property Search',
                  title: '',
                  // 'data-tooltip': 'Property Search',
                  // 'data-tooltip-pos': 'bottom',
                },
                command: 'md-el-prop-search',
              });

              if ($this.template.category.ID === 5) {
                tb.push({
                  attributes: { class: 'fa fa-refresh replace-property', title: 'Replace Property' },
                  command: 'md-el-prop-replace',
                });
              }
            }

            try {
              const listingId = this.model.view.attr['data-listing-id'];

              if (listingId) {
                const isUpdatePriceExist = tb.find(function (tbi) {
                  return tbi.command === 'md-el-listing-update';
                });
                if (typeof isUpdatePriceExist === 'undefined') {
                  tb.push({
                    attributes: {
                      class: $this.frontService.authService.isUk() ? 'fa fa-gbp' : 'icon feather icon-dollar-sign',
                      title: '',
                      'data-tooltip': 'Update Price',
                      'data-tooltip-pos': 'bottom',
                    },
                    command: 'md-el-listing-update',
                  });
                }
              }
            } catch (updateToolBarErr) {
              console.error(updateToolBarErr);
            }

            

            this.model.set('toolbar', tb);

            return this;
          },
        }),
      });
    }

    comps.addType('md-library-search', {
      model: originalImage.model.extend(
        {
          defaults: Object.assign({}, originalImage.model.prototype.defaults, {
            draggable: false,
            droppable: false,
            copyable: false,
            removable: true,
            badgable: false,
            stylable: true,
          }),
        },
        {
          isComponent: function (el) {
            const elm = $(el);
            if (elm.hasClass('md-library-search')) {
              return { type: 'md-library-search' };
            }
          },
        }
      ),
      view: originalImage.view.extend({
        events: {
          click: 'handleClick',
        },
        handleClick: function (e) {
          const selectedComponent = $this.editor.getSelected();

          let selectedCid = '';
          if (typeof selectedComponent !== 'undefined' && typeof selectedComponent.view !== 'undefined') {
            selectedCid = selectedComponent.view.cid;
          }

          if (selectedCid !== this.cid) {
            $this.letZoom = false;
            $this.disableZoom();
          }

          $this.initTooltip();

          const parentEl = this.model.view.$el.parent();
          const iframeLeft = $('iframe').offset().left;
          const panelWidth = $('.gjs-pn-views-container').width();
          const iframeScrollTop = $('iframe').contents().find('body').scrollTop();
          const parentOffsetTop = iframeScrollTop + parentEl.offset().top + 2 + 'px';
          let parentOffsetLeft = iframeLeft - panelWidth + parentEl.offset().left - 8 + 'px';

          if ($this.frontService.authService.getApp() === 'Designly') {
            parentOffsetLeft = iframeLeft + parentEl.offset().left + 'px';
          }

          let defaultStyle =
            `
            <style id="tb_overwrite">
            .tb_overwrite {
              top:` +
            parentOffsetTop +
            ` !important;
              left: ` +
            parentOffsetLeft +
            ` !important;
            }
            </style>
          `;

          const iframeElement = $('iframe');
            if ($this.socialMediaCategories.includes($this.template.category_id) ||
              iframeElement.width() > iframeElement.height()) {
            defaultStyle =
              `
              <style id="tb_overwrite">
              .tb_overwrite {
                left: ` +
              parentOffsetLeft +
              ` !important;
              }
              </style>
            `;
          }

          $('#tb_overwrite').remove();
          $('head').append(defaultStyle);

          try {
            setTimeout(() => {
              const iframe = $($this.builder.nativeElement).find('iframe').contents();
              let cssRules = iframe.find('#gjs-css-rules').html();

              cssRules = String(cssRules).replace('md-library-search', 'md-library-x-search').replace('md-prop-search', 'md-prop-x-search');

              iframe.find('#gjs-css-rules').html(cssRules);
            }, 0);
          } catch (e) {
            console.log(e);
          }
        },
        render: function () {
          originalImage.view.prototype.render.apply(this, arguments);

          let tb = this.model.get('toolbar');

          const cropToolbarIndex = tb.findIndex(tbi => tbi.command === 'open-crop-modal');

          if (cropToolbarIndex === -1) {
            tb.push({
              attributes: {
                class: 'icon feather icon-crop tb-crop',
                'data-tooltip': 'Crop Image',
                'data-tooltip-pos': 'bottom',
              },
              command: 'open-crop-modal'
            });
          }

          const isExist = tb.find(function (tbi) {
            return tbi.command === 'md-el-image-gallery';
          });

          if (typeof isExist === 'undefined') {
            tb.push({
              attributes: {
                class: 'icon feather icon-image',
                // title: 'Image Gallery'
                title: '',
                'data-tooltip': 'Image Gallery',
                'data-tooltip-pos': 'bottom',
              },
              command: 'md-el-image-gallery',
            });
          }

          if ($this.template.category_id !== 7 && $this.template.category_id !== 1 && $this.template.category_id !== 2 && $this.template.category_id !== 3) {
            const isExist2 = tb.find(function (tbi) {
              return tbi.command === 'md-el-image-fit';
            });

            if (typeof isExist2 === 'undefined') {
              tb.push({
                attributes: {
                  class: 'icon feather icon-maximize-2',
                  // title: 'Fit Image'
                  title: '',
                  'data-tooltip': 'Fit Image',
                  'data-tooltip-pos': 'bottom',
                },
                command: 'md-el-image-fit',
              });
            }

            const isExistZoom = tb.find(function (tbi) {
              return tbi.command === 'md-el-image-zoom';
            });

            if (typeof isExistZoom === 'undefined') {
              tb.push({
                attributes: {
                  class: 'icon feather icon-move',
                  // title: 'Zoom Image'
                  title: '',
                  'data-tooltip': 'Drag and Zoom',
                  'data-tooltip-pos': 'bottom',
                },
                command: 'md-el-image-zoom',
              });
            }
          }

          const isExistDefaultDelete = tb.find(function (tbi) {
            return tbi.command === 'tlb-delete';
          });

          if (typeof isExistDefaultDelete !== 'undefined') {
            const defaultDeleteindex = tb.findIndex(item => {
              return item.command === 'tlb-delete';
            });
            tb.push(tb[defaultDeleteindex]);
            tb.splice(defaultDeleteindex, 1); 
            
          }

          this.model.set('toolbar', tb);

          return this;
        },
      }),
    });

    comps.addType('md-article-search', {
      model: originalImage.model.extend(
        {
          defaults: Object.assign({}, originalImage.model.prototype.defaults, {
            draggable: false,
            droppable: false,
            removable: false,
            copyable: false,
            badgable: false,
            stylable: true,
          }),
        },
        {
          isComponent: function (el) {
            const elm = $(el);
            if (elm.hasClass('md-article-search')) {
              return { type: 'md-article-search' };
            }
          },
        }
      ),
      view: originalImage.view.extend({
        events: {
          mouseleave: 'handleMouseLeave',
          click: 'handleClick',
        },
        handleClick: function(e) {

          this.initTooltip();

          const parentEl = this.model.view.$el.parent();
          const iframeLeft = $('iframe').offset().left;
          const panelWidth = $('.gjs-pn-views-container').width();
          const iframeScrollTop = $('iframe').contents().find('body').scrollTop();
          const parentOffsetTop = iframeScrollTop + parentEl.offset().top + 2 + 'px';
          let parentOffsetLeft = iframeLeft - panelWidth + parentEl.offset().left - 8 + 'px';

          if ($this.frontService.authService.getApp() === 'Designly') {
            parentOffsetLeft = iframeLeft + parentEl.offset().left + 'px';
          }

          $('#tb_overwrite').remove();
          $('head').append(
            `
            <style id="tb_overwrite">
            .tb_overwrite {
              top:` +
              parentOffsetTop +
              ` !important;
              left: ` +
              parentOffsetLeft +
              ` !important;
            }
            </style>
          `
          );
        },
        render: function () {
          originalImage.view.prototype.render.apply(this, arguments);

          const tb = this.model.get('toolbar');

          const isImageExist = tb.find(function (tbi) {
            return tbi.command === 'md-el-image-gallery';
          });

          if (typeof isImageExist === 'undefined') {
            tb.push({
              attributes: {
                class: 'icon feather icon-image',
                title: '',
                'data-tooltip': 'Image Gallery',
                'data-tooltip-pos': 'bottom',
              },
              command: 'md-el-image-gallery',
            });
          }     

          const cropToolbarIndex = tb.findIndex(tbi => tbi.command === 'open-crop-modal');

          if (cropToolbarIndex === -1) {
            tb.push({
              attributes: {
                class: 'icon feather icon-crop tb-crop',
                'data-tooltip': 'Crop Image',
                'data-tooltip-pos': 'bottom',
              },
              command: 'open-crop-modal'
            });
          }

          const isExist = tb.find(function (tbi) {
            return tbi.command === 'md-el-article-search';
          });

          if (typeof isExist === 'undefined') {
            tb.push({
              attributes: {
                class: 'icon feather icon-search icon-search-article',
                // title: 'Article Search'
                title: '',
                // 'data-tooltip': 'Article Search',
                // 'data-tooltip-pos': 'bottom',
              },
              command: 'md-el-article-search',
            });
          }

          this.model.set('toolbar', tb);
          return this;
        },
      }),
    });

    comps.addType('mdbox', {
      model: originalDefault.model.extend(
        {
          defaults: Object.assign({}, originalDefault.model.prototype.defaults, {
            droppable: false,
            highlightable: false,
            layerable: true,
            badgable: false,
          }),
        },
        {
          isComponent: function (el) {
            const elm = $(el);
            if (elm.hasClass('md-box')) {
              return { type: 'mdbox' };
            }
          },
        }
      ),
      view: originalDefault.view
    });

    comps.addType('dsgnly-agent', {
      model: originalDefault.model.extend(
        {
          defaults: Object.assign({}, originalDefault.model.prototype.defaults, {
            droppable: false,
            highlightable: false,
            layerable: true,
            badgable: false,
          }),
        },
        {
          isComponent: function (el) {
            const elm = $(el);
            if (elm.hasClass('dsgnly-agent')) {
              return { type: 'dsgnly-agent' };
            }
          },
        }
      ),
      view: originalDefault.view.extend({
        render: function() {
          originalDefault.view.prototype.render.apply(this, arguments);
          const model = this.model;
          model.addAttributes({
            draggable: '.dsgnly-drag-area',
            droppable: '.row'
          });

          const tb = model.get('toolbar');

          const isAgentExist = tb.find(function (tbi) {
            return tbi.command === 'md-el-dsgnly-agent';
          });
          this.selectedAgents = [];
          if (typeof isAgentExist === 'undefined') {
            tb.push({
              attributes: {
                class: 'icon feather icon-users tb-agent-search',
                title: '',
                'data-tooltip': 'Insert agent',
                'data-tooltip-pos': 'bottom',
              },
              command: 'md-el-dsgnly-agent',
            });
          }

          return this;
        }
      })
    });

    comps.addType('custom-link', {
      model: originalDefault.model.extend(
        {
          defaults: Object.assign({}, originalDefault.model.prototype.defaults, {
            draggable: false,
            droppable: false,
            highlightable: false,
            layerable: true,
            badgable: false,
            selectable: false,
            copyable: false,
            stylable: false,
            hoverable: false,
            editable: false,
          }),
        },
        {
          isComponent(el) {
            const elm = $(el);
            if (elm.hasClass('md-updatable-anchor')) {
              // elm.attr('draggable', false);
              return { type: 'custom-link' };
            }
          },
        }
      ),
      // view: originalDefault.view
      view: originalDefault.view.extend({
        events: {
          dblclick: 'handleClick',
        },
        handleClick: function (e) {
          const linkElement = $(this.el);
          const dataLink = $(linkElement).data('link');
          let linkTitle = '';

          let anchorText = linkElement.html();
          let anchorLink = linkElement.attr('href');
          let html = '';

          const is_mail = String(anchorLink).includes('mailto:');
          const is_phone = String(anchorLink).includes('tel:');
          let is_custom = true;
          let isHttps = false;

          if (is_mail || is_phone || String(anchorLink).includes('https') || String(anchorLink).includes('http')) {
            is_custom = false;
          }

          if (is_mail) {
            anchorLink = String(anchorLink).replace('mailto:', '');
            $this.updateable_link_type = 'mail';
          } else if (is_phone) {
            anchorLink = String(anchorLink).replace('tel:', '');
            $this.updateable_link_type = 'tel';
          } else if (is_custom) {
            $this.updateable_link_type = 'custom';
          } else {
            if (String(anchorLink).includes('https')) {
              isHttps = true;
            } else {
              isHttps = false;
            }

            anchorLink = String(anchorLink).replace('https://', '').replace('http://', '').replace('data%md3%-', 'data-');
            $this.updateable_link_type = 'url';
          }

          if (linkElement.children().is('img')) {
            html =
              `
            <div class="row" style="text-align: left">
              <div class="col-md-12" style="margin-bottom: 10px;">
                <label for="mdl-link-type">Link Type</label>
                <select id="mdl-link-type" onchange="onModalLinkTypeChange()"
                 class="form-control" style="border: 1px solid #d3d3d3 !important">
                  <option value="url" ` +
              (is_mail || is_phone ? '' : 'selected') +
              `>URL</option>
                  <option value="mail" ` +
              (is_mail ? 'selected' : '') +
              `>Mail</option>
                  <option value="tel" ` +
              (is_phone ? 'selected' : '') +
              `>Telephone</option>
              <option value="custom" ` +
              (is_custom ? 'selected' : '') +
              `>Custom</option>
                </select>
              </div>
              <div class="mdl-link-holder col-md-3 ` +
              (is_mail || is_phone || is_custom ? 'hidden' : '') +
              `">
                <select id="mdl-url-types" class="form-control" style="border: 1px solid #d3d3d3 !important">
                  <option value="http://" ` +
              (!isHttps ? 'selected' : '') +
              `>http://</option>
                  <option value="https://" ` +
              (isHttps ? 'selected' : '') +
              `>https://</option>
                </select>
              </div>
              <div class="col-md-9">
                <input
                  id="anchorLink"
                  placeholder="Href"
                  value="` +
              $.trim(anchorLink) +
              `"
                  onpaste="onAnchorPaste()"
                  class="form-control mb-1"
                  style="border: 1px solid #e8e8e8">
              </div>
            `;
          } else {
            html =
              `
            <p>Please enter your desired url below.
            Please note this will not update the link in your settings, only the link for this field.</a>
            <br />
            <br />
            Link Name:
             <input
              id="anchorText"
              placeholder="Label"
              value="` +
              $.trim(anchorText) +
              `"
              class="form-control mb-1"
              style="border: 1px solid #e8e8e8">
            <br />
            <div class="row" style="text-align: left">
              <div class="col-md-12" style="margin-bottom: 10px;">
                <label for="mdl-link-type">Link Type</label>
                <select id="mdl-link-type" onchange="onModalLinkTypeChange()"
                 class="form-control" style="border: 1px solid #d3d3d3 !important">
                  <option value="url" ` +
              (is_mail || is_phone ? '' : 'selected') +
              `>URL</option>
                  <option value="mail" ` +
              (is_mail ? 'selected' : '') +
              `>Mail</option>
                  <option value="tel" ` +
              (is_phone ? 'selected' : '') +
              `>Telephone</option>
                  <option value="custom" ` +
              (is_custom ? 'selected' : '') +
              `>Custom</option>
                </select>
              </div>
              <div class="mdl-link-holder col-md-3 ` +
              (is_mail || is_phone || is_custom ? 'hidden' : '') +
              `">
                <select id="mdl-url-types" class="form-control" style="border: 1px solid #d3d3d3 !important">
                  <option value="http://" ` +
              (!isHttps ? 'selected' : '') +
              `>http://</option>
                  <option value="https://" ` +
              (isHttps ? 'selected' : '') +
              `>https://</option>
                </select>
              </div>
              <div class="col-md-9">
                <input
                  id="anchorLink"
                  placeholder="Href"
                  value="` +
              $.trim(anchorLink) +
              `"
                  onpaste="onAnchorPaste()"
                  class="form-control mb-1"
                  style="border: 1px solid #e8e8e8">
              </div>
            `;
          }

          if (dataLink === undefined) {
            linkTitle = 'Link Destination';
          } else {
            linkTitle = dataLink;
          }

          const model = this.model;
          swal({
            title: linkTitle,
            html: html,
            showCancelButton: true,
            confirmButtonText: 'Update',
            showLoaderOnConfirm: true,
            preConfirm: function (data) {
              return new Promise(function (resolve, reject) {
                setTimeout(function () {
                  resolve();
                }, 200);
              });
            },
            allowOutsideClick: false,
          })
            .then(function (data) {
              const anchorType = $('#mdl-link-type').val();
              anchorText = $('#anchorText').val();
              anchorLink = $('#anchorLink').val();

              if (anchorType === 'url') {
                if (!String(anchorLink).includes('{%')) {
                  anchorLink = String(anchorLink).replace('https://', '').replace('http://', '').replace('data-', 'data%md3%-');

                  const url_type = $('#mdl-url-types').val();
                  anchorLink = url_type + anchorLink;
                }
              } else if (anchorType === 'custom') {
                anchorLink = anchorLink;
              } else {
                if (String(anchorLink).includes('{%')) {
                  anchorLink = anchorLink;
                } else {
                  if (anchorType === 'tel') {
                    anchorLink = 'tel:' + anchorLink;
                  } else {
                    anchorLink = 'mailto:' + anchorLink;
                  }
                }
              }

              if (linkElement.children().is('img')) {
                model.setAttributes({ href: anchorLink, 'data-updated': true });
              } else {
                model.set('content', anchorText);
                model.setAttributes({ href: anchorLink, 'data-updated': true });
              }
            })
            .catch(swal.noop);
        },
        render: function () {
          originalDefault.view.prototype.render.apply(this, arguments);

          // Fix dragging issue of anchor tag element
          const model = this.model;
          model.addAttributes({ draggable: false });

          return this;
        },
      }),
    });

    comps.addType('span-b-em-u-i-strong', {
      model: originalText.model.extend(
        {
          defaults: Object.assign({}, originalText.model.prototype.defaults, {
            editable: false,
            selectable: false,
            badgable: false,
            droppable: false,
          }),
        },
        {
          isComponent(el) {
            const elm = $(el);
            if (elm.is('span') || elm.is('b') || elm.is('em') || elm.is('u') || elm.is('i') || elm.is('strong')) {
              return { type: 'span-b-em-u-i-strong' };
            }
          },
        }
      ),
      view: originalText.view.extend({
        events: {
          dblclick: 'handleClick',
        },
        handleClick: function (e) {
          const parentEl = this.model.view.$el.parents('p');
          parentEl.trigger('dblclick');
        },
      }),
    });

    // comps.addType('custom-image', {
    //   model: originalDefault.model.extend({
    //     defaults: Object.assign({}, originalDefault.model.prototype.defaults, {
    //       draggable: false
    //     })
    //   })
    // });

    comps.addType('p-editable-w-spans', {
      model: originalText.model.extend(
        {
          defaults: Object.assign({}, originalText.model.prototype.defaults, {
            droppable: false,
            badgable: false,
            removable: false,
          }),
        },
        {
          isComponent(el) {
            const elm = $(el);
            // check all element in dsgnly-ckeditor and set to uneditable
            if (elm.is('p') && (elm.has('span') || elm.has('b') || elm.has('em') || elm.has('u') || elm.has('i') || elm.has('strong')) || (elm.has('div') && elm.hasClass('dsgnly-ckeditor'))) {
              if(elm.parent().hasClass('dsgnly-ckeditor')) {
                return { type: 'md-uneditable' };
              }
              return { type: 'p-editable-w-spans' };
            }
          },
        }
      ),
      view: originalText.view,
    });

    // Add p-editable-w-span type for default element in dsgnly-ckeditor
    comps.addType('p-editable-w-spans-container', {
      model: originalDefault.model.extend(
        {
          defaults: Object.assign({}, originalText.model.prototype.defaults, {
            droppable: false,
            badgable: false,
            removable: true
          }),
        },
        {
          isComponent(el) {
            const elm = $(el);
            if ((elm.has('div') && elm.hasClass('dsgnly-ckeditor'))) {
              return { type: 'p-editable-w-spans' };
            }
          },
        }
      ),
      view: originalDefault.view,
    });

    comps.addType('p-editable-w-spans-copyable', {
      model: originalText.model.extend(
        {
          defaults: Object.assign({}, originalText.model.prototype.defaults, {
            droppable: false,
            badgable: true,
            copyable: true,
            removable: true,
          }),
        },
        {
          isComponent(el) {
            const elm = $(el);
            if (elm.is('p') && (elm.has('span') || elm.has('b') || elm.has('em') || elm.has('u') || elm.has('i') || elm.has('strong'))) {
              if (elm.hasClass('md-copyable')) {
                return { type: 'p-editable-w-spans-copyable' };
              }
            }
          },
        }
      ),
      view: originalText.view,
    });

    comps.addType('md-content-editable', {
      model: {
        isComponent(el) {
          const elm = $(el);
          if (elm.hasClass('md-content-editable') && elm.is('div')) {
            return { type: 'text' };
          }
        },
      },
    });

    comps.addType('non-droppable-ol', {
      model: originalDefault.model.extend(
        {
          defaults: Object.assign({}, originalDefault.model.prototype.defaults, {
            droppable: false,
            badgable: false,
            hoverable: false,
            highlightable: false
          }),
        },
        {
          isComponent(el) {
            const elm = $(el);

            if (elm.is('ol')) {
              if(elm.parent().hasClass('dsgnly-ckeditor')) {
                return { type: 'md-uneditable' };
              }
              return { type: 'non-droppable-ol' };
            }
          },
        }
      ),
      view: originalDefault.view,
    });

    comps.addType('non-droppable-ul', {
      model: originalDefault.model.extend(
        {
          defaults: Object.assign({}, originalDefault.model.prototype.defaults, {
            droppable: false,
            badgable: false,
            hoverable: false,
            highlightable: false
          }),
        },
        {
          isComponent(el) {
            const elm = $(el);

            if (elm.is('ul')) {
              if(elm.parent().hasClass('dsgnly-ckeditor')) {
                return { type: 'md-uneditable' };
              }
              return { type: 'md-uneditable' };
            }
          },
        }
      ),
      view: originalDefault.view,
    });

    comps.addType('non-droppable-li', {
      model: originalText.model.extend(
        {
          defaults: Object.assign({}, originalText.model.prototype.defaults, {
            droppable: false,
            badgable: false,
            hoverable: false,
            highlightable: false
          }),
        },
        {
          isComponent(el) {
            const elm = $(el);

            if (elm.is('li')) {
              if(elm.parent().parent().hasClass('dsgnly-ckeditor')) {
                return { type: 'md-uneditable' };
              }
              return { type: 'non-droppable-li' };
            }
          },
        }
      ),
      view: originalText.view,
    });

    comps.addType('a-link', {
      model: originalLink.model.extend(
        {
          defaults: Object.assign({}, originalLink.model.prototype.defaults, {
            draggable: false,
            droppable: false,
            badgable: false,
            copyable: false,
            stylable: false,
            selectable: false,
            hoverable: false,
            editable: false,
          }),
        },
        {
          isComponent(el) {
            const elm = $(el);
            if (elm.is('a') && elm.has('img') && !elm.hasClass('md-updatable-anchor')) {
              return { type: 'a-link' };
            }
          },
        }
      ),
      view: originalLink.view.extend({
        render: function () {
          originalLink.view.prototype.render.apply(this, arguments);

          // Fix dragging issue of anchor tag element
          const model = this.model;
          model.setAttributes(Object.assign({}, model.getAttributes(), { draggable: false }));

          return this;
        },
      }),
    });
    comps.addType('md-uneditable', {
      model: originalDefault.model.extend(
        {
          defaults: Object.assign({}, originalDefault.model.prototype.defaults, {
            draggable: false,
            droppable: false,
            removable: false,
            highlightable: false,
            copyable: false,
            badgable: false,
            stylable: false,
            selectable: false,
            hoverable: false,
            editable: false,
            toolbar: null,
          }),
        },
        {
          isComponent: function (el) {
            const elm = $(el);
            if (elm.hasClass('md-uneditable')) {
              return { type: 'md-uneditable' };
            }
          },
        }
      ),
      view: originalDefault.view,
    });

    this.editor.render();

    let _tmp_html = '';

    if (this.emailCategories.includes(this.template.category_id)) {
      _tmp_html = '#wrapper {' + 'overflow: inherit;}';
    }

    const customOverflowCategories = [6];
    if (customOverflowCategories.includes(this.template.category_id)) {
      _tmp_html = '#wrapper {' + 'overflow: inherit;}';
    }

    if (_tmp_html !== '') {
      const iframe = $(this.builder.nativeElement).find('iframe').contents();
      iframe.find('head').append($('<style>').text(_tmp_html));
    }

    const iframeBody = this.editor.Canvas.getBody();
    $(iframeBody).find('style').append(`
    #wrapper .allowed {border: 1px dashed red; }
    `);
    $(iframeBody).on('paste', '[contenteditable="true"]', function (e) {
      if (!$this.ckeditor_active) {
        e.preventDefault();
        const text = e.originalEvent.clipboardData.getData('text');
        e.target.ownerDocument.execCommand('insertText', false, text);
      }

      const $el = $(this);
      setTimeout(function () {
        let html = $el.html();
        html = $this.stripSmartQuotes(html);
        $el.html(html);
      });
    });

    $('.gjs-cv-canvas').attr('id', 'gjs-ck-editor-test');

    if (this.tmpUserGlobalClass.length > 0) {
      let body = this.editor.Canvas.getBody();
      $(body).attr('class', this.tmpUserGlobalClass.join(' '));
    }

    let propertyIdHC;

    if (this.template.hug_connect_data) {
       propertyIdHC = Number(Object.keys(this.template.hug_connect_data)[0]); // Get the property ID
    }

    if (
        this.template.hug_connect_data &&
        propertyIdHC
    ) {
      this.loading = true;
      this.vaultService.getListing(propertyIdHC, {
        classification: this.template.hug_connect_data[propertyIdHC][0].class,
        status: this.template.hug_connect_data[propertyIdHC][0].status,
        sync_data: true,
      }).takeUntil(this.destroy$).subscribe((response: any) => {
        if (typeof response !== 'undefined' && typeof response.status !== 'undefined') {
          if (response.status === 'success') {
            const iframe = $($this.builder.nativeElement).find('iframe').contents();

            $this.editor.select(iframe.find('.md-prop-search')[0]);

            setTimeout(() => {
              this.selectedListings = [response.data];
              this.onSelectListings(true);

              this.loading = false;
            }, 1000);
          } else {
            swal('Property not found', '', 'error');
          }
        }
      });
    }
  }

  initBuild() {
    const $this = this;
    const panelManager = this.editor.Panels;
    const blockManager = this.editor.BlockManager;
    const commands = this.editor.Commands;

    if (this.frontService.authService.getApp() === 'MyDesign3') {
      const leftPanel = panelManager.addPanel({
        id: 'left-control',
        buttons: [
          {
            id: 'back',
            className: 'fa fa-arrow-left',
            command: 'back',
            attributes: { ['title']: 'Back' },
          },
        ],
      });

      const rightPanel = panelManager.addPanel({
        id: 'right-control',
        buttons: [
          {
            id: 'undo',
            className: 'fa fa-undo',
            command: 'undo',
            attributes: {
              title: '',
              'data-tooltip': 'Undo changes',
              'data-tooltip-pos': 'bottom',
            },
          },
          {
            id: 'redo',
            className: 'fa fa-repeat',
            command: 'redo',
            attributes: {
              title: '',
              'data-tooltip': 'Redo changes',
              'data-tooltip-pos': 'bottom',
            },
          },
          {
            id: 'send',
            className: 'btn-html-send',
            command: 'send',
            attributes: { ['title']: 'Send' },
          },
          {
            id: 'facebook',
            className: 'fa fa-facebook',
            command: 'fbshare',
            attributes: { ['title']: 'Share on Facebook' },
          },
          {
            id: 'twitter',
            className: 'fa fa-twitter',
            command: 'twittershare',
            attributes: { ['title']: 'Share on Twitter' },
          },
          {
            id: 'linkedin',
            className: 'fa fa-linkedin',
            command: 'linkedinshare',
            attributes: { ['title']: 'Share on LinkedIn' },
          },
          {
            id: 'save',
            className: 'icon feather icon-save',
            command: 'save',
            attributes: { ['title']: 'Save' },
          },
          {
            id: 'saveoptions',
            className: 'btn-html-save-campaign',
            command: 'saveoptions',
            attributes: { ['title']: 'Save Options' },
          },
          {
            id: 'pricefinder-search',
            command: 'pricefinder-search',
            className: 'btn-html-pricefinder-search',
            attributes: { ['title']: 'Pricefinder' },
          },
          {
            id: 'pricefinder-sharelink',
            command: 'pricefinder-sharelink',
            className: 'btn-html-pricefinder-sharelink',
            attributes: { ['title']: 'Share Link' },
          },
          // {
          //   id: 'emarketing-options',
          //   className: 'btn-html-emarketing-options',
          //   command: 'emarketing-options',
          //   attributes: {['title']: 'eMarketing Options'}
          // },
          // {
          //   id: 'save-quick',
          //   className: 'btn-save-quick',
          //   command: 'save-quick',
          //   attributes: {['title']: 'Quick Design'}
          // },
          // {
          //   id: 'save-quick-office',
          //   className: 'btn-save-quick-office',
          //   command: 'save-quick-office',
          //   attributes: {['title']: 'Office Quick Design'}
          // },
          {
            id: 'pdf',
            className: 'btn-html-pdf',
            command: 'pdf',
            attributes: { ['title']: 'PDF' },
          },
          {
            id: 'xhtml',
            className: 'btn-html-html',
            command: 'view-as',
            attributes: { ['title']: 'HTML' },
          },
          {
            id: 'highres-pdf',
            className: 'btn-html-highres-pdf',
            command: 'highres-pdf',
            attributes: { ['title']: 'PDF' },
          },
          {
            id: 'img',
            className: 'btn-html-img',
            command: 'save-as-image',
            attributes: { ['title']: 'Image' },
          },
          {
            id: 'tour',
            className: 'btn-html-tour',
            command: 'tour',
            attributes: { ['title']: 'Start Tour' },
          },
          //WB-3447: Floating Elevio helper to be used instead of builder help. Commenting code for the meantime? might need in the future?
          // {
          //   id: 'help',
          //   className: 'btn-html-help',
          //   command: 'help',
          //   attributes: {
          //     title: '',
          //     'data-tooltip': 'Need help',
          //     'data-tooltip-pos': 'bottom',
          //   },
          // },
        ],
      });

      const controlPanel = panelManager.addPanel({
        id: 'controls',
        buttons: [
          {
            id: 'fullscreen',
            command: 'fullscreen',
            className: 'fa fa-arrows-alt html-arrow',
            attributes: {
              title: '',
              'data-tooltip': 'Fullscreen',
              'data-tooltip-pos': 'bottom',
            },
          },
          {
            id: 'clean-all',
            command: 'clean-all',
            className: 'icon feather icon-trash-2 html-trash',
            attributes: {
              title: '',
              'data-tooltip': 'Clear your template',
              'data-tooltip-pos': 'bottom',
            },
          },
        ],
      });

      const devicePanel = panelManager.addPanel({
        id: 'devices',
        buttons: [
          {
            id: 'set-device-desktop',
            command: 'set-device-desktop',
            className: 'fa fa-desktop html-desktop',
            attributes: { ['title']: 'Desktop View' },
            active: true,
          },
          {
            id: 'set-device-mobile',
            command: 'set-device-mobile',
            className: 'fa fa-mobile html-mobile',
            attributes: { ['title']: 'Mobile View' },
          },
        ],
      });

      const titlePanel = panelManager.addPanel({
        id: 'title-control',
        buttons: [
          {
            id: 'template-title',
            command: 'template-title',
            className: 'html-template-title',
            attributes: {
              title: '',
              'data-tooltip': 'Rename template',
              'data-tooltip-pos': 'bottom',
            },
            active: 1,
          },
        ],
      });
    }

    if (this.frontService.authService.getApp() === 'Designly') {
      const leftPanel = panelManager.addPanel({
        id: 'left-control',
        buttons: [
          {
            id: 'back',
            className: 'icon feather icon-chevron-left',
            command: 'back',
            attributes: { ['title']: 'Back' },
          },
        ],
      });

      const rightPanel = panelManager.addPanel({
        id: 'right-control',
        buttons: [
          //TODO: Disabled autosaving for now, will continue until further notice
          // {
          //   id: 'autosave',
          //   command: '',
          //   className: 'btn-html-autosave',
          //   attributes: {
          //     title: '',
          //     'data-tooltip': 'Turn on auto save',
          //     'data-tooltip-pos': 'bottom',
          //   },
          // },
          {
            id: 'clean-all',
            command: 'clean-all',
            className: 'icon feather icon-trash-2 html-trash',
            attributes: {
              title: '',
              'data-tooltip': 'Clear your template',
              'data-tooltip-pos': 'bottom',
            },
          },
          {
            id: 'reset-design',
            className: 'icon feather icon-refresh-ccw reset-design',
            command: 'reset-design',
            attributes: {
              title: '',
              'data-tooltip': 'Reset Design',
              'data-tooltip-pos': 'bottom',
            },
          },
          {
            id: 'undo',
            className: 'icon feather icon-rotate-ccw',
            command: 'undo',
            attributes: {
              title: '',
              'data-tooltip': 'Undo changes',
              'data-tooltip-pos': 'bottom',
            },
          },
          {
            id: 'redo',
            className: 'icon feather icon-rotate-cw',
            command: 'redo',
            attributes: {
              title: '',
              'data-tooltip': 'Redo changes',
              'data-tooltip-pos': 'bottom',
            },
          },
          {
            id: 'view-in-browser',
            className: 'icon feather icon-globe view-in-browser',
            command: 'view-in-browser',
            attributes: {
              title: '',
              'data-tooltip': 'View in Browser',
              'data-tooltip-pos': 'bottom',
            },
          },
          {
            id: 'design-print',
            className: 'icon feather icon-printer design-print',
            command: 'design-print',
            attributes: {
              title: '',
              'data-tooltip': 'Print',
              'data-tooltip-pos': 'bottom',
            }
          },
          //WB-3447: Floating Elevio helper to be used instead of builder help. Commenting code for the meantime? might need in the future?
          // {
          //   id: 'help',
          //   className: 'icon feather icon-help-circle btn-html-help',
          //   command: 'help',
          //   attributes: {
          //     title: '',
          //     'data-tooltip': 'Need help',
          //     'data-tooltip-pos': 'bottom',
          //   },
          // },
          {
            id: 'fullscreen',
            command: 'fullscreen',
            className: 'icon feather icon-maximize html-arrow',
            attributes: {
              title: '',
              'data-tooltip': 'Fullscreen',
              'data-tooltip-pos': 'bottom',
            },
          },
          {
            id: 'send',
            className: 'btn-html-new-send',
            command: 'send',
            attributes: {
              title: '',
              'data-tooltip': 'Send to MRI Vault',
              'data-tooltip-pos': 'bottom',
            },
          },
          {
            id: 'saveoptions',
            className: $this.frontService.authService.isUk() ? 'btn-html-save-campaign btn-html-save-campaign-uk' : 'btn-html-save-campaign',
            command: 'saveoptions',
            attributes: { ['title']: 'Save Options' },
          },
          {
            id: 'save',
            className: 'btn-html-save',
            command: 'save',
            attributes: { ['title']: 'Save' },
          },
          {
            id: 'upload',
            className: 'btn-html-upload-hug-connect',
            command: 'upload',
            attributes: {
              'data-tooltip': 'Upload to Filing Cabinet',
              'data-tooltip-pos': 'bottom',
            },
          },
          {
            id: "insert-property",
            command: 'insert-property',
            className: 'btn-html-insert-property',
            attributes: {
              ['title']: 'Insert property',
              'data-tooltip': 'insert property',
              'data-tooltip-pos': 'bottom',
            },
          },
          // {
          //   id: 'save-quick',
          //   className: 'btn-save-quick',
          //   command: 'save-quick',
          //   attributes: {['title']: 'Quick Design'}
          // },
          // {
          //   id: 'save-quick-office',
          //   className: 'btn-save-quick-office',
          //   command: 'save-quick-office',
          //   attributes: {['title']: 'Office Quick Design'}
          // },
          {
            id: 'emarketing-options',
            className: 'btn-html-emarketing-options',
            command: 'emarketing-options',
            attributes: { ['title']: 'eMarketing Options' },
          },
          {
            id: 'pricefinder-sharelink',
            command: 'pricefinder-sharelink',
            className: 'btn-html-pricefinder-sharelink',
            attributes: { ['title']: 'Share Link' },
          },
          {
            id: 'pdf',
            className: 'btn-html-pdf',
            command: 'pdf',
            attributes: { ['title']: 'PDF' },
          },
          {
            id: 'pricefinder-search',
            command: 'pricefinder-search',
            className: 'btn-html-pricefinder-search',
            attributes: { ['title']: 'Pricefinder' },
          },
          {
            id: 'xhtml',
            className: 'btn-html-html',
            command: 'view-as',
            attributes: { ['title']: 'HTML' },
          },
          {
            id: 'highres-pdf',
            className: 'btn-html-highres-pdf',
            command: 'highres-pdf',
            attributes: { ['title']: 'PDF' },
          },
          {
            id: 'img',
            className: 'btn-html-img',
            command: 'save-as-image',
            attributes: { ['title']: 'Image' },
          },
          // {
          //   id: 'tour',
          //   className: 'btn-html-tour',
          //   command: 'tour',
          //   attributes: {['title']: 'Start Tour'}
          // }
        ],
      });

      const devicePanel = panelManager.addPanel({
        id: 'devices',
        buttons: [
          {
            id: 'set-device-desktop',
            command: 'set-device-desktop',
            className: 'icon feather icon-monitor html-desktop',
            attributes: {
              title: '',
              'data-tooltip': 'Desktop View',
              'data-tooltip-pos': 'bottom',
            },
            active: true,
          },
          {
            id: 'set-device-mobile',
            command: 'set-device-mobile',
            className: 'icon feather icon-smartphone html-mobile',
            attributes: {
              title: '',
              'data-tooltip': 'Mobile View',
              'data-tooltip-pos': 'bottom',
            },
          },
        ],
      });

      if (this.isVaultMail) {
        panelManager.addButton('right-control', {
          id: 'populate-listing',
          className: 'btn-populate-listing',
          command: 'populate-listing',
          attributes: { ['title']: 'Populate Listing' },
        });
      }
      
      //Template setting icon will only show if global template setting for this master template has data
      if (this.globalSettings !== undefined && this.template.category_id !== this.templateCategoryId.EMARKETING) {
        panelManager.addButton('right-control', {
          id: 'global-template-setting',
          command: 'global-template-setting',
          className: 'btn-global-setting icon feather icon-settings',
          attributes: {
            'data-tooltip': 'Template Settings',
            'data-tooltip-pos': 'bottom'
          }
        });
        $('.btn-global-setting').insertBefore('.view-in-browser');
      }
    }

    const viewPanel = panelManager.addPanel({
      id: 'views',
      buttons: [
        {
          id: 'open-blocks',
          command: 'open-blocks',
          className: 'fa fa-desktop',
          attributes: { ['title']: 'Blocks' },
          active: true,
        },
      ],
    });

    const viewStylePanel = panelManager.addPanel({
      id: 'view-style',
      buttons: [
        {
          id: 'open-style-manager',
          command: 'open-sm',
          className: 'fa fa-paint-brush btn-view-style-manager',
          attributes: { ['title']: 'Styling' },
          active: false,
        },
      ],
    });

    // const viewElQdPanel = panelManager.addPanel({
    //   id: 'el-qd-menu',
    //   buttons: [{
    //     id: 'show-element',
    //     command: 'show-element',
    //     className: 'btn-el-qd-menu btn-el-menu inactive',
    //     attributes: {['title']: 'Elements'},
    //     content: 'hello'
    //   },
    //   // {
    //   //   id: 'show-quick-design',
    //   //   command: 'show-quick-design',
    //   //   className: 'btn-el-qd-menu btn-qd-menu',
    //   //   attributes: {['title']: 'Quick Designs'}
    //   // }
    //   ]
    // });

    commands.add('show-element', {
      run: function (editor, sender) {
        sender.set('active', false);
        const conElQd = $('.gjs-pn-views-container');
        conElQd.find('.gjs-block-category').show();

        conElQd.find('.qd-box').parents('.gjs-block-category').hide();
      },
    });

    commands.add('show-quick-design', {
      run: function (editor, sender) {
        sender.set('active', false);
        const conElQd = $('.gjs-pn-views-container');
        conElQd.find('.gjs-block-category').hide();

        conElQd.find('.qd-box').parents('.gjs-block-category').show();
      },
    });

    commands.add('back', {
      run: function (editor, sender) {
        sender.set('active', false);
        const backUrl = $this.campaignService.backUrl;

        if (backUrl !== null) {
          return (window.location.href = backUrl);
        }

        return (window.location.href = '/mydesigns');
      },
    });

    commands.add('reset-design', {
      run: function (editor, sender) {
        sender.set('active', false);

        let isResetDesign = false;
        let titleText = 'This Design has no reference to its original template and cannot be reset.';
        if (typeof $this.template.reference_template_id !== 'undefined' && $this.template.reference_template_id) {
          isResetDesign = true;
          titleText = 'This will reset the Design to its original template and remove any changes you have made.';
        }

        swal({
          title: titleText,
          type: 'warning',
          showCancelButton: isResetDesign,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok',
        })
        .then(async function () {
          if (isResetDesign) {
            $this.loading = true;
            if (typeof $this.template.reference_template_id !== 'undefined' && $this.template.reference_template_id) {
              let params = {
                templateId: $this.template.ID,
                referenceTemplateId: $this.template.reference_template_id
              }
              const referenceData = await $this.templateService.resetDesignTemplateDataAsync(params).toPromise();
              setTimeout(()=> {
                $this.loading = false;
                window.location.reload();
              }, 1000);
            }
          }
        })
        .catch(swal.noop);
      },
    });

    commands.add('undo', {
      run: function (editor, sender) {
        
        sender.set('active', false);
        editor.UndoManager.undo(true);
      },
    });

    commands.add('redo', {
      run: function (editor, sender) {
        sender.set('active', false);
        editor.UndoManager.redo(true);
      },
    });

    commands.add('populate-listing', {
      run: function (editor, sender) {
        if (!$this.isVaultFirstLoad) {
          sender.set('active', false);
        }
        $this.loading = false;

        const iframe = $($this.builder.nativeElement).find('iframe').contents();

        $this.editor.select(iframe.find('.md-prop-box')[0]);

        setTimeout(() => {
          const model = editor.getSelected();

          const mdPropBox = iframe.find('.md-prop-box');

          const element_id = mdPropBox.data('id');
          const element_limit = mdPropBox.data('limit');

          if (typeof element_id !== 'undefined') {
            $this.element_parent_id = element_id;
            // tslint:disable-next-line:radix
            $this.element_listing_limit = parseInt(element_limit);
          }

          if (typeof element_limit === 'undefined' && $this.template.category_id === 6) {
            $this.element_listing_limit = 1;
          }

          $this.element_listing_index = 0;
          $this.elementListingHtml = [];

          if ($this.element_parent_id > 0) {
            $this.subLoading = true;
            $this.elementService.httpGetElementListingHtml($this.element_parent_id);
            $this.ref.detectChanges();

            $this.loading = true;
          }
        }, 500);
      },
    });

    if (this.template.category_id === 4 || this.template.category_id === 5) {
      if (this.frontService.authService.getApp() === 'MyDesign3') {
        $('.btn-html-send').addClass('hidden');
      } else {
        $('.btn-html-new-send').addClass('hidden');
      }
      $('.gjs-pn-devices').addClass('hidden');
    }

    if (this.template.category_id !== 7) {
      $('.btn-html-html').addClass('hidden');
    }

    if (this.template.category_id !== 6) {
      if (this.frontService.authService.getApp() === 'MyDesign3') {
        $('.fa-facebook').addClass('hidden');
        $('.fa-twitter').addClass('hidden');
        $('.fa-linkedin').addClass('hidden');
      } else {
        $('.icon-facebook').addClass('hidden');
        $('.icon-twitter').addClass('hidden');
        $('.icon-linkedin').addClass('hidden');
      }
    }

    commands.add('set-device-desktop', {
      run: function (editor, sender) {
        sender.set('active', true);
        editor.setDevice('Desktop View');
      },
    });
    commands.add('set-device-mobile', {
      run: function (editor, sender) {
        sender.set('active', true);
        editor.setDevice('Mobile portrait');
      },
    });

    commands.add('global-template-setting', {
      run: function (editor, sender) {
        sender.set('active', false);
        $this.closeSideBarSettings();
        $('.container-global').show('slide', $this.slideHideSpeed);
      }
    });
    commands.add('clean-all', {
      run: function (editor, sender) {
        sender.set('active', false);
        swal({
          title: 'Are you sure you want to clean the canvas?',
          text: "You won't be able to revert this!",
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'CLEAN',
        })
          .then(function () {
            editor.DomComponents.clear();
          })
          .catch(swal.noop);
      },
    });

    commands.add('highres-pdf', {
      run: function (editor, sender) {
        $this.is_save = false;
        const btnOffset = $('.btn-html-highres-pdf').offset();
        $('.highres-pdf').css('left', `${btnOffset.left - 15}px`);
        $('.highres-pdf').removeClass('hidden');

        setTimeout(() => {
          $(document).on('click', function (e) {
            if ($(e.target).closest('.highres-pdf').length === 0) {
              $('.highres-pdf').addClass('hidden');

              $(document).off('click');
              sender.set('active', false);
            }
          });
        });
      },
      stop: function (editor, sender) {
        $(document).off('click');
        $('.highres-pdf').addClass('hidden');
      },
    });

    commands.add('save-as-image', {
      run: function (editor, sender) {
        $this.is_save = false;
        const btnOffset = $('.btn-html-img').offset();
        $('.save-as-image').css('left', `${btnOffset.left - 25}px`);
        $('.save-as-image').removeClass('hidden');

        setTimeout(() => {
          $(document).on('click', function (e) {
            if ($(e.target).closest('.save-as-image').length === 0) {
              $('.save-as-image').addClass('hidden');

              $(document).off('click');
              sender.set('active', false);
            }
          });
        });
      },
      stop: function (editor, sender) {
        $(document).off('click');
        $('.save-as-image').addClass('hidden');
      },
    });

    commands.add('save', {
      run: function (editor, sender) {
        sender.set('active', false);

        // tslint:disable-next-line:forin
        if ($this.ckeditor_active) {
          for (const name in CKEDITOR.instances) {
            if (name) {
              CKEDITOR.instances[name].destroy(true);
            }
          }
        }

        swal({
          title: 'Are you sure you want to save the changes?',
          text: "You won't be able to revert this!",
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'SAVE',
        })
          .then(function () {
            $this.loading = true;
            setTimeout(() => {
              const _valid = $this.saveHtml(editor);
              if (!_valid) {
                return;
              }
              $this.is_save = true;
            }, 200);
          })
          .catch(swal.noop);
      },
    });

    commands.add('upload', {
      run: function (editor, sender) {
        sender.set('active', false);
        $('#uploadHugConnect').modal('show');
        $this.pullFilingCabinetFolders();
      },
    });
    commands.add('send', {
      run: function (editor, sender) {
        sender.set('active', false);
        if (!$this.isHarcourtsAu) {
          $('#sendEmailModal').modal('show');       
          $this.vaultShareUsers = $this.authUser.client.users.map((user: any) => {
          return {
            name: user.firstname + ' ' + user.lastname,
            ID: user.ID,
            agent_id: user.agent_id,
            isSelected: false,
            isVisible: true,
            is_active: Number(user.is_active), // Convert to number
          };
        }).filter((user: any) => user.ID !== $this.authUser.user.ID && user.is_active === 1); 
          $this.httpGetVaultTemplate($this.template.vault_template_id);
        } else {
          $this.savePopupShow = false;
          const _valid = $this.saveHtml(editor);
          if (!_valid) {
            return;
          }
    
          let link = 'secure';
    
          if ($this.authUser.brandid === '12' || $this.authUser.brandid === '19') {
            link = 'rwg';
          }
          if ($this.authUser.brandid === '13' || $this.authUser.brandid === '15') {
            link = 'rhcompass';
          }
    
          if ($this.authUser.provider === 'mydesktop') {
            if ($this.template.category_id === 2) {
              swal({
                title: 'Send',
                type: 'info',
                html:
                  // tslint:disable-next-line:max-line-length
                  '<a onclick="onClickSend(\'' +
                  link +
                  "', " +
                  "'mydesktop.com.au/cgi-bin/clients/agents/propsearch2.cgi'" +
                  ')" class="btn btn-primary">Send</a>',
                showCancelButton: false,
                showConfirmButton: false,
                focusConfirm: false,
              });
            } else if ($this.template.category_id === 3) {
              swal({
                title: 'Send',
                type: 'info',
                html:
                  // tslint:disable-next-line:max-line-length
                  '<a onclick="onClickSend(\'' +
                  link +
                  "', " +
                  "'mydesktop.com.au/cgi-bin/clients/agents/version6/quickenquiry/managev2.cgi'" +
                  ')" class="btn btn-primary">Send</a>',
                showCancelButton: false,
                showConfirmButton: false,
                focusConfirm: false,
              });
            } else {
              swal({
                title: 'Who do you want to send to?',
                type: 'info',
                html:
                  // tslint:disable-next-line:max-line-length
                  '<a onclick="onClickSend(\'' +
                  link +
                  "', " +
                  "'mydesktop.com.au/cgi-bin/clients/agents/version6/addressbook/addressbook.cgi'" +
                  ')" class="btn btn-primary">A single contact</a> &nbsp; <a onclick="onClickSend(\'' +
                  link +
                  "', " +
                  "'mydesktop.com.au/cgi-bin/clients/agents/version6/marketreport4/manage.cgi'" +
                  ')" class="btn btn-info">Multiple Contacts</a>',
                showCancelButton: false,
                showConfirmButton: false,
                focusConfirm: false,
              });
            }
          }
    
          if ($this.authUser.provider === 'vaultre') {
            $this.isSendToVault = true;
          }
    
          if ($this.authUser.provider === 'designly') {
            $this.isSendToDesignlyMail = true;
          }
        }
      },
    });

    commands.add('tour', {
      run: function (editor, sender) {
        sender.set('active', false);

        const intro = introJs();

        intro.setOption('disableInteraction', true);
        intro.setOption('exitOnOverlayClick', false);
        intro.setOption('hidePrev', true);
        intro.setOption('hideNext', true);
        intro.setOption('showStepNumbers', false);
        intro.setOption('overlayOpacity', '0.5');

        intro.addSteps([
          {
            element: document.querySelector('.btn-html-tour'),
            intro: 'Take a Tour - Template Builder - Provides a step-by-step guide to the Template Builder.',
          },
          {
            element: document.querySelector('.html-template-title'),
            intro: 'Template name - Here you can view and change the name for your Template.',
          },
          {
            element: document.querySelector('.btn-el-menu'),
            // tslint:disable-next-line:max-line-length
            intro: 'Elements - Here you can build your Template from scratch by dragging the elements onto the canvas then arranging and editing the Template as required.',
          },
          {
            element: document.querySelector('.btn-qd-menu'),
            intro: 'Template - Templates are a group of elements that make up a complete template.',
          },
          {
            element: document.querySelector('.gjs-pn-devices'),
            // tslint:disable-next-line:max-line-length
            intro: 'Accessibility Mode - Here you can toggle between views to see how the Template will be viewed on Desktop or Mobile devices (This can only be used in eMarketing Templates).',
          },
          {
            element: document.querySelector('.fa-undo'),
            intro: 'Undo Button - Here you can undo your last action if you made a mistake.',
          },
          {
            element: document.querySelector('.fa-repeat'),
            intro: 'Redo Button - Here you can redo your last action if it was undone by mistake.',
          },
          {
            // tslint:disable-next-line:max-line-length
            element: document.querySelector($this.frontService.authService.getApp() === 'MyDesign3' ? '.btn-html-send' : '.btn-html-new-send'),
            intro: 'Send - This will link you to MyDesktop where you can send the Template to your database.',
          },
          {
            element: document.querySelector('.btn-html-save'),
            intro: 'Save - This will save the Template in its current state.',
          },
          {
            element: document.querySelector('.html-trash'),
            intro: 'Delete - Here you can delete the Template and this CANNOT BE UNDONE.',
          },
          {
            element: document.querySelector('.html-arrow'),
            intro: 'Fullscreen - Here you can expand the Template to view it in fullscreen mode',
          },
          {
            element: document.querySelector('.fa-arrow-left'),
            intro: 'Back to Dashboard - Here you can go back to the Dashboard however this will not save your Template.',
          },
        ]);

        intro.start().onchange(function (element) {
          const step = this._currentStep;

          const bg_00a9dd_steps = [1, 4, 5, 6, 7, 8, 9, 10, 11];

          if (bg_00a9dd_steps.includes(step)) {
            setTimeout(() => {
              $('.introjs-helperLayer').css('background-color', $this.mainColor);
            });
          }
        });
      },
    });

    commands.add('help', {
      run: function (editor, sender) {

        $('.help-box-control').removeClass('hidden');

        const btnOffset = $('.btn-html-help').offset();

        if ($this.template.category_id === 10 || $this.template.category_id === 11 || $this.template.category_id === 2) {
          $('.help-box').css('left', `${btnOffset.left - 10}px`);
        } else {
          $('.help-box').css('left', `${btnOffset.left - 10}px`);
        }

        setTimeout(() => {
          $(document).on('click', function (e) {
            if ($(e.target).closest('.help-box-control').length === 0) {
              $('.help-box-control').addClass('hidden');

              $(document).off('click');
              sender.set('active', false);
            }
          });
        });
      },
      stop: function (editor, sender) {
        $(document).off('click');
        $('.help-box-control').addClass('hidden');
      },
    });

    commands.add('view-in-browser', {
      run: function (editor, sender) {
        sender.set('active', false);

        $this.onView('browser');
      },
    });

    commands.add('design-print', {
      run: function (editor, sender) {
        setTimeout(() => {
          const html = $($this.builder.nativeElement).find('iframe').contents();

          const popupWindow = window.open('', '_blank');
          popupWindow.document.open();
          popupWindow.document.write(new XMLSerializer().serializeToString(html.get()[0]));
          popupWindow.document.close();
          popupWindow.window.frames.focus();
          popupWindow.window.frames.print();

          $this.ref.detectChanges();
        }, 800);
      },
    });

    if (this.authUser.provider !== 'designly') {
      commands.add('saveoptions', {
        run: function (editor, sender) {
          const btnOffset = $('.btn-html-save-campaign').offset();

          if ($this.template.category_id === 10 || $this.template.category_id === 11 || $this.template.category_id === 2) {
            $('.save-options').css('left', `${btnOffset.left - 150}px`);
          } else {
            // $('.save-options').css('left', `${btnOffset.left - 73}px`);
            $('.save-options').css('left', `${btnOffset.left - 150}px`);
          }

          $('.save-options').removeClass('hidden');

          setTimeout(() => {
            $(document).on('click', function (e) {
              if ($(e.target).closest('.save-options').length === 0) {
                $('.save-options').addClass('hidden');

                $(document).off('click');
                sender.set('active', false);
              }
            });
          });
        },
        stop: function (editor, sender) {
          $(document).off('click');
          $('.save-options').addClass('hidden');
        },
      });
    }

    commands.add('pricefinder-search', {
      run: function (editor, sender) {
        $($this.mdPricefinderSearchModal.nativeElement).modal('show');

        if (typeof sender.set === 'function') {
          sender.set('active', false);
        }

        const body = editor.Canvas.getBody();
        const html = $(body).find('#wrapper');

        const maxListings = html.find('.market-snapshot').data('limit');

        const segmentationChart = {};
        const pricetrendChart = {};
        const map = {};

        // Segmentation Chart
        const segmentationChartParentHeight = html.find('.md-pricefinder-segmentation-image').data('image-parentheight');
        if (typeof segmentationChartParentHeight !== 'undefined') {
          segmentationChart['parentHeight'] = segmentationChartParentHeight;
        }

        const segmentationChartCanvasHeight = html.find('.md-pricefinder-segmentation-image').data('image-canvasheight');
        if (typeof segmentationChartCanvasHeight !== 'undefined') {
          segmentationChart['canvasHeight'] = segmentationChartCanvasHeight;
        }

        const segmentationChartParentWidth = html.find('.md-pricefinder-segmentation-image').data('image-parentwidth');
        if (typeof segmentationChartParentWidth !== 'undefined') {
          segmentationChart['parentWidth'] = segmentationChartParentWidth;
        }

        const segmentationChartCanvasWidth = html.find('.md-pricefinder-segmentation-image').data('image-canvaswidth');
        if (typeof segmentationChartCanvasWidth !== 'undefined') {
          segmentationChart['canvasWidth'] = segmentationChartCanvasWidth;
        }

        const segmentationChartHouseColor = html.find('.md-pricefinder-segmentation-image').data('house-color');
        if (typeof segmentationChartHouseColor !== 'undefined') {
          segmentationChart['house-color'] = segmentationChartHouseColor;
        }

        const segmentationChartUnitColor = html.find('.md-pricefinder-segmentation-image').data('unit-color');
        if (typeof segmentationChartUnitColor !== 'undefined') {
          segmentationChart['unit-color'] = segmentationChartUnitColor;
        }

        // Price Trend
        const pricetrendChartParentHeight = html.find('.md-pricefinder-price-trend-image').data('image-parentheight');
        if (typeof pricetrendChartParentHeight !== 'undefined') {
          pricetrendChart['parentHeight'] = pricetrendChartParentHeight;
        }

        const pricetrendChartCanvasHeight = html.find('.md-pricefinder-price-trend-image').data('image-canvasheight');
        if (typeof pricetrendChartCanvasHeight !== 'undefined') {
          pricetrendChart['canvasHeight'] = pricetrendChartCanvasHeight;
        }

        const pricetrendChartParentWidth = html.find('.md-pricefinder-price-trend-image').data('image-parentwidth');
        if (typeof pricetrendChartParentWidth !== 'undefined') {
          pricetrendChart['parentWidth'] = pricetrendChartParentWidth;
        }

        const pricetrendChartCanvasWidth = html.find('.md-pricefinder-price-trend-image').data('image-canvaswidth');
        if (typeof pricetrendChartCanvasWidth !== 'undefined') {
          pricetrendChart['canvasWidth'] = pricetrendChartCanvasWidth;
        }

        const pricetrendChartHouseColor = html.find('.md-pricefinder-price-trend-image').data('house-color');
        if (typeof pricetrendChartHouseColor !== 'undefined') {
          pricetrendChart['house-color'] = pricetrendChartHouseColor;
        }

        const pricetrendChartUnitColor = html.find('.md-pricefinder-price-trend-image').data('unit-color');
        if (typeof pricetrendChartUnitColor !== 'undefined') {
          pricetrendChart['unit-color'] = pricetrendChartUnitColor;
        }

        // Map
        const mapHeight = html.find('.md-pricefinder-recent-history-map-image').data('image-height');
        if (typeof mapHeight !== 'undefined') {
          map['height'] = mapHeight;
        }

        const mapWidth = html.find('.md-pricefinder-recent-history-map-image').data('image-width');
        if (typeof mapWidth !== 'undefined') {
          map['width'] = mapWidth;
        }

        const mapPinColor = html.find('.md-pricefinder-recent-history-map-image').data('pin-bg-color');
        if (typeof mapPinColor !== 'undefined') {
          map['pin-color'] = mapPinColor;
        }

        const mapPinTextColor = html.find('.md-pricefinder-recent-history-map-image').data('pin-text-color');
        if (typeof mapPinTextColor !== 'undefined') {
          map['pin-text-color'] = mapPinTextColor;
        }

        const mapScaleHeight = html.find('.md-pricefinder-recent-history-map-image').data('pin-height');
        if (typeof mapScaleHeight !== 'undefined') {
          map['scaledHeight'] = mapScaleHeight;
        }

        const mapScaleWidth = html.find('.md-pricefinder-recent-history-map-image').data('pin-width');
        if (typeof mapScaleWidth !== 'undefined') {
          map['scaledWidth'] = mapScaleWidth;
        }

        const mapLabelSize = html.find('.md-pricefinder-recent-history-map-image').data('pin-text-size');
        if (typeof mapLabelSize !== 'undefined') {
          map['labelFontSize'] = mapLabelSize;
        }

        const mapZoom = html.find('.md-pricefinder-recent-history-map-image').data('zoom-level');
        if (typeof mapZoom !== 'undefined') {
          map['zoom'] = mapZoom;
        }

        $this.priceFinderMapValues = map;

        $this.priceFinderSuburbData.requestData = {
          segmentationChart: segmentationChart,
          pricetrendChart: pricetrendChart,
          map: map,
          maxListings: maxListings,
        };

        $this.pricefinderService.onSearch.next({
          maxListings: maxListings,
        });
      },
    });

    commands.add('insert-property', {
      run: function (editor, sender) {
        if (typeof sender.set === 'function') {
          sender.set('active', false);
        }
        $this.checkCheckers(editor);
        $this.selectedListings = [];
        $this.propertySearchService.propertyInsertSource = 'button';
        $this.insertPropertyButton = true;
        $this.insertPropertyElement = false;
        $($this.mdPropSearchModal.nativeElement).modal('show');

      },
      stop: function (editor, sender) {
        $(document).off('click');
      },
    });

    commands.add('pricefinder-sharelink', {
      run: function (editor, sender) {
        if (typeof sender.set === 'function') {
          sender.set('active', false);
        }

        $this.sharePriceFinderLinkClicked = true;
        $this.saveHtml(editor, 'pdf');

      },
      stop: function (editor, sender) {
        $(document).off('click');
      },
    });

    commands.add('emarketing-options', {
      run: function (editor, sender) {
        const btnOffset = $('.btn-html-new-send').offset();
        $('.emarketing-options').css('left', `${btnOffset.left - 73}px`);
        $('.emarketing-options').removeClass('hidden');

        setTimeout(() => {
          $(document).on('click', function (e) {
            if ($(e.target).closest('.emarketing-options').length === 0) {
              $('.emarketing-options').addClass('hidden');

              $(document).off('click');
              sender.set('active', false);
            }
          });
        });
      },
      stop: function (editor, sender) {
        $(document).off('click');
        $('.emarketing-options').addClass('hidden');
      },
    });

    commands.add('fbshare', {
      run: function (editor, sender) {
        sender.set('active', false);
        $this.saveHtml(editor, 'img');
        $this.fbButtonClicked = true;
      },
    });

    commands.add('twittershare', {
      run: function (editor, sender) {
        sender.set('active', false);
        $this.saveHtml(editor, 'img');
        $this.twitterButtonClicked = true;
      },
    });

    commands.add('linkedinshare', {
      run: function (editor, sender) {
        sender.set('active', false);
        $this.saveHtml(editor, 'img');
        $this.linkedinButtonClicked = true;
      },
    });

    if (this.template.category_id === 1 || this.template.category_id === 2 || this.template.category_id === 3 || this.template.category_id === 10 || this.template.category_id === 11 || this.template.category_id === 13) {
      $('.btn-html-pdf').addClass('hidden');
      $('.btn-html-highres-pdf').addClass('hidden');
      $('.btn-html-img').addClass('hidden');
    }

    commands.add('pdf', {
      run: function (editor, sender) {
        $this.is_save = false;
        sender.set('active', false);

        if ($this.authUser.client.group_id === 10) {
          swal({
            title: 'Confirm to save this template as PDF',
            confirmButtonText: 'DOWNLOAD PDF',
            confirmButtonClass: 'btn-rural-pdf',
            html: `<div style="text-align: left !important;">
                <ol>
                  <li>I acknowledge that if I
                  have used high quality images that do not have any watermarks.</li>
                  <li>I acknowledge that I am liable to proof check
                  the artwork and ensure images aren’t blurry.</li>
                  <li>I acknowledge that I am
                  liable to supply all final working files to the print company and</li>
                  <li>I acknowledge that I am responsible if
                  artwork is printed and the images are blurry.</li>
                </ol>
                <div class="checkbox">
                  <label>
                  <input id="confirm-1" type="checkbox" onclick="onPdfCheck()" value="1">Check here to indicate that you have read
                   and acknowledge the above.</label>
                </div>
              </div>`,
            preConfirm: function () {
              return new Promise(function (resolve) {
                resolve([$('#confirm-1:checked').val()]);
              });
            },
          })
            .then(function (results) {
              results.forEach((result) => {
                let is_failed = false;
                if (result === undefined) {
                  is_failed = true;
                }

                if (is_failed) {
                  swal('Error', 'You need to agree with the terms to save as PDF', 'error');
                  return false;
                }

                $this.imgButtonClicked = false;
                $this.pdfButtonClicked = true;
                $this.qdtButtonClicked = false;
                $this.saveHtml(editor, 'pdf');
              });
            })
            .catch(swal.noop);
          $('.btn-rural-pdf').attr('disabled', true).css('width', '200px');
        } else {
          swal({
            title: 'Template will be saved before downloading',
            text: "You won't be able to revert this!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'DOWNLOAD',
          })
            .then(function () {
              $this.imgButtonClicked = false;
              $this.pdfButtonClicked = true;
              $this.qdtButtonClicked = false;
              $this.saveHtml(editor, 'pdf');
            })
            .catch(swal.noop);
        }
      },
    });

    commands.add('save-quick', {
      run: function (editor, sender) {
        if ($this.frontService.authService.getApp() === 'MyDesign3') {
          sender.set('active', false);
        }

        swal({
          title: 'Template will be saved before it will be saved as a Template.',
          text: "You won't be able to revert this!",
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Template',
        })
          .then(function () {
            $this.qdtButtonClicked = true;
            $this.qdoButtonClicked = false;
            $this.imgButtonClicked = false;
            $this.pdfButtonClicked = false;
            $this.saveHtml(editor);
          })
          .catch(swal.noop);
      },
    });

    commands.add('save-quick-office', {
      run: function (editor, sender) {
        if ($this.frontService.authService.getApp() === 'MyDesign3') {
          sender.set('active', false);
        }

        swal({
          title: 'Template will be saved before it will be saved as a Template.',
          text: "You won't be able to revert this!",
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Template',
        })
          .then(function () {
            $this.qdoButtonClicked = true;
            $this.qdtButtonClicked = false;
            $this.imgButtonClicked = false;
            $this.pdfButtonClicked = false;
            $this.saveHtml(editor);
          })
          .catch(swal.noop);
      },
    });

    commands.add('view-as', {
      run: function (editor, sender) {
        if ($this.frontService.authService.getApp() === 'MyDesign3') {
          $('.view-as-control').css('right', '215px');
        } else {
          $('.view-as-control').css('right', '132px');
        }
        $('.view-as-control').removeClass('hidden');

        setTimeout(() => {
          $(document).on('click', function (e) {
            if ($(e.target).closest('.view-as-control').length === 0) {
              $('.view-as-control').addClass('hidden');

              $(document).off('click');
              sender.set('active', false);
            }
          });
        });
      },
      stop: function (editor, sender) {
        $(document).off('click');
        $('.view-as-control').addClass('hidden');
      },
    });

    commands.add('xhtml', {
      run: function (editor, sender) {
        const body = editor.Canvas.getBody();
        const plainHtml = $(body).find('#wrapper').html();

        const html = $this.editor.getHtml();

        const html_data_css = $this.editor.getCss();
        let html_raw = juice(html + '<style>' + html_data_css + '</style>', { preserveMediaQueries: false, preserveImportant: true, applyHeightAttributes: false, applyWidthAttributes: false });

        let htmlStyle = $this.style.style;

        // Non-Class Setting - Merge Field
        if ($this.clientSetting.color_1) {
          htmlStyle = htmlStyle.replace(/\{%COLOR1%\}/gi, $this.clientSetting.color_1);
        } else {
          if (this.groupSetting !== null && this.groupSetting !== undefined) {
            htmlStyle = htmlStyle.replace(/\{%COLOR1%\}/gi, $this.groupSetting.color_1);
          }
        }
        if ($this.clientSetting.color_2) {
          htmlStyle = htmlStyle.replace(/\{%COLOR2%\}/gi, $this.clientSetting.color_2);
        } else {
          if (this.groupSetting !== null && this.groupSetting !== undefined) {
            htmlStyle = htmlStyle.replace(/\{%COLOR2%\}/gi, $this.groupSetting.color_2);
          }
        }
        if ($this.clientSetting.color_3) {
          htmlStyle = htmlStyle.replace(/\{%COLOR3%\}/gi, $this.clientSetting.color_3);
        } else {
          if (this.groupSetting !== null && this.groupSetting !== undefined) {
            htmlStyle = htmlStyle.replace(/\{%COLOR3%\}/gi, $this.groupSetting.color_3);
          }
        }
        if ($this.clientSetting.color_4) {
          htmlStyle = htmlStyle.replace(/\{%COLOR4%\}/gi, $this.clientSetting.color_4);
        } else {
          if (this.groupSetting !== null && this.groupSetting !== undefined) {
            htmlStyle = htmlStyle.replace(/\{%COLOR4%\}/gi, $this.groupSetting.color_4);
          }
        }
        if ($this.clientSetting.color_5 && $this.clientSetting.color_5 !== '#') {
          htmlStyle = htmlStyle.replace(/\{%COLOR5%\}/gi, $this.clientSetting.color_5);
        } else {
          if (this.groupSetting !== null && this.groupSetting !== undefined) {
            htmlStyle = htmlStyle.replace(/\{%COLOR5%\}/gi, $this.groupSetting.color_5);
          }
        }
        if ($this.clientSetting.color_6) {
          htmlStyle = htmlStyle.replace(/\{%FONTCOLOR1%\}/gi, $this.clientSetting.color_6);
        } else {
          if (this.groupSetting !== null && this.groupSetting !== undefined) {
            htmlStyle = htmlStyle.replace(/\{%FONTCOLOR1%\}/gi, $this.groupSetting.color_6);
          }
        }
        if ($this.clientSetting.color_7) {
          htmlStyle = htmlStyle.replace(/\{%FONTCOLOR2%\}/gi, $this.clientSetting.color_7);
        } else {
          if (this.groupSetting !== null && this.groupSetting !== undefined) {
            htmlStyle = htmlStyle.replace(/\{%FONTCOLOR2%\}/gi, $this.groupSetting.color_7);
          }
        }
        if ($this.clientSetting.color_8) {
          htmlStyle = htmlStyle.replace(/\{%ICONCOLOR1%\}/gi, $this.clientSetting.color_8);
        } else {
          if (this.groupSetting !== null && this.groupSetting !== undefined) {
            htmlStyle = htmlStyle.replace(/\{%ICONCOLOR1%\}/gi, $this.groupSetting.color_8);
          }
        }

        if ($this.clientSetting.color_9) {
          htmlStyle = htmlStyle.replace(/\{%FONTCOLOR3%\}/gi, $this.clientSetting.color_9);
          htmlStyle = htmlStyle.replace(/\{%FONTCOLOR9%\}/gi, $this.clientSetting.color_9);
        } else {
          if (this.groupSetting !== null && this.groupSetting !== undefined) {
            htmlStyle = htmlStyle.replace(/\{%FONTCOLOR3%\}/gi, $this.groupSetting.color_9);
            htmlStyle = htmlStyle.replace(/\{%FONTCOLOR9%\}/gi, $this.groupSetting.color_9);
          }
        }

        if ($this.clientSetting.color_10) {
          htmlStyle = htmlStyle.replace(/\{%FONTCOLOR4%\}/gi, $this.clientSetting.color_10);
          htmlStyle = htmlStyle.replace(/\{%FONTCOLOR10%\}/gi, $this.clientSetting.color_10);
        } else {
          if (this.groupSetting !== null && this.groupSetting !== undefined) {
            htmlStyle = htmlStyle.replace(/\{%FONTCOLOR4%\}/gi, $this.groupSetting.color_10);
            htmlStyle = htmlStyle.replace(/\{%FONTCOLOR10%\}/gi, $this.groupSetting.color_10);
          }
        }

        if ($this.clientSetting.font_family) {
          htmlStyle = htmlStyle.replace(/\{%FONTFAMILY%\}/gi, $this.clientSetting.font_family);
        } else {
          if (this.groupSetting !== null && this.groupSetting !== undefined) {
            htmlStyle = htmlStyle.replace(/\{%FONTFAMILY%\}/gi, $this.groupSetting.font_family);
          }
        }

        // low res logo
        if ($this.clientSetting.primary_logo) {
          htmlStyle = htmlStyle.replace(/\{%PRIMARYLOGO%\}/gi, $this.clientSetting.primary_logo);
        } else {
          if (this.groupSetting !== null && this.groupSetting !== undefined) {
            htmlStyle = htmlStyle.replace(/\{%PRIMARYLOGO%\}/gi, $this.groupSetting.primary_logo);
          }
        }
        if ($this.clientSetting.secondary_logo) {
          htmlStyle = htmlStyle.replace(/\{%SECONDARYLOGO%\}/gi, $this.clientSetting.secondary_logo);
        } else {
          if (this.groupSetting !== null && this.groupSetting !== undefined) {
            htmlStyle = htmlStyle.replace(/\{%SECONDARYLOGO%\}/gi, $this.groupSetting.secondary_logo);
          }
        }

        // hi res logo
        if ($this.clientSetting.hires_primary_logo) {
          htmlStyle = htmlStyle.replace(/\{%PRIMARYLOGOHIRES%\}/gi, $this.clientSetting.hires_primary_logo);
        } else {
          if (this.groupSetting !== null && this.groupSetting !== undefined) {
            htmlStyle = htmlStyle.replace(/\{%PRIMARYLOGOHIRES%\}/gi, $this.groupSetting.hires_primary_logo);
          }
        }
        if ($this.clientSetting.hires_secondary_logo) {
          htmlStyle = htmlStyle.replace(/\{%SECONDARYLOGOHIRES%\}/gi, $this.clientSetting.hires_secondary_logo);
        } else {
          if (this.groupSetting !== null && this.groupSetting !== undefined) {
            htmlStyle = htmlStyle.replace(/\{%SECONDARYLOGOHIRES%\}/gi, $this.groupSetting.hires_secondary_logo);
          }
        }

        if ($this.clientSetting.primary_icon_bed) {
          htmlStyle = htmlStyle.replace(/\{%PRIMARYICONBED%\}/gi, $this.clientSetting.primary_icon_bed);
        } else {
          if (this.groupSetting !== null && this.groupSetting !== undefined) {
            htmlStyle = htmlStyle.replace(/\{%PRIMARYICONBED%\}/gi, $this.groupSetting.primary_icon_bed);
          }
        }
        if ($this.clientSetting.primary_icon_bath) {
          htmlStyle = htmlStyle.replace(/\{%PRIMARYICONBATH%\}/gi, $this.clientSetting.primary_icon_bath);
        } else {
          if (this.groupSetting !== null && this.groupSetting !== undefined) {
            htmlStyle = htmlStyle.replace(/\{%PRIMARYICONBATH%\}/gi, $this.groupSetting.primary_icon_bath);
          }
        }
        if ($this.clientSetting.primary_icon_car) {
          htmlStyle = htmlStyle.replace(/\{%PRIMARYICONCAR%\}/gi, $this.clientSetting.primary_icon_car);
        } else {
          if (this.groupSetting !== null && this.groupSetting !== undefined) {
            htmlStyle = htmlStyle.replace(/\{%PRIMARYICONCAR%\}/gi, $this.groupSetting.primary_icon_car);
          }
        }
        if ($this.clientSetting.secondary_icon_bed) {
          htmlStyle = htmlStyle.replace(/\{%SECONDARYICONBED%\}/gi, $this.clientSetting.secondary_icon_bed);
        } else {
          if (this.groupSetting !== null && this.groupSetting !== undefined) {
            htmlStyle = htmlStyle.replace(/\{%SECONDARYICONBED%\}/gi, $this.groupSetting.secondary_icon_bed);
          }
        }
        if ($this.clientSetting.secondary_icon_bath) {
          htmlStyle = htmlStyle.replace(/\{%SECONDARYICONBATH%\}/gi, $this.clientSetting.secondary_icon_bath);
        } else {
          if (this.groupSetting !== null && this.groupSetting !== undefined) {
            htmlStyle = htmlStyle.replace(/\{%SECONDARYICONBATH%\}/gi, $this.groupSetting.secondary_icon_bath);
          }
        }
        if ($this.clientSetting.secondary_icon_car) {
          htmlStyle = htmlStyle.replace(/\{%SECONDARYICONCAR%\}/gi, $this.clientSetting.secondary_icon_car);
        } else {
          if (this.groupSetting !== null && this.groupSetting !== undefined) {
            htmlStyle = htmlStyle.replace(/\{%SECONDARYICONCAR%\}/gi, $this.groupSetting.secondary_icon_car);
          }
        }

        html_raw = '<style>\n' + beautify(htmlStyle, { format: 'css' }) + '\n</style>\n' + beautify(html_raw, { format: 'html' });

        swal({
          title: 'Select & Copy',
          type: false,
          html: '<textarea style="width: 100%; height: 300px;">' + html_raw + '</textarea>',
          showConfirmButton: false,
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'COPY',
        }).catch(swal.noop);
      },
    });

    commands.add('md-el-prop-search', {
      run: function (editor, sender) {
        const vaultIntegratedCategories = [2, 10, 11, 13];
        if (vaultIntegratedCategories.includes($this.template.category_id)) {
          const currentPlatform = $this.frontService.authService.isUk() ? 'VaultEA' : 'VaultRE';
          let textNote = 'If you are wanting to insert your properties manually please try using a Market Report template instead.';
          
          if ($this.template.category_id === 10 ) {
            textNote = 'If you are wanting to insert your properties manually please try using a Property Guide or Stocklist template instead.';
          }

          if ($this.template.category_id === 13) {
            textNote = 'If you are wanting to insert your properties manually please try using a Newsletter or Market Report template instead.';
          }

          swal(`Unavailable`, `This is a ${$this.template.category.title} template where property elements are locked down, using this template in ${currentPlatform} will automatically populate your properties here. <br><br> <b>Please note</b> ${textNote}`, 'warning');
          return false;
        }

        $this.isReplace = false;
        const model = editor.getSelected();

        const mdPropBox = model.view.$el.parents('.md-box');
        let propBox = null;
        
        const element_id = mdPropBox.data('id');
        const element_limit = mdPropBox.data('limit');
        const elementMax = mdPropBox.data('limitMax');

        if (mdPropBox.hasClass('md-prop-single-box')) {
          propBox = model.view.$el.parents('.md-prop-box');
        }

        $this.checkCheckers(editor);

        const clickQuery = {
          listingtype: '',
          classification: '',
          element_id: '',
          orderby: 'modifydateDesc',
        };

        this.selectedListingIDs = [];
        $('#mdPropSearch').find('#propertyList > ul').children('li').removeClass('selected');

        if (typeof elementMax !== 'undefined') {
          $this.maxLimit = parseInt(elementMax);
        }

        if (typeof element_id !== 'undefined') {
          $this.element_parent_id = element_id;
          // tslint:disable-next-line:radix
          $this.element_listing_limit = parseInt(element_limit);

          clickQuery.element_id = element_id;
        }

        if (typeof element_limit === 'undefined' && $this.template.category_id === 6) {
          $this.element_listing_limit = 1;
        }

        $this.element_listing_index = 0;
        $this.elementListingHtml = [];

        if ($this.element_parent_id > 0) {
          $this.subLoading = true;
          $this.elementService.httpGetElementListingHtml($this.element_parent_id);
          $this.ref.detectChanges();
        }

        if (propBox !== null) {
          $this.element_listing_index = propBox.data('index');
        }

        $this.propertySearchService.propertyInsertSource = null;
        $this.selectedListings = [];
        $($this.mdPropSearchModal.nativeElement).modal('show');

      },
    });

    commands.add('md-el-prop-replace', {
      run: function (editor, sender) {
        const model = editor.getSelected();

        const mdPropBox = model.view.$el.parents('.md-box');
        let propBox = null;

        const element_id = mdPropBox.data('id');
        const element_limit = mdPropBox.data('limit');

        if (mdPropBox.hasClass('md-prop-single-box')) {
          propBox = model.view.$el.parents('.md-prop-box');
        }

        $this.checkCheckers(editor);

        const clickQuery = {
          listingtype: '',
          classification: '',
          element_id: '',
          orderby: 'modifydateDesc',
        };

        this.selectedListingIDs = [];
        $('#mdPropSearch').find('#propertyList > ul').children('li').removeClass('selected');

        if (typeof element_id !== 'undefined') {
          $this.element_parent_id = element_id;
          // tslint:disable-next-line:radix
          $this.element_listing_limit = parseInt(element_limit);

          clickQuery.element_id = element_id;
        }

        if (typeof element_limit === 'undefined' && $this.template.category_id === 6) {
          $this.element_listing_limit = 1;
        }

        $this.element_listing_index = 0;
        $this.elementListingHtml = [];

        if ($this.element_parent_id > 0) {
          $this.subLoading = true;
          $this.elementService.httpGetElementListingHtml($this.element_parent_id);
          $this.ref.detectChanges();
        }

        if (propBox !== null) {
          $this.element_listing_index = propBox.data('index');
        }

        $this.isReplace = true;

        $this.selectedListings = [];
        $($this.mdPropSearchModal.nativeElement).modal('show');
      },
    });

    commands.add('open-crop-modal', (editor) => {
      const selectedComponent = editor.getSelected();
      const imageAttributes = selectedComponent.getAttributes();

      if (typeof imageAttributes.src !== 'undefined' && imageAttributes.src) {
        this.cropImageUrl = `${this.frontService.appConfig.API_ENDPOINT}/settings/image?url=${imageAttributes.src}`;
        if (this.frontService.appConfig.PRODUCTION) {
          this.cropImageUrl = imageAttributes.src + "?t=" + Date.now();
        }

        this.cropImageModal.show();
      }

    });

    commands.add('md-el-image-gallery', {
      run: function (editor) {
        // $this.showImageLibrary = true;
        const model = editor.getSelected();
        const mdPropBox = model.view.$el.parents('.md-prop-box');

        $this.subLoading = true;
        $this.selectedFileHolder = model.view.el;
        $this.selectedFile = [];
        //_ $('#mdAgentsSearch').modal('show');
        $($this.mdLibrarySearchModal.nativeElement).modal('show');

        $this.imageService.openImageLibrary();
        $this.ref.detectChanges();
      },
    });

    commands.add('md-el-dsgnly-agent', {
      run: function (editor) {
        $this.selectedAgents = [];
        $('#mdAgentsSearch').modal('show');

        $this.ref.detectChanges();
      },
    });

    commands.add('md-el-article-search', {
      run: function () {
        $this.showPartica = true;
        $($this.mdParticaSearchModal.nativeElement).modal('show');

        $this.ref.detectChanges();
      },
    });

    commands.add('md-el-image-fit', {
      run: function (editor) {
        const model = editor.getSelected();
        const imgEl = model.view.$el;

        imgEl.removeAttr('style');

        const elImgStyle = model.getStyle();
        elImgStyle.width = imgEl.width();
        elImgStyle.height = imgEl.height();
        model.set('style', elImgStyle);

        imgEl.css({ top: 0, left: 0, transform: 'scale(1.0)' });
      },
    });

    commands.add('md-el-image-zoom', {
      run: function (editor) {
        const selectedComponent = $this.editor.getSelected();

        if ($this.letZoom) {
          $this.letZoom = false;
          return $this.disableZoom();
        }

        if (typeof selectedComponent !== 'undefined' && typeof selectedComponent.view !== 'undefined') {
          $this.letZoom = true;
          $this.enableZoom(selectedComponent.view.$el);

          $('.icon-move').css('background-color', 'rgb(0 0 0 / 16%)');
          return true;
        }

        return false;
      },
    });

    commands.add('md-el-prop-search-delete', {
      run: function (editor) {
        const selectedComponent = $this.editor.getSelected();

        const parentEl = selectedComponent.view.$el.parent();

        parentEl.remove();

        $('.gjs-toolbar').css('display', 'none');
      },
    });

    commands.add('md-el-prop-edit-text', {
      run: function (editor) {
        const selectedComponent = $this.editor.getSelected();
        const componentElement = selectedComponent.view.el;
        componentElement.contentEditable = 'true';
        
        const doubleClickEvent = new MouseEvent('dblclick');
        componentElement.dispatchEvent(doubleClickEvent);
     },
    });

    commands.add('md-el-lock', {
      run: function (editor) {
        const selected = editor.getSelected();
        if (typeof selected.view !== 'undefined') {
          if (selected.view.$el.hasClass('md-lock')) {
            selected.removeClass('md-lock');
            selected.setAttributes({
              'data-lock-id': null,
              'data-lock-user': null,
            });
          } else {
            swal({
              title: 'Select Locking Option',
              input: 'radio',
              inputOptions: {
                editable: 'Content can be edited',
                restricted: 'Content cannot be edited',
              },
            }).then((result) => {
              if (result === null) {
                swal('Error', 'You need to select an option', 'error').catch(swal.noop);
              } else {
                selected.addClass('md-lock');
                selected.addAttributes({
                  'data-lock-id': $this.template.client_id,
                  'data-lock-user': $this.frontService.authService.auth.agentid,
                  'data-lock-type': result,
                });
              }
            });
          }

          editor.select();
        }
      },
    });

    commands.add('md-el-prop-delete-text', {
      run: function (editor) {
        const selectedComponent = $this.editor.getSelected();

        const selectedEl = selectedComponent.view;

        selectedEl.remove();

        $('.gjs-toolbar').css('display', 'none');
      },
    });

    commands.add('md-elm-tmp-setting', {
      run: (editor) => {
        $this.closeSideBarSettings();
        $('.container-element-setting').show('slide', $this.slideHideSpeed);
      }
    });

    commands.add('md-el-locked', {
      run: function (editor) {
        swal({
          title: 'This element is locked and has limited editing options available.',
          type: false,
          showConfirmButton: false,
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'CLOSE',
        });
      },
    });

    commands.add('md-element-palette', {
      run: function (editor) {
        $('.icon-palette').asColorPicker('open');
        const selectedElement = editor.getSelected();

        $this.color_selected_element = selectedElement.view.$el;
      },
    });

    if (this.template.category_id === 6) {
      if (this.frontService.authService.getApp() === 'MyDesign3') {
        $('.btn-html-send').addClass('hidden');
      } else {
        $('.btn-html-new-send').addClass('hidden');
      }
      $('.btn-html-pdf').addClass('hidden');
      $('.btn-html-highres-pdf').addClass('hidden');
      $('.html-mobile').addClass('hidden');
      $('.html-desktop').addClass('hidden');
    }

    if (this.template.category_id !== 6 && this.template.category_id !== 1 && this.template.category_id !== 4 && this.template.category_id !== 9) {
      $('.btn-html-img').addClass('hidden');
    }

    if (this.template.category_id !== 6 && this.template.category_id !== 1) {
      if (this.frontService.authService.getApp() === 'MyDesign3') {
        $('.btn-html-send').addClass('hidden');
      } else {
        $('.btn-html-new-send').addClass('hidden');
      }
    }

    if (this.template.category_id === 7) {
      if (this.frontService.authService.getApp() === 'MyDesign3') {
        $('.btn-html-send').addClass('hidden');
      } else {
        $('.btn-html-new-send').addClass('hidden');
      }
      $('.btn-html-pdf').addClass('hidden');
      $('.btn-html-highres-pdf').addClass('hidden');
      $('.btn-html-img').removeClass('hidden');
    }

    if (!this.emailCategories.includes(this.template.category_id) || this.template.category_id === 13) {
      $('.view-in-browser').addClass('hidden');
    }

    if (this.template.category_id !== 1) {
      $('.design-print').addClass('hidden');
    }

    commands.add('img', {
      run: function (editor, sender) {
        sender.set('active', false);

        swal({
          title: 'Template will be saved before downloading',
          text: "You won't be able to revert this!",
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'DOWNLOAD',
        })
          .then(function () {
            $this.pdfButtonClicked = false;
            $this.imgButtonClicked = true;
            $this.qdtButtonClicked = false;
            $this.saveHtml(editor, 'img');
          })
          .catch(swal.noop);
      },
    });

    commands.add('md-el-listing-update', {
      run: function (editor) {
        const model = editor.getSelected();

        try {
          const listingId = model.view.attr['data-listing-id'];

          if (listingId) {
            const templateListingIndex = $this.templateListings.findIndex((templateListing) => templateListing.listing_id === listingId);

            if (templateListingIndex !== -1) {
              $this.checkCheckers(editor);

              if ($this.authUser.provider === 'mydesktop') {
                $this.getCustomParams($this.templateListings[templateListingIndex].listing_id, 1);
              }

              if ($this.authUser.provider === 'vaultre') {
                const queryParams = $this.templateListings[templateListingIndex].query_params;

                if ($this.checkOwners) {
                  queryParams['checkOwners'] = 1;
                } else {
                  queryParams['checkOwners'] = 0;
                }

                if ($this.checkTenants) {
                  queryParams['checktTenants'] = 1;
                } else {
                  queryParams['checktTenants'] = 0;
                }

                if ($this.checkSalePrice) {
                  queryParams['checkSalePrice'] = 1;
                } else {
                  queryParams['checkSalePrice'] = 0;
                }

                if ($this.checkInspections) {
                  queryParams['checkInspections'] = 1;
                } else {
                  queryParams['checkInspections'] = 0;
                }

                if ($this.checkCustomFields) {
                  queryParams['checkCustomFields'] = 1;
                } else {
                  queryParams['checkCustomFields'] = 0;
                }

                if ($this.checkRooms) {
                  queryParams['checkRooms'] = 1;
                } else {
                  queryParams['checkRooms'] = 0;
                }

                if ($this.checkMapImage) {
                  queryParams['checkMapImage'] = 1;
                } else {
                  queryParams['checkMapImage'] = 0;
                }

                if ($this.checkFeatures) {
                  queryParams['checkFeatures'] = 1;
                } else {
                  queryParams['checkFeatures'] = 0;
                }

                if ($this.checkPropertyOffers) {
                  queryParams['checkPropertyOffers'] = 1;
                } else {
                  queryParams['checkPropertyOffers'] = 0;
                }

                if ($this.checkPropertyAdvertising) {
                  queryParams['checkPropertyAdvertising'] = 1;
                } else {
                  queryParams['checkPropertyAdvertising'] = 0;
                }

                if ($this.checkPropertySolicitor) {
                  queryParams['checkPropertySolicitor'] = 1;
                } else {
                  queryParams['checkPropertySolicitor'] = 0;
                }

                if ($this.checkPropertyOfferConditions) {
                  queryParams['checkPropertyOfferConditions'] = 1;
                } else {
                  queryParams['checkPropertyOfferConditions'] = 0;
                }

                queryParams['sync_data'] = 1;

                $this.vaultService.httpGetListing($this.templateListings[templateListingIndex].listing_id, queryParams);
              }

              if ($this.authUser.provider === 'domain') {
                $this.domainService.httpGetListing($this.templateListings[templateListingIndex].listing_id, {
                  sync_data: 1,
                });
              }

              $this.loading = true;
              $this.ref.detectChanges();
              return true;
            }
          }
        } catch (listingUpdateErr) {
          console.error(listingUpdateErr);
        }

        swal('No Listing Data available', '', 'error');
      },
    });
    commands.add('md-edit-property-icons', {
      run: function (editor) {
        $this.closeSideBarSettings();
        $('.container-element-property-icons').show('slide', $this.slideHideSpeed);
      }
    });

    commands.add('md-element-insert-property', {
      run: function (editor) {

        $this.isReplace = false;
        const model = editor.getSelected();
        const mdPropBox = model.view.$el;
        let propBox = null;

        const element_id = mdPropBox.data('id');
        const element_limit = mdPropBox.data('limit');
        const elementMax = mdPropBox.data('limitMax');

        if (mdPropBox.hasClass('md-prop-single-box')) {
          propBox = model.view.$el.parents('.md-prop-box');
        }

        $this.checkCheckers(editor);

        const clickQuery = {
          listingtype: '',
          classification: '',
          element_id: '',
          orderby: 'modifydateDesc',
        };

        this.selectedListingIDs = [];
        $('#mdPropSearch').find('#propertyList > ul').children('li').removeClass('selected');

        if (typeof elementMax !== 'undefined') {
          $this.maxLimit = parseInt(elementMax);
        }

        if (typeof element_id !== 'undefined') {
          $this.element_parent_id = element_id;
          // tslint:disable-next-line:radix
          $this.element_listing_limit = parseInt(element_limit);

          clickQuery.element_id = element_id;
        }

        if (typeof element_limit === 'undefined' && $this.template.category_id === $this.templateCategoryId.SOCIAL_MEDIA) {
          $this.element_listing_limit = 1;
        }

        $this.element_listing_index = 0;
        $this.elementListingHtml = [];

        if ($this.element_parent_id > 0) {
          $this.subLoading = true;
          $this.elementService.httpGetElementListingHtml($this.element_parent_id);
          $this.ref.detectChanges();
        }

        if (propBox !== null) {
          $this.element_listing_index = propBox.data('index');
        }

        $this.selectedListings = [];
        $($this.mdPropSearchModal.nativeElement).modal('show');
        $this.insertPropertyButton = false;
        $this.insertPropertyElement = true;
      },
    });

    if ($this.frontService.authService.auth.client.is_account_master !== 1 || $this.frontService.authService.auth.client.group.master_client_id !== $this.frontService.authService.auth.client.ID) {
      $('.btn-save-quick').addClass('hidden');
    }

    if ($this.frontService.authService.auth.is_master === true || $this.frontService.authService.auth.is_admin !== true) {
      $('.btn-save-quick-office').addClass('hidden');
    }

    const openBlocksBtn = this.editor.Panels.getButton('views', 'open-blocks');

    if (openBlocksBtn) {
      openBlocksBtn.set('active', 1);
    }

    const pricefinderUrl = this.route.snapshot.queryParamMap.get('pricefinder_url');

    for (const element of this.elements) {
      let elementHtml = element.html;
      elementHtml = this.globalService.fillMergeText(this.authUser, elementHtml, 'replicate', 'element', {
        user: this.templateAgent,
        isMergedSaved: this.isMergedSaved,
        template: this.template,
        fillType: 'template'
      });

      if (element.block_id !== 0) {
        const labelHtml = `<img src="${element.icon}" class="builder-block-icon" draggable="false" />${element.title}`;
        blockManager.add(`${element.title}-${element.block.title}`, {
          category: element.block.title,
          label: labelHtml, // element.title,
          content: elementHtml,
          attributes: {
            id: element.ID,
            'data-preview-description': element.description,
            'data-preview-thumbnail': element.thumbnail
          },
        });
      }

    }
    const previewDiv = '<div class="element-preview hidden"><p class="preview-title"></p><p class="preview-description"></p><img src="" class="preview-thumbnail"><div class="pointed"></div></div>';
    $(document.body).append(previewDiv);

    const conElQd = $('.gjs-pn-views-container');

    conElQd.find('.gjs-block-category').show();

    conElQd.find('.qd-box').parents('.gjs-block-category').hide();

    // tslint:disable-next-line: max-line-length
    if (String(this.template.html_raw).includes('<!--MERGED_SAVED-->') && !this.template.is_master_template && !(typeof this.template.quickdesign !== 'undefined' && this.template.quickdesign)) {
      this.isMergedSaved = true;
      this.template.html_raw = String(this.template.html_raw).replace(/<!--MERGED_SAVED-->/g, '');
    }

    const builderHtml = this.globalService.fillMergeText(this.authUser, this.template.html_raw, 'subtitute', 'template', {
      user: this.templateAgent,
      isMergedSaved: this.isMergedSaved,
      template: this.template,
      fillType: 'template',
      pricefinderUrl: pricefinderUrl
    });

    this.editor.setComponents(builderHtml);

    setTimeout(() => {
      let timeout;
      $('#builder-html .gjs-block').on({
        mouseenter: function () {
          timeout = setTimeout(() => {
            const title = $(this).attr('title');
            const description = $(this).attr('data-preview-description');
            const thumbnail = $(this).attr('data-preview-thumbnail');

            $('.element-preview .preview-title').text(title);
            $('.element-preview .preview-description').text(description);
            $('.element-preview .preview-thumbnail').attr('src', thumbnail);

            const elementPosition = $(this).offset();

            $('.element-preview').css('left', `${elementPosition.left - 300}px`);
            const bodyHeight = $( document ).height();

            if (elementPosition.top > 750) {
              $('.element-preview').css('top', `${elementPosition.top - 80}px`);
              $('.element-preview .pointed').css('top', `110px`);
            } else {
              $('.element-preview').css('top', `${elementPosition.top}px`);
              $('.element-preview .pointed').css('top', `25px`);
            }


            if (thumbnail !== '') {
              $('.element-preview').removeClass('hidden');
            }

          }, 1000);
        },
        mouseleave: function () {
          clearTimeout(timeout);
          $('.element-preview').addClass('hidden');
        },
      });
    }, 1500);

    $(document).on('click', '#builder-html .gjs-block', function() {
      const position = $(this).offset();
      setTimeout(function () {
        if (!$this.startDragging) {
          if (!$('.gjs-cv-canvas').find('.drag-drop-overlay').length) {
            // tslint:disable-next-line: max-line-length
            $('.gjs-cv-canvas').append($('<div>').attr({'class': 'drag-drop-overlay', 'style': `top:${position.top}px`}).html('<span class="drag-drop-overlay-icon"></span>'));
          } else {
            $('.drag-drop-overlay').css('top', `${position.top}px`);
          }
        }
      }, 500);
    });

    $('body').click(function(e){
      // tslint:disable-next-line: curly
      if (e.target.id === 'gjs-block')
          return;

      // tslint:disable-next-line: curly
      if ($(e.target).closest('.gjs-block').length)
          return;

      // Otherwise
      if ($('.gjs-cv-canvas').find('.drag-drop-overlay').length) {
        $('.gjs-cv-canvas').find('.drag-drop-overlay').remove();
      }
    });

    // .on('mouseup mouseleave', '#builder-html .gjs-block', function() {
    //   if ($('.gjs-cv-canvas').find('.drag-drop-overlay').length) {
    //     $('.gjs-cv-canvas').find('.drag-drop-overlay').remove();
    //   }
    // });

    this.editor.on('block:drag:start', (data, model) => {
      this.startDragging = true;

      if ($('.gjs-cv-canvas').find('.drag-drop-overlay').length) {
        $('.gjs-cv-canvas').find('.drag-drop-overlay').remove();
      }
      
      this.isUserHasChanges = true;
    });

    this.editor.on('block:drag:stop', (data, model) => {
      //set data-id attribute to the dropped components in canvas
      data.set('attributes', {'data-element-id': model.attributes.attributes.id});
      this.startDragging = false;
    });

    const body = this.editor.Canvas.getBody();
    $(body).find('#wrapper').on('click', function(){
      if ($('.gjs-cv-canvas').find('.drag-drop-overlay').length) {
        $('.gjs-cv-canvas').find('.drag-drop-overlay').remove();
      }
          });

    this.editor.on('component:clone', (model: any) => {
      this.editor.select(model);
    });

    this.editor.on('canvas:dragenter', function (e, result) {
      setTimeout(() => {
        $($this.builder.nativeElement).find('iframe').attr('style', `width: ${$this.currIframeWidth}px !important`);
      }, 100);
    });

    this.editor.on('canvas:dragdata', function (e, result) {

      /* 
        Get the new element that will be added to the 
        canvas then check the templates validation code 
        for that specific element ID 
      */
      let html = $(body).find('#wrapper').html();
      const elementId = $(result.content)[0].id ? '#' + $(result.content)[0].id : '';
      const validation = $this.validateCodeAddElement($this.template.master.validation_code, html, elementId);
      const isValid = validation.status;
      
      if (!isValid) {
        swal({
          title: validation.title,
          html: validation.message,
          type: 'error',
        });
        return result.content = '';
      }
      
      const content = String(result.content);
      if (!content.includes('md-box') && !content.includes('class="page-divider"')) {
        return (result.content = '');
      }

      // tslint:disable-next-line: max-line-length
      if (content.includes('md-prop-box') && ($this.template.category_id === 2 || $this.template.category_id === 10 || $this.template.category_id === 11)) {
        const iframe = $($this.builder.nativeElement).find('iframe').contents();
        const listingElementCount = iframe.find('body').find('.md-prop-box').length;

        if (listingElementCount >= 1) {
          swal('You can only drag 1 listing element', '', 'error');

          return (result.content = '');
        }
      }

      if (content.includes('md-prop-box') && $this.template.category_id === 13) {
        const iframe = $($this.builder.nativeElement).find('iframe').contents();

        const newListingElementCount = iframe.find('body').find('.md-newlistings').length;
        if (newListingElementCount >= 1 && content.includes('md-newlistings')) {
          swal('You can only insert 1 New Listing element', '', 'error');

          return (result.content = '');
        }

        const priceReductionElementCount = iframe.find('body').find('.md-pricereductions').length;
        if (priceReductionElementCount >= 1 && content.includes('md-pricereductions')) {
          swal('You can only insert 1 Price Reduction element', '', 'error');

          return (result.content = '');
        }

        const openHomesElementCount = iframe.find('body').find('.md-opens').length;
        if (openHomesElementCount >= 1 && content.includes('md-opens')) {
          swal('You can only insert 1 Open Homes element', '', 'error');

          return (result.content = '');
        }

        const soldElementCount = iframe.find('body').find('.md-sold').length;
        if (soldElementCount >= 1 && content.includes('md-sold')) {
          swal('You can only insert 1 Sold element', '', 'error');

          return (result.content = '');
        }
      }

      if ($this.template.category_id === 6) {
        // Social media category remove previous element to prevent multiple stacked elements
        $this.editor.DomComponents.clear();
      }

      $this.isVaultPopulated = false;
      this.isUserHasChanges = true;
    });

    this.editor.on('component:add', function (model) {
      setTimeout(function () {
        const iframeWidth = $($this.builder.nativeElement).find('iframe').width();
        const wrapperWidth = $($this.builder.nativeElement).find('iframe').contents().find("#wrapper").width();
        const wrapperContentWidth = $($this.builder.nativeElement).find('iframe').contents().find("#wrapper").contents().width();

        if (wrapperWidth < wrapperContentWidth) {
          const scrollbarWidth = iframeWidth - wrapperWidth;
          $($this.builder.nativeElement).find('iframe').width('+='+scrollbarWidth);
        }
      }, 1000);

      try {
        if (typeof model.view !== 'undefined') {
          model.view.$el.find('a').click(function (e) {
            e.preventDefault();
          });

          const template_agent_id = $this.templateAgent.id ? $this.templateAgent.id : '';
          model.view.$el.find('.md-user-field-id').html(template_agent_id);

          const template_agent_email = $this.templateAgent.email ? $this.templateAgent.email : '';
          model.view.$el.find('.md-user-field-email').html(template_agent_email);

          const template_agent_fax = $this.templateAgent.fax ? $this.templateAgent.fax : '';
          model.view.$el.find('.md-user-field-fax').html(template_agent_fax);

          const template_agent_firstname = $this.templateAgent.firstname ? $this.templateAgent.firstname : '';
          model.view.$el.find('.md-user-field-fname').html(template_agent_firstname);

          const template_agent_lastname = $this.templateAgent.lastname ? $this.templateAgent.lastname : '';
          model.view.$el.find('.md-user-field-lname').html(template_agent_lastname);

          model.view.$el.find('.md-user-field-name').html(template_agent_firstname + ' ' + template_agent_lastname);

          const template_agent_imageurl = $this.templateAgent.imageurl ? $this.templateAgent.imageurl : '';
          model.view.$el.find('.md-user-field-photo').html(template_agent_imageurl);

          const template_agent_telephone = $this.templateAgent.telephone ? $this.templateAgent.telephone : '';
          model.view.$el.find('.md-user-field-phone').html(template_agent_telephone);

          const template_agent_position = $this.templateAgent.position ? $this.templateAgent.position : '';
          model.view.$el.find('.md-user-field-position').html(template_agent_position);

          const template_agent_profile = $this.templateAgent.profile ? $this.templateAgent.profile : '';
          model.view.$el.find('.md-user-field-profile').html(template_agent_profile);

          let template_agent_mobiledisplay = $this.templateAgent.mobile ? $this.templateAgent.mobile : '';
          template_agent_mobiledisplay = template_agent_mobiledisplay.replace('+61', '').replace('+64', '').trim();
          if (template_agent_mobiledisplay && template_agent_mobiledisplay.charAt(0) !== '0') {
            template_agent_mobiledisplay = '0' + template_agent_mobiledisplay;
          }
          model.view.$el.find('.md-user-field-mobile').html(template_agent_mobiledisplay);

          // model.view.$el.find('.md-content-editable').attr('contenteditable', 'true');

          // Non-Class Office Details - Merge Field
          model.view.$el.find('.md-office-field-id').html($this.frontService.authService.auth.groupid);

          if ($this.clientSetting.company_name) {
            model.view.$el.find('.md-business-field-name').html($this.clientSetting.company_name);
          } else {
            if (this.groupSetting !== null && this.groupSetting !== undefined) {
              model.view.$el.find('.md-business-field-name').html($this.groupSetting.company_name);
            }
          }
          if ($this.clientSetting.website) {
            model.view.$el.find('.md-website-field-name').html($this.clientSetting.website);
          } else {
            if (this.groupSetting !== null && this.groupSetting !== undefined) {
              model.view.$el.find('.md-website-field-name').html($this.groupSetting.website);
            }
          }
          if ($this.clientSetting.name) {
            model.view.$el.find('.md-office-field-name').html($this.clientSetting.name);
          } else {
            if (this.groupSetting !== null && this.groupSetting !== undefined) {
              model.view.$el.find('.md-office-field-name').html($this.groupSetting.name);
            }
          }
          if ($this.clientSetting.abn) {
            model.view.$el.find('.md-office-field-abn').html($this.clientSetting.abn);
          } else {
            if (this.groupSetting !== null && this.groupSetting !== undefined) {
              model.view.$el.find('.md-office-field-abn').html($this.groupSetting.abn);
            }
          }
          if ($this.clientSetting.phone) {
            model.view.$el.find('.md-office-field-phone').html($this.clientSetting.phone);
          } else {
            if (this.groupSetting !== null && this.groupSetting !== undefined) {
              model.view.$el.find('.md-office-field-phone').html($this.groupSetting.phone);
            }
          }
          if ($this.clientSetting.email) {
            model.view.$el.find('.md-office-field-email').html($this.clientSetting.email);
          } else {
            if (this.groupSetting !== null && this.groupSetting !== undefined) {
              model.view.$el.find('.md-office-field-email').html($this.groupSetting.email);
            }
          }
          if ($this.clientSetting.street) {
            model.view.$el.find('.md-office-field-street').html($this.clientSetting.street);
          } else {
            if (this.groupSetting !== null && this.groupSetting !== undefined) {
              model.view.$el.find('.md-office-field-street').html($this.groupSetting.street);
            }
          }
          if ($this.clientSetting.suburb) {
            model.view.$el.find('.md-office-field-suburb').html($this.clientSetting.suburb);
          } else {
            if (this.groupSetting !== null && this.groupSetting !== undefined) {
              model.view.$el.find('.md-office-field-suburb').html($this.groupSetting.suburb);
            }
          }

          model.view.$el.find('.md-office-field-state').html($this.clientSetting.state_abbr);
          model.view.$el.find('.md-office-field-postcode').html($this.clientSetting.postcode);
        }
      } catch (error) {
        console.log(error);
      }

      $this.initScript();
      $this.initScriptContentAlert();
      this.isUserHasChanges = true;
    });

    this.editor.on('component:remove', function (model) {
      const wrapperWidth = $($this.builder.nativeElement).find('iframe').contents().find("#wrapper").width();
      const wrapperContentWidth = $($this.builder.nativeElement).find('iframe').contents().find("#wrapper").contents().width();

      if (wrapperWidth > wrapperContentWidth) {
        $($this.builder.nativeElement).find('iframe').width(wrapperContentWidth);
      }
      
      try {
        $('.gjs-toolbar').css('display', 'none');
        $this.initScript();
        $this.initScriptContentAlert();
      } catch (error) {
        console.log(error);
      }

      this.isUserHasChanges = true;
    });

    this.editor.on('component:selected', function (model) {
      const selectedComponent = $this.editor.getSelected();
      $this.closeSideBarSettings();
      $('#gjs-tools .gjs-toolbar').removeClass('tb_overwrite');
      $this.letZoom = false;
      $this.disableZoom();
      
      if (typeof model.view !== 'undefined') {
        if (model.view.$el.hasClass('md-prop-search') || model.view.$el.hasClass('md-library-search')) {
          $('#gjs-tools .gjs-toolbar').addClass('tb_overwrite');
        }

        if (model.view.$el.find('.unselectable').length && !$this.isVaultMail) {
          const vaultIntegratedCategories = [2, 10, 11, 13];

          if (vaultIntegratedCategories.includes($this.template.category_id)) {
            const currentPlatform = $this.frontService.authService.isUk() ? 'VaultEA' : 'VaultRE';
            let textNote = 'If you are wanting to insert your properties manually please try using a Market Report template instead.';

            if ($this.template.category_id === 10 ) {
              textNote = 'If you are wanting to insert your properties manually please try using a Property Guide or Stocklist template instead.';
            }

            if ($this.template.category_id === 13) {
              textNote = 'If you are wanting to insert your properties manually please try using a Newsletter or Market Report template instead.';
            }

            swal(`Unavailable`, `This is a ${$this.template.category.title} template where property elements are locked down, using this template in ${currentPlatform} will automatically populate your properties here. <br><br> <b>Please note</b> ${textNote}`, 'warning');
            return false;
          }
        }

        if (model.view.$el.hasClass('md-box')) {
          const selected_component = $this.editor.getSelected();
          const tb = model.get('toolbar');
          let idxElemSetting = tb.findIndex((tbi) => {
            return tbi.command == 'md-elm-tmp-setting';
          });

          if (idxElemSetting >= 0) {
            //remove from list
            tb.splice(idxElemSetting, 1);
          }

          let tbItem = null;
          // class to show the insert property per element. allow only on live account
          if ((model.view.$el.hasClass('dsgnly-element-prop-insert') || model.view.$el.hasClass('dsgnly-prop-grid-box')) && $this.FeatureFlagService.checkUser('bayleys', $this.authUser)) {
            let idxElmInsertProperty = tb.findIndex((tbi) => {
              return tbi.command == 'md-element-insert-property';
            });
            if (idxElmInsertProperty >= 0)  {
              // remove from list
              tb.splice(idxElmInsertProperty, 1);
            }
            tbItem = {
              attributes: {class: 'icon feather icon-search'},
              command: 'md-element-insert-property'
            }
          }
          // const paletteExist = tb.find(
          //   function (tbi) {
          //     return tbi.command === 'md-element-palette';
          //   }
          // );

          // if ( typeof paletteExist === 'undefined' ) {
          //   tb.push({
          //     attributes: { class: 'icon-palette', title: 'Background Color' },
          //     command: 'md-element-palette'
          //   });
          // }

          // setTimeout(() => {
          //   if ( !$this.init_colorpicker ) {
          //     // tslint:disable-next-line:max-line-length
          //     $('head').append('<style>.asColorPicker-trigger { display: none; } .asColorPicker-input { border: unset !important; } .asColorPicker-clear { display: none; }</style>');
          //     $this.init_colorpicker = true;
          //   }
          //   $('.icon-palette').asColorPicker({
          //     onChange: function(picker) {
          //       $($this.color_selected_element).css('background', picker);
          //     }
          //   });
          // }, 200);

          if ($this.authUser.is_master || $this.authUser.is_admin) {
            let idx_lock = tb.findIndex((tbi) => {
              return tbi.command === 'md-el-lock';
            });

            if (idx_lock >=0) {
              //remove from list
              tb.splice(idx_lock, 1);
            }
            let md_lock_icon = 'fa-unlock';
            let md_lock_title = 'Unlocked';

            if (model.view.$el.hasClass('md-lock')) {
              md_lock_icon = 'fa-lock';
              md_lock_title = 'Locked';
            }
            tb.push({
              attributes: {class: 'fa ' + md_lock_icon, title: '', 'data-tooltip': md_lock_title, 'data-tooltip-pos': 'bottom'},
              command: 'md-el-lock',
            });
            // add new toolbar item
            if (tbItem !== null) {
              tb.push(tbItem);
            }

            let elementSettingId = selected_component.view.$el.attr('data-element-id');
            $this.elementTemplateSettingList = [];
            if (elementSettingId !== null && elementSettingId != '' && $this.template.category_id !== $this.templateCategoryId.EMARKETING) {
              tb.push({
                attributes: {class: 'btn-element-setting icon feather icon-settings'},
                command: 'md-elm-tmp-setting'
              })
              $this.templateElementSettingService.getElementSetting(elementSettingId);
              $this.templateElementSettingService.elementChanged.subscribe(
                (response: any) => {
                  if (response.data) {
                    let reformedElementTemplateSetting = response.data;
                    response.data.forEach((obj: any, index: number) => {
                      let selectedElement = $this.editor.getSelected();
                      let elemArrayClass = selectedElement.view.el.classList.value.split(' ');
                      if (obj.group_id != null) {
                        obj.data.forEach((grpItem: any, indx: number) => {
                          //find class id in user_global_setting
                          if (grpItem.settings_type === 0) { //check if dropdown option class is in user global setting
                            let optIdx = grpItem.settings_dropdown_options.findIndex((optVal) => {
                              return (elemArrayClass.includes(optVal.class_name)) ? true : false;
                            });
                            if (optIdx >= 0) {
                              reformedElementTemplateSetting[index].data[indx].settings_dropdown_options[optIdx]['is_class_exists'] = true;
                            }
                          } else {// toggle setting 
                            if (elemArrayClass.includes(grpItem.class_name)) {
                              reformedElementTemplateSetting[index].data[indx]['is_class_exists'] = true;
                            }
                          }
                        })
                      } else { //non-group global template settings
                        if (obj.settings_type === 0) { //check if dropdown option class is in user global setting
                          let optIdx = obj.settings_dropdown_options.findIndex((optVal) => {
                            return (elemArrayClass.includes(optVal.class_name)) ? true : false;
                          });
                          if (optIdx >= 0) {
                            reformedElementTemplateSetting[index].settings_dropdown_options[optIdx]['is_class_exists'] = true;
                          }
                        } else {
                          if (elemArrayClass.includes(obj.class_name)) {
                            //update response data to include this setting has the user
                            reformedElementTemplateSetting[index]['is_class_exists'] = true;
                          }
                        }
                      }
                    });
                    $this.elementTemplateSettingList = reformedElementTemplateSetting;
                  }
                }
              );
            }
            model.set('toolbar', tb);
          } else {
            if (model.view.$el.hasClass('md-lock')) {
              const isExist = tb.find(function (tbi) {
                return tbi.command === 'md-el-locked';
              });
              if (typeof isExist === 'undefined') {
                tb.push({
                  attributes: { class: 'fa fa-lock', title: 'Locked' },
                  command: 'md-el-locked',
                });
                model.set('toolbar', tb);
              }
            }
          }
        }

        if (model.view.$el.parents().hasClass('md-lock') || model.view.$el.hasClass('md-lock')) {
          const parent = model.view.$el.attr('data-lock-id');
          const child = model.view.$el.parents().find('.md-lock').attr('data-lock-id');
          const type = model.view.$el.parents().find('.md-lock').attr('data-lock-type');

          if (parent !== null && child !== null) {
            // tslint:disable-next-line:radix
            if (parseInt(parent) !== $this.authUser.client.ID) {
              const _tb = [
                {
                  attributes: { class: 'fa fa-lock lock-' + type, title: 'Edit Image' },
                  command: 'md-el-locked',
                },
              ];
              model.set('toolbar', _tb);
            }
          } else {
            // tslint:disable-next-line:radix
            if (parseInt(child) !== $this.authUser.client.ID && !model.view.$el.hasClass('md-library-search') && !model.view.$el.hasClass('md-prop-search')) {
              if (type === 'restricted') {
                model.set({
                  draggable: false,
                  droppable: false,
                  copyable: false,
                  removable: false,
                  selectable: false,
                  editable: false,
                  toolbar: [],
                });
              } else {
                model.set('toolbar', []);
              }
            }
          }
        }

        const modelTypes = ['p-editable-w-spans-copyable', 'span-b-em-u-i-strong', 'p-editable-w-spans', 'text'];

        if (modelTypes.includes(model.get('type'))) {
          model.view.disableEditing();
          model.view.el.contentEditable = 'true';

          const _tb = [];
          
          if (model.is('p-editable-w-spans-copyable')) {
            _tb.push({
              attributes: {class: 'fa fa-clone', title: ''},
              command: 'tlb-clone',
            });
          }
          
          _tb.push({
            attributes: { class: 'icon feather icon-edit-2', title: '', 'data-tooltip': 'Format Text', 'data-tooltip-pos': 'bottom' },
            command: 'md-el-prop-edit-text',
          });

          _tb.push({
            attributes: { class: 'icon feather icon-trash-2', title: '' },
            command: 'md-el-prop-delete-text',
          });

          model.set('toolbar', _tb);
        }

        /* This to allow the user to edit the selected element */
        if (model.is('p-editable-w-spans')) {
          model.view.el.contentEditable = 'true';
        }

        if (selectedComponent.view.$el.attr('data-is-property-icon-list') != null && selectedComponent.view.$el.attr('data-is-property-icon-list') == 1) {
          const toolbar = model.get('toolbar');
          let editPropertyIconIdx = toolbar.findIndex((tlb) => {
            return tlb.command === 'md-edit-property-icons'
          });

          if (editPropertyIconIdx >= 0) {
            toolbar.splice(editPropertyIconIdx, 1);
          }
          //gather available icon in this selected element
          let icons = selectedComponent.view.$el.find('.icons .icon');
          $this.elementPropertyIcons = [];
          $this.getElementPropertyIcons().clear();
          icons.each(function(icon, index) {
              $this.getElementPropertyIcons().push($this.fb.group({
                position: index,
                label: $(icon).data('icon-label'),
                is_show: $(icon).is(':visible'),
                img: $(icon).children('img:first-child').attr('src')
              }));
          });

          toolbar.push({
            attributes: {class: 'icon feather icon-edit-2 edit-property-icons edit-sidebar', title: '', 'data-tooltip': 'Edit Property Icons', 'data-tooltip-pos': 'bottom'},
            command: 'md-edit-property-icons'
          });
          model.set('toolbar', toolbar);
        }

        if (selectedComponent.view.$el.attr('data-disable-editor')) {
          /* To prevent delete the selected element through 'backspace' or 'delete' key */
          selectedComponent.set({
            removable: false
          })
          /* Remove delete button from toolbar if selected element has data-disable-editor=1 attribute */
          const toolbar = model.get('toolbar');
            let icon = toolbar.findIndex((tlb) => {
              return tlb.command === 'tlb-delete'
            });

            if (icon >= 0) {
              toolbar.splice(icon, 1);
            }
            model.set('toolbar', toolbar);
        }
      }
      
      $this.initTooltip();

      if ($('.gjs-cv-canvas').find('.drag-drop-overlay').length) {
        $('.gjs-cv-canvas').find('.drag-drop-overlay').remove();
      }

      this.isUserHasChanges = true;
    });

    this.editor.on('change', function () {
      /*
       * Override Styles Tooltip
       */
      $('.fa-arrows').attr({'title': '', 'data-tooltip': 'Drag', 'data-tooltip-pos': 'bottom'});
      $('.fa-clone').attr({'title': '', 'data-tooltip': 'Duplicate', 'data-tooltip-pos': 'bottom'});
      $('.fa-trash-o, .icon-trash-2').attr({'title': '', 'data-tooltip': 'Clear Design', 'data-tooltip-pos': 'bottom'});

      $('.gjs-cv-canvas').find('iframe').contents().find('a').click(function(e){
        e.preventDefault();
      });

      $this.initTooltip();
      this.isUserHasChanges = true;
    });

    this.editor.on('load', function () {
      let _tmp_html = '';

      _tmp_html = '#wrapper {overflow: inherit !important;}'; // for custom sizes

      if ($this.template.media_attribute.orientation === 'eMarketing') {
        _tmp_html = '#wrapper {overflow-x: hidden !important; overflow-y: hidden !important;}';
      }

      if ($this.template.media_attribute.size === 'A3') {
        if ($this.template.media_attribute.orientation === 'Landscape') {
          // if ( $this.template.master.puppeteer ) {
          //   $($this.builder.nativeElement).find('iframe').css('max-width', '1586px');
          // } else {
          //   $($this.builder.nativeElement).find('iframe').css('max-width', '1550px');
          // }

          $this.editor.setDevice('A3 landscape');
        } else {
          $this.editor.setDevice('A3 portrait');
        }

      } else if ($this.template.media_attribute.size === 'fb-cover') {
        $this.editor.setDevice('FB cover');
      } else if ($this.template.media_attribute.size === 'fb-post') {
        $($this.builder.nativeElement).find('iframe').css('max-height', '100%');
        $this.editor.setDevice('FB post');
      } else if ($this.template.media_attribute.size === 'insta-post') {
        $this.editor.setDevice('IG post');
        $('.gjs-frame').css('max-height', '1080px');
      } else if ($this.template.media_attribute.size === 'tw-post') {
        $this.editor.setDevice('TW post');
      } else if ($this.template.media_attribute.size === 'DL') {
        $this.editor.setDevice('DL card');

        _tmp_html = 'html {overflow: overlay;}#wrapper {' +
        'overflow: auto;' +
        'overflow-y: overlay !important;}';

      } else if ($this.template.media_attribute.size === 'A4') {
        if ($this.template.media_attribute.orientation === 'Landscape') {
          $this.editor.setDevice('A4 landscape');
        } else {
          $this.editor.setDevice('A4 portrait');
        }

        _tmp_html = '';

      } else if ($this.template.media_attribute.size === '1200x1800') {
        $this.editor.setDevice('Portrait 1200x1800');
      } else if ($this.template.media_attribute.size === '1200x2400' || $this.template.media_attribute.size === '1800x1200' || $this.template.media_attribute.size === '1750x1130') {
        $this.editor.setDevice('Portrait 1200x2400');
      } else if ($this.template.media_attribute.size === 'custom') {
        $this.editor.setDevice($this.template.media_attribute.slug);
      } else {
        $this.editor.setDevice('Desktop View');
      }

      if (_tmp_html !== '') {
        const iframe = $($this.builder.nativeElement).find('iframe').contents();
        iframe.find('head').append( $('<style>').text(_tmp_html) );
      }

      /* For iframe issue with scrollbar width */
      setTimeout(function () {
        
        const iframeWidth = $($this.builder.nativeElement).find('iframe').width();
        const wrapperWidth = $($this.builder.nativeElement).find('iframe').contents().find("#wrapper").width();
        
        /* Adjust iframe width depends on the content */
        if ($this.template.media_attribute.size === 'A3') {
          /* If A3, update the iframe size and add scrollbar width if necessary */
          let newWidth = 0;
          const contentWidth = $($this.builder.nativeElement).find('iframe').contents().width();
          newWidth = contentWidth;
          let mediaQueryCondition = window.matchMedia("(min-width: 1750px)");

          if (wrapperWidth < iframeWidth) {
            const scrollbarWidth = iframeWidth - wrapperWidth;
            newWidth += +scrollbarWidth;
          }

          if (mediaQueryCondition.matches && $this.template.media_attribute.orientation === 'Landscape') {
            $($this.builder.nativeElement).find('iframe').css({'width': '97%', 'max-width': '1593.75px'});
          } else {
            $($this.builder.nativeElement).find('iframe').width(newWidth);
          }

          const maxContentWidth = 1542;

          if (contentWidth < maxContentWidth) {
            // _tmp_html = '#wrapper {' + // removed 9.15.2021
            // 'overflow: auto;}';
            _tmp_html = '#wrapper {overflow: inherit !important;}' +
            'html {overflow: overlay;}' +
            '#wrapper .a6page { margin-right: 0 }'; // added 9.15.2021, 9.22.2021 - https://app.clickup.com/t/a10cu2
          } else {
            _tmp_html = '#wrapper {' +
            // 'overflow: auto;' +
            // 'overflow-x: hidden !important;' +
            'overflow: inherit !important;}html {overflow: overlay;}';
          }

          const iframe = $($this.builder.nativeElement).find('iframe').contents();
          iframe.find('head').append( $('<style>').text(_tmp_html) );
        } else {
          /* Update the iframe size and add scrollbar width if necessary */
          if (wrapperWidth < iframeWidth) {
            const scrollbarWidth = iframeWidth - wrapperWidth;
            $($this.builder.nativeElement).find('iframe').width('+='+scrollbarWidth);
          }
        }
        
      }, 1000);

    });

    /*
     * Custom $ style append
     */

    // $('.btn-el-menu').text('Elements');
    // $('.btn-qd-menu').text('Quick Design');

    // $('.btn-el-menu').click(() => {
    //   if ( this.frontService.authService.getApp() === 'MyDesign3' ) {
    //     $('.btn-el-menu').attr('style', 'background: #37AAE1 !important; color: #ffffff !important;');
    //     // $('.btn-qd-menu').attr('style', 'background: #ffffff !important; color: #37AAE1 !important;');
    //   } else {
    //     $('.btn-el-menu').attr('style', `background: #FFF !important; color: ${$this.mainColor} !important;`);
    //     // $('.btn-qd-menu').attr('style', 'background: #F4F4F5 !important; color: #5B6A72 !important;');
    //   }
    // });

    // $('.btn-qd-menu').click(() => {
    //   if ( this.frontService.authService.getApp() === 'MyDesign3' ) {
    //     $('.btn-el-menu').attr('style', 'background: #ffffff !important; color: #37AAE1 !important;');
    //     $('.btn-qd-menu').attr('style', 'background: #37AAE1 !important; color: #ffffff !important;');
    //   } else {
    //     $('.btn-el-menu').attr('style', 'background: #F4F4F5 !important; color: #5B6A72 !important;');
    //     $('.btn-qd-menu').attr('style', `background: #FFF !important; color: ${$this.mainColor} !important;`);
    //   }
    // });

    $('.btn-html-tour').html('Start Tour');

    if (this.frontService.authService.getApp() === 'MyDesign3') {
      $('.gjs-pn-btn.fa.fa-arrow-left').after(' <span style="font-size: 1.5em">Back to Dashboard</div>');
    } else {
      $('.gjs-pn-btn.icon.feather.icon-chevron-left').after(`<span title="" data-tooltip="Rename template" data-tooltip-pos="bottom" class="gjs-pn-btn html-template-title"> ${$this.template.title}</span>`);
    }

    $('.html-template-title').click(function () {
      swal({
        title: 'Change Template Name',
        input: 'text',
        inputValue: $this.new_template_name!=='' ? $this.new_template_name : $this.template.title,
        showCancelButton: true,
        confirmButtonText: 'Submit',
        showLoaderOnConfirm: true,
        preConfirm: function (text) {
          return new Promise(function (resolve, reject) {
            setTimeout(function () {
              resolve();
            }, 2000);
          });
        },
        allowOutsideClick: false,
      }).then(function (text) {
        swal({
          type: 'success',
          title: 'Success',
        });
        $this.new_template_name = text;

        const template = <any>{
          title: text,
        };

        $this.customCategoryForm.patchValue({
          title: text,
        });

        $this.uploadCabinateForm.patchValue({
          title: text,
        });
        
        $this.templateService.httpPutTemplate($this.id, template);

        if (text.length > 30) {
          text = text.substring(0, 30) + '...';
        }
        $('.html-template-title').text(text);
      });
    });

    if (this.frontService.authService.getApp() === 'MyDesign3') {
      if (this.template.category_id === 2 || this.template.category_id === 11) {
        if (this.isVaultMail) {
          $('.btn-html-save').html('<i class="fa fa-send-o"></i> Send');
        } else {
          $('.btn-html-save').html('<i class="fa fa-floppy-o"></i> Save');
        }
      } else {
        $('.btn-html-save').html('<i class="fa fa-floppy-o"></i> Save');
      }

      $('.btn-save-quick').html('<span class="fa fa-file-picture-o"></span> Template');
      $('.btn-save-quick-office').html('<span class="fa fa-file-picture-o"></span> Template');
      $('.btn-html-send').html('<span class="fa fa-send-o"></span> Send');
      $('.btn-html-pdf').html('<span class="fa fa-file-pdf-o"></span> Download PDF');
      $('.btn-html-highres-pdf').html('<span class="fa fa-file-pdf-o"></span> Download PDF');
      $('.btn-html-img').html('<span class="fa fa-picture-o"></span> Download Image');
      $('.btn-html-html').html('<span class="icon feather icon-search"></span> View');
      $('.btn-html-help').html('<span class="fa fa-question-circle"></span>');

      $('.btn-html-save-campaign').insertAfter('.btn-html-save');
      $('.btn-html-save-campaign').html('<i class="icon feather icon-chevron-down"></i>');

      const allowedCategoriesForTestEmail = [1, 2, 11];

      if (allowedCategoriesForTestEmail.includes(this.template.category.ID)) {
        $('.btn-html-emarketing-options').insertAfter('.btn-html-send');
        $('.btn-html-emarketing-options').html('<i class="icon feather icon-chevron-down"></i>');
      }
    }

    if (this.frontService.authService.getApp() === 'Designly') {
      // limit the feature to bayleys and demo real state account for the meantime
      if (this.FeatureFlagService.checkUser('bayleys', this.authUser)) {
        $('.btn-html-insert-property').html('<i class="icon feather icon-home"></i> <span>Insert Property</span>');
      }
      $('.btn-save-quick').html('<span class="fa fa-file-picture-o"></span> Template');
      $('.btn-save-quick-office').html('<span class="fa fa-file-picture-o"></span> Template');
      $('.btn-html-pdf').html('<i class="icon feather icon-file-text"></i> <span>Download PDF</span>');
      $('.btn-html-highres-pdf').html('<i class="icon feather icon-file-text"></i> <span>Download PDF</span>');
      $('.btn-html-img').html('<i class="icon feather icon-image"></i> <span>Download Image</span>');
      $('.btn-html-html').html('<i class="icon feather icon-search"></i> <span>View</span>');

      //TODO: Disabled autosaving for now, will continue until further notice

      // const isAutoSaving = this.authUser.user.is_autosaving ? 'checked' : '';

      // $('.btn-html-autosave').html(`<span class="title">Auto Save</span> <label class="switch"><input onclick="onCheckAutoSave(this)" type="checkbox" ${isAutoSaving}><span class="slider round"></span></label>`);

      const excludeCategories = [2, 10, 11, 13];

      if (excludeCategories.includes(this.template.category_id)) {
        $('.btn-html-autosave').css('display', 'none');
      }

      if (this.template.category_id === 2 || this.template.category_id === 11) {
        if (this.isVaultMail) {
          $('.btn-html-save').html('<i class="icon feather icon-mail"></i> Send');
        } else {
          $('.btn-html-save').html('<i class="icon feather icon-save"></i> Save');
        }
      } else {
        $('.btn-html-save').html('<i class="icon feather icon-save"></i> Save');
      }

      $('.btn-html-save-campaign').insertAfter('.btn-html-save');
      $('.btn-html-save-campaign').html('<i class="icon feather icon-chevron-down"></i>');

      $('.btn-html-new-send').insertAfter('.btn-html-save-campaign');
      
      if (this.isHarcourtsAu) {
        $('.btn-html-new-send').html('<i class="icon feather icon-mail"></i> <span>Send to VRE</span>');
      } else {
        $('.btn-html-new-send').html('<i class="icon feather icon-mail"></i> <span>Send to MRI Vault</span>');
      }

      if (this.template.hug_connect_data !== null && Object.entries(this.template.hug_connect_data).length > 0 && this.template.category_id != 6) {
        $('.btn-html-upload-hug-connect').html('<i class="icon feather icon-upload"></i> <span>Upload</span>');
      } else {
        $('.btn-html-upload-hug-connect').css('display', 'none');
      }
     
      if (this.template.category.ID === 1) {
        $('.btn-html-emarketing-options').insertAfter('.btn-html-new-send');
        $('.btn-html-emarketing-options').html('<i class="icon feather icon-chevron-down"></i>');
      }

      $('.btn-populate-listing').insertBefore('.btn-html-save');
      $('.btn-populate-listing').html('<span>Populate Listings</span>');

      $('<span style="border-left: 2px solid #F4F4F5; height: 25px; margin: 0 5px 0 5px;"></span>').insertAfter('.icon-rotate-cw');
    }

    $('.btn-html-pricefinder-search').html('<i class="icon feather icon-search"></i>');
    $('.btn-html-pricefinder-sharelink').html('<i class="icon feather icon-link"></i> <span>Share Link</span>');

    if (this.authUser.provider === 'designly') {
      $('.btn-html-save-campaign').css('display', 'none');
    }

    if (this.template.category.ID !== 1) {
      $('.btn-html-emarketing-options').css('display', 'none');
    }

    // tslint:disable-next-line: max-line-length
    if (this.isDesignlyMail || this.template.category_id === 2 || this.template.category_id === 11 || this.template.category_id === 10 || (this.template.category_id === 13 && !this.authUser.is_master)) {
      $('.btn-html-new-send').css('display', 'none');
    }

    if (this.isDesignlyMail) {
      $('.icon-chevron-left').css('display', 'none');
    }

    if (this.isVaultMail) {
      $('.btn-html-save').addClass('btn-html-vault-save');
    }

    $('.btn-view-style-manager').trigger('click');

    const view_sm = $('.gjs-pn-views-container > div:nth-child(2)');
    view_sm.addClass('view-style-manager-container');

    const iframe = $(this.builder.nativeElement).find('iframe');

    let txtTitle = this.template.title;

    if (txtTitle.length > 30) {
      txtTitle = this.template.title.substring(0, 30) + '...';
    }

    $('.html-template-title').text(txtTitle);

    // WB-3447: Elevio helper and help center
    $('.gjs-pn-btn.btn-global-setting.icon.feather.icon-settings').after('<div id="template_settings" style="margin-right: 15px;"><span data-elevio-article="73"></span></div>');

    const generateTags = (tag_items: any) => {
      if (tag_items && tag_items.length) {

        const tagsElementString = tag_items.map((tag:any) => {
          const style = `
            background-color: #e1e5e8;
            font-size: 13px;
            padding: 2px 7px;
            color: #223646;
            margin-right: 3px;
            border: unset;
          `;
          return `<span style="${style}">${tag}</span>`;
        }).join("");

        // const tagElement = $(`<div>${tagsElementString}</div>`).insertAfter(".html-template-title");
        
        const tagsElement = $(`<button class="btn btn-tags" data-bs-placement="right" data-bs-container="body" data-bs-toggle="popover"
          style="background-color: #e1e5e8; color: #223646; font-size: 13px; font-weight: 700">Tags</button>`)
          .insertAfter(".html-template-title");

        setTimeout(() => {
          $(".gjs-pn-devices").css({
            'transform': `translateX(${tagsElement.width()}px)`
          });
          new bootstrap.Popover(tagsElement.get(0), {
            container: 'body',
            placement: 'right',
            trigger: 'focus',
            html: true,
            content: $(`<div style="display: flex; flex-wrap: wrap; row-gap: 5px">${tagsElementString}</div>`)
          })
        }, 500);
      }
    }


    if (this.route.snapshot.queryParamMap.get("tags")) {
      generateTags(this.route.snapshot.queryParamMap.get("tags").split(','));
    }

    else if (this.template.quickdesign) {

      const { quickdesign: { tag_items } } = this.template;

      generateTags(tag_items);

    }


    /*
     * Restrict resize desktop mobile to eMarketing
     */

    // tslint:disable-next-line: max-line-length
    if (!this.emailCategories.includes(this.template.category_id)) {
      $('.html-desktop').css('display', 'none');
      $('.html-mobile').css('display', 'none');
    }

    if (localStorage.getItem('first_builder') === '1') {
      $('.btn-html-tour').trigger('click');

      // set builder tour to 0
      localStorage.setItem('first_builder', '0');
    }

    if (this.template.master.puppeteer) {
      $('.btn-html-pdf').css('display', 'none');  // hide original pdf button for high and low resolution pdf download
    } else {
      $('.btn-html-highres-pdf').css('display', 'none');  // hide pdf compress button
    }

    // var titles = document.querySelectorAll('*[title]');

    // for (var i = 0; i < titles.length; i++) {
    //   var el = titles[i];
    //   var title = el.getAttribute('title');
    //   title = title ? title.trim(): '';
    //   if(!title)
    //     break;
    //   el.setAttribute('data-tooltip', title);
    //   el.setAttribute('title', '');
    //   el.setAttribute('data-tooltip-pos', 'bottom');
    // }

    if (this.template.category_id !== 14) {
      $('.btn-html-pricefinder-search').css('display', 'none');
      $('.btn-html-pricefinder-sharelink').css('display', 'none');
    }

    // limit insert property button to bayleys client
    if (!this.FeatureFlagService.checkUser('bayleys', $this.authUser)) {
      $('.btn-html-insert-property').addClass('hidden');
      $('.reset-design').addClass('hidden');
    } else {
      if (this.template.category_id !== this.templateCategoryId.BROCHURE && this.template.category_id !== this.templateCategoryId.PROPERTY_GUIDE && this.template.category_id !== this.templateCategoryId.SOCIAL_MEDIA && this.template.category_id !== this.templateCategoryId.DL_CARD && this.template.category_id !== this.templateCategoryId.SIGNBOARD) {
        $('.btn-html-insert-property').addClass('hidden');
      }
    }


    setTimeout(() => {
      let timeout;
      $('[data-tooltip]').on({
        mouseenter: function () {
          timeout = setTimeout(() => {
            $('[data-tooltip]').removeClass('hovered');
            $(this).addClass('hovered');
          }, 1000);
        },
        mouseleave: function () {
          clearTimeout(timeout);
          $(this).removeClass('hovered');
        },
      });

      this.currIframeWidth = $($this.builder.nativeElement).find('iframe').width();
    }, 1500);
  }

  initStyle() {
    const iframe = $(this.builder.nativeElement).find('iframe').contents();
    let htmlStyle = this.style.style;
    // iframe.find('.md-content-editable').attr('data-gjs-type', 'text');

    iframe.find('.gjs-css-rules').remove();

    const clientSetting = this.frontService.authService.auth.client;
    const groupSetting = this.clientSetting.group.master;

    // Non-Class Setting - Merge Field
    if (this.clientSetting.color_1) {
      htmlStyle = htmlStyle.replace(/\{%COLOR1%\}/gi, this.clientSetting.color_1);
    } else {
      if (this.groupSetting !== null && this.groupSetting !== undefined) {
        htmlStyle = htmlStyle.replace(/\{%COLOR1%\}/gi, this.groupSetting.color_1);
      }
    }
    if (this.clientSetting.color_2) {
      htmlStyle = htmlStyle.replace(/\{%COLOR2%\}/gi, this.clientSetting.color_2);
    } else {
      if (this.groupSetting !== null && this.groupSetting !== undefined) {
        htmlStyle = htmlStyle.replace(/\{%COLOR2%\}/gi, this.groupSetting.color_2);
      }
    }
    if (this.clientSetting.color_3) {
      htmlStyle = htmlStyle.replace(/\{%COLOR3%\}/gi, this.clientSetting.color_3);
    } else {
      if (this.groupSetting !== null && this.groupSetting !== undefined) {
        htmlStyle = htmlStyle.replace(/\{%COLOR3%\}/gi, this.groupSetting.color_3);
      }
    }
    if (this.clientSetting.color_4) {
      htmlStyle = htmlStyle.replace(/\{%COLOR4%\}/gi, this.clientSetting.color_4);
    } else {
      if (this.groupSetting !== null && this.groupSetting !== undefined) {
        htmlStyle = htmlStyle.replace(/\{%COLOR4%\}/gi, this.groupSetting.color_4);
      }
    }
    if (this.clientSetting.color_5 && this.clientSetting.color_5 !== '#') {
      htmlStyle = htmlStyle.replace(/\{%COLOR5%\}/gi, this.clientSetting.color_5);
    } else {
      if (this.groupSetting !== null && this.groupSetting !== undefined) {
        htmlStyle = htmlStyle.replace(/\{%COLOR5%\}/gi, this.groupSetting.color_5);
      }
    }
    if (this.clientSetting.color_6) {
      htmlStyle = htmlStyle.replace(/\{%FONTCOLOR1%\}/gi, this.clientSetting.color_6);
    } else {
      if (this.groupSetting !== null && this.groupSetting !== undefined) {
        htmlStyle = htmlStyle.replace(/\{%FONTCOLOR1%\}/gi, this.groupSetting.color_6);
      }
    }
    if (this.clientSetting.color_7) {
      htmlStyle = htmlStyle.replace(/\{%FONTCOLOR2%\}/gi, this.clientSetting.color_7);
    } else {
      if (this.groupSetting !== null && this.groupSetting !== undefined) {
        htmlStyle = htmlStyle.replace(/\{%FONTCOLOR2%\}/gi, this.groupSetting.color_7);
      }
    }
    if (this.clientSetting.color_8) {
      htmlStyle = htmlStyle.replace(/\{%ICONCOLOR1%\}/gi, this.clientSetting.color_8);
    } else {
      if (this.groupSetting !== null && this.groupSetting !== undefined) {
        htmlStyle = htmlStyle.replace(/\{%ICONCOLOR1%\}/gi, this.groupSetting.color_8);
      }
    }

    if (this.clientSetting.color_9) {
      htmlStyle = htmlStyle.replace(/\{%FONTCOLOR3%\}/gi, this.clientSetting.color_9);
      htmlStyle = htmlStyle.replace(/\{%FONTCOLOR9%\}/gi, this.clientSetting.color_9);
    } else {
      if (this.groupSetting !== null && this.groupSetting !== undefined) {
        htmlStyle = htmlStyle.replace(/\{%FONTCOLOR3%\}/gi, this.groupSetting.color_9);
        htmlStyle = htmlStyle.replace(/\{%FONTCOLOR9%\}/gi, this.groupSetting.color_9);
      }
    }

    if (this.clientSetting.color_10) {
      htmlStyle = htmlStyle.replace(/\{%FONTCOLOR4%\}/gi, this.clientSetting.color_10);
      htmlStyle = htmlStyle.replace(/\{%FONTCOLOR10%\}/gi, this.clientSetting.color_10);
    } else {
      if (this.groupSetting !== null && this.groupSetting !== undefined) {
        htmlStyle = htmlStyle.replace(/\{%FONTCOLOR4%\}/gi, this.groupSetting.color_10);
        htmlStyle = htmlStyle.replace(/\{%FONTCOLOR10%\}/gi, this.groupSetting.color_10);
      }
    }

    if (this.clientSetting.font_family) {
      htmlStyle = htmlStyle.replace(/\{%FONTFAMILY%\}/gi, this.clientSetting.font_family);
    } else {
      if (this.groupSetting !== null && this.groupSetting !== undefined) {
        htmlStyle = htmlStyle.replace(/\{%FONTFAMILY%\}/gi, this.groupSetting.font_family);
      }
    }

    // low res logo
    if (this.clientSetting.primary_logo) {
      htmlStyle = htmlStyle.replace(/\{%PRIMARYLOGO%\}/gi, this.clientSetting.primary_logo);
    } else {
      if (this.groupSetting !== null && this.groupSetting !== undefined) {
        htmlStyle = htmlStyle.replace(/\{%PRIMARYLOGO%\}/gi, this.groupSetting.primary_logo);
      }
    }
    if (this.clientSetting.secondary_logo) {
      htmlStyle = htmlStyle.replace(/\{%SECONDARYLOGO%\}/gi, this.clientSetting.secondary_logo);
    } else {
      if (this.groupSetting !== null && this.groupSetting !== undefined) {
        htmlStyle = htmlStyle.replace(/\{%SECONDARYLOGO%\}/gi, this.groupSetting.secondary_logo);
      }
    }

    // high res logo
    if (this.clientSetting.hires_primary_logo) {
      htmlStyle = htmlStyle.replace(/\{%PRIMARYLOGOHIRES%\}/gi, this.clientSetting.hires_primary_logo);
    } else {
      if (this.groupSetting !== null && this.groupSetting !== undefined) {
        htmlStyle = htmlStyle.replace(/\{%PRIMARYLOGOHIRES%\}/gi, this.groupSetting.hires_primary_logo);
      }
    }
    if (this.clientSetting.hires_secondary_logo) {
      htmlStyle = htmlStyle.replace(/\{%SECONDARYLOGOHIRES%\}/gi, this.clientSetting.hires_secondary_logo);
    } else {
      if (this.groupSetting !== null && this.groupSetting !== undefined) {
        htmlStyle = htmlStyle.replace(/\{%SECONDARYLOGOHIRES%\}/gi, this.groupSetting.hires_secondary_logo);
      }
    }

    if (this.clientSetting.primary_icon_bed) {
      htmlStyle = htmlStyle.replace(/\{%PRIMARYICONBED%\}/gi, this.clientSetting.primary_icon_bed);
    } else {
      if (this.groupSetting !== null && this.groupSetting !== undefined) {
        htmlStyle = htmlStyle.replace(/\{%PRIMARYICONBED%\}/gi, this.groupSetting.primary_icon_bed);
      }
    }
    if (this.clientSetting.primary_icon_bath) {
      htmlStyle = htmlStyle.replace(/\{%PRIMARYICONBATH%\}/gi, this.clientSetting.primary_icon_bath);
    } else {
      if (this.groupSetting !== null && this.groupSetting !== undefined) {
        htmlStyle = htmlStyle.replace(/\{%PRIMARYICONBATH%\}/gi, this.groupSetting.primary_icon_bath);
      }
    }
    if (this.clientSetting.primary_icon_car) {
      htmlStyle = htmlStyle.replace(/\{%PRIMARYICONCAR%\}/gi, this.clientSetting.primary_icon_car);
    } else {
      if (this.groupSetting !== null && this.groupSetting !== undefined) {
        htmlStyle = htmlStyle.replace(/\{%PRIMARYICONCAR%\}/gi, this.groupSetting.primary_icon_car);
      }
    }
    if (this.clientSetting.secondary_icon_bed) {
      htmlStyle = htmlStyle.replace(/\{%SECONDARYICONBED%\}/gi, this.clientSetting.secondary_icon_bed);
    } else {
      if (this.groupSetting !== null && this.groupSetting !== undefined) {
        htmlStyle = htmlStyle.replace(/\{%SECONDARYICONBED%\}/gi, this.groupSetting.secondary_icon_bed);
      }
    }
    if (this.clientSetting.secondary_icon_bath) {
      htmlStyle = htmlStyle.replace(/\{%SECONDARYICONBATH%\}/gi, this.clientSetting.secondary_icon_bath);
    } else {
      if (this.groupSetting !== null && this.groupSetting !== undefined) {
        htmlStyle = htmlStyle.replace(/\{%SECONDARYICONBATH%\}/gi, this.groupSetting.secondary_icon_bath);
      }
    }
    if (this.clientSetting.secondary_icon_car) {
      htmlStyle = htmlStyle.replace(/\{%SECONDARYICONCAR%\}/gi, this.clientSetting.secondary_icon_car);
    } else {
      if (this.groupSetting !== null && this.groupSetting !== undefined) {
        htmlStyle = htmlStyle.replace(/\{%SECONDARYICONCAR%\}/gi, this.groupSetting.secondary_icon_car);
      }
    }

    const external_font_list = this.style.external_font.split('\n');
    for (const external_font of external_font_list) {
      if (external_font) {
        const externalFont = $('<link>').attr('rel', 'stylesheet').attr('href', external_font);

        iframe.find('head').append(externalFont);
      }
    }

    const external_css_list = this.style.external_css.split('\n');
    for (const external_css of external_css_list) {
      if (external_css) {
        const externalLink = $('<link>').attr('rel', 'stylesheet').attr('type', 'text/css').attr('href', external_css);

        iframe.find('head').append(externalLink);
      }
    }

    const $this = this;

    let emailBackgroundColor = '';

    if (this.emailCategories.includes(this.template.category.ID)) {
      let color5 = '#eeeeee'; // WB-3439 change to default email background color of Standard group as a fallback
      if (this.clientSetting.color_5 && this.clientSetting.color_5 !== '#') {
        color5 = this.clientSetting.color_5;
      } else {
        if (this.groupSetting !== null && this.groupSetting !== undefined) {
          color5 = this.groupSetting.color_5;
        }
      }
      if (color5) {
        emailBackgroundColor = `
        html, body, #wrapper
        {
          background-color: ${color5} !important;
        }
        `;
      }
    }

    iframe.find('head').append($('<style>').text(htmlStyle));

    iframe.find('head').append('<meta http-equiv="Content-Type" content="text/html; charset=utf-8" />');

    iframe.find('body').append(
      $('<style>').text(`
      html {
        overflow: overlay;
      }
      .page-divider {
        border-bottom: 2px dashed #8da3b0;
        display: block;
        padding: 20px 0 0;
        margin: 0 0 20px 0;
      }
      .page {
        margin:0 auto 40px auto;
        box-shadow: 0 0 40px rgba(0,0,0,0.3);
      }
      .md-hidden {
        display: none;
      }
      .md-prop-search.ui-draggable, .md-library-search.ui-draggable  {
        cursor: pointer;
      }
      p.gjs-comp-selected {
          outline: 1px dashed ${$this.mainColor} !important;
          outline-offset: -1px;
      }
      .gjs-badge, .gjs-com-badge, .gjs-com-badge-red {
        display:none!important;
      }
      body {
        height: auto !important;
        -webkit-print-color-adjust: exact !important;
      }
      @media print {
        body, html, #wrapper {
          width: 100%;
        }
        @page {
          size: auto;
          margin: 20mm 0 20mm 0;
        }
      }
      .gjs-comp-selected {
        outline: 3px solid ${$this.mainColor} !important;
        outline-offset: -1px !important;
      }
      #wrapper {
        outline: none !important;
      }
      .dsgnly-ckeditor > ul,ol {padding: revert;} 
      .dsgnly-ckeditor > ol {list-style: auto;}
      .dsgnly-ckeditor > ul {list-style: initial;}
      .dsgnly-ckeditor > ul li {display: list-item;}
      em {
        font-style: italic !important;
      }
      ` + emailBackgroundColor
      )
    );

    if (this.template.category_id === 13) {
      $('head').append($('<style>').text('.fa-clone { display: none !important; }'));
    }

    if (this.template.category_id === 14) {
      $('head').append(
        $('<style>').text(`
        .gjs-cv-canvas { width: 100% !important; }
        .gjs-pn-views-container { display: none !important; }
        `)
      );
    }

    const external_js_list = this.style.external_js.split('\n');

    for (const external_js of external_js_list) {
      if (external_js) {
        const externalLink = $('<script>').attr('type', 'text/javascript').attr('src', external_js);

        iframe.find('head').append(externalLink);
      }
    }

    setTimeout(() => {
      iframe.find('body').append($('<div>').attr('id', 'after-body-script').html(''));
      const priceFinderType = this.route.snapshot.queryParamMap.get('pricefinder');
      if (priceFinderType === 'new') {
        setTimeout(() => {
          this.editor.runCommand('pricefinder-search');
        }, 1000);
      }

      this.initScript();
      this.initScriptContentAlert();
    }, 1000);
  }

  initScript() {
    try {
      const iframe = $(this.builder.nativeElement).find('iframe').contents();
      iframe.find('.gjs-css-rules').empty();

      const afterBody = iframe.find('#after-body-script').html('');
      afterBody.append($('<script type="text/javascript">').text(`` + this.style.script + ``));

      // cdn for FitText.js - textFill feature
      afterBody.append($('<script type="text/javascript" src="https://cdnjs.cloudflare.com/ajax/libs/FitText.js/1.2.0/jquery.fittext.min.js">'));
      afterBody.append($('<script type="text/javascript" src="https://cdnjs.cloudflare.com/ajax/libs/FitText.js/1.2.0/jquery.fittext.js">'));

      setTimeout(() => {
        $(document).ready(function(e) {
            const iframeElement = document.querySelector('iframe').contentDocument;
            if (typeof iframeElement !== 'undefined') {
                const iframeBody = iframeElement.querySelector('body');
                if (typeof iframeBody !== 'undefined' && iframeBody) {
                    const iframeContents = iframeBody.querySelectorAll<HTMLElement>('.md-fittext');
                    if (typeof iframeContents !== 'undefined' && iframeContents.length) {
                      iframeContents.forEach(iframeContent => {
                        if (typeof iframeContent !== 'undefined' && iframeContent) {
                          const dataMaxlength = iframeContent.getAttribute('data-max-character').split(',');
                          const dataFontSize = iframeContent.getAttribute('data-font-size').split(',');
                          iframeContent.removeAttribute('style');
                          // get the content length of the header to determine the needed font size adjustments
                          const htmlContent = iframeContent.innerHTML;
                          let fontSize = window.getComputedStyle(iframeContent).fontSize;  // default font size

                          if (htmlContent.length > 0) {
                              const contentLength = htmlContent.length;
                              if (typeof dataMaxlength !== 'undefined' && dataMaxlength) {
                                dataMaxlength.forEach((value, index) => {
                                  const num = parseInt(value);
                                  if (contentLength > num) {
                                    fontSize = dataFontSize[index] + 'px';                              
                                  }
                                });
                              }
                              $(iframeContent).fitText(1.2, {
                                  minFontSize: fontSize,
                                  maxFontSize: fontSize
                              });
                          }
                        }
                      });
                    }
                }
            }
        });
      }, 500);

    } catch (e) {
      console.log(e);
    }
  }

  initScriptContentAlert() {
    try {
      const iframe = $(this.builder.nativeElement).find('iframe').contents();
      iframe.find('.gjs-css-rules').empty();

      const afterBody = iframe.find('#after-body-script').html('');
      afterBody.append($('<script type="text/javascript">').text(`` + this.style.script + ``));
      
      $(document).ready(function(e) {
        const iframeElement = document.querySelector('iframe').contentDocument;
        if (typeof iframeElement !== 'undefined') {
          const iframeBody = iframeElement.querySelector('body');
          if (typeof iframeBody !== 'undefined' && iframeBody) {
            const iframeContents = iframeBody.querySelectorAll<HTMLElement>('.md-content-alert');
            iframeContents.forEach(element => {
              try {
                const elementChar = element.textContent;
                const maxCharacterValue = element.getAttribute('data-max-character');
                const allowedCharCount = parseInt(maxCharacterValue)
                if (elementChar.length > allowedCharCount) {
                  element.classList.add('allowed');
                  element.setAttribute('title', 'Overflow text');
                } else {
                  element.classList.remove('allowed');
                  element.removeAttribute('title');
                }
              } catch (e) {
                console.warn(e);
              }
            });
          }
        }
      });

    } catch (e) {
      console.warn(e);
    }
  }

  saveHtml(editor: any, download: string = '', loading: boolean = true, isAutoSave: boolean = false, uploadFileToCabinate: boolean = false) {
    const $this = this;
    this.loading = loading;

    if (this.template.category_id === 4 || this.template.category_id === 8) {
      const selectedElement = editor.getSelected();

      if (typeof selectedElement !== 'undefined') {
        const elementBox = selectedElement.view.$el;
        const elementBlock = elementBox.parents('#wrapper');

        if (elementBox.length > 0 && elementBlock.length > 0) {
          editor.setComponents(elementBlock.html());
        }
      }
    }

    let html = editor.getHtml();
    
    let html_data_css = this.editor.getCss({
      avoidProtected: true,
    });
  
    // Use regex to match only ID selectors
    let id_css_rules = html_data_css.match(/#\w+\s*{[^}]*}/g);
    html_data_css = id_css_rules ? id_css_rules.join('\n') : '';
    
    const body = this.editor.Canvas.getBody();
    if (this.template.category_id === 1) {
      const property_ids = [];
      $(html)
        .find('[data-propid]')
        .each(function () {
          property_ids.push($(this).data('propid'));
        });

      if (property_ids.length > 0) {
        const unique_property_ids = Array.from(new Set(property_ids));

        if (this.authUser.provider === 'mydesktop') {
          const pid_list_comment = '<!--PIDLIST:' + unique_property_ids.join() + ':PIDLIST-->';
          $(body).find('#wrapper').append(pid_list_comment);
        }
      }
    } else {
      html_data_css = String(html_data_css).replace(/(\..*?\})/gm, '');
    }

    const htmlCollectionScope = this.editor.DomComponents.getWrapper().view.el.children.length;
    if (!this.template.quickdesign && !this.template.is_master_template && !this.isMergedSaved && htmlCollectionScope > 0) {
      $(body).find('#wrapper').append('<!--MERGED_SAVED-->');
    }

    if (this.template.category_id === 13) {
      const newListingElementCount = $(body).find('.md-newlistings').length;
      const priceReductionElementCount = $(body).find('.md-pricereductions').length;
      const openHomesElementCount = $(body).find('.md-opens').length;
      const soldElementCount = $(body).find('.md-sold').length;

      if (!newListingElementCount || !priceReductionElementCount || !openHomesElementCount || !soldElementCount) {
        const missingElements = [];

        if (!newListingElementCount) {
          missingElements.push('New Listings');
        }

        if (!priceReductionElementCount) {
          missingElements.push('Price Reductions');
        }

        if (!openHomesElementCount) {
          missingElements.push('Opens');
        }

        if (!soldElementCount) {
          missingElements.push('Sold');
        }

        swal({
          title: 'All property elements required',
          html: `Please make sure you have included all property element types, for example New Listings, Price Reductions, Opens and Sold, these are required to work in VaultRE
          <br />
          <br />
          Please note that each property type will only display in the emails when relevant.
          <br />
          <br />
          <strong>Missing Elements:</strong> ${missingElements.join(', ')}`,
          type: 'warning',
        });

        this.loading = false;
        return false;
      }
    }

    /* Download PDF with custom wrapper for user global settings */
    if (this.pdfButtonClicked === true) {
        let userGlobalTmpClass = [];
        this.userGlobalSettings.forEach((obj) => {
          userGlobalTmpClass.push(obj.class);
        });
        
        if (userGlobalTmpClass.length > 0) {
          const custom_class = userGlobalTmpClass.join(' ');
          $(body).find('#wrapper').children().wrapAll(`<div id="customWrapper" class="${custom_class}"></div>`);
        }
    }

    html = $(body).find('#wrapper').html();

    if (this.template.is_master_template || (typeof this.template.quickdesign !== 'undefined' && this.template.quickdesign)) {
      html = String(html).replace(/<!--MERGED_SAVED-->/g, '');
    }

    if (this.template.category_id === 1 && html_data_css.includes('.cke_editable_inline.cke_contents_ltr')) {
      const classString = this.getSubString(html_data_css, '.cke_editable_inline.cke_contents_ltr', '}');
      if (classString) {
        html_data_css = html_data_css.replace(classString, '');
      }
    }

    //Converts CSS rules (from a string) into a JavaScript object 
    const parseCss = (html_data_css) => {
      const rules = {};
      html_data_css.split('}').forEach(rule => {
        if (rule.trim()) {
          const [selectors, declarations] = rule.split('{');
          if (selectors && declarations) {
            selectors.split(',').forEach(selector => {
              rules[selector.trim()] = declarations.trim();
            });
          }
        }
      });
      return rules;
    };

    //Applies CSS rules to a specific element based on the element's selectors
    const applyStyles = (element, rules) => {
      let inlineStyles = '';
      Object.entries(rules).forEach(([selector, declarations]) => {
        // Check if the selector matches the element's class or tag
        if (element.includes(selector)) {
          inlineStyles += declarations + ' ';
        }
      });
      return inlineStyles.trim();
    };
    
    //Applies inline styles to HTML elements based on the parsed CSS rules
    const inlineCss = (html, html_data_css) => {
      const rules = parseCss(html_data_css);
    
      return html.replace(/(<[^>]+>)/g, (match) => {
        // Extract tag and attributes from the match
        const tagContent = match.slice(1, -1).trim();
    
        // Extract class name from attributes if available
        const classMatch = tagContent.match(/class="([^"]+)"/);
        let style = '';
        if (classMatch) {
          const classNames = classMatch[1].split(/\s+/);
          classNames.forEach(className => {
            const classSelector = `.${className}`;
            const classStyle = applyStyles(classSelector, rules);
            if (classStyle) style += classStyle + ' ';
          });
        }
    
        // Apply inline styles
        if (style) {
          if (tagContent.includes('style="')) {
            return match.replace(/style="([^"]*)"/, `style="$1; ${style}"`);
          } else {
            return match.replace('>', ` style="${style}">`);
          }
        }
    
        return match;
      });
    };
    
    const html_raw = inlineCss(html, html_data_css);

    const templateValues: Object = {};

    const validation = this.validateCodeElement(this.template.master.validation_code, html);
    const isValid = validation.status;

    if (isValid) {
      if (this.new_template_name !== '') {
        templateValues['title'] = this.new_template_name;
      }
      if (this.qdtButtonClicked) {
        templateValues['qdt'] = true;
        this.qdtButtonClicked = false;
      }
      if (this.qdoButtonClicked) {
        templateValues['qdt'] = true;
        templateValues['client_id'] = this.client.ID;
        this.qdoButtonClicked = false;
      }

      templateValues['html'] = html;
      templateValues['html_raw'] = html_raw;
      templateValues['html_data'] = { style: '', css: html_data_css };
      templateValues['download'] = download;
      templateValues['provider'] = this.authUser.provider;
      templateValues['customImage'] = this.customImageType;
      templateValues['isVaultMail'] = this.isVaultMail;
      templateValues['isHighResPdf'] = this.isHighResPdf;
      templateValues['uploadFileToCabinate'] = uploadFileToCabinate;
      const addedListings = [];
      $(html)
        .find('[data-listing-id]')
        .each(function () {
          const listingId = String($(this).data('listing-id'));
          addedListings.push(listingId);
        });

      templateValues['listings'] = this.templateListings = $this.templateListings.filter((listing) => addedListings.includes(listing.listing_id));

      if (isAutoSave) {
        templateValues['autosave'] = 1;
      }

      if (this.isAddUpdateMaster !== '') {
        templateValues['is_master_template'] = this.isAddUpdateMaster;
        this.isAddUpdateMaster = '';
      }

      const printableCategories = [5, 8, 10];

      if (printableCategories.includes(this.template.category_id)) {
        try {
          templateValues['thumbnailHtml'] = $(html_raw).first()[0].outerHTML;
        } catch (e) {
          console.log(e);
        }
      }

      if (this.sharePriceFinderLinkClicked) {
        templateValues['pricefinder_share'] = true;
      }

      if(uploadFileToCabinate){
        const dataQuery = {
          templateValues: <Template>templateValues,
          id: this.id
        }
        return dataQuery;
      } else {
        this.templateService.httpBuildTemplate(this.id, <Template>templateValues);
      }

      this.customImageType = {
        isCustom: false,
        type: 'jpg',
      };
    } else {

      let errorMessageList = '<ul style="width: fit-content;margin: auto;">'

      validation.message.map((msg) => {
        errorMessageList = errorMessageList + `<li><span>${msg}</span></li>`
      })

      errorMessageList = errorMessageList + '</ul>'
      
      swal({
        title: validation.title,
        html: errorMessageList,
        type: 'error',
      });
      this.loading = false;
    }
    this.isUserHasChanges = false;

    return isValid;
  }

  validateCodeElement(code: string = '', html: string = '') {
    const result = { status: true, title: '', message: [] };
    if (code) {
      const validationCodes = code.split('\n');
      if (validationCodes.length > 0) {
        validationCodes.forEach(function (validationCode) {
          const vcRules = validationCode.split(':');
          if (vcRules.length > 0) {
            /*
              Values:
              • vcRules[0] - element id
              • vcRules[1] - minimum number of element
              • vcRules[2] - maximum number of element
              • vcRules[3] - error message
            */
            if (vcRules[0]) {
              const elm = $('<div>').html(html).find(vcRules[0]);
              let elementStatus = true;
              result.title = "Doesn't meet the template requirements";

              //Check if the set minimum number of elements is satisfied
              if (typeof vcRules[1] !== 'undefined' && +vcRules[1] > 0 && elm.length < +vcRules[1]) {
                elementStatus = false;
              }

              //Check if the element added shouldn't exist
              if (typeof vcRules[1] !== 'undefined' && +vcRules[1] < 0 && elm.length > 0) {
                elementStatus = false;
              }

              //Check if the set maximum number of elements is satisfied
              if (typeof vcRules[2] !== 'undefined' && +vcRules[2] > 0 && elm.length > +vcRules[2]) {
                elementStatus = false;
              }

              if (!elementStatus) {
                const title = typeof vcRules[3] !== 'undefined' && vcRules[3] ? vcRules[3] : 'Element';
                result.status = elementStatus;
                result.message.push(title);
              }
            }
          }
        });
      }
    }

    return result;
  }

  /* Method for validation code for specific elemenet using ID when adding it to canvas */
  validateCodeAddElement(code: string = '', html: string = '', elementID: string = '') {
    const result = { status: true, title: '', message: '' };
    if (code) {
      const validationCodes = code.split('\n');
      if (validationCodes.length > 0) {
        validationCodes.forEach(function (validationCode) {
          const vcRules = validationCode.split(':');
            if (vcRules[0] && vcRules[0] === elementID) {
              if (vcRules.length > 0) {
                const elm = $('<div>').html(html).find(vcRules[0]);
                const title = typeof vcRules[3] !== 'undefined' && vcRules[3] ? vcRules[3] : 'Element';
  
                result.title = title;

                /* 
                  Check if the number of element with ID in the canvas 
                  will exceed to the validation code set to the template
                */
                if (typeof vcRules[2] !== 'undefined' && +vcRules[2] > 0 && elm.length >= +vcRules[2]) {
                  result.message = 'Exceed maximum (' + vcRules[2] + ') number of element required.';
                  result.status = false;
                }
              }
            }
        });
      }
    }

    return result;
  }

  getCustomParams(property_id: number, sync_data = 0) {
    this.myDesktopService.httpGetMDListingByCustomParams({
      ID: property_id,
      sync_data,
    });
  }

  onSelectedArticle(article) {
    try {
      let selectedElement = this.editor.getSelected();
      let elementBox = selectedElement.view.$el;

      if (elementBox.hasClass('md-article-search')) {
        elementBox = elementBox.parents('.md-box');
        elementBox.trigger('click');

        selectedElement = this.editor.getSelected();
      }

      const selectedHtmlEl = selectedElement.view.$el;

      let articleText = '';

      if (typeof article.raw !== 'undefined') {
        articleText = String(article.raw);
        articleText = articleText.replace(new RegExp('<p>', 'g'), '');
        articleText = articleText.replace(new RegExp('</p>', 'g'), '<br><br>');
        articleText = articleText.replace(new RegExp('<strong>', 'g'), '<b>').replace(new RegExp('</strong>', 'g'), '</b>');
        articleText = articleText.replace(new RegExp('<h1>', 'g'), '<b>').replace(new RegExp('</h1>', 'g'), '</b><br>');
        articleText = articleText.replace(new RegExp('<h2>', 'g'), '<b>').replace(new RegExp('</h2>', 'g'), '</b><br>');
        articleText = articleText.replace(new RegExp('<h3>', 'g'), '<b>').replace(new RegExp('</h3>', 'g'), '</b><br>');
        articleText = articleText.replace(new RegExp('<h4>', 'g'), '<b>').replace(new RegExp('</h4>', 'g'), '</b><br>');
        articleText = articleText.replace(new RegExp('<h5>', 'g'), '<b>').replace(new RegExp('</h5>', 'g'), '</b><br>');
      }

      selectedHtmlEl.find('.md-partica-heading').text(article.title);
      selectedHtmlEl.find('.md-partica-description').text(articleText);

      let tmpHtml = selectedHtmlEl.html();

      if (article.provider !== 'rss') {
        selectedHtmlEl.find('.md-partica-image').attr('src', environment.particaViewerEndpoint + article.image_src);
        // tslint:disable-next-line:max-line-length
        selectedHtmlEl.find('.md-partica-url').attr('href', `${environment.particaArticleEndpoint}/static/article/${article.slug}_a${article.articleId}`);
        selectedHtmlEl.find('.md-partica-abstract').text(article.abstract);
      } else {
        selectedHtmlEl.find('.md-partica-image').attr('src', article.thumbnail);
        selectedHtmlEl.find('.md-partica-url').attr('href', article.link);
        selectedHtmlEl.find('.md-partica-abstract').text(article.content);

        if (typeof article.description !== 'undefined' && article.description && article.description !== '') {
          selectedHtmlEl.find('.md-partica-abstract').text(`${article.description}...`);
        }
      }

      selectedHtmlEl.find("a[data-url*='{$PARTICAURL$}']").attr('href', article.link);

      tmpHtml = selectedHtmlEl.html();

      if (article.provider !== 'rss') {
        // tslint:disable-next-line:max-line-length
        tmpHtml = tmpHtml.replace(/\{%PARTICAURL%\}/gi, `${environment.particaArticleEndpoint}/static/article/${article.slug}_a${article.articleId}`);
      }

      selectedElement.components('');
      selectedElement.set('content', tmpHtml);

      const html = this.editor.getHtml();

      const html_data_css = this.editor.getCss();
      const html_raw = juice(html + '<style>' + html_data_css + '</style>', {
        preserveMediaQueries: false,
        preserveImportant: true,
        applyHeightAttributes: false,
        applyWidthAttributes: false,
      });

      this.editor.setComponents(html_raw);
    } catch (err) {
      console.log(err);
    }
  }

  onSelectListings(bypassSelect = false) {
    this.subLoading = true;
    setTimeout(() => {
      try {
        const $this = this;

        if (!this.isVaultMail) {
          const isManualOrder = $('#manual-insert-order').is(':checked');

          if (isManualOrder && this.template.category_id !== 5) {
            this.selectedListings = this.selectedListings.reverse();
          } else {
            this.selectedListings.sort((a, b) => {
              return a.order > b.order ? 1 : 0;
            });
          }
        }

        let listings = this.selectedListings.slice();
        let selectedElement = this.editor.getSelected();
        let singleElement = false;
        let elementBox = selectedElement.view.$el;
        
        const elementBoxIndex = elementBox.parents('.md-prop-box').index();
        const elementGroupIndex = elementBox.parents('.md-box').index();
        
        if (elementBox.hasClass('md-prop-search')) {
          elementBox = elementBox.parents('.md-box');
          elementBox.trigger('click');

          selectedElement = this.editor.getSelected();
        }
        // trigger click for the selected element
        if (this.insertPropertyElement === true && !elementBox.hasClass('md-prop-search')) {
          if (elementBox.hasClass('dsgnly-element-prop-insert') || elementBox.hasClass('dsgnly-prop-grid-box')) {
            elementBox.trigger('click');
            selectedElement = this.editor.getSelected();
          }
        }

        let elementBlock = elementBox.find('.md-prop-box');
        let selectedBlock = selectedElement.find('.md-prop-box')[0];

        if (elementBox.hasClass('md-prop-box')) {
          elementBlock = elementBox;
          selectedBlock = selectedElement;
        }

        if (this.element_listing_index > 0 && elementBox.hasClass('md-prop-single-box')) {
          singleElement = true;
          elementBlock = elementBox.find('.md-prop-box').eq(this.element_listing_index - 1);
          selectedBlock = selectedElement.find('.md-prop-box')[this.element_listing_index - 1];
        }

        if (this.template.category_id === 4 || this.template.category_id === 8 || this.template.category_id === 9) {
          elementBlock = elementBox.parents('#wrapper');
        }

        let elementBlockGroup = null;
        let elementParentProperty = null;

        let propGuideCount = 0;
        let elementChildren = [];
        let currentElementChildren = [];

        if (elementBlock.find('.md-prop-building-opportunities').length > 0) {
          let propertyConfigCount = 0;
          if (typeof listings[0].propertyConfig !== 'undefined') {
            propertyConfigCount = parseInt(listings[0].propertyConfig.totalItems);
          }

          if (elementBlock.find('.md-prop-building-opportunities').length > 0) {
            this.resetPropertyConfigTable(elementBlock.find('.md-prop-building-opportunities'));
          }
          if (elementBlock.find('.md-prop-building-opportunities-2').length > 0) {
            this.resetPropertyConfigTable(elementBlock.find('.md-prop-building-opportunities-2'));
            elementBlock.find('.md-prop-building-opportunities-2').prop('hidden', true);
          }

          elementBlockGroup = elementBlock.find('.md-prop-building-opportunities');
          let elementTbody = elementBlockGroup.find('tbody');
          let elementRow = elementTbody.find('tr').last();
          if (elementBlockGroup.length > 0 && propertyConfigCount > 0) {
            const strataBuildings = this.selectedListings[0].propertyConfig['items'];
            const tableElementLimit = parseInt(elementBlockGroup.data('limit'));
            let index = 0;
            let index_2 = 0;

            for (const strataBuilding of strataBuildings) {
              if (index >= tableElementLimit) {
                elementBlock.find('.md-prop-building-opportunities-2').prop('hidden', false);
                elementBlockGroup = elementBlock.find('.md-prop-building-opportunities-2'); // populate the 2nd table
                elementTbody = elementBlockGroup.find('tbody');
                elementRow = elementTbody.find('tr').last();
                if (index_2 > 0 && elementBlock.find('.md-prop-building-opportunities-2').length > 0) {
                  elementTbody.append(elementRow.html());
                  elementRow = elementTbody.find('tr').last();
                }
                index_2++;
              } else {
                if (index > 0) {
                  elementTbody.append(elementRow.html());
                  elementRow = elementTbody.find('tr').last();
                }
              }

              const strataBuildingDetails = this.getPropertyConfigInfo(strataBuilding);
              elementRow.find('.md-prop-building-opportunities-level').html(strataBuildingDetails.level_column);
              elementRow.find('.md-prop-building-opportunities-area').html(strataBuildingDetails.area_column);
              elementRow.find('.md-prop-building-opportunities-rent').html(strataBuildingDetails.rent_column);
              elementRow.find('.md-prop-building-opportunities-availability').html(strataBuildingDetails.availability_column);
              elementRow.find('.md-prop-building-opportunities-comments').html(strataBuildingDetails.comments_column);
              index++;
            }
          }
        }

        if (this.template.category_id === 5) {
          elementBlockGroup = elementBlock.parents('.md-prop-group-box');
          elementParentProperty = elementBlock.parents('[data-limit]');

          if (!elementParentProperty.hasClass('gjs-filled')) {
            elementBlockGroup.html('');
            elementParentProperty.addClass('gjs-filled');
          }
          // empty the element block group on insert property element
          if (this.insertPropertyElement && elementParentProperty.hasClass('gjs-filled')) {
            elementBlockGroup.html('');
          }

          propGuideCount = elementBlockGroup.children().length;

          if (this.isReplace) {
            const replaceBlock = elementBlockGroup.children()[elementBoxIndex];

            if (typeof replaceBlock !== 'undefined' && typeof replaceBlock.innerHTML === 'string') {
              replaceBlock.remove();
            }
          }

          currentElementChildren = Array.from(elementBlockGroup.children());

          if (this.isReplace) {
            currentElementChildren = currentElementChildren.slice(elementBoxIndex);
          } else {
            currentElementChildren = currentElementChildren.slice(elementBoxIndex + 1);
          }

          currentElementChildren.forEach((element) => element.remove());

          elementChildren = currentElementChildren;

          propGuideCount = elementBlockGroup.children().length;

          listings = [...listings, ...elementChildren];
        }

        let idxListing = 0;
        for (const listing of listings) {
          idxListing++;

          if (this.template.category_id === 5 && propGuideCount >= this.element_listing_limit) {
            this.property_guide_skip = true;

            const nextElement = elementParentProperty.next('[data-limit]');
            let newStockList = null;
            
            if (nextElement.length) {               

              var hasNext = false;

              const nextHeader = nextElement.find('.header');
              const parentHeader = elementParentProperty.find('.header');
              if (nextHeader.length && parentHeader.length) {
                hasNext = nextHeader.html() === parentHeader.html()
              }

              if (hasNext) {
                const nextElementLimit = nextElement.data('limit');
                if (
                  typeof nextElementLimit !== 'undefined' &&
                  // tslint:disable-next-line: radix
                  parseInt(nextElementLimit) === this.element_listing_limit
                ) {
                  const nextElementBlockGroup = nextElement.find('.md-prop-group-box');
                  const nextElementlistingsCount = nextElementBlockGroup.children().length;

                  if (this.element_listing_limit > nextElementlistingsCount) {
                    newStockList = nextElementBlockGroup;
                    Array.from(nextElementBlockGroup.children()).forEach((nextElementChildren) => listings.push(nextElementChildren));
                  }
                }
              }
            }

            if (newStockList === null) {
              newStockList = elementParentProperty.clone().insertAfter(elementBox);
            }

            singleElement = false;
            elementBox = newStockList.find('.md-prop-search').first();
            selectedElement = newStockList;

            if (elementBox.hasClass('md-prop-search')) {
              elementBox = elementBox.parents('.md-box');
              elementBox.trigger('click');
            }

            elementBlock = elementBox.find('.md-prop-box');

            if (elementBox.hasClass('md-prop-box')) {
              elementBlock = elementBox;
              selectedBlock = selectedElement;
            }

            if (this.element_listing_index > 0 && elementBox.hasClass('md-prop-single-box')) {
              singleElement = true;
              elementBlock = elementBox.find('.md-prop-box').eq(this.element_listing_index - 1);
              selectedBlock = selectedElement.find('.md-prop-box')[this.element_listing_index - 1];
            }

            elementBlockGroup = null;

            if (this.template.category_id === 5) {
              elementBlockGroup = elementBlock.parents('.md-prop-group-box');
              elementParentProperty = elementBlock.parents('[data-limit]');
              elementBlockGroup.html('');
            }

            propGuideCount = 0;
          }
          propGuideCount++;

          if (typeof listing.innerHTML === 'string') {
            elementBlock = listing;
          } else {
            let listingHtml = '';

            if (listing.listing_category === 'clearingSales') {
              listing.listing_category = 'clearingsale';
            }

            if (listing.listing_category === 'livestock') {
              listing.listing_category = 'stocklist';
            }

            if (Object.keys(this.elementListingHtml).length > 0) {
              listingHtml = this.elementListingHtml[String(listing.listing_category).toLowerCase()];
            }

            if (typeof listingHtml !== 'undefined' && listingHtml !== '') {
              const tmpHtmlDOM = $(listingHtml);

              if (this.template.category_id === 5) {
                elementBlock = tmpHtmlDOM;
              }

              let tmpHtml = tmpHtmlDOM.html();

              tmpHtml = tmpHtml.replace(/\{%DATE%\}/gi, moment().format('Do, MMM YYYY'));
              tmpHtml = tmpHtml.replace(/\{%DATEALT%\}/gi, moment().format('MMM YYYY'));

              if (this.clientSetting.name) {
                tmpHtml = tmpHtml.replace(/\{%OFFICENAME%\}/gi, this.clientSetting.name);
              } else {
                tmpHtml = tmpHtml.replace(/\{%OFFICENAME%\}/gi, '');
              }
              if (this.clientSetting.abn) {
                tmpHtml = tmpHtml.replace(/\{%OFFICEABN%\}/gi, this.clientSetting.abn);
              } else {
                tmpHtml = tmpHtml.replace(/\{%OFFICEABN%\}/gi, '');
              }
              if (this.clientSetting.company_name) {
                tmpHtml = tmpHtml.replace(/\{%OFFICEBUSINESS%\}/gi, this.clientSetting.company_name);
              } else {
                tmpHtml = tmpHtml.replace(/\{%OFFICEBUSINESS%\}/gi, '');
              }
              if (this.clientSetting.branch_name) {
                tmpHtml = tmpHtml.replace(/\{%OFFICEBRANCH%\}/gi, this.clientSetting.branch_name);
              } else {
                if (this.groupSetting !== null && this.groupSetting !== undefined) {
                  tmpHtml = tmpHtml.replace(/\{%OFFICEBRANCH%\}/gi, this.groupSetting.branch_name);
                }
              }
              if (this.clientSetting.phone) {
                tmpHtml = tmpHtml.replace(/\{%OFFICEPHONE%\}/gi, this.clientSetting.phone);
              } else {
                tmpHtml = tmpHtml.replace(/\{%OFFICEPHONE%\}/gi, '');
              }
              if (this.clientSetting.email) {
                tmpHtml = tmpHtml.replace(/\{%OFFICEEMAIL%\}/gi, this.clientSetting.email);
              } else {
                if (this.groupSetting !== null && this.groupSetting !== undefined) {
                  tmpHtml = tmpHtml.replace(/\{%OFFICEEMAIL%\}/gi, this.groupSetting.email);
                }
              }
              if (this.clientSetting.fax) {
                tmpHtml = tmpHtml.replace(/\{%OFFICEFAX%\}/gi, this.clientSetting.fax);
              } else {
                if (this.groupSetting !== null && this.groupSetting !== undefined) {
                  tmpHtml = tmpHtml.replace(/\{%OFFICEFAX%\}/gi, this.groupSetting.fax);
                }
              }
              if (this.clientSetting.street) {
                tmpHtml = tmpHtml.replace(/\{%OFFICESTREET%\}/gi, this.clientSetting.street);
              } else {
                tmpHtml = tmpHtml.replace(/\{%OFFICESTREET%\}/gi, '');
              }
              if (this.clientSetting.suburb) {
                tmpHtml = tmpHtml.replace(/\{%OFFICESUBURB%\}/gi, this.clientSetting.suburb);
              } else {
                tmpHtml = tmpHtml.replace(/\{%OFFICESUBURB%\}/gi, '');
              }
              if (this.clientSetting.state_abbr) {
                tmpHtml = tmpHtml.replace(/\{%OFFICESTATE%\}/gi, this.clientSetting.state_abbr);
              } else {
                if (typeof this.groupSetting !== 'undefined' && this.groupSetting !== null) {
                  tmpHtml = tmpHtml.replace(/\{%OFFICESTATE%\}/gi, this.groupSetting.state_abbr);
                } else {
                  tmpHtml = tmpHtml.replace(/\{%OFFICESTATE%\}/gi, '');
                }
              }
              if (this.clientSetting.postcode) {
                tmpHtml = tmpHtml.replace(/\{%OFFICEPOSTCODE%\}/gi, this.clientSetting.postcode);
              } else {
                if (this.groupSetting !== null && this.groupSetting !== undefined) {
                  tmpHtml = tmpHtml.replace(/\{%OFFICEPOSTCODE%\}/gi, this.groupSetting.postcode);
                }
              }

              // Non-Class Setting - Merge Field
              if (this.clientSetting.color_1) {
                tmpHtml = tmpHtml.replace(/\{%COLOR1%\}/gi, this.clientSetting.color_1);
              } else {
                if (this.groupSetting !== null && this.groupSetting !== undefined) {
                  tmpHtml = tmpHtml.replace(/\{%COLOR1%\}/gi, this.groupSetting.color_1);
                }
              }
              if (this.clientSetting.color_2) {
                tmpHtml = tmpHtml.replace(/\{%COLOR2%\}/gi, this.clientSetting.color_2);
              } else {
                if (this.groupSetting !== null && this.groupSetting !== undefined) {
                  tmpHtml = tmpHtml.replace(/\{%COLOR2%\}/gi, this.groupSetting.color_2);
                }
              }
              if (this.clientSetting.color_3) {
                tmpHtml = tmpHtml.replace(/\{%COLOR3%\}/gi, this.clientSetting.color_3);
              } else {
                if (this.groupSetting !== null && this.groupSetting !== undefined) {
                  tmpHtml = tmpHtml.replace(/\{%COLOR3%\}/gi, this.groupSetting.color_3);
                }
              }
              if (this.clientSetting.color_4) {
                tmpHtml = tmpHtml.replace(/\{%COLOR4%\}/gi, this.clientSetting.color_4);
              } else {
                if (this.groupSetting !== null && this.groupSetting !== undefined) {
                  tmpHtml = tmpHtml.replace(/\{%COLOR4%\}/gi, this.groupSetting.color_4);
                }
              }
              if (this.clientSetting.color_5 && this.clientSetting.color_5 !== '#') {
                tmpHtml = tmpHtml.replace(/\{%COLOR5%\}/gi, this.clientSetting.color_5);
              } else {
                if (this.groupSetting !== null && this.groupSetting !== undefined) {
                  tmpHtml = tmpHtml.replace(/\{%COLOR5%\}/gi, this.groupSetting.color_5);
                }
              }
              if (this.clientSetting.color_6) {
                tmpHtml = tmpHtml.replace(/\{%FONTCOLOR1%\}/gi, this.clientSetting.color_6);
              } else {
                if (this.groupSetting !== null && this.groupSetting !== undefined) {
                  tmpHtml = tmpHtml.replace(/\{%FONTCOLOR1%\}/gi, this.groupSetting.color_6);
                }
              }
              if (this.clientSetting.color_7) {
                tmpHtml = tmpHtml.replace(/\{%FONTCOLOR2%\}/gi, this.clientSetting.color_7);
              } else {
                if (this.groupSetting !== null && this.groupSetting !== undefined) {
                  tmpHtml = tmpHtml.replace(/\{%FONTCOLOR2%\}/gi, this.groupSetting.color_7);
                }
              }
              if (this.clientSetting.color_8) {
                tmpHtml = tmpHtml.replace(/\{%ICONCOLOR1%\}/gi, this.clientSetting.color_8);
              } else {
                if (this.groupSetting !== null && this.groupSetting !== undefined) {
                  tmpHtml = tmpHtml.replace(/\{%ICONCOLOR1%\}/gi, this.groupSetting.color_8);
                }
              }
              if (this.clientSetting.color_9) {
                tmpHtml = tmpHtml.replace(/\{%FONTCOLOR3%\}/gi, this.clientSetting.color_9);
                tmpHtml = tmpHtml.replace(/\{%FONTCOLOR9%\}/gi, this.clientSetting.color_9);
              } else {
                if (this.groupSetting !== null && this.groupSetting !== undefined) {
                  tmpHtml = tmpHtml.replace(/\{%FONTCOLOR3%\}/gi, this.groupSetting.color_9);
                  tmpHtml = tmpHtml.replace(/\{%FONTCOLOR9%\}/gi, this.clientSetting.color_9);
                }
              }
              if (this.clientSetting.color_10) {
                tmpHtml = tmpHtml.replace(/\{%FONTCOLOR4%\}/gi, this.clientSetting.color_10);
                tmpHtml = tmpHtml.replace(/\{%FONTCOLOR10%\}/gi, this.clientSetting.color_10);
              } else {
                if (this.groupSetting !== null && this.groupSetting !== undefined) {
                  tmpHtml = tmpHtml.replace(/\{%FONTCOLOR4%\}/gi, this.groupSetting.color_10);
                  tmpHtml = tmpHtml.replace(/\{%FONTCOLOR10%\}/gi, this.clientSetting.color_10);
                }
              }
              if (this.clientSetting.font_family) {
                tmpHtml = tmpHtml.replace(/\{%FONTFAMILY%\}/gi, this.clientSetting.font_family);
              } else {
                if (this.groupSetting !== null && this.groupSetting !== undefined) {
                  tmpHtml = tmpHtml.replace(/\{%FONTFAMILY%\}/gi, this.groupSetting.font_family);
                }
              }

              // low res logo
              if (this.clientSetting.primary_logo) {
                tmpHtml = tmpHtml.replace(/\{%PRIMARYLOGO%\}/gi, this.clientSetting.primary_logo);
              } else {
                if (this.groupSetting !== null && this.groupSetting !== undefined) {
                  tmpHtml = tmpHtml.replace(/\{%PRIMARYLOGO%\}/gi, this.groupSetting.primary_logo);
                }
              }
              if (this.clientSetting.secondary_logo) {
                tmpHtml = tmpHtml.replace(/\{%SECONDARYLOGO%\}/gi, this.clientSetting.secondary_logo);
              } else {
                if (this.groupSetting !== null && this.groupSetting !== undefined) {
                  tmpHtml = tmpHtml.replace(/\{%SECONDARYLOGO%\}/gi, this.groupSetting.secondary_logo);
                }
              }

              // high res logo
              if (this.clientSetting.hires_primary_logo) {
                tmpHtml = tmpHtml.replace(/\{%PRIMARYLOGOHIRES%\}/gi, this.clientSetting.hires_primary_logo);
              } else {
                if (this.groupSetting !== null && this.groupSetting !== undefined) {
                  tmpHtml = tmpHtml.replace(/\{%PRIMARYLOGOHIRES%\}/gi, this.groupSetting.hires_primary_logo);
                }
              }
              if (this.clientSetting.hires_secondary_logo) {
                tmpHtml = tmpHtml.replace(/\{%SECONDARYLOGOHIRES%\}/gi, this.clientSetting.hires_secondary_logo);
              } else {
                if (this.groupSetting !== null && this.groupSetting !== undefined) {
                  tmpHtml = tmpHtml.replace(/\{%SECONDARYLOGOHIRES%\}/gi, this.groupSetting.hires_secondary_logo);
                }
              }

              if (this.clientSetting.link_facebook) {
                tmpHtml = tmpHtml.replace(/\{%FACEBOOKURL%\}/gi, this.clientSetting.link_facebook);
              } else {
                if (this.groupSetting !== null && this.groupSetting !== undefined) {
                  tmpHtml = tmpHtml.replace(/\{%FACEBOOKURL%\}/gi, this.groupSetting.link_facebook);
                }
              }
              if (this.clientSetting.link_twitter) {
                tmpHtml = tmpHtml.replace(/\{%TWITTERURL%\}/gi, this.clientSetting.link_twitter);
              } else {
                if (this.groupSetting !== null && this.groupSetting !== undefined) {
                  tmpHtml = tmpHtml.replace(/\{%TWITTERURL%\}/gi, this.groupSetting.link_twitter);
                }
              }
              if (this.clientSetting.link_instagram) {
                tmpHtml = tmpHtml.replace(/\{%INSTAGRAMURL%\}/gi, this.clientSetting.link_instagram);
              } else {
                if (this.groupSetting !== null && this.groupSetting !== undefined) {
                  tmpHtml = tmpHtml.replace(/\{%INSTAGRAMURL%\}/gi, this.groupSetting.link_instagram);
                }
              }
              if (this.clientSetting.link_youtube) {
                tmpHtml = tmpHtml.replace(/\{%YOUTUBEURL%\}/gi, this.clientSetting.link_youtube);
              } else {
                if (this.groupSetting !== null && this.groupSetting !== undefined) {
                  tmpHtml = tmpHtml.replace(/\{%YOUTUBEURL%\}/gi, this.groupSetting.link_youtube);
                }
              }
              if (this.clientSetting.link_linkedin) {
                tmpHtml = tmpHtml.replace(/\{%LINKEDINURL%\}/gi, this.clientSetting.link_linkedin);
              } else {
                if (this.groupSetting !== null && this.groupSetting !== undefined) {
                  tmpHtml = tmpHtml.replace(/\{%LINKEDINURL%\}/gi, this.groupSetting.link_linkedin);
                }
              }
              if (this.clientSetting.link_gplus) {
                tmpHtml = tmpHtml.replace(/\{%GPLUSURL%\}/gi, this.clientSetting.link_gplus);
              } else {
                if (this.groupSetting !== null && this.groupSetting !== undefined) {
                  tmpHtml = tmpHtml.replace(/\{%GPLUSURL%\}/gi, this.groupSetting.link_gplus);
                }
              }
              if (this.clientSetting.link_pinterest) {
                tmpHtml = tmpHtml.replace(/\{%PINTERESTURL%\}/gi, this.clientSetting.link_pinterest);
              } else {
                if (this.groupSetting !== null && this.groupSetting !== undefined) {
                  tmpHtml = tmpHtml.replace(/\{%PINTERESTURL%\}/gi, this.groupSetting.link_pinterest);
                }
              }
              if (this.clientSetting.link_website) {
                tmpHtml = tmpHtml.replace(/\{%WEBSITEURL%\}/gi, this.clientSetting.link_website);
              } else {
                if (this.groupSetting !== null && this.groupSetting !== undefined) {
                  tmpHtml = tmpHtml.replace(/\{%WEBSITEURL%\}/gi, this.groupSetting.link_website);
                }
              }

              if (this.clientSetting.link_short_website) {
                tmpHtml = tmpHtml.replace(/\{%SHORTWEBSITEURL%\}/gi, this.clientSetting.link_short_website);
              } else {
                if ((this.groupSetting !== null && this.groupSetting !== undefined)) {
                  if (this.groupSetting.link_short_website) {
                    tmpHtml = tmpHtml.replace(/\{%SHORTWEBSITEURL%\}/ig, this.groupSetting.link_short_website);
                  } else {
                    if (this.clientSetting.link_website) {
                      tmpHtml = tmpHtml.replace(/\{%SHORTWEBSITEURL%\}/ig, this.clientSetting.link_website);
                    } else {
                      tmpHtml = tmpHtml.replace(/\{%SHORTWEBSITEURL%\}/ig, '');
                    }
                  }
                } else {
                  if (this.clientSetting.link_website) {
                    tmpHtml = tmpHtml.replace(/\{%SHORTWEBSITEURL%\}/ig, this.clientSetting.link_website);
                  } else {
                    tmpHtml = tmpHtml.replace(/\{%SHORTWEBSITEURL%\}/ig, '');
                  }
                }
              }

              if (this.clientSetting.link_buy) {
                tmpHtml = tmpHtml.replace(/\{%BUYURL%\}/gi, this.clientSetting.link_buy);
              } else {
                if (this.groupSetting !== null && this.groupSetting !== undefined) {
                  tmpHtml = tmpHtml.replace(/\{%BUYURL%\}/gi, this.groupSetting.link_buy);
                }
              }
              if (this.clientSetting.link_sell) {
                tmpHtml = tmpHtml.replace(/\{%SELLURL%\}/gi, this.clientSetting.link_sell);
              } else {
                if (this.groupSetting !== null && this.groupSetting !== undefined) {
                  tmpHtml = tmpHtml.replace(/\{%SELLURL%\}/gi, this.groupSetting.link_sell);
                }
              }
              if (this.clientSetting.link_rent) {
                tmpHtml = tmpHtml.replace(/\{%RENTURL%\}/gi, this.clientSetting.link_rent);
              } else {
                if (this.groupSetting !== null && this.groupSetting !== undefined) {
                  tmpHtml = tmpHtml.replace(/\{%RENTURL%\}/gi, this.groupSetting.link_rent);
                }
              }
              if (this.clientSetting.link_unsubscribe) {
                tmpHtml = tmpHtml.replace(/\{%UNSUBSCRIBEURL%\}/gi, this.clientSetting.link_unsubscribe);
              } else {
                if (this.groupSetting !== null && this.groupSetting !== undefined) {
                  tmpHtml = tmpHtml.replace(/\{%UNSUBSCRIBEURL%\}/gi, this.groupSetting.link_unsubscribe);
                }
              }
              if (this.clientSetting.link_home) {
                tmpHtml = tmpHtml.replace(/\{%HOMEURL%\}/gi, this.clientSetting.link_home);
              } else {
                if (this.groupSetting !== null && this.groupSetting !== undefined) {
                  tmpHtml = tmpHtml.replace(/\{%HOMEURL%\}/gi, this.groupSetting.link_home);
                }
              }
              if (this.clientSetting.link_about) {
                tmpHtml = tmpHtml.replace(/\{%ABOUTURL%\}/gi, this.clientSetting.link_about);
              } else {
                if (this.groupSetting !== null && this.groupSetting !== undefined) {
                  tmpHtml = tmpHtml.replace(/\{%ABOUTURL%\}/gi, this.groupSetting.link_about);
                }
              }
              if (this.clientSetting.link_careers) {
                tmpHtml = tmpHtml.replace(/\{%CAREERSURL%\}/gi, this.clientSetting.link_careers);
              } else {
                if (this.groupSetting !== null && this.groupSetting !== undefined) {
                  tmpHtml = tmpHtml.replace(/\{%CAREERSURL%\}/gi, this.groupSetting.link_careers);
                }
              }
              if (this.clientSetting.link_blog) {
                tmpHtml = tmpHtml.replace(/\{%BLOGURL%\}/gi, this.clientSetting.link_blog);
              } else {
                if (this.groupSetting !== null && this.groupSetting !== undefined) {
                  tmpHtml = tmpHtml.replace(/\{%BLOGURL%\}/gi, this.groupSetting.link_blog);
                }
              }
              if (this.clientSetting.link_contact) {
                tmpHtml = tmpHtml.replace(/\{%CONTACTURL%\}/gi, this.clientSetting.link_contact);
              } else {
                if (this.groupSetting !== null && this.groupSetting !== undefined) {
                  tmpHtml = tmpHtml.replace(/\{%CONTACTURL%\}/gi, this.groupSetting.link_contact);
                }
              }
              if (this.clientSetting.link_testimonials) {
                tmpHtml = tmpHtml.replace(/\{%TESTIMONIALSURL%\}/gi, this.clientSetting.link_testimonials);
              } else {
                if (this.groupSetting !== null && this.groupSetting !== undefined) {
                  tmpHtml = tmpHtml.replace(/\{%TESTIMONIALSURL%\}/gi, this.groupSetting.link_testimonials);
                }
              }
              if (this.clientSetting.primary_icon_bed) {
                tmpHtml = tmpHtml.replace(/\{%PRIMARYICONBED%\}/gi, this.clientSetting.primary_icon_bed);
              } else {
                if (this.groupSetting !== null && this.groupSetting !== undefined) {
                  tmpHtml = tmpHtml.replace(/\{%PRIMARYICONBED%\}/gi, this.groupSetting.primary_icon_bed);
                }
              }
              if (this.clientSetting.primary_icon_bath) {
                tmpHtml = tmpHtml.replace(/\{%PRIMARYICONBATH%\}/gi, this.clientSetting.primary_icon_bath);
              } else {
                if (this.groupSetting !== null && this.groupSetting !== undefined) {
                  tmpHtml = tmpHtml.replace(/\{%PRIMARYICONBATH%\}/gi, this.groupSetting.primary_icon_bath);
                }
              }
              if (this.clientSetting.primary_icon_car) {
                tmpHtml = tmpHtml.replace(/\{%PRIMARYICONCAR%\}/gi, this.clientSetting.primary_icon_car);
              } else {
                if (this.groupSetting !== null && this.groupSetting !== undefined) {
                  tmpHtml = tmpHtml.replace(/\{%PRIMARYICONCAR%\}/gi, this.groupSetting.primary_icon_car);
                }
              }
              if (this.clientSetting.secondary_icon_bed) {
                tmpHtml = tmpHtml.replace(/\{%SECONDARYICONBED%\}/gi, this.clientSetting.secondary_icon_bed);
              } else {
                if (this.groupSetting !== null && this.groupSetting !== undefined) {
                  tmpHtml = tmpHtml.replace(/\{%SECONDARYICONBED%\}/gi, this.groupSetting.secondary_icon_bed);
                }
              }
              if (this.clientSetting.secondary_icon_bath) {
                tmpHtml = tmpHtml.replace(/\{%SECONDARYICONBATH%\}/gi, this.clientSetting.secondary_icon_bath);
              } else {
                if (this.groupSetting !== null && this.groupSetting !== undefined) {
                  tmpHtml = tmpHtml.replace(/\{%SECONDARYICONBATH%\}/gi, this.groupSetting.secondary_icon_bath);
                }
              }
              if (this.clientSetting.secondary_icon_car) {
                tmpHtml = tmpHtml.replace(/\{%SECONDARYICONCAR%\}/gi, this.clientSetting.secondary_icon_car);
              } else {
                if (this.groupSetting !== null && this.groupSetting !== undefined) {
                  tmpHtml = tmpHtml.replace(/\{%SECONDARYICONCAR%\}/gi, this.groupSetting.secondary_icon_car);
                }
              }
              if (this.clientSetting.disclaimer_email) {
                tmpHtml = tmpHtml.replace(/\{%DISCLAIMEREMAIL%\}/gi, this.clientSetting.disclaimer_email);
              } else {
                if (this.groupSetting !== null && this.groupSetting !== undefined) {
                  tmpHtml = tmpHtml.replace(/\{%DISCLAIMEREMAIL%\}/gi, this.groupSetting.disclaimer_email);
                }
              }
              if (this.clientSetting.disclaimer_print) {
                tmpHtml = tmpHtml.replace(/\{%DISCLAIMERPRINT%\}/gi, this.clientSetting.disclaimer_print);
              } else {
                if (this.groupSetting !== null && this.groupSetting !== undefined) {
                  tmpHtml = tmpHtml.replace(/\{%DISCLAIMERPRINT%\}/gi, this.groupSetting.disclaimer_print);
                } else {
                  tmpHtml = tmpHtml.replace(/\{%DISCLAIMERPRINT%\}/gi, '');
                }
              }

              let legal_1 = '';
              let legal_2 = '';
              let legal_3 = '';

              if (this.clientSetting.legal_1 !== null) {
                legal_1 = this.clientSetting.legal_1;
              } else {
                if (this.groupSetting !== null && this.groupSetting !== undefined && this.groupSetting.legal_1 !== null) {
                  legal_1 = this.clientSetting.legal_1;
                }
              }
              tmpHtml = tmpHtml.replace(/\{%LEGAL1%\}/gi, legal_1);

              if (this.clientSetting.legal_2 !== null) {
                legal_2 = this.clientSetting.legal_2;
              } else {
                if (this.groupSetting !== null && this.groupSetting !== undefined && this.groupSetting.legal_2 !== null) {
                  legal_2 = this.clientSetting.legal_2;
                }
              }
              tmpHtml = tmpHtml.replace(/\{%LEGAL2%\}/gi, legal_2);

              if (this.clientSetting.legal_3 !== null) {
                legal_3 = this.clientSetting.legal_3;
              } else {
                if (this.groupSetting !== null && this.groupSetting !== undefined && this.groupSetting.legal_3 !== null) {
                  legal_3 = this.clientSetting.legal_3;
                }
              }
              tmpHtml = tmpHtml.replace(/\{%LEGAL3%\}/gi, legal_3);

              if (this.template.category_id !== 4 && this.template.category_id !== 8 && this.template.category_id !== 5) {
                selectedBlock.components('');
                selectedBlock.set('content', tmpHtml);

                const selectedBlockHTML = selectedBlock.toHTML();

                elementBlock = $(selectedBlockHTML);
              } else {
                elementBlock.html(tmpHtml);
              }
            }

            elementBlock = this.globalService.fillMergeClass(this.authUser, elementBlock, listing, {
              template: this.template,
              s2StatusValue: this.s2StatusValue
            });

            this.validatePropertyIconCount(elementBlock);
          }

          this.initScript();
          this.initScriptContentAlert();

          if (this.template.category_id === 5) {
            if (elementBlock instanceof $) {
              elementBlockGroup.append(elementBlock[0].outerHTML);
            } else {
              elementBlockGroup.append(elementBlock);
            }
          } else if (this.template.category_id === 4 || this.template.category_id === 8) {
            this.editor.setComponents(elementBlock.html());
          } else {
            selectedBlock.set('content', elementBlock.html());

            if (idxListing < listings.length) {
              elementBox = selectedBlock.view.$el;
              let nextPropIndex = elementBox.index() + 1;
              const componentParent = selectedBlock.parent();

              let nextElementBox;
              if (typeof componentParent !== 'undefined' && componentParent && componentParent.view.el.id !== 'wrapper') {
                let componentChildrens = componentParent.view.el.children;
                const originalComponentChildrenCount = componentChildrens.length;

                componentChildrens = Array.from(componentChildrens).filter((elm: HTMLElement) => {
                  return elm.classList.contains('md-prop-box');
                });

                if (originalComponentChildrenCount !== componentChildrens.length && nextPropIndex > 1) {
                  nextPropIndex = nextPropIndex - 1;
                }

                if (componentChildrens.length) {
                  const nextSibling = componentChildrens[nextPropIndex];

                  if (typeof nextSibling !== 'undefined' && nextSibling) {
                    nextElementBox = nextSibling;
                  }
                }
              }

              let defaultPropIndex = 0;
              if (nextElementBox) {
                elementBox = $(`<div>${nextElementBox}</div>`);
                defaultPropIndex = nextPropIndex;
              } else {
                this.editor.runCommand('tlb-clone');

                selectedElement = this.editor.getSelected();
                elementBox = selectedElement.view.$el;
              }

              if (!this.property_guide_skip) {
                if (elementBox.hasClass('md-prop-search')) {
                  elementBox = elementBox.parents('.md-box');
                  elementBox.trigger('click');

                  selectedElement = this.editor.getSelected();
                }

                elementBlock = elementBox.find('.md-prop-box');
                selectedBlock = selectedElement.find('.md-prop-box')[defaultPropIndex];

                if (elementBox.hasClass('md-prop-box')) {
                  elementBlock = elementBox;
                  selectedBlock = selectedElement;
                }
              }
            }

            if (this.template.category_id === 1 && listings.length > 1) {
              // tslint:disable-next-line: max-line-length
              let defaultListingHtml = typeof this.elementListingHtml['residential'] !== 'undefined' ? this.elementListingHtml['residential'] : null;
              if (idxListing === listings.length && defaultListingHtml) {
                defaultListingHtml = this.globalService.fillMergeText(this.authUser, defaultListingHtml, 'substitute', 'element', {
                  user: this.templateAgent,
                  isMergedSaved: this.isMergedSaved,
                  template: this.template,
                  fillType: 'template'
                });
                elementBox = selectedBlock.view.$el;

                let nextPropIndex = elementBox.index() + 1;
                const componentParent = selectedBlock.parent();

                if (typeof componentParent !== 'undefined' && componentParent && componentParent.view.el.id !== 'wrapper') {
                  let componentChildrens = componentParent.components();
                  const originalComponentChildrenCount = componentChildrens.length;

                  componentChildrens = Array.from(componentChildrens.models).filter((children: any) => {
                    return children.view.$el.hasClass('md-prop-box');
                  });

                  if (originalComponentChildrenCount !== componentChildrens.length && nextPropIndex > 1) {
                    nextPropIndex = nextPropIndex - 1;
                  }

                  componentChildrens.forEach((emptyComponent, index) => {
                    if (nextPropIndex <= index) {
                      emptyComponent.set('content', '');
                      emptyComponent.components($(defaultListingHtml).html());
                    }
                  });
                }
              }
            }
          }

          if (typeof listing.query_params !== 'undefined' && listing.query_params) {
            this.addListingToTemplate(listing.id, listing.query_params);
          }
        }
        this.insertPropertyElement = false;
        this.isReplace = false;
        let html = this.editor.getHtml();

        if (this.template.category_id === 5 || this.template.category_id === 6 || this.template.category_id === 9 || this.template.category_id === 1) {
          const body = this.editor.Canvas.getBody();
          html = $(body).find('#wrapper').html();
        }

        html = $this.stripSmartQuotes(html);

        let html_data_css = this.editor.getCss();

        // Exclude Images
        if (this.template.category_id === 1) {
          html_data_css = String(html_data_css).replace('md-library-search', 'md-library-x-search').replace('md-prop-search', 'md-prop-x-search');
          html_data_css = String(html_data_css).replace('.md-prop-field-address', 'p.md-prop-field-address');
        }

        const html_raw = juice(html + '<style>' + html_data_css + '</style>', {
          preserveMediaQueries: false,
          preserveImportant: true,
          applyHeightAttributes: false,
          applyWidthAttributes: false,
        });

        this.editor.setComponents(html_raw);

        setTimeout(() => {
          this.mdPropSearchModalClose.nativeElement.click();
          this.subLoading = false;
          this.progressService.hideProgress();
        }, 200);

        if (!this.isVaultMail) {
          this.selectedListings = [];
          this.selectedListingIDs = [];
        }

        if (this.ckeditor_active) {
          for (const name in CKEDITOR.instances) {
            if (name) {
              CKEDITOR.instances[name].destroy(true);
            }
          }
        }

        if (this.frontService.authService.isUk() && this.ukStyleId.includes(this.style.ID)) {
          this.runUkScript();
        }

        this.isVaultFirstLoad = false;
      } catch (err) {
        console.log(err);
        this.subLoading = false;

        if (this.isVaultFirstLoad) {
          this.isVaultFirstLoad = false;
          return false;
        }

        if (this.isVaultMail) {
          swal('Drag at least 1 Listing Element', '', 'error');
        } else {
          swal(
            'Opps, something went wrong!',
            // tslint:disable-next-line:max-line-length
            'Kindly close this window then save your template and refresh the page to try again. If error persists please submit a support request',
            'error'
          );
        }
      }
    }, 200);
  }

  /* Insert property button global for bayleys. This will insert property details accross template as long as no md-prop-search class in the element */
  onSelectListingsInsertButton(bypassSelect = false) {
    this.subLoading = true;
    setTimeout(() => {
      try {
        const $this = this;
        if (!this.isVaultMail) {
          const isManualOrder = $('#manual-insert-order').is(':checked');

          if (isManualOrder && this.template.category_id !== 5) {
            this.selectedListings = this.selectedListings.reverse();
          } else {
            this.selectedListings.sort((a, b) => {
              return a.order > b.order ? 1 : 0;
            });
          }
        }

        let listings = this.selectedListings.slice();
        let singleElement = false;

        // select whole iframe contents
        const iframe = $(this.builder.nativeElement).find('iframe').contents();
        this.editor.select(iframe.find('#wrapper').get());  // select the html wrapper
        let selectedElement = this.editor.getSelected();
 
        let elementBox = selectedElement.view.$el;
        const elementBoxIndex = elementBox.parents('.md-prop-box').index();
        const elementGroupIndex = elementBox.parents('.md-box').index();
        let elementBlock = elementBox;
        let selectedBlock = selectedElement;

        let elementBlockGroup = null;

        let propGuideCount = 0;

        if (elementBlock.find('.md-prop-building-opportunities').length > 0) {
            let propertyConfigCount = 0;
            if (typeof listings[0].propertyConfig !== 'undefined') {
              propertyConfigCount = parseInt(listings[0].propertyConfig.totalItems);
            }

            if (elementBlock.find('.md-prop-building-opportunities').length > 0) {
              this.resetPropertyConfigTable(elementBlock.find('.md-prop-building-opportunities'));
            }
            if (elementBlock.find('.md-prop-building-opportunities-2').length > 0) {
              this.resetPropertyConfigTable(elementBlock.find('.md-prop-building-opportunities-2'));
              elementBlock.find('.md-prop-building-opportunities-2').prop('hidden', true);
            }

            elementBlockGroup = elementBlock.find('.md-prop-building-opportunities');
            let elementTbody = elementBlockGroup.find('tbody');
            let elementRow = elementTbody.find('tr').last();
            if (elementBlockGroup.length > 0 && propertyConfigCount > 0) {
              const strataBuildings = this.selectedListings[0].propertyConfig['items'];
              const tableElementLimit = parseInt(elementBlockGroup.data('limit'));
              let index = 0;
              let index_2 = 0;

              for (const strataBuilding of strataBuildings) {
                if (index >= tableElementLimit) {
                  elementBlock.find('.md-prop-building-opportunities-2').prop('hidden', false);
                  elementBlockGroup = elementBlock.find('.md-prop-building-opportunities-2'); // populate the 2nd table
                  elementTbody = elementBlockGroup.find('tbody');
                  elementRow = elementTbody.find('tr').last();
                  if (index_2 > 0 && elementBlock.find('.md-prop-building-opportunities-2').length > 0) {
                    elementTbody.append(elementRow.html());
                    elementRow = elementTbody.find('tr').last();
                  }
                  index_2++;
                } else {
                  if (index > 0) {
                    elementTbody.append(elementRow.html());
                    elementRow = elementTbody.find('tr').last();
                  }
                }

                const strataBuildingDetails = this.getPropertyConfigInfo(strataBuilding);
                elementRow.find('.md-prop-building-opportunities-level').html(strataBuildingDetails.level_column);
                elementRow.find('.md-prop-building-opportunities-area').html(strataBuildingDetails.area_column);
                elementRow.find('.md-prop-building-opportunities-rent').html(strataBuildingDetails.rent_column);
                elementRow.find('.md-prop-building-opportunities-availability').html(strataBuildingDetails.availability_column);
                elementRow.find('.md-prop-building-opportunities-comments').html(strataBuildingDetails.comments_column);
                index++;
              }
            }
        }

        let idxListing = 0;
        for (const listing of listings) {
          idxListing++;
          propGuideCount++;

          if (typeof listing.innerHTML === 'string') {
            elementBlock = listing;
          } else {
            let listingHtml = '';

            /* brochure[category_id=4], property-guide[template.category_id=5], social-media[template.category_id=6], dl-card[template.category_id=8], sign-board[template.category_id=9] */
            if(this.insertPropertyButton === true) {
                let className = elementBlock.find('.md-box').length > 0 ? '.md-box' : '.md-prop-box';
                // loop throught each element block for merge. skip the elements with individual insert
                elementBlock.find(className).each(component => {
                  Array.from($(component).children()).forEach((currentChildren) => {
                    // WB-3391: if from email template skip
                    if (($this.template.category_id === this.templateCategoryId.EMARKETING ||  $this.template.category_id === this.templateCategoryId.BUYER_MATCH || $this.template.category_id === this.templateCategoryId.HTML_SIGNATURE
                        || $this.template.category_id === this.templateCategoryId.MATCH_PROPERTIES || $this.template.category_id === this.templateCategoryId.PROPERTY_ALERTS)) {
                        return;
                    }
                    currentChildren = $this.globalService.fillMergeClass($this.authUser, $(currentChildren), listing, {
                      template: $this.template,
                      s2StatusValue: $this.s2StatusValue
                    }); 
                  });
                });
            }
            if (this.insertPropertyButton === false) {
              elementBlock = this.globalService.fillMergeClass(this.authUser, elementBlock, listing, {
                template: this.template,
                s2StatusValue: this.s2StatusValue
              });
            }
 
          }

          this.initScript();

          if (typeof listing.query_params !== 'undefined' && listing.query_params) {
            this.addListingToTemplate(listing.id, listing.query_params);
          }
        }

        this.insertPropertyButton = false;
        this.insertPropertyElement = false;
        this.propertySearchService.propertyInsertSource = null;
        this.isReplace = false;
        let html = this.editor.getHtml();
        // limit to this category id's only
        if (this.template.category_id === this.templateCategoryId.PROPERTY_GUIDE || this.template.category_id === this.templateCategoryId.SOCIAL_MEDIA || 
            this.template.category_id === this.templateCategoryId.SIGNBOARD || this.template.category_id === this.templateCategoryId.EMARKETING || 
            this.template.category_id === this.templateCategoryId.BROCHURE || this.template.category_id === this.templateCategoryId.DL_CARD) {
          const body = this.editor.Canvas.getBody();
          html = $(body).find('#wrapper').html();
        }

        html = $this.stripSmartQuotes(html);

        let html_data_css = this.editor.getCss();

        // Exclude Images
        if (this.template.category_id === this.templateCategoryId.EMARKETING) {
          html_data_css = String(html_data_css).replace('md-library-search', 'md-library-x-search').replace('md-prop-search', 'md-prop-x-search');
          html_data_css = String(html_data_css).replace('.md-prop-field-address', 'p.md-prop-field-address');
        }

        const html_raw = juice(html + '<style>' + html_data_css + '</style>', {
          preserveMediaQueries: false,
          preserveImportant: true,
          applyHeightAttributes: false,
          applyWidthAttributes: false,
        });

        this.editor.setComponents(html_raw);

        setTimeout(() => {
          this.mdPropSearchModalClose.nativeElement.click();
          this.subLoading = false;
          this.progressService.hideProgress();
        }, 200);

        if (!this.isVaultMail) {
          this.selectedListings = [];
          this.selectedListingIDs = [];
        }

        if (this.ckeditor_active) {
          for (const name in CKEDITOR.instances) {
            if (name) {
              CKEDITOR.instances[name].destroy(true);
            }
          }
        }
        if (this.frontService.authService.isUk() && this.ukStyleId.includes(this.style.ID)) {
          this.runUkScript();
        }

        this.isVaultFirstLoad = false;
      } catch (err) {
        console.log(err);
        this.subLoading = false;

        if (this.isVaultFirstLoad) {
          this.isVaultFirstLoad = false;
          return false;
        }

        if (this.isVaultMail) {
          swal('Drag at least 1 Listing Element', '', 'error');
        } else {
          swal(
            'Opps, something went wrong!',
            // tslint:disable-next-line:max-line-length
            'Kindly close this window then save your template and refresh the page to try again. If error persists please submit a support request',
            'error'
          );
        }
      }
    }, 200);

  }

  getLink(item: any) {
    if (this.checkImageFileType(item.mimetype)) {
      const itemPath = item.path.replace('resources/', '');
      return this.frontService.appConfig.CACHED_S3_BUCKET_URL + itemPath;
    }

    return this.frontService.appConfig.NEW_S3_URL + item.path;
  }

  replace(text: string, search: string, replace: string) {
    return text.replace(search, replace);
  }

  onFeedback() {
    swal({
      title: 'We would love to hear from you about the new MyDesign',
      imageUrl: 'http://mydesign3.wbclients.com/mydesign/assets/images/message-square.svg',
      imageWidth: 300,
      imageHeight: 100,
      showConfirmButton: false,
      html:
        `
        <p>Please choose to provide either Feedback or log a Support Ticket</p>` +
        `<a
          class="btn btn-success btn-bh-feedback">
          Send Feedback
        </a> &nbsp;
        <a
          href="` +
        this.supportLink +
        `"
          target="_blank"
          class="btn btn-info btn-support-log">
          Log Support Ticket
        </a>`,
    });

    $('.btn-bh-feedback').click(function () {
      swal.close();
      _bugHerd.win.bugherd.applicationView.anonymousbar.toggleOptions();
    });
  }

  ngAfterViewInit() {
    this.mScrollbarService.initScrollbar('.agentListScroll', {
      axis: 'y',
      theme: 'dark-3',
      scrollInertia: 600,
      advanced: { autoExpandHorizontalScroll: true }
    });

    const $this = this;

    if (this.clientSetting.group.master_client_id === this.clientSetting.ID && this.clientSetting.group.builder_ckeditor_font_family === 3) {
      $('head').append(
        $('<style>').text(`
        .cke_combo.cke_combo__font {
          display: none;
        }
        `)
      );
    }
    if (this.clientSetting.group.master_client_id !== this.clientSetting.ID && this.clientSetting.group.builder_ckeditor_font_family === 2) {
      $('head').append(
        $('<style>').text(`
        .cke_combo.cke_combo__font {
          display: none;
        }
        `)
      );
    }
    if (this.clientSetting.group.builder_ckeditor_font_family === 0) {
      $('head').append(
        $('<style>').text(`
        .cke_combo.cke_combo__font {
          display: none;
        }
        `)
      );
    }
  }

  closeListingSearchModal() {
    this.insertPropertyButton = false;
    this.insertPropertyElement = false;
    this.propertySearchService.propertyInsertSource = null;
    this.loading = false;
  }

  /* End Library Storage Functions */
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    $('.modal-backdrop').hide();
  }

  enableZoom(selectedElement: any) {
    if (this.template.category_id !== 7 && this.template.category_id !== 1 && this.template.category_id !== 2 && this.template.category_id !== 3) {
      const elemMd = $(selectedElement);

      if (elemMd.length) {
        const $this = this;

        elemMd
          .draggable({
            start: function () {
              const elImg = $this.editor.getSelected();
              $(this).css({ cursor: 'grabbing' });

              if (elImg.view.$el.is('.md-library-search, .md-prop-search') === false) {
                return false;
              }
            },
            stop: function () {
              const elImg = $this.editor.getSelected();
              const elImgStyle = elImg.getStyle();

              const dragTop = $(this).css('top');
              const dragLeft = $(this).css('left');

              elImgStyle.position = 'absolute';
              elImgStyle.top = dragTop;
              elImgStyle.left = dragLeft;

              elImg.set('style', elImgStyle);
              $(this).css({ cursor: 'pointer' });

              setTimeout(() => {
                $(elImg.view.$el).trigger('click');
              }, 10);
            },
          })
          .parent()
          .css({ overflow: 'hidden' })
          .find('img')
          .unbind('wheel mousewheel DOMMouseScroll')
          .bind('wheel mousewheel DOMMouseScroll', function (e) {
            const elImg = $this.editor.getSelected();
            const elImgStyle = elImg.getStyle();

            if (e.type === 'DOMMouseScroll' || e.type === 'wheel') {
              e.preventDefault();

              return;
            }

            let delta;

            if (e.originalEvent.wheelDelta !== undefined) {
              delta = e.originalEvent.wheelDelta;
            } else {
              delta = e.originalEvent.deltaY * -1;
            }

            const naturalWidth = $(this).get(0).naturalWidth;
            const naturalHeight = $(this).get(0).naturalHeight;

            let sizeWidth = $(this).width();
            let sizeHeight = $(this).height();

            if (delta > 0) {
              sizeHeight += 30;
              sizeWidth += 30;
            } else {
              sizeHeight -= 30;
              sizeWidth -= 30;
            }

            const new_size = $this.calculateAspectRatioFit(naturalWidth, naturalHeight, sizeWidth, sizeHeight);

            sizeHeight = Math.round(new_size.height);
            sizeWidth = Math.round(new_size.width);

            sizeWidth += 'px';
            sizeHeight += 'px';

            $(this).width(sizeWidth);
            $(this).height(sizeHeight);

            elImgStyle.width = sizeWidth;
            elImgStyle.height = sizeHeight;

            elImg.set('style', elImgStyle);
          });
      }
    }
  }

  disableZoom() {
    const elemMd = $('.gjs-cv-canvas').find('iframe').contents().find('.md-prop-search, .md-library-search');

    elemMd.unbind('wheel mousewheel DOMMouseScroll');

    if (elemMd.length) {
      elemMd.each(function () {
        const elm = $(this);

        if (elm.is('.ui-draggable')) {
          try {
            elm.draggable('destroy');
            elm.css({ cursor: 'grab' });
          } catch (e) {
            console.log(e);
          }
        }
      });
    }

    $('.icon-move').removeAttr('style');
  }

  stripSmartQuotes(string) {
    return String(string)
    .replace(/[“]/g, '"')
    .replace(/[”]/g, '"')
    .replace(/[‘]/g, '&#39;')
    .replace(/[’]/g, '&#39;')
    .replace(/â€™/g, '&#39;');
  }

  blobToFile = (theBlob: Blob, fileName: string): File => {
    const b: any = theBlob;
    // A Blob() is almost a File() - it's just missing the two properties below which we will add
    b.lastModifiedDate = new Date();
    b.name = fileName;

    // Cast to a File() type
    return <File>theBlob;
  }

  onPdfDownloadCheck() {
    if (typeof $('#confirm-1:checked').val() !== 'undefined') {
      $('.btn-rural-pdf').removeAttr('disabled');
    } else {
      $('.btn-rural-pdf').attr('disabled', true);
    }
  }

  onModalLinkTypeChange() {
    const link_type = $('#mdl-link-type').val();

    if (link_type === 'url') {
      this.updateable_link_type = 'url';

      $('.mdl-link-holder').removeClass('hidden');
    } else {
      if (link_type === 'tel') {
        this.updateable_link_type = 'tel';
      } else {
        this.updateable_link_type = 'mail';
      }

      $('.mdl-link-holder').addClass('hidden');
    }
  }

  onClickSend(link, val) {
    if (link !== 'secure') {
      window.location.href = 'http://' + link + '.' + val;
    } else {
      swal({
        title: 'I am signed in via',
        type: 'info',
        html:
          // tslint:disable-next-line:max-line-length
          '<a href="http://login.' + val + '" target="_blank" class="btn btn-primary btn-send-trigger">MyDesktop</a> &nbsp; <a href="http://secure.' + val + '" target="_blank" class="btn btn-info btn-send-trigger">Domain Group</a>',
        showCancelButton: false,
        showConfirmButton: false,
        focusConfirm: false,
      });

      $('.btn-send-trigger').click(function () {
        swal.close();
      });
    }
  }

  onAnchorPaste() {
    setTimeout(() => {
      const link_type = $('#mdl-link-type').val();

      if (link_type === 'url') {
        const linkValue = $('#anchorLink').val();

        if (String(linkValue).includes('https')) {
          $('#mdl-url-types').val('https://');
        } else {
          $('#mdl-url-types').val('http://');
        }
      }
    }, 100);
  }

  openSupportModal() {
    $('#supportModal').modal('show');
  }

  onSupportCapture() {
    this.supportService.onCapture.next(true);
    this.showScreenshot = false;

    this.loading = true;
  }

  onSupportCancel() {
    this.showScreenshot = false;
    $('#supportModal').modal('show');
  }

  closeLibraryModal() {
    // this.showImageLibrary = false;
    this.mdLibrarySearchModalClose.nativeElement.click();
    this.ref.detectChanges();
  }

  /*
   * Thanks to https://stackoverflow.com/a/14731922/382536
   * */
  calculateAspectRatioFit(srcWidth, srcHeight, maxWidth, maxHeight) {
    const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
    return { width: srcWidth * ratio, height: srcHeight * ratio };
  }

  onView(type: string) {
    if (type === 'code') {
      this.viewCodeButtonClicked = true;
      this.saveHtml(this.editor);
    }

    if (type === 'browser') {
      this.viewBrowserButtonClicked = true;
      this.saveHtml(this.editor);
    }
  }

  onShareCampaignPreloaded() {
    $('#selectCampaign').modal('show');
  }

  downloadPdf(isHighresPdf = false) {
    const $this = this;
    this.isHighResPdf = isHighresPdf;
    swal({
      title: 'Template will be saved before downloading',
      text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'DOWNLOAD',
    })
      .then(function () {
        $this.pdfButtonClicked = true;
        $this.sharePriceFinderLinkClicked = false;
        $this.saveHtml($this.editor, 'pdf');
      })
      .catch(swal.noop);
  }

  imageSaveAs(type) {
    const $this = this;
    this.customImageType = {
      isCustom: true,
      type: type,
    };

    swal({
      title: 'Template will be saved before downloading',
      text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'DOWNLOAD',
    })
      .then(function () {
        $this.pdfButtonClicked = false;
        $this.imgButtonClicked = true;
        $this.qdtButtonClicked = false;
        $this.saveHtml($this.editor, 'img');
      })
      .catch(swal.noop);
  }

  onCampaignSave() {
    if (this.s2CampaignValue === '' && this.isPreloadedCampaignMethod !== 'remove' && this.isPreloaded) {
      swal('Select Category', '', 'error');

      return false;
    }

    if (this.isPreloaded) {
      const data = {
        template_id: this.template.ID,
        group_id: this.authUser.client.group.ID,
        categories: this.s2CampaignValue,
        user_id: this.authUser.user.ID,
      };

      if (this.isPreloadedCampaignMethod === 'remove') {
        data['delete'] = true;

        this.isPreloadedCampaign = false;
      } else {
        this.isPreloadedCampaign = true;
      }

      this.templateService.httpAddPreloaded(data);
    } else {
      const data = {
        client_id: this.authUser.client.ID,
        group_id: this.authUser.client.group_id,
        user_id: this.authUser.user.ID,
        categories: this.s2CampaignValue,
        template_id: this.id,
      };

      this.campaignService.httpPostCampaignTemplate(data);
    }
    this.loading = true;
  }

  selectCampaign(type = 'savecampaign', method = 'add') {
    if (type === 'preloaded') {
      this.preloadedSaveType = 'campaign';
      this.isPreloaded = true;

      if (method === 'remove') {
        this.isPreloadedCampaignMethod = 'remove';

        this.onCampaignSave();
        return false;
      } else {
        this.isPreloadedCampaignMethod = 'add';
      }
    } else {
      this.isPreloaded = false;
    }
    $('#selectCampaign').modal('show');
  }

  onSharePreloaded(method = 'add') {
    this.preloadedSaveType = 'template';
    this.isPreloaded = true;

    const data = {
      template_id: this.template.ID,
      group_id: this.authUser.client.group.ID,
      user_id: this.authUser.user.ID,
    };

    if (method === 'remove') {
      data['delete'] = true;

      this.isPreloadedTemplate = false;
    } else {
      this.isPreloadedTemplate = true;
    }

    this.templateService.httpAddPreloaded(data);
    this.loading = true;
  }

  saveQuickDesign() {
    if (this.frontService.authService.auth.is_master === true || this.frontService.authService.auth.is_admin !== true) {
      $('.btn-save-quick-office').addClass('hidden');
      this.editor.runCommand('save-quick');
    } else {
      this.editor.runCommand('save-quick-office');
    }
  }

  saveMasterTemplate(type) {
    this.template.is_master_template = 0;
    this.isAddUpdateMaster = 'remove';

    if (type === 'add') {
      this.isAddUpdateMaster = 'add';
      this.template.is_master_template = 1;
    }
    this.saveHtml(this.editor, '', true);
  }

  sendTestEmail() {
    $('#sendTestEmail').modal('show');
  }

  onSubmitTestEmail() {
    this.loading = true;
    this.saveHtml(this.editor, '', true);
    this.testMailSent = true;
  }

  saveToCustomCategory() {
    $('#saveToCategory').modal('show');
  }

  onSaveToCustomCategory() {

    var tags = "";

    if (this.tagifyTemplateTags != null && this.tagifyTemplateTags.value.length) {
      this.tagifyTemplateTags.value.forEach((tag: any) => {
        const tagValue = tag.value.replaceAll(';', '').replaceAll(',', '');
        tags += `${tagValue};`
      })
    } 

    const sharedUsers = this.templateShareUsers.filter((user: any) => user.isSelected).map((user: any) => user.ID);

    const customCategoryFormValues = this.customCategoryForm.value;

    if (this.customCategoryForm.valid) {
      customCategoryFormValues['template_id'] = this.template.ID;
      customCategoryFormValues['tags'] = tags;
      customCategoryFormValues['shared_users'] = sharedUsers;
      customCategoryFormValues['quick_design_group_id'] = customCategoryFormValues['quick_design_group_id'].length > 0 ? customCategoryFormValues['quick_design_group_id'] : '';
      customCategoryFormValues['shared_with'] = this.templateShareWith;

      if (this.templateVisibleInVault === 'yes') {
        customCategoryFormValues['visible_in_vault'] = true;
      }

      this.customCategorySave = true;

      this.quickDesignService.httpAdd(customCategoryFormValues, this.customCategoryIcon.nativeElement.files);
      this.loading = true;
    }
  }

  removeFromCustomCategory() {
    if (typeof this.template.quickdesign !== 'undefined') {
      if (Array.isArray(this.template.quickdesign)) {
        // Loop through the quickdesign where the copy might reside in multiple folder.
        this.template.quickdesign.forEach((item, index) => {
          this.quickDesignService.httpDelete(item.ID);
        });
      } else {
         this.quickDesignService.httpDelete(this.template.quickdesign.ID);
      }
      this.loading = true;
    }
  }

  showReferenceOption(category: string) {
    if (typeof this.template !== 'undefined') {
      if (category === 'emails' && this.emailCategories.includes(this.template.category.ID)) {
        if (!this.quickDesignOptionLoaded) {
          this.quickDesignOptionLoaded = true;
          this.customCategoryForm.patchValue({
            reference: 'emails',
          });

          this.quickDesignService.httpGetGroupsByCategory('emails', 'emails');
        }
        return true;
      }

      if (category === 'printables' && this.printableCategories.includes(this.template.category.ID)) {
        if (!this.quickDesignOptionLoaded) {
          this.quickDesignOptionLoaded = true;
          this.customCategoryForm.patchValue({
            reference: 'printables',
          });

          this.quickDesignService.httpGetGroupsByCategory('printables', 'printables');
        }
        return true;
      }

      if (category === 'socialmedia' && this.socialMediaCategories.includes(this.template.category.ID)) {
        if (!this.quickDesignOptionLoaded) {
          this.quickDesignOptionLoaded = true;
          this.customCategoryForm.patchValue({
            reference: 'socialmedia',
          });

          this.quickDesignService.httpGetGroupsByCategory('socialmedia', 'socialmedia');
        }
        return true;
      }

      if (category === 'signboards' && this.signboardCategories.includes(this.template.category.ID)) {
        if (!this.quickDesignOptionLoaded) {
          this.quickDesignOptionLoaded = true;
          this.customCategoryForm.patchValue({
            reference: 'signboards',
          });

          this.quickDesignService.httpGetGroupsByCategory('signboards', 'signboards');
        }
        return true;
      }

      if (category === 'pricefinder' && this.pricefinderCategories.includes(this.template.category.ID)) {
        if (!this.quickDesignOptionLoaded) {
          this.quickDesignOptionLoaded = true;
          this.customCategoryForm.patchValue({
            reference: 'pricefinder',
          });

          this.quickDesignService.httpGetGroupsByCategory('pricefinder', 'pricefinder');
        }
        return true;
      }
    }
    return false;
  }

  onSelectedCustomCategories(value: string) {
    this.customCategoryForm.patchValue({
      quick_design_group_id: '',
    });

    this.loading = true;
    const defaultCategories =['emails', 'printables', 'socialmedia', 'signboards'];

    if(defaultCategories.includes(value)){
      this.quickDesignService.httpGetGroupsByCategory(value, value);
      return true;
    } else {
      this.quickDesignService.httpGetGroupsByCategory(value, 'custom');
      return true;
    }
  }

  checkCheckers(editor: any) {
    const $this = this;
    try {
      let currentHtml = editor.getHtml();
      currentHtml = $(currentHtml);

      if (currentHtml.has('.md-prop-field-owners-name').length || currentHtml.has('.md-prop-field-owners-company').length
        || currentHtml.has('.md-prop-field-owners-address').length || currentHtml.has('.md-prop-field-owners-mobile').length
        || currentHtml.has('.md-prop-field-owners-email').length || currentHtml.has('.md-prop-field-owners-name-1').length
        || currentHtml.has('.md-prop-field-owners-company-1').length || currentHtml.has('.md-prop-field-owners-address-1').length
        || currentHtml.has('.md-prop-field-owners-mobile-1').length || currentHtml.has('.md-prop-field-owners-email-1').length
        || currentHtml.has('.md-prop-field-owners-name-2').length || currentHtml.has('.md-prop-field-owners-company-2').length
        || currentHtml.has('.md-prop-field-owners-address-2').length || currentHtml.has('.md-prop-field-owners-mobile-2').length
        || currentHtml.has('.md-prop-field-owners-email-2').length) {
        $this.checkOwners = 1;
      } else {
        $this.checkOwners = 0;
      }

      if (currentHtml.has('.md-prop-field-tenant-firstname-1').length || currentHtml.has('.md-prop-field-tenant-firstname-2').length
        || currentHtml.has('.md-prop-field-tenant-lastname-1').length || currentHtml.has('.md-prop-field-tenant-lastname-2').length
        || currentHtml.has('.md-prop-field-tenant-mobile-1').length || currentHtml.has('.md-prop-field-tenant-mobile-2').length) {
        $this.checkTenants = 1;
      } else {
        $this.checkTenants = 0;
      }

      if (currentHtml.has('.md-prop-field-price').length || currentHtml.has('.md-prop-field-saleprice').length) {
        $this.checkSalePrice = 1;
      } else {
        $this.checkSalePrice = 0;
      }

      if (currentHtml.has('.md-prop-field-opentime').length ||
      currentHtml.has('.md-prop-field-multiple-opentimes').length ||
      currentHtml.has('.md-prop-field-opentimes-title').length ||
      currentHtml.has('.md-prop-field-opentimes-title-br').length ||
      currentHtml.has('.md-prop-field-multiple-opentimes-title').length ||
      currentHtml.has('.md-prop-field-opentimes-title-alt').length ||
      currentHtml.has('.md-prop-field-multiple-opentimes-title-alt').length ||
      currentHtml.has('.md-prop-field-multiple-opentimes-title-br').length) {
        $this.checkInspections = 1;
      } else {
        $this.checkInspections = 0;
      }

      if (currentHtml.has('.md-prop-field-rooms').length || currentHtml.find("[class*='md-prop-field-rooms-']").length) {
        $this.checkRooms = 1;
      } else {
        $this.checkRooms = 0;
      }

      if (currentHtml.has('.md-prop-field-map-image').length) {
        $this.checkMapImage = 1;
      } else {
        $this.checkMapImage = 0;
      }

      // tslint:disable-next-line: quotemark
      if (currentHtml.find("[class*='md-prop-field-custom-']").length) {
        $this.checkCustomFields = 1;
      } else {
        $this.checkCustomFields = 0;
      }

      // tslint:disable-next-line: quotemark
      if (currentHtml.find("[class*='md-prop-field-feature-']").length) {
        $this.checkFeatures = 1;
      } else {
        $this.checkFeatures = 0;
      }

      // tslint:disable-next-line: quotemark
      if (currentHtml.find("[class*='md-prop-field-offer-']").length) {
        $this.checkPropertyOffers = 1;
      } else {
        $this.checkPropertyOffers = 0;
      }

      // tslint:disable-next-line: quotemark
      if (currentHtml.find("[class*='md-prop-field-marketing-']").length) {
        $this.checkPropertyAdvertising = 1;
      } else {
        $this.checkPropertyAdvertising = 0;
      }

      // tslint:disable-next-line: quotemark
      if (currentHtml.find("[class*='md-prop-field-purchaser-']").length || currentHtml.find("[class*='md-prop-field-vendor-']").length) {
        $this.checkPropertySolicitor = 1;
      } else {
        $this.checkPropertySolicitor = 0;
      }

      // tslint:disable-next-line: quotemark
      if (currentHtml.find("[class*='md-prop-field-offer-conditions']").length) {
        $this.checkPropertyOfferConditions = 1;
      } else {
        $this.checkPropertyOfferConditions = 0;
      }
    } catch (e) {
      console.log(e);
      $this.checkOwners = 0;
      $this.checkSalePrice = 0;
      $this.checkInspections = 0;
      $this.checkCustomFields = 0;
      $this.checkRooms = 0;
      $this.checkMapImage = 0;
      $this.checkFeatures = 0;
      $this.checkPropertyOffers = 0;
      $this.checkPropertyAdvertising = 0;
      $this.checkPropertySolicitor = 0;
      $this.checkPropertyOfferConditions = 0;
    }

    this.propertySearchService.setCheckers({
      checkOwners: this.checkOwners,
      checkSalePrice: this.checkSalePrice,
      checkInspections: this.checkInspections,
      checkCustomFields: this.checkCustomFields,
      checkRooms: this.checkRooms,
      checkMapImage: this.checkMapImage,
      checkFeatures: this.checkFeatures,
      checkPropertyOffers: this.checkPropertyOffers,
      checkPropertyAdvertising: this.checkPropertyAdvertising,
      checkPropertySolicitor: this.checkPropertySolicitor,
      checkPropertyOfferConditions: this.checkPropertyOfferConditions,
    });
  }

  onCheckAutoSave(event) {
    let userData = {
      is_autosaving: 0,
    };
    this.authUser.user.is_autosaving = 0;

    if (event.checked) {
      userData.is_autosaving = this.authUser.user.is_autosaving = 1;
    }

    this.userService.httpPutUserByAgentID(this.authUser.user.agent_id, userData);
  }

  generatePriceFinderReport(suburbData) {
    this.progressService.initProgress({
      type: 'pricefinder',
    });
    this.progressService.progress(0 / 4);

    this.priceFinderSuburbData = { ...this.priceFinderSuburbData, ...suburbData };
    this.pricefinderService.httpGetStats(this.priceFinderSuburbData.suburb.suburb.id, this.priceFinderSuburbData.queryData);
  }

  onGeneratePricefinderReport(suburbData) {
    const body = this.editor.Canvas.getBody();
    let html = $(body).find('#wrapper');
    const date = new Date();
    const currentYear = date.getFullYear();
    const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);
    const years = range(currentYear, currentYear - 2, -1);

    html.find('.box1').removeAttr('style');
    html.find('.box2').removeAttr('style');
    html.find('.box1').removeClass('box-extended');
    html.find('.box2').removeClass('box-extended');
    if (suburbData.queryData.displayBoth === false || suburbData.queryData.displayBoth === 'false') {
      if (suburbData.queryData.type === 'house') {
        html.find('.box2').css('display', 'none');
        //html.find('.box1').css('height', '100%');
        html.find('.box1').addClass('box-extended');
      }
      if (suburbData.queryData.type === 'unit') {
        html.find('.box1').css('display', 'none');
        //html.find('.box2').css('height', '100%');
        html.find('.box2').addClass('box-extended');
      }
    }

    years.forEach((year, index) => {
      const yearIndex = index + 1;
      html.find(`.md-pricefinder-sales-year-${yearIndex}`).html(year);

      let firstQuarterCount = 0;
      let secondQuarterCount = 0;
      let thirdQuarterCount = 0;
      let fourthQuarterCount = 0;

      let firstQuarterChangePercentage = 0;
      let secondQuarterChangePercentage = 0;
      let thirdQuarterChangePercentage = 0;
      let fourthQuarterChangePercentage = 0;

      if (typeof suburbData.stats.house !== 'undefined') {
        // Q1 - Count
        if (typeof suburbData.stats.house[`${year}_first_quarter_count`] !== 'undefined' && suburbData.stats.house[`${year}_first_quarter_count`]) {
          firstQuarterCount += suburbData.stats.house[`${year}_first_quarter_count`];
        }
        // Q1 - Count Change
        if (typeof suburbData.stats.house[`${year}_first_quarter_count_previous_change`] !== 'undefined' && suburbData.stats.house[`${year}_first_quarter_count_previous_change`]) {
          firstQuarterChangePercentage += suburbData.stats.house[`${year}_first_quarter_count_previous_change`];
        }
        // Q2 - Count
        if (typeof suburbData.stats.house[`${year}_second_quarter_count`] !== 'undefined' && suburbData.stats.house[`${year}_second_quarter_count`]) {
          secondQuarterCount += suburbData.stats.house[`${year}_second_quarter_count`];
        }
        // Q2 - Count Change
        if (typeof suburbData.stats.house[`${year}_second_quarter_count_previous_change`] !== 'undefined' && suburbData.stats.house[`${year}_second_quarter_count_previous_change`]) {
          secondQuarterChangePercentage += suburbData.stats.house[`${year}_second_quarter_count_previous_change`];
        }
        // Q3 - Count
        if (typeof suburbData.stats.house[`${year}_third_quarter_count`] !== 'undefined' && suburbData.stats.house[`${year}_third_quarter_count`]) {
          thirdQuarterCount += suburbData.stats.house[`${year}_third_quarter_count`];
        }
        // Q3 - Count Change
        if (typeof suburbData.stats.house[`${year}_third_quarter_count_previous_change`] !== 'undefined' && suburbData.stats.house[`${year}_third_quarter_count_previous_change`]) {
          thirdQuarterChangePercentage += suburbData.stats.house[`${year}_third_quarter_count_previous_change`];
        }
        // Q4 - Count
        if (typeof suburbData.stats.house[`${year}_fourth_quarter_count`] !== 'undefined' && suburbData.stats.house[`${year}_fourth_quarter_count`]) {
          fourthQuarterCount += suburbData.stats.house[`${year}_fourth_quarter_count`];
        }
        // Q4 - Count Change
        if (typeof suburbData.stats.house[`${year}_fourth_quarter_count_previous_change`] !== 'undefined' && suburbData.stats.house[`${year}_fourth_quarter_count_previous_change`]) {
          fourthQuarterChangePercentage += suburbData.stats.house[`${year}_fourth_quarter_count_previous_change`];
        }
      }

      if (typeof suburbData.stats.unit !== 'undefined') {
        // Q1 - Count
        if (typeof suburbData.stats.unit[`${year}_first_quarter_count`] !== 'undefined' && suburbData.stats.unit[`${year}_first_quarter_count`]) {
          firstQuarterCount += suburbData.stats.unit[`${year}_first_quarter_count`];
        }
        // Q1 - Count Change
        if (typeof suburbData.stats.unit[`${year}_first_quarter_count_previous_change`] !== 'undefined' && suburbData.stats.unit[`${year}_first_quarter_count_previous_change`]) {
          firstQuarterChangePercentage += suburbData.stats.unit[`${year}_first_quarter_count_previous_change`];
        }
        // Q2 - Count
        if (typeof suburbData.stats.unit[`${year}_second_quarter_count`] !== 'undefined' && suburbData.stats.unit[`${year}_second_quarter_count`]) {
          secondQuarterCount += suburbData.stats.unit[`${year}_second_quarter_count`];
        }
        // Q2 - Count Change
        if (typeof suburbData.stats.unit[`${year}_second_quarter_count_previous_change`] !== 'undefined' && suburbData.stats.unit[`${year}_second_quarter_count_previous_change`]) {
          secondQuarterChangePercentage += suburbData.stats.unit[`${year}_second_quarter_count_previous_change`];
        }
        // Q3 - Count
        if (typeof suburbData.stats.unit[`${year}_third_quarter_count`] !== 'undefined' && suburbData.stats.unit[`${year}_third_quarter_count`]) {
          thirdQuarterCount += suburbData.stats.unit[`${year}_third_quarter_count`];
        }
        // Q3 - Count Change
        if (typeof suburbData.stats.unit[`${year}_third_quarter_count_previous_change`] !== 'undefined' && suburbData.stats.unit[`${year}_third_quarter_count_previous_change`]) {
          thirdQuarterChangePercentage += suburbData.stats.unit[`${year}_third_quarter_count_previous_change`];
        }
        // Q4 - Count
        if (typeof suburbData.stats.unit[`${year}_fourth_quarter_count`] !== 'undefined' && suburbData.stats.unit[`${year}_fourth_quarter_count`]) {
          fourthQuarterCount += suburbData.stats.unit[`${year}_fourth_quarter_count`];
        }
        // Q4 - Count Change
        if (typeof suburbData.stats.unit[`${year}_fourth_quarter_count_previous_change`] !== 'undefined' && suburbData.stats.unit[`${year}_fourth_quarter_count_previous_change`]) {
          fourthQuarterChangePercentage += suburbData.stats.unit[`${year}_fourth_quarter_count_previous_change`];
        }
      }

      html.find(`.md-pricefinder-sales-year-${yearIndex}-quarter-1-count`).html(firstQuarterCount ? firstQuarterCount : '—');
      html.find(`.md-pricefinder-sales-year-${yearIndex}-quarter-2-count`).html(secondQuarterCount ? secondQuarterCount : '—');
      html.find(`.md-pricefinder-sales-year-${yearIndex}-quarter-3-count`).html(thirdQuarterCount ? thirdQuarterCount : '—');
      html.find(`.md-pricefinder-sales-year-${yearIndex}-quarter-4-count`).html(fourthQuarterCount ? fourthQuarterCount : '—');

      html.find(`.md-pricefinder-sales-year-${yearIndex}-quarter-1-prevchange`).html(firstQuarterChangePercentage ? `${firstQuarterChangePercentage}%` : 'No');
      html.find(`.md-pricefinder-sales-year-${yearIndex}-quarter-2-prevchange`).html(secondQuarterChangePercentage ? `${secondQuarterChangePercentage}%` : 'No');
      html.find(`.md-pricefinder-sales-year-${yearIndex}-quarter-3-prevchange`).html(thirdQuarterChangePercentage ? `${thirdQuarterChangePercentage}%` : 'No');
      html.find(`.md-pricefinder-sales-year-${yearIndex}-quarter-4-prevchange`).html(fourthQuarterChangePercentage ? `${fourthQuarterChangePercentage}%` : 'No');
    });

    html.find('.md-pricefinder-median-house-price-prevchange').html('No');
    html.find('.md-pricefinder-median-unit-price-prevchange').html('No');

    if (typeof suburbData.suburb.house !== 'undefined') {
      if (typeof suburbData.suburb.house.medianSalePriceView !== 'undefined' && suburbData.suburb.house.medianSalePriceView) {
        html.find('.md-pricefinder-median-house-price').html(suburbData.suburb.house.medianSalePriceView);
      }
      if (typeof suburbData.suburb.house.suburbGrowth !== 'undefined' && suburbData.suburb.house.suburbGrowth) {
        html.find('.md-pricefinder-median-house-price-prevchange').html(`${suburbData.suburb.house.suburbGrowth}%`);
      }
    }

    if (typeof suburbData.suburb.unit !== 'undefined') {
      if (typeof suburbData.suburb.unit.medianSalePriceView !== 'undefined' && suburbData.suburb.house.medianSalePriceView) {
        html.find('.md-pricefinder-median-unit-price').html(suburbData.suburb.unit.medianSalePriceView);
      }
      if (typeof suburbData.suburb.unit.suburbGrowth !== 'undefined' && suburbData.suburb.unit.suburbGrowth) {
        html.find('.md-pricefinder-median-unit-price-prevchange').html(`${suburbData.suburb.unit.suburbGrowth}%`);
      }
    }

    // tslint:disable-next-line: max-line-length
    let propBoxHtml = html.find('.md-pricefinder-recent-history .md-pricefinder-recent-history-property, .md-pricefinder-recent-rental-history .md-pricefinder-recent-rental-history-property');

    if (propBoxHtml.length) {
      propBoxHtml = propBoxHtml[0].outerHTML;

      // tslint:disable-next-line: max-line-length
      html.find('.md-pricefinder-recent-history .md-pricefinder-recent-history-property, .md-pricefinder-recent-rental-history .md-pricefinder-recent-rental-history-property').remove();

      suburbData.listings.forEach((listing, index) => {
        const propBox = $(propBoxHtml);

        propBox.find('.md-pricefinder-recent-history-property-number').html(index + 1);

        if (typeof listing.address.streetAddress !== 'undefined' && listing.address.streetAddress) {
          propBox.find('.md-pricefinder-recent-history-property-address').html(listing.address.streetAddress);
        }

        let propCars = 0;
        let propBeds = 0;
        let propBaths = 0;
        
        if (typeof listing.features !== 'undefined') {
          if (typeof listing.features.bedrooms !== 'undefined' && listing.features.bedrooms) {
            propBeds = listing.features.bedrooms;
          }

          if (typeof listing.features.bathrooms !== 'undefined' && listing.features.bathrooms) {
            propBaths = listing.features.bathrooms;
          }

          if (typeof listing.features.carParks !== 'undefined' && listing.features.carParks) {
            propCars = listing.features.carParks;
          }
        }

        propBox.find('.md-pricefinder-recent-history-property-features').html(`${propBeds} beds, ${propBaths} baths, ${propCars} cars`);

        if (typeof listing.price.display !== 'undefined' && listing.price.display) {
          propBox.find('.md-pricefinder-recent-history-property-price').html(listing.price.display);
        }

        html.find('.md-pricefinder-recent-history').append(propBox);
      });
    }

    if (typeof suburbData.segmentations !== 'undefined' && typeof suburbData.segmentations.chart !== 'undefined') {
      html.find('.md-pricefinder-segmentation-image').attr('src', suburbData.segmentations.chart);
    }

    if (typeof suburbData.segmentations !== 'undefined' && typeof suburbData.segmentations.chart !== 'undefined') {
      html.find('.md-pricefinder-segmentation-image').attr('src', suburbData.segmentations.chart);
    }

    if (typeof suburbData.stats !== 'undefined' && typeof suburbData.stats.chart !== 'undefined') {
      html.find('.md-pricefinder-price-trend-image').attr('src', suburbData.stats.chart);
    }

    if (typeof suburbData.stats.startYear !== 'undefined' && typeof suburbData.stats.endYear !== 'undefined') {
      html.find('.md-pricefinder-sales-year-pricerange').html(`${suburbData.stats.startYear} - ${suburbData.stats.endYear}`);
    }

    if (typeof suburbData.suburb !== 'undefined') {
      if (typeof suburbData.suburb.suburb.suburbName !== 'undefined') {
        html.find('.md-pricefinder-suburb').html(suburbData.suburb.suburb.suburbName);
      }
    }

    html.find('.md-pricefinder-timestamp').html(moment().format());
    html.find('.md-pricefinder-recent-history-map-image').attr('src', suburbData.suburb.map);

    html = html.html();
    html = this.stripSmartQuotes(html);

    const html_data_css = this.editor.getCss();
    const html_raw = juice(html + '<style>' + html_data_css + '</style>', {
      preserveMediaQueries: false,
      preserveImportant: true,
      applyHeightAttributes: false,
      applyWidthAttributes: false,
    });

    this.editor.setComponents(html_raw);
    this.progressService.hideProgress();
  }

  addListingToTemplate(listingId: any, queryParams = null) {
    listingId = String(listingId);
    const templateListingIndex = this.templateListings.findIndex((listing) => listing.listing_id === listingId);

    if (templateListingIndex === -1) {
      this.templateListings.push({
        listing_id: listingId,
        query_params: queryParams,
      });
    }
  }

  onUpdateListing(listing: any) {
    try {
      let selectedElement = this.editor.getSelected();

      let singleElement = false;
      let elementBox = selectedElement.view.$el;

      const elementBoxIndex = elementBox.parents('.md-prop-box').index();
      const elementGroupIndex = elementBox.parents('.md-box').index();

      if (elementBox.hasClass('md-prop-search')) {
        elementBox = elementBox.parents('.md-box');
        elementBox.trigger('click');

        selectedElement = this.editor.getSelected();
      }

      let elementBlock = elementBox.find('.md-prop-box');
      let selectedBlock = selectedElement.find('.md-prop-box')[0];

      if (elementBox.hasClass('md-prop-box')) {
        elementBlock = elementBox;
        selectedBlock = selectedElement;
      }

      if (this.element_listing_index > 0 && elementBox.hasClass('md-prop-single-box')) {
        singleElement = true;
        elementBlock = elementBox.find('.md-prop-box').eq(this.element_listing_index - 1);
        selectedBlock = selectedElement.find('.md-prop-box')[this.element_listing_index - 1];
      }

      if (this.template.category_id === 4 || this.template.category_id === 8 || this.template.category_id === 9) {
        elementBlock = elementBox.parents('#wrapper');
      }

      elementBlock.find('.md-prop-field-price').html(listing.price);

      if (typeof listing.priceMain !== 'undefined' && listing.priceMain) {
        if (listing.listing_status === 'sold' && typeof listing.salepricecurrent !== 'undefined' && listing.salepricecurrent) {
          if (typeof listing.showsaleprice !== 'undefined' && listing.showsaleprice !== null) {
            if (listing.showsaleprice === true) {
              elementBlock.find('.md-prop-field-pricemain').html(listing.salepricecurrent);
            } else {
              elementBlock.find('.md-prop-field-pricemain').html('');
            }
          } else {
            elementBlock.find('.md-prop-field-pricemain').html(listing.salepricecurrent);
          }
        } else {
          elementBlock.find('.md-prop-field-pricemain').html(listing.priceMain);
        }
      } else {
        elementBlock.find('.md-prop-field-pricemain').html('');
      }

      if (typeof listing.displayprice !== 'undefined' && listing.displayprice) {
        elementBlock.find('.md-prop-field-displayprice').html(listing.displayprice);
      } else {
        elementBlock.find('.md-prop-field-displayprice').html('');
      }

      if (typeof listing.searchprice !== 'undefined' && listing.searchprice) {
        elementBlock.find('.md-prop-field-searchprice').html(listing.searchprice);
      } else {
        elementBlock.find('.md-prop-field-searchprice').html('');
      }

      if (typeof listing.salepricecurrent !== 'undefined' && listing.salepricecurrent) {
        if (typeof listing.showsaleprice !== 'undefined' && listing.showsaleprice !== null) {
          if (listing.showsaleprice === true) {
            elementBlock.find('.md-prop-field-saleprice').html(listing.salepricecurrent);
          } else {
            elementBlock.find('.md-prop-field-saleprice').html('');
          }
        } else {
          elementBlock.find('.md-prop-field-saleprice').html(listing.salepricecurrent);
        }
      } else {
        elementBlock.find('.md-prop-field-saleprice').html('');
      }

      if (typeof listing.saleprice !== 'undefined') {
        if (listing.saleprice === null) {
          elementBlock.find('.md-prop-field-price').html('Contact Agent');
        } else {
          elementBlock.find('.md-prop-field-price').html(listing.saleprice);
        }
      }

      if (this.frontService.authService.getApp() === 'Designly') {
        const dateToday = Date.parse(new Date().toString());

        let dateAuction = 0;

        if (typeof listing.auctiondateformat !== 'undefined' && listing.auctiondateformat !== null && listing.auctiondateformat !== '') {
          dateAuction = Date.parse(listing.auctiondateformat.toString());
        }

        if (typeof listing.auctiondate !== 'undefined' && listing.auctiondate !== null && listing.listing_status !== 'sold' && dateAuction >= dateToday) {
          elementBlock.find('.md-prop-field-price').html('');
          elementBlock.find('.md-prop-field-pricemain').html('');
        } else {
          if (this.s2StatusValue === 'sold') {
            if (typeof listing.showsaleprice !== 'undefined' && listing.showsaleprice !== null) {
              if (listing.showsaleprice === true) {
                if (typeof listing.salepricecurrent !== 'undefined' && listing.salepricecurrent) {
                  elementBlock.find('.md-prop-field-price').html(listing.salepricecurrent);
                  elementBlock.find('.md-prop-field-pricemain').html(listing.salepricecurrent);
                } else {
                  elementBlock.find('.md-prop-field-price').html('');
                  elementBlock.find('.md-prop-field-pricemain').html('');
                }
              } else {
                elementBlock.find('.md-prop-field-price').html('');
                elementBlock.find('.md-prop-field-pricemain').html('');
              }
            } else {
              if (typeof listing.salepricecurrent !== 'undefined' && listing.salepricecurrent) {
                elementBlock.find('.md-prop-field-price').html(listing.salepricecurrent);
                elementBlock.find('.md-prop-field-pricemain').html(listing.salepricecurrent);
              } else {
                elementBlock.find('.md-prop-field-price').html('');
                elementBlock.find('.md-prop-field-pricemain').html('');
              }
            }
          } else {
            if (typeof listing.pricetext !== 'undefined' && listing.pricetext !== null && listing.pricetext) {
              elementBlock.find('.md-prop-field-price').html(listing.pricetext);
              if (typeof listing.priceMain !== 'undefined' && listing.priceMain !== null && listing.priceMain) {
                elementBlock.find('.md-prop-field-pricemain').html(listing.priceMain);
              } else {
                elementBlock.find('.md-prop-field-pricemain').html('');
              }
            } else {
              if (typeof listing.priceMain !== 'undefined' && listing.priceMain !== null && listing.priceMain) {
                elementBlock.find('.md-prop-field-price').html(listing.priceMain);
                elementBlock.find('.md-prop-field-pricemain').html(listing.priceMain);
              } else {
                elementBlock.find('.md-prop-field-price').html('');
                elementBlock.find('.md-prop-field-pricemain').html('');
              }
            }
          }
          elementBlock.find('.md-prop-field-auctiondate').html('');
          elementBlock.find('.md-prop-field-auctiondate-title').html('');
          elementBlock.find('.md-prop-field-auctiondate-title-br').html('');
          elementBlock.find('.md-prop-field-auctiondate-title-alt').html('');
        }

      }
    } catch (e) {
      swal('There\'s an error updating your listing.', 'Please try again later.', 'error');
      console.error(e);
    }

    this.loading = false;
    this.ref.detectChanges();
  }

  initTooltip() {
    setTimeout(() => {
      let timeout;
      $('[data-tooltip]').on({
        'mouseenter': function () {
          timeout = setTimeout(() => {
              $('[data-tooltip]').removeClass('hovered');
              $(this).addClass('hovered');
            }, 350);
        },
        'mouseleave' : function () {
          clearTimeout(timeout);
          $(this).removeClass('hovered');
        }
      });
    }, 300);
  }

  onPriceFinderSendEmail(url: string) {
    const emailTemplateValues = {
      title: this.template.title,
      user_id: this.template.user_id,
      user_agent_id: this.template.user_agent_id,
      pricefinder_mail: 1
    } as any;
    this.templateService.httpDuplicateTemplate(this.template.group.pricefinder_template_id, emailTemplateValues);
    this.loading = true;

    this.priceFinderPdfUrl = url;
  }

  s2ValueChanged(field: string, value = '') {
    if (field === 'campaign') {
      this.s2CampaignValue = value;
      return true;
    }
  }

  runUkScript() {
    try {
      const iframe = $(this.builder.nativeElement).find('iframe').contents();
      const wrapper = iframe.find('#wrapper').get();

      if (wrapper && wrapper.length) {
        const wrapperEl = wrapper[0];

        if (this.ukStyleId.includes(this.style.ID)) {
          let text = wrapperEl.querySelector('#article').innerHTML;
          const p1 = wrapperEl.querySelector('#column1');
          const p2 = wrapperEl.querySelector('#column2');
          const p3 = wrapperEl.querySelector('#column3');
          const p4 = wrapperEl.querySelector('#column4');
          const desc = wrapperEl.querySelector('#description').innerHTML;
          const rooms = wrapperEl.querySelector('.md-prop-field-rooms');
          const p5 = wrapperEl.querySelector('#column5');
          const p6 = wrapperEl.querySelector('#column6');
          const p7 = wrapperEl.querySelector('#column7');
          const p8 = wrapperEl.querySelector('#column8');
          p5.style.display = 'none';
          p6.style.display = 'none';
          p7.style.display = 'none';
          p8.style.display = 'none';
          if (rooms.length) {
            if (rooms[0].innerHTML === '') {
              p2.innerHTML = '';
              p3.innerHTML = '';
            }
            if (rooms[0].innerHTML === 'Lorem') {
              p2.innerHTML = '';
              // tslint:disable-next-line: max-line-length
              text = '<span id="description"><span class="head bold md-prop-field-heading">Property Heading</span><br><span class="description md-prop-field-description"></span><br></span><br><b class="head rooms-head">Rooms</b><br><span class="rooms md-prop-field-rooms">Lorem</span>';
            }
          }
          p1.innerHTML = desc;
          p2.innerHTML = text;
          p3.innerHTML = text;
          p4.innerHTML = text;
          let flag = 0;
          if (p1.clientHeight > 1600) {
            flag = 1;
          }
          if (p1.clientHeight > 2400) {
            flag = 2;
          }
          if (p1.clientHeight < 800) {
            p1.innerHTML = text;
            for (let i = p1.innerHTML.split(' ').length - 1 ; p1.clientHeight > 800 ; i--) {
              let str = p1.innerHTML;
              const index = str.lastIndexOf(' ');
              str = str.substring(0, index);
              p1.innerHTML = str;
            }
            const short_description = p2.innerHTML.split(' ').slice(p1.innerHTML.split(' ').length).join(' ');
            p2.innerHTML = short_description;
          } else {
            for (let i = p1.innerHTML.split(' ').length - 1 ; p1.clientHeight > 800 ; i--) {
              let str = p1.innerHTML;
              const index = str.lastIndexOf(' ');
              str = str.substring(0, index);
              p1.innerHTML = str;
            }
            const short_description = p2.innerHTML.split(' ').slice(p1.innerHTML.split(' ').length + 4).join(' ');
            p2.innerHTML = short_description;
          }
            if (p2.innerHTML.split(' ')[0].includes('data-gjs-type')) {
              p2.innerHTML = '<span ' + p2.innerHTML.replace( /&gt;/g, '>' );
              p2.innerHTML = p2.innerHTML.replace( '</code>', '</code></span>' );
            }
            if (p2.innerHTML.split(' ')[0].includes('style=')) {
              p2.innerHTML = '<span ' + p2.innerHTML.replace( /&gt;/g, '>' );
            }
            if (p2.innerHTML.split(' ')[0].includes('data-highlightable')) {
              p2.innerHTML = '<span ' + p2.innerHTML.replace( /&gt;/g, '>' );
            }
            if (p2.innerHTML.split(' ')[0].includes('class="room')) {
              p2.innerHTML = '<span ' + p2.innerHTML.replace( /&gt;/g, '>' );
              p2.innerHTML = p2.innerHTML.replace( '</code>', '</code></span>' );
              p2.innerHTML = p2.innerHTML.replace( '</code>', '</code></span>' );
            }
            if (p2.innerHTML.split(' ')[0].includes('class="description')) {
              p2.innerHTML = '<span ' + p2.innerHTML.replace( /&gt;/g, '>' );
              p2.innerHTML = p2.innerHTML.replace( '</code>', '</code></span>' );
              p2.innerHTML = p2.innerHTML.replace( '</code>', '</code></span>' );
            }
            if (p2.innerHTML.split(' ')[0].includes('class="head')) {
              p2.innerHTML = '<span ' + p2.innerHTML.replace( /&gt;/g, '>' );
              p2.innerHTML = p2.innerHTML.replace( '</code>', '</code></span>' );
              p2.innerHTML = p2.innerHTML.replace( '</code>', '</code></span>' );
            }
            if (flag > 0) {
              for (let i = p2.innerHTML.split(' ').length - 1 ; p2.clientHeight > 800 ; i--) {
                let str = p2.innerHTML;
                const index = str.lastIndexOf(' ');
                str = str.substring(0, index);
                p2.innerHTML = str;
              }
              const short_description4 = p3.innerHTML.split(' ').slice(p1.innerHTML.split(' ').length + 2).join(' ');
              const short_description5 = short_description4.split(' ').slice(p2.innerHTML.split(' ').length + 2).join(' ');
              p3.innerHTML = short_description5;
            } else {
              for (let i = p2.innerHTML.split(' ').length - 1 ; p2.clientHeight > 800 ; i--) {
                let str = p2.innerHTML;
                const index = str.lastIndexOf(' ');
                str = str.substring(0, index);
                p2.innerHTML = str;
              }
              const short_description2 = p3.innerHTML.split(' ').slice(p1.innerHTML.split(' ').length - 1).join(' ');
              const short_description3 = short_description2.split(' ').slice(p2.innerHTML.split(' ').length).join(' ');
              p3.innerHTML = short_description3;
            }
          if (p3.innerHTML.split(' ')[0].includes('data-gjs-type')) {
            p3.innerHTML = '<span ' + p3.innerHTML.replace( /&gt;/g, '>' );
            p3.innerHTML = p3.innerHTML.replace( '</code>', '</code></span>' );
          }
          if (p3.innerHTML.split(' ')[0].includes('data-highlightable')) {
            p3.innerHTML = '<span ' + p3.innerHTML.replace( /&gt;/g, '>' );
          }
          if (p3.innerHTML.split(' ')[0].includes('style="')) {
            p3.innerHTML = '<span ' + p3.innerHTML.replace( /&gt;/g, '>' );
          }
          if (p3.innerHTML.split(' ')[0].includes('class="room')) {
            p3.innerHTML = '<span ' + p3.innerHTML.replace( /&gt;/g, '>' );
            p3.innerHTML = p3.innerHTML.replace( '</code>', '</code></span>' );
            p3.innerHTML = p3.innerHTML.replace( '</code>', '</code></span>' );
          }
          if (p3.innerHTML.split(' ')[0].includes('class="head')) {
            p3.innerHTML = '<span ' + p3.innerHTML.replace( /&gt;/g, '>' );
            p3.innerHTML = p3.innerHTML.replace( '</code>', '</code></span>' );
            p3.innerHTML = p3.innerHTML.replace( '</code>', '</code></span>' );
          }
          if (flag === 2) {
            for (let i = p3.innerHTML.split(' ').length - 1 ; p3.clientHeight > 800 ; i--) {
              let str = p3.innerHTML;
              const index = str.lastIndexOf(' ');
              str = str.substring(0, index);
              p3.innerHTML = str;
            }
            const short_description4 = p4.innerHTML.split(' ').slice(p1.innerHTML.split(' ').length + 2).join(' ');
            const short_description5 = short_description4.split(' ').slice(p2.innerHTML.split(' ').length + 1).join(' ');
            const short_description6 = short_description5.split(' ').slice(p3.innerHTML.split(' ').length + 1).join(' ');
            p4.innerHTML = short_description6;
            } else {
              for (let i = p3.innerHTML.split(' ').length - 1 ; p3.clientHeight > 800 ; i--) {
                let str = p3.innerHTML;
                const index = str.lastIndexOf(' ');
                str = str.substring(0, index);
                p3.innerHTML = str;
              }
              const short_description4 = p4.innerHTML.split(' ').slice(p1.innerHTML.split(' ').length - 1).join(' ');
              const short_description5 = short_description4.split(' ').slice(p2.innerHTML.split(' ').length - 1).join(' ');
              const short_description6 = short_description5.split(' ').slice(p3.innerHTML.split(' ').length).join(' ');
              p4.innerHTML = short_description6;
            }
            if (p4.innerHTML.split(' ')[0].includes('id="')) {
              p4.innerHTML = '<span ' + p4.innerHTML.replace( /&gt;/g, '>' );
            }
            if (p4.innerHTML.split(' ')[0].includes('data-gjs-type')) {
              p4.innerHTML = '<span ' + p4.innerHTML.replace( /&gt;/g, '>' );
              p4.innerHTML = p4.innerHTML.replace( '</code>', '</code></span>' );
            }
            if (p4.innerHTML.split(' ')[0].includes('style=')) {
              p4.innerHTML = '<span ' + p4.innerHTML.replace( /&gt;/g, '>' );
            }
            if (p4.innerHTML.split(' ')[0].includes('data-highlightable')) {
              p4.innerHTML = '<span ' + p4.innerHTML.replace( /&gt;/g, '>' );
              p4.innerHTML = p4.innerHTML.replace( '</code>', '</code></span>' );
            }
            if (p4.innerHTML.split(' ')[0].includes('class="head')) {
              p4.innerHTML = '<span ' + p4.innerHTML.replace( /&gt;/g, '>' );
              p4.innerHTML = p4.innerHTML.replace( '</code>', '</code></span>' );
            }
            if (p4.innerHTML.split(' ')[0].includes('class="room')) {
              p4.innerHTML = '<span ' + p4.innerHTML.replace( /&gt;/g, '>' );
              p4.innerHTML = p4.innerHTML.replace( '</code>', '</code></span>' );
            }
        }

        if (this.style.ID === 421) {
            const text = wrapperEl.querySelector('#article').innerHTML;
            const p1 = wrapperEl.querySelector('#column1');
            const p2 = wrapperEl.querySelector('#column2');
            const p3 = wrapperEl.querySelector('#column3');
            const p4 = wrapperEl.querySelector('#column4');
            const desc = wrapperEl.querySelector('#description').innerHTML;
            const p5 = wrapperEl.querySelector('#column5');
            const p6 = wrapperEl.querySelector('#column6');
            const p7 = wrapperEl.querySelector('#column7');
            const p8 = wrapperEl.querySelector('#column8');
            p1.innerHTML = desc;
            p2.innerHTML = text;
            p3.innerHTML = text;
            p4.innerHTML = text;
            p5.style.display = 'none';
            p6.style.display = 'none';
            p7.style.display = 'none';
            p8.style.display = 'none';
            let flag = 0;
            if (p1.clientHeight > 1054) {
              flag = 1;
            }
            if (p1.clientHeight > 1572) {
              flag = 2;
            }
            if (p1.clientHeight < 730) {
              p1.innerHTML = text;
              for (let i = p1.innerHTML.split(' ').length - 1 ; p1.clientHeight > 730 ; i--) {
                let str = p1.innerHTML;
                const index = str.lastIndexOf(' ');
                str = str.substring(0, index);
                p1.innerHTML = str;
              }
              const short_description = p2.innerHTML.split(' ').slice(p1.innerHTML.split(' ').length).join(' ');
              p2.innerHTML = short_description;
            } else {
              for (let i = p1.innerHTML.split(' ').length - 1 ; p1.clientHeight > 730 ; i--) {
                let str = p1.innerHTML;
                const index = str.lastIndexOf(' ');
                str = str.substring(0, index);
                p1.innerHTML = str;
              }
              const short_description = p2.innerHTML.split(' ').slice(p1.innerHTML.split(' ').length + 4).join(' ');
              p2.innerHTML = short_description;
            }
            if (p1.innerHTML.split(' ')[0].includes('data-gjs-type')) {
              p1.innerHTML = '<span ' + p1.innerHTML.replace( /&gt;/g, '>' );
              p1.innerHTML = p1.innerHTML.replace( '</code>', '</code></span>' );
            }
              if (p2.innerHTML.split(' ')[0].includes('data-gjs-type')) {
                p2.innerHTML = '<span ' + p2.innerHTML.replace( /&gt;/g, '>' );
                p2.innerHTML = p2.innerHTML.replace( '</code>', '</code></span>' );
              }
              if (p2.innerHTML.split(' ')[0].includes('id="')) {
                p2.innerHTML = '<span ' + p2.innerHTML.replace( /&gt;/g, '>' );
              }
              if (p2.innerHTML.split(' ')[0].includes('style=')) {
                p2.innerHTML = '<span ' + p2.innerHTML.replace( /&gt;/g, '>' );
              }
              if (p2.innerHTML.split(' ')[0].includes('data-highlightable')) {
                p2.innerHTML = '<span ' + p2.innerHTML.replace( /&gt;/g, '>' );
                p2.innerHTML = p2.innerHTML.replace( '</code>', '</code></span>' );
              }
              if (p2.innerHTML.split(' ')[0].includes('class="head')) {
                p2.innerHTML = '<span ' + p2.innerHTML.replace( /&gt;/g, '>' );
                p2.innerHTML = p2.innerHTML.replace( '</code>', '</code></span>' );
              }
              if (p2.innerHTML.split(' ')[0].includes('class="description')) {
                p2.innerHTML = '<span ' + p2.innerHTML.replace( /&gt;/g, '>' );
                p2.innerHTML = p2.innerHTML.replace( '</code>', '</code></span>' );
                p2.innerHTML = p2.innerHTML.replace( '</code>', '</code></span>' );
              }
              if (p2.innerHTML.split(' ')[0].includes('class="room')) {
                p2.innerHTML = '<span ' + p2.innerHTML.replace( /&gt;/g, '>' );
                p2.innerHTML = p2.innerHTML.replace( '</code>', '</code></span>' );
              }
              if (flag > 0) {
                for (let i = p2.innerHTML.split(' ').length - 1 ; p2.clientHeight > 730 ; i--) {
                  let str = p2.innerHTML;
                  const index = str.lastIndexOf(' ');
                  str = str.substring(0, index);
                  p2.innerHTML = str;
                }
                const short_description2 = p3.innerHTML.split(' ').slice(p1.innerHTML.split(' ').length + 2).join(' ');
                const short_description3 = short_description2.split(' ').slice(p2.innerHTML.split(' ').length + 2).join(' ');
                p3.innerHTML = short_description3;
              } else {
                for (let i = p2.innerHTML.split(' ').length - 1 ; p2.clientHeight > 730 ; i--) {
                  let str = p2.innerHTML;
                  const index = str.lastIndexOf(' ');
                  str = str.substring(0, index);
                  p2.innerHTML = str;
                }
                const short_description2 = p3.innerHTML.split(' ').slice(p1.innerHTML.split(' ').length - 1).join(' ');
                const short_description3 = short_description2.split(' ').slice(p2.innerHTML.split(' ').length).join(' ');
                p3.innerHTML = short_description3;
              }
              if (p3.innerHTML.split(' ')[0].includes('id="')) {
                p3.innerHTML = '<span ' + p3.innerHTML.replace( /&gt;/g, '>' );
              }
              if (p3.innerHTML.split(' ')[0].includes('data-gjs-type')) {
                p3.innerHTML = '<span ' + p3.innerHTML.replace( /&gt;/g, '>' );
                p3.innerHTML = p3.innerHTML.replace( '</code>', '</code></span>' );
              }
              if (p3.innerHTML.split(' ')[0].includes('style=')) {
                p3.innerHTML = '<span ' + p3.innerHTML.replace( /&gt;/g, '>' );
              }
              if (p3.innerHTML.split(' ')[0].includes('data-highlightable')) {
                p3.innerHTML = '<span ' + p3.innerHTML.replace( /&gt;/g, '>' );
                p3.innerHTML = p3.innerHTML.replace( '</code>', '</code></span>' );
              }
              if (p3.innerHTML.split(' ')[0].includes('class="head')) {
                p3.innerHTML = '<span ' + p3.innerHTML.replace( /&gt;/g, '>' );
                p3.innerHTML = p3.innerHTML.replace( '</code>', '</code></span>' );
              }
              if (p3.innerHTML.split(' ')[0].includes('class="room')) {
                p3.innerHTML = '<span ' + p3.innerHTML.replace( /&gt;/g, '>' );
                p3.innerHTML = p3.innerHTML.replace( '</code>', '</code></span>' );
              }
              if (flag === 2) {
                for (let i = p3.innerHTML.split(' ').length - 1 ; p3.clientHeight > 730 ; i--) {
                  let str = p3.innerHTML;
                  const index = str.lastIndexOf(' ');
                  str = str.substring(0, index);
                  p3.innerHTML = str;
                }
                const short_description4 = p4.innerHTML.split(' ').slice(p1.innerHTML.split(' ').length + 2).join(' ');
                const short_description5 = short_description4.split(' ').slice(p2.innerHTML.split(' ').length + 1).join(' ');
                const short_description6 = short_description5.split(' ').slice(p3.innerHTML.split(' ').length + 1).join(' ');
                p4.innerHTML = short_description6;
              } else {
                for (let i = p3.innerHTML.split(' ').length - 1 ; p3.clientHeight > 730 ; i--) {
                  let str = p3.innerHTML;
                  const index = str.lastIndexOf(' ');
                  str = str.substring(0, index);
                  p3.innerHTML = str;
                }
                const short_description4 = p4.innerHTML.split(' ').slice(p1.innerHTML.split(' ').length - 1).join(' ');
                const short_description5 = short_description4.split(' ').slice(p2.innerHTML.split(' ').length - 1).join(' ');
                const short_description6 = short_description5.split(' ').slice(p3.innerHTML.split(' ').length).join(' ');
                p4.innerHTML = short_description6;
              }
              if (p4.innerHTML.split(' ')[0].includes('id="')) {
                p4.innerHTML = '<span ' + p4.innerHTML.replace( /&gt;/g, '>' );
              }
              if (p4.innerHTML.split(' ')[0].includes('data-gjs-type')) {
                p4.innerHTML = '<span ' + p4.innerHTML.replace( /&gt;/g, '>' );
                p4.innerHTML = p4.innerHTML.replace( '</code>', '</code></span>' );
              }
              if (p4.innerHTML.split(' ')[0].includes('style=')) {
                p4.innerHTML = '<span ' + p4.innerHTML.replace( /&gt;/g, '>' );
              }
              if (p4.innerHTML.split(' ')[0].includes('data-highlightable')) {
                p4.innerHTML = '<span ' + p4.innerHTML.replace( /&gt;/g, '>' );
                p4.innerHTML = p4.innerHTML.replace( '</code>', '</code></span>' );
              }
              if (p4.innerHTML.split(' ')[0].includes('class="head')) {
                p4.innerHTML = '<span ' + p4.innerHTML.replace( /&gt;/g, '>' );
                p4.innerHTML = p4.innerHTML.replace( '</code>', '</code></span>' );
              }
              if (p4.innerHTML.split(' ')[0].includes('class="room')) {
                p4.innerHTML = '<span ' + p4.innerHTML.replace( /&gt;/g, '>' );
                p4.innerHTML = p4.innerHTML.replace( '</code>', '</code></span>' );
              }
          }

        this.editor.setComponents(wrapperEl.innerHTML);
      }
    } catch (e) {
      console.error(e);
    }
  }

  getSubString(elementString: string, startSearchString: string, endSearchString: string) {
      const startIndex = elementString.indexOf(startSearchString);
      const endIndex = elementString.indexOf(endSearchString, startIndex);
      if (startIndex === -1 || endIndex === -1) {
          return false;
      }
      return elementString.substring(startIndex, endIndex + 1);
  }

  getPropertyConfigInfo(strataBuilding) {
    let level_column = '';
    let area_column = '';
    let rent_column = '';
    let availability_column = '';
    let comments_column = '';

    if (strataBuilding.address.level) {
      level_column += 'Level ' + strataBuilding.address.level + ' ';
    }
    if (strataBuilding.address.unitNumber) {
      level_column += 'Unit ' + strataBuilding.address.unitNumber;
    }
    if (strataBuilding.floorArea.value) {
      area_column += strataBuilding.floorArea.value + ' ' + strataBuilding.floorArea.units;
    }
    if (strataBuilding.ratePerSQM) {
      rent_column += '$' + String(strataBuilding.ratePerSQM);
    }

    availability_column = !strataBuilding.tenanted ? 'Now' : 'Tenanted' ;

    const buildingInfo = {
      level_column: level_column,
      area_column: area_column,
      rent_column: rent_column,
      availability_column: availability_column,
      comments_column: comments_column
    };

    return buildingInfo;
  }

  resetPropertyConfigTable(elementBlockGroup) {
    const elementRow = elementBlockGroup.find('tbody').find('tr');
    if (elementRow.length > 0) {
      const rowCount = elementRow.length;
      for (let index = 0; index < rowCount; index++) {
        if (index > 0) {
          elementRow[index].remove();
        } else {
          elementRow.find('.md-prop-building-opportunities-level').html('Level X Unit X');
          elementRow.find('.md-prop-building-opportunities-area').html('XX');
          elementRow.find('.md-prop-building-opportunities-rent').html('$XX');
          elementRow.find('.md-prop-building-opportunities-availability').html('XX');
          elementRow.find('.md-prop-building-opportunities-comments').html('');
        }
      }
    }
  }

  checkAgentIfSelected(ID: number) {
    const agentIndex = this.selectedAgents.findIndex(agent => agent.ID === ID);

    if (agentIndex >= 0) {
      return true;
    }

    return false;
  }

  selectAgent(agent: User) {
    this.selectedAgents.push(agent);
  }

  unselectAgent(ID: number) {
    const agentIndex = this.selectedAgents.findIndex(agent => agent.ID === ID);

    if (agentIndex !== -1) {
      this.selectedAgents.splice(agentIndex, 1);
    }
  }

  insertAgents() {
    if (this.selectedAgents.length === 0) {
      return false;
    }
    const selectedComponent = this.editor.getSelected();
    if (selectedComponent) {
      let agentGrid = selectedComponent.find('.dsgnly-agent-grid');
      if (agentGrid.length) {
        const agentBlocks = agentGrid[0].components();

        if (agentBlocks.models.length) {
          const agentBlock = agentBlocks.models[0].toHTML();
          agentGrid[0].view.$el.empty();

          this.selectedAgents.forEach(agent => {
            const agentName = `${agent.firstname} ${agent.lastname}`;
            const formattedAgentBlock = this.globalService.fillAgentMergeClass($(`<div>${agentBlock}</div>`), {
              agentName: agentName ? agentName : '-',
              agentPosition: agent.position ? agent.position : '-',
              agentPhoto: agent.photo ? agent.photo : '',
              agentPhone: agent.telephone ? agent.telephone : '-',
              agentMobile: agent.mobile ? agent.mobile : '-',
              agentEmail: agent.email ? agent.email : '-',
              agentProfile: agent.profile ? agent.profile : '-'
            });
            agentGrid[0].append(formattedAgentBlock);
          });
        }
      } else {
        agentGrid = selectedComponent.parent().find('.dsgnly-agent-grid');
        if (agentGrid.length) {
          const agentBlocks = agentGrid[0].components();

          if (agentBlocks.models.length) {
            const agentBlock = agentBlocks.models[0].toHTML();
            agentGrid[0].view.$el.empty();

            this.selectedAgents.forEach(agent => {
              const agentName = `${agent.firstname} ${agent.lastname}`;
              const formattedAgentBlock = this.globalService.fillAgentMergeClass($(`<div>${agentBlock}</div>`), {
                agentName: agentName ? agentName : '-',
                agentPosition: agent.position ? agent.position : '-',
                agentPhoto: agent.photo ? agent.photo : '',
                agentPhone: agent.telephone ? agent.telephone : '-',
                agentMobile: agent.mobile ? agent.mobile : '-',
                agentEmail: agent.email ? agent.email : '-',
                agentProfile: agent.profile ? agent.profile : '-'
              });
              agentGrid[0].append(formattedAgentBlock);
            });
          }
        } else {
          const dropSection = this.editor.getWrapper().find('.dwb-drop-section');

          if (dropSection.length) {
            const singleAgentBlockHtml = selectedComponent.toHTML();
            let lastInsertedIndex = selectedComponent.index();
            selectedComponent.remove();

            this.selectedAgents.forEach(agent => {
              const agentName = `${agent.firstname} ${agent.lastname}`;
              const formattedAgentBlock = this.globalService.fillAgentMergeClass($(`<div>${singleAgentBlockHtml}</div>`), {
                agentName: agentName ? agentName : '-',
                agentPosition: agent.position ? agent.position : '-',
                agentPhoto: agent.photo ? agent.photo : '',
                agentPhone: agent.telephone ? agent.telephone : '-',
                agentMobile: agent.mobile ? agent.mobile : '-',
                agentEmail: agent.email ? agent.email : '-',
                agentProfile: agent.profile ? agent.profile : '-',
                id: 'regenerate'
              });

              dropSection[0].append(formattedAgentBlock, {
                at: lastInsertedIndex
              });

              lastInsertedIndex += 1;
            });
          }
        }

      }
    }
    $('#mdAgentsSearch').modal('hide');
  }

  showSaveAsTemplateModal() {

    this.templateShareUsers = this.authUser.client.users.map((user: any) => {
      return {
        name: user.firstname + " " + user.lastname,
        ID: user.ID,
        isSelected: false,
        isVisible: true
      }
    }).filter((user: any) => user.ID !== this.authUser.user.ID);

    this.customCategoryForm.patchValue({
      'quick_design_group_id' : []
    });
    this.customCategoryIcon.nativeElement.value = '';

    if(this.tagifyTemplateTags != null){
      this.tagifyTemplateTags.removeAllTags();
    }

    this.selectSaveAsTemplateTab();

    $("#saveAsTemplateModal").modal('show');
  }

  checkImageFileType(type) {
    const validImageTypes = ['image/gif', 'image/jpeg', 'image/jpg', 'image/png', 'image/svg', 'image/svg+xml'];
    if (validImageTypes.includes(type)) {
      return true;
    }
    return false;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  cropChangeAspectRatio(aspectRatio) {

    if(aspectRatio === 0) {
      this.maintainAspectRatio = false;
      return;
    }

    this.maintainAspectRatio = true;
    this.defaultAspectRatio = aspectRatio;
  }
  
  cropRotateCanvas(rotation: number) {
    this.cropperCanvasRotation += rotation;
  }

  cropFlipImage(flipH?: boolean, flipV?: boolean) {
    this.cropperImageTransform = {
      ...this.cropperImageTransform,
      flipH: flipH ? !this.cropperImageTransform.flipH : this.cropperImageTransform.flipH,
      flipV: flipV ? !this.cropperImageTransform.flipV : this.cropperImageTransform.flipV,
    };
  }

  onUploadCropped() {
    this.storageService.httpUploadBase64({
      image: this.croppedImage
    });
    this.loading = true;
  }

  selectSaveAsTemplateTab(tab = 'details-tab') {
    this.saveAsTemplateState.selectedTab = tab;
  }

  onSearchUser(searchTerm: string) {
    
    this.templateShareUsers = this.templateShareUsers.map((user: any) => {

      if (searchTerm && !user.name.toLowerCase().includes(searchTerm.toLocaleLowerCase())) {
        return {
          ...user,
          isVisible: false
        }
      }

      return {
        ...user,
        isVisible: true
      };

    });

  }

  onShareQuickDesign() {
    $('#createTemplate').modal('hide');

    this.loading = true;
    this.quickDesignService.getShared(this.template.ID).subscribe((res: any) => {
      const sharedUsers = res.data.shared_users;
      this.templateShareWith = res.data.shared_to_office === 1 ? 'office' : 'agent';

      this.templateShareUsers = this.authUser.client.users.map((user: any) => {
        return {
          name: user.firstname + " " + user.lastname,
          ID: user.ID,
          isSelected: sharedUsers.some((sharedUser: any) => user.ID === sharedUser.ID),
          isVisible: true
        }
      }).filter((user: any) => user.ID !== this.authUser.user.ID);

      this.loading = false;
      $('#shareTemplateModal').modal('show');
    });

  }

  shareQuickDesignTemplate() {

    const sharedUsers = this.templateShareUsers.filter((user: any) => user.isSelected).map((user: any) => user.ID);

    this.loading = true;
    this.quickDesignService.share(
      this.template.ID, 
      sharedUsers,
      this.templateShareWith === 'office')
      .subscribe((res: any) => {

      swal('Success', 'Template sharing has been updated.', 'success');
      this.loading = false;
      $("#shareTemplateModal").modal('hide');
    });

  }

  unshare() {
    this.loading = true;
    this.quickDesignService.unshare(this.template.ID)
      .subscribe((res: any) => {
        swal('Success', 'Sharing has been successfully revoked. Template is no longer shared with any users.', 'success');
        this.loading = false;
        $("#shareTemplateModal").modal('hide');
      });
  }

  back() {
    this.insertPropertyButton = false;
    this.insertPropertyElement = false;
    this.propertySearchService.propertyInsertSource = null;
  }

  removeAllSharing() {
    this.templateShareWith = 'agent';
    this.templateShareUsers = this.authUser.client.users.map((user: any) => {
      return {
        name: user.firstname + " " + user.lastname,
        ID: user.ID,
        isSelected: false,
        isVisible: true
      }
    }).filter((user: any) => user.ID !== this.authUser.user.ID);;
  }

  togglePropertyIcon(data: any, propertyIdx, event) {
    //check how many property icon in the selected element
    let selectedElement = this.editor.getSelected();
    const maxIconCount = selectedElement.view.$el.attr('data-max-property-icon');
    let visibleIconCount = 0;
    let selectedIconIdx = $(event.target).closest('.row').index();

    //check first how many property icon are visible on selected element
    selectedElement.view.$el.find('.icon[data-icon-label]').each((icon) => {
      if ($(icon).is(':visible')) {
        visibleIconCount++;
      }
    });
    if (event.target.checked == true) {
      if (visibleIconCount >= maxIconCount) {
        event.target.checked = false;
        swal({
          title: 'Reached the limit',
          text: `Maximum of ${maxIconCount} property icon can be placed here`, 
          type: 'warning'
        });
      } else {
        $(selectedElement.view.el).find(`.icon[data-icon-label]:eq(${selectedIconIdx})`).show();
        event.target.checked = true;
      }
    } else {
      $(selectedElement.view.el).find(`.icon[data-icon-label]:eq(${selectedIconIdx})`).hide();
    }
  }

  movePropertyIcon(dir, collection) {
    let selectedElement = this.editor.getSelected();
    let currIconIdx = $(collection.target).closest('.row').index();
    let directionIdx = (dir == 0) ? currIconIdx-1 : currIconIdx+1;

    let curr = $(selectedElement.view.el).find(`.icon[data-icon-label]:eq(${currIconIdx})`).clone(true);
    let directionItem = $(selectedElement.view.el).find(`.icon[data-icon-label]:eq(${directionIdx})`).clone(true);

    $(selectedElement.view.el).find(`.icon[data-icon-label]:eq(${currIconIdx})`).replaceWith(directionItem);
    $(selectedElement.view.el).find(`.icon[data-icon-label]:eq(${directionIdx})`).replaceWith(curr);
    let elementProperty = this.getElementPropertyIcons();

    const currData = elementProperty.controls[(currIconIdx)];
    const directionData = elementProperty.controls[(directionIdx)];
    elementProperty.controls[(currIconIdx)] = directionData;
    elementProperty.controls[(directionIdx)] = currData;
    elementProperty.controls[(currIconIdx)].patchValue(directionData);
    elementProperty.controls[(directionIdx)].patchValue(currData);
  }

  getElementPropertyIcons(): FormArray {
    return <FormArray> this.elementPropertyForm.get('icons');
  }

  createElement() {
    let elemt = $('#elmnt-select').val();
    switch(elemt) {
      case 2:

      break;
    }
  }

  chooseSettingOption(event, class_val: any, setting_id) {
    var index = this.userGlobalSettings.findIndex(setting => setting.id == setting_id);
    if (event.target.value != ''){ //remove this setting 
      if (index >= 0) { //is in here
        this.userGlobalSettings[index].class = class_val
      } else {
        this.userGlobalSettings.push({
          id: setting_id,
          class: class_val
        });
      }
    } else {
      this.userGlobalSettings.splice(index, 1);
    }

    this.rebuildCanvasBodyClass();
  }

  rebuildCanvasBodyClass() {
    /* Reconstruct user global class in <body> of canvas */
    var tmpChosenClass = [];
    this.userGlobalSettings.forEach((obj) => {
      tmpChosenClass.push(obj.class);
    });
    let body = this.editor.Canvas.getBody();
    console.log(tmpChosenClass);
    $(body).attr('class', tmpChosenClass.join(' '));
  }

  toggleDropDownClass(event, settingId) {
    let classValue = event.value;
    var index = this.userGlobalSettings.findIndex(setting => setting.id == settingId);

    if (event.checked) {
      if (index >= 0) { //is in here
        this.userGlobalSettings[index].class = classValue
      } else {
        this.userGlobalSettings.push({
          id: settingId,
          class: classValue
        });
      }
    } else {
      //remove from class list
      this.userGlobalSettings.splice(index, 1);
    }
    this.rebuildCanvasBodyClass();
  }

  saveUserGlobalSetting() {
    let data = {
      user_id: this.template.user_id,
      template_id: this.template.ID,
      classes: this.userGlobalSettings
    };

    this.templateGlobalSettingService.postUserGlobalTemplate(data)
    .takeUntil(this.destroy$)
    .subscribe((response: any) => {
      swal({
        title: response.message,
        type: response.success ? 'success' : 'error'
      });
    });
  }

  toggleRowElementSetting(event) {
    let selectedElement = this.editor.getSelected();
    if (event.target.checked) {
      selectedElement.view.$el.addClass(event.target.value);
      return;
    } 
    selectedElement.view.$el.removeClass(event.target.value);
  }
  
  selectElementSettingOption(event, settingId, settingInfo) {
    let selectedElement = this.editor.getSelected();
    let elClassNames = Array.from(selectedElement.view.el.classList);
    let selectedDropdownOptions = settingInfo.settings_dropdown_options;
    /* Removal of option class value if exist in the selected element */
    selectedDropdownOptions.forEach((data, idx) => { /* selected dropdown option value */
      if (elClassNames.includes(data.class_name)) {
        selectedElement.view.$el.removeClass(data.class_name);
      }
    });
    if (event.target.value == '') {
      return;
    }
    /* If no class name on selected element class list, add this option value as class */
    if (!selectedElement.view.$el.hasClass(event.target_value)) {
      selectedElement.view.$el.addClass(event.target.value);
      return;
    }
  }

  /* After merge field process, validate if one of its property icon is set to empty then make it hidden */
  validatePropertyIconCount(html) {
    /* Gather all property icon with .dsgnly-property-icons [data-is-property-icon=true] selector and check each property total count with .property-count' */
    $(html).find('.dsgnly-property-icons [data-is-property-icon="true"]').each(function () {
      /* If a text is a symbol or alphabet it'll become NaN otherwise number */
      let propertyIconCount = parseInt($(this).find('.property-count').text());
      if (isNaN(propertyIconCount) || propertyIconCount == 0) {
        $(this).hide();
      }
    });

    let prprtyClass = $(html).find('.dsgnly-property-icons-email');
    if (prprtyClass.length != 0 && this.template.category_id == Categories.EMARKETING) {
      /* Check each icons with .property-icon-container */
      prprtyClass.find('.property-icon-container').each(function () {
        let propertyCount = parseInt($(this).find('.property-count').text());
        if (isNaN(propertyCount) || propertyCount == 0) {
          $(this).hide();
        }
      });
    }
  }

  onShareStaffMembers(selectedStaffMembers = []): void {
    const authUserAgentId = this.authUser.user.agent_id;

    if (selectedStaffMembers.length > 0) {
      let authUserFound = selectedStaffMembers.find(userId => userId.toString() === authUserAgentId.toString());
      if (!authUserFound) {
        selectedStaffMembers.push(authUserAgentId.toString());
      }
    }
    
    this.selectedStaffMembers = selectedStaffMembers;
    this.isShareStaffMembers = true;

    if (this.isShareStaffMembers) {
      this.savePopupShow = false;
        const _valid = this.saveHtml(this.editor);
        if (!_valid) {
          return;
        }

        let link = 'secure';

        if (this.authUser.brandid === '12' || this.authUser.brandid === '19') {
          link = 'rwg';
        }
        if (this.authUser.brandid === '13' || this.authUser.brandid === '15') {
          link = 'rhcompass';
        }

        if (this.authUser.provider === 'mydesktop') {
          if (this.template.category_id === 2) {
            swal({
              title: 'Send',
              type: 'info',
              html:
                // tslint:disable-next-line:max-line-length
                '<a onclick="onClickSend(\'' + link + "', " + "'mydesktop.com.au/cgi-bin/clients/agents/propsearch2.cgi'" + ')" class="btn btn-primary">Send</a>',
              showCancelButton: false,
              showConfirmButton: false,
              focusConfirm: false,
            });
          } else if (this.template.category_id === 3) {
            swal({
              title: 'Send',
              type: 'info',
              html:
                // tslint:disable-next-line:max-line-length
                '<a onclick="onClickSend(\'' + link + "', " + "'mydesktop.com.au/cgi-bin/clients/agents/version6/quickenquiry/managev2.cgi'" + ')" class="btn btn-primary">Send</a>',
              showCancelButton: false,
              showConfirmButton: false,
              focusConfirm: false,
            });
          } else {
            swal({
              title: 'Who do you want to send to?',
              type: 'info',
              html:
                // tslint:disable-next-line:max-line-length
                '<a onclick="onClickSend(\'' + link + "', " + "'mydesktop.com.au/cgi-bin/clients/agents/version6/addressbook/addressbook.cgi'" + ')" class="btn btn-primary">A single contact</a> &nbsp; <a onclick="onClickSend(\'' + link + "', " + "'mydesktop.com.au/cgi-bin/clients/agents/version6/marketreport4/manage.cgi'" + ')" class="btn btn-info">Multiple Contacts</a>',
              showCancelButton: false,
              showConfirmButton: false,
              focusConfirm: false,
            });
          }
        }

        if (this.authUser.provider === 'vaultre') {
          this.isSendToVault = true;
        }

        if (this.authUser.provider === 'designly') {
          this.isSendToDesignlyMail = true;
        }
    }

  }

  onShareWithEveryone(event: boolean): void {
    this.isShareToAllStaffMembers = !!event ? true : false;
    this.shareWithEveryone = !!event ? true : false;
  }

  onRemoveVaultTemplate(templateId: number, vaultId: number): void {
    this.templateService.httpDeleteVaultTemplateById(templateId, vaultId);
    this.loading = true;
  }

  private httpSendService(accessByIds = []): void {
    this.loading = true;
    this.vaultService.httpSendTemplate({
      title: this.template.title,
      subject: this.template.title,
      content: '',
      propertyTemplate: false,
      template_id: this.template.ID,
      accessBy: accessByIds
    });
  }

  private httpGetVaultTemplate(id: number): void {
    this.templateService.httpGetVaultTemplateById(id);
    this.loading = true;
  }

  private createAccessByIds(numbers: number[]): { id: number }[] {
    if(numbers.length === 0) {
      let tempArray = [];
      let accessIdObject = {
        "id": this.authUser.user.agent_id
      };
      tempArray.push(accessIdObject);
      return tempArray;
    }
    else {
      return numbers.map((num) => ({ id: num }));
    }
    
  }

  private defaultUserForSendVre(authUserAgentId): void {
    this.selectedStaffMembers = [];
    this.vaultShareUsers.forEach(users => {
      let isUserSelected = users.agent_id === authUserAgentId.toString();

      if (isUserSelected) {
        users.isSelected = true;
        this.selectedStaffMembers.push(users.agent_id);
      }
    });
  }

  closeSideBarSettings (): void {
    $('.container-element-setting').hide('slide', {direction: 'left'}, this.slideHideSpeed);
    $('.container-global').hide('slide', {direction: 'left'}, this.slideHideSpeed);
    $('.container-element-property-icons').hide('slide', {direction: 'left'}, this.slideHideSpeed);
  }

  pullFilingCabinetFolders(): void {
    this.vaultService.httpGetFilingCabinetFolders().takeUntil(this.destroy$)
    .subscribe((response: any) => {
      this.filingCabinetFolders = response.data.items;
    });
  }

  async onSubmitUploadFileCabinate(){
    this.loading = true;
    let formValues = this.uploadCabinateForm.value;

    if(formValues.resolutionFilter == 'for-print'){
      this.isHighResPdf = true;
    } else {
      this.isHighResPdf = false;
    }

    $('#uploadHugConnect').modal('hide');

    // Create template save pdf format builder query.
    const buildQuery = this.saveHtml(this.editor, 'pdf', true, false, true);

    if(buildQuery){
      // promise on http call will return data in sync manner even there is underlined error, conditionally handled the subscribe method in calling function.
      const pdfBuild: any = await this.templateService.httpBuildTemplateForCabinateUpload(buildQuery['id'], buildQuery['templateValues']).toPromise();

      if(pdfBuild.additional_data.pdf_path){
        this.pdfUploadPath =  this.frontService.appConfig.CACHED_S3_BUCKET_URL + pdfBuild.additional_data.pdf_path;

        // Uploading generated pdf file to Cabinate using new endpoint.
        this.vaultService.httpPushFileToCabinetFolders({
          id: this.template.ID,
          title: formValues.title,
          folder: formValues.folder,
          hug_connect_data : this.template.hug_connect_data,
          pdfUploadPath : this.pdfUploadPath
        });

      } else {
        this.loading = false;
        swal({
          title: 'Something went wrong',
          text: 'Unable to generate the PDF',
          showConfirmButton: false,
          allowEscapeKey: false,
          allowOutsideClick: false,
          type: 'error',
        });
      }
    } else {
      this.loading = false;
      swal({
        title: 'Something went wrong',
        text: 'Unable to generate the PDF',
        showConfirmButton: false,
        allowEscapeKey: false,
        allowOutsideClick: false,
        type: 'error',
      });
    }
  }
}


