import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs/Subscription';
import { RowGlobalSetting, GlobalTemplate, GroupedGlobalSetting, SettingGroup } from '../../../../models/global-template.model';
import { ActivatedRoute, Router } from '@angular/router';
import { MasterService } from '../../../../services/master.service';
import { Subject } from 'rxjs/Subject';
import { Master } from '../../../../models/master.model';
import { TemplateGlobalSettingService } from '../../../../services/template-global-setting.service';
import { TemplateSettingGroupService } from '../../../../services/template-setting-group.service';
import swal from 'sweetalert2';
import { Observable } from 'rxjs';
declare var $: any;

@Component({
  selector: 'app-global-template',
  templateUrl: './global-template.component.html',
  styleUrls: ['./global-template.component.scss']
})
export class GlobalTemplateComponent implements OnInit {
  private destroy$: Subject<boolean> = new Subject<boolean>();
  subscriptionGlobalTemplate: Subscription;
  subscriptionTemplateGroup: Subscription;
  master: Master;
  settingGroupList: SettingGroup;
  globalTemplate: GlobalTemplate;
  globalTemplateForm: FormGroup;
  newSettingGroupForm: FormGroup;
  newSettingForm: FormGroup;
  masterTemplateList: any;

  masterId: number;
  loading: boolean = true;

  backUrl: string;
  templateType = 'master';

  @ViewChild('modalAddRowSetting', { static: true }) modalAddRowSetting: ElementRef;
  @ViewChild('modalCloseAddRowSetting', { static: true }) modalCloseAddRowSetting: ElementRef;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private masterService: MasterService,
    private templateGlobalSettingService: TemplateGlobalSettingService,
    private templateSettingGroupService: TemplateSettingGroupService
  ) {
    
  }

  ngOnInit() {
    this.masterId = this.route.snapshot.params['id'];
    this.templateSettingGroupService.getList(this.templateType, this.masterId);
    this.subscriptionTemplateGroup = this.templateSettingGroupService.templateGroupChanged
    .takeUntil(this.destroy$)
    .subscribe((response: any) => {
      this.settingGroupList = response;
    });

    this.masterService.httpGetMasterNew(this.masterId.toString());

    this.masterService.onGetMaster
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (response) {
          this.loading = false;
          if (typeof response.status !== 'undefined') {
            if (response.status === 'success') {
              this.master = response.data;
            }

            if (response.status === 'error') {
              swal('Error getting master', 'Contact Dev Team', 'error');
              this.router.navigateByUrl('/admin/templates/masters/enewsletter');
            }
          } else {
            swal('Error getting master', 'Contact Dev Team', 'error');
              this.router.navigateByUrl('/admin/templates/masters/enewsletter');
          }
        }
      }
    );

    this.templateGlobalSettingService.getGlobalTemplate(this.masterId);

    this.initForm();
    this.initGlobalTemplateEdit();
  }

  /* Initialize form that need to use */
  private initForm() {
    this.globalTemplateForm = this.fb.group({
      master_id: [this.masterId, Validators.required],
      data: this.fb.array([
        this.fb.group({
          group_name: '',
          group_id: '',
          items: this.fb.array([
            this.fb.group({
              id: [''],
              title: ['', Validators.required],
              description: [''],
              settings_type: ['', Validators.required],
              class_name: ['', Validators.required],
              master_id: [this.masterId, Validators.required],
              group_id: [''],
              options: this.fb.array([])
            })
          ])
        })
      ])
    });

    this.newSettingGroupForm = this.fb.group({
      template_type: 'master',
      id: this.masterId,
      groups: this.fb.array([
        this.fb.group({
          id: [null],
          group_name: ['', Validators.required],
          master_template_id: this.masterId
        })
      ])
    });

    this.newSettingForm = this.fb.group({
      setting_group: [''],
      setting_type: ['', Validators.required],
      setting_title: ['', Validators.required],
      setting_description: [''],
      setting_class_name: ['']
    });
  }

  /* Insert data for template setting form from database */
  private initGlobalTemplateEdit() {
    this.loading = true;
    let arr = this.globalTemplateForm.get('data') as FormArray;
    this.templateGlobalSettingService.globalTemplateChanged.takeUntil(this.destroy$).subscribe(
      (response: GlobalTemplate) => {
        if (response.data.length > 0) {
          (<FormArray>this.globalTemplateForm.get('data')).clear();
          response.data.forEach((obj: RowGlobalSetting & GroupedGlobalSetting) => {
            if (obj.group_id != null) {
              let groupIdx = arr.value.findIndex(group => group.group_id == obj.group_id);
              if (groupIdx) {
                //create new row setting as group
                arr.push(this.fb.group({
                  group_name: obj.group_name,
                  group_id: obj.group_id,
                  items: this.fb.array([])
                }));
              }
              groupIdx = arr.value.findIndex(group => group.group_id == obj.group_id);
              let tempItems = this.globalTemplateForm.get(`data.${groupIdx}.items`) as FormArray;
              obj.data.forEach((setting) => {
                //get options
                let opt = this.fb.array([]);
                if (setting.settings_dropdown_options) {
                  setting.settings_dropdown_options.forEach(element => {
                    opt.push(this.fb.group({
                      id: element.id,
                      title: [element.title, Validators.required],
                      description: element.description,
                      class_name: [element.class_name, Validators.required]
                    }));
                  });
                }
                
                tempItems.push(this.fb.group({
                  id: setting.id,
                  title: [setting.title, Validators.required],
                  settings_type: [setting.settings_type, Validators.required],
                  description: setting.description,
                  class_name: [setting.class_name, (setting.settings_type == 1 ? Validators.required : null)],
                  group_id: setting.group_id,
                  master_id: setting.master_id,
                  options: opt
                }));
              });
            } else {
              //this only use when the dropdown doesn't have a options list
              let options = this.fb.array([]);
              if (obj.settings_type == 0 && obj.settings_dropdown_options.length == 0) {
                options = this.fb.array([
                  this.fb.group({
                    id: '',
                    title: ['', Validators.required],
                    description: '',
                    class_name: ['', Validators.required],
                    global_template_settings_id: obj.id
                  })
                ]);
              }
              
              //add option info when has global_template_settings_dropdown data
              if (obj.settings_dropdown_options) {
                obj.settings_dropdown_options.forEach((option) => {
                  options.push(this.fb.group({
                    id: option.id,
                    title: [option.title, Validators.required],
                    description: option.description,
                    class_name: [option.class_name, Validators.required],
                    global_template_settings_id: option.global_template_settings_id
                  }));
                });
              }

              arr.push(this.fb.group({
                group_name: '',
                group_id: '',
                items: this.fb.array([
                  this.fb.group({
                    id: obj.id,
                    title: [obj.title, Validators.required],
                    description: obj.description,
                    settings_type: obj.settings_type,
                    class_name: [obj.class_name, (obj.settings_type == 1 ? Validators.required : null)],
                    master_id: obj.master_id,
                    group_id: null,
                    options: options
                  })
                ])
              }));
            }
          });
        }
        this.loading = false;
      }
    )
  }

  // Adding of setting dynamically
  settingsData(): FormArray {
    return this.globalTemplateForm.get('data') as FormArray;
  }

  /* Get info of non-group or grouped setting item */
  settingsItems(parent): FormArray {
    return this.settingsData().at(parent).get('items') as FormArray;
  }

  /* Get settings option of chosen grouped setting item or non-group setting */
  settingsOptions(parent, index): FormArray {
    return (<FormArray>(<FormArray>this.globalTemplateForm.get('data')).at(parent).get('items')).at(index).get('options') as FormArray
  }

  settingGroupName(index) {
    return (<FormArray>this.globalTemplateForm.get('data')).at(index).get('group_name');
  }

  /* Adding of pre setting */
  insertNewSetting() {
    if (this.newSettingForm.invalid) {
      swal({
        title: 'Invalid inputs',
        text: 'Please fill up all required fields (*)',
        type: 'error'
      });
      return;
    }
    let temp = this.globalTemplateForm.get('data') as FormArray;
    let groupIdx: number;
    if (this.newSettingForm.value.setting_group != '') {
      groupIdx = this.settingGroupList.data.findIndex(obj => obj.id == this.newSettingForm.value.setting_group);
      //adding of new setting for non or existing group
      let g_idx = (<FormArray>this.globalTemplateForm.get('data')).value.findIndex(objgrp => objgrp.group_id == this.newSettingForm.value.setting_group);
      if (g_idx < 0) { //create new group
        let opt = this.fb.array([]);
        if (this.newSettingForm.value.setting_type == 0 && this.newSettingForm.value.setting_type != "") {
          opt.push(this.fb.group({
            id: '',
            title: ['', Validators.required],
            description: '',
            class_name: ['', Validators.required],
            global_template_settings_id: ''
          }));
        }
        temp.push(this.fb.group({
          group_name: (this.newSettingForm.value.setting_group) ? this.settingGroupList.data[groupIdx].group_name : '',
          group_id: this.newSettingForm.value.setting_group,
          items: this.fb.array([
            this.fb.group({
              id: null,
              title: [this.newSettingForm.value.setting_title, Validators.required],
              description: [this.newSettingForm.value.setting_description],
              settings_type: [this.newSettingForm.value.setting_type, Validators.required],
              class_name: new FormControl({value: this.newSettingForm.value.setting_class_name, disabled: (this.newSettingForm.value.setting_type == 0 ? true : false)}),
              master_id: [this.masterId, Validators.required],
              group_id: [this.newSettingForm.value.setting_group, Validators.required],
              options: opt
            })
          ])
        }));
      } else {
        //insert into to existing group
        let tempItems = this.globalTemplateForm.get(`data.${g_idx}.items`) as FormArray;
        let opt = this.fb.array([]);
        if (this.newSettingForm.value.setting_type == 0) {
          opt.push(this.fb.group({
            id: '',
            title: ['', Validators.required],
            description: '',
            class_name: ['', Validators.required],
            global_template_settings_id: ''
          }));
        }

        tempItems.push(this.fb.group({
          id: null,
          title: [this.newSettingForm.value.setting_title, Validators.required],
          description: [this.newSettingForm.value.setting_description],
          settings_type: [this.newSettingForm.value.setting_type, Validators.required],
          class_name: new FormControl({value: this.newSettingForm.value.setting_class_name, disabled: (this.newSettingForm.value.setting_type == 0 ? true : false)}),
          master_id: [this.masterId, Validators.required],
          group_id: [this.newSettingForm.value.setting_group, Validators.required],
          options: opt
        }));
      }
    } else { //no group row setting
      let opt = this.fb.array([]);

      if (this.newSettingForm.value.setting_type == 0) {
        opt.push(this.fb.group({
          id: '',
          title: ['', Validators.required],
          description: '',
          class_name: ['', Validators.required],
          global_template_settings_id: ''
        }))
      }
      temp.push(this.fb.group({
        group_name: '',
        group_id: '',
        items: this.fb.array([
          this.fb.group({
            id: null,
            title: [this.newSettingForm.value.setting_title, Validators.required],
            description: [this.newSettingForm.value.setting_description],
            settings_type: [this.newSettingForm.value.setting_type, Validators.required],
            class_name: new FormControl({value: this.newSettingForm.value.setting_class_name, disabled: (this.newSettingForm.value.setting_type == 0 ? true : false)}),
            master_id: [this.masterId, Validators.required],
            group_id: [''],
            options: opt
          })
        ])
      }));
    }

    this.modalActionEvent('add_setting', 'hide');
    //reset new Setting form
    this.newSettingForm.reset();
  }

  /* Removal of item from Grouped Setting */
  removeItemFromGroupedSetting(parentIdx, index) {
    (<FormArray>this.globalTemplateForm.get(`data.${parentIdx}.items`)).removeAt(index);
  }

  /* Add item in to existing Group settings */
  addGroupSettingItem(parentIdx, index, groupId, dir) {
    let data = this.globalTemplateForm.get(`data.${parentIdx}.items`) as FormArray;
    data.insert((dir == 0) ? (index == 0 ? 0 : index) : index+1, this.fb.group({
      id: null,
        master_id: this.masterId,
        title: ['', Validators.required],
        description: '',
        class_name: ['', Validators.required],
        settings_type: ['', Validators.required],
        group_id: groupId,
        options: this.fb.array([])
    }));
  }

  /* Adding of row setting */
  addNewRowTemplateSetting(index, dir) {
    let data = this.globalTemplateForm.get('data') as FormArray;
    data.insert((dir == 0) ? (index == 0 ? 0 : index) : index+1, this.fb.group({
      group_name: '',
      group_id: '',
      items: this.fb.array([this.fb.group({
        id: null,
        master_id: this.masterId,
        title: ['', Validators.required],
        description: '',
        class_name: ['', Validators.required],
        settings_type: ['', Validators.required],
        group_id: '',
        options: this.fb.array([])
      })])
    }));
  }

  /* Removing of row setting */
  removeRowTemplateSetting(index: number) {
    (<FormArray>this.globalTemplateForm.get('data')).removeAt(index);
  }

  /* to determine if the delete button should be shown */
  hasValidIdInItems(data_index: number): boolean {
    const items = this.settingsItems(data_index).controls;
    // Check if any item's id is not null
    return items.some(item => item.value.id !== '' && item.value.id != null && item.value.id !== undefined);
  }
  /* Add dropdown option if value is 0 */
  onChangeSettingsType(controller, event) {
    let dropdownOptions = <FormArray>controller.get('options')
    let rowSettingClass = <FormGroup>controller.get('class_name');
    rowSettingClass.setValue(null);
    rowSettingClass.disable();
    if (event.target.value == 0) { //add options
      dropdownOptions.push(this.fb.group({
        id: '',
        title: ['', Validators.required],
        description: '',
        class_name: ['', Validators.required],
        global_template_settings_id: controller.value.id
      }));
    } else {
      dropdownOptions.clear();
      rowSettingClass.updateValueAndValidity();
      rowSettingClass.enable();
    }
  }

  /* Add dropdown options dynamically */
  addSettingsOptions(parent, itemIdx, optionIdx, settingId) {
    let optionList = this.globalTemplateForm.get(`data.${parent}.items.${itemIdx}.options`) as FormArray;
    optionList.insert(optionIdx+1, this.fb.group({
      id: '',
      title: ['', Validators.required],
      description: '',
      class_name: ['', Validators.required],
      global_template_settings_id: settingId
    }));
  }

  /* Remove chosen option from dropdown settings */
  removeSettingsOption(parent, itemIdx, optIdx) {
    let temp = this.globalTemplateForm.get(`data.${parent}.items.${itemIdx}.options`) as FormArray;
    temp.removeAt(optIdx);
  }

  /* Submission of Template setting form */
  storeGlobalTemplateSetting () {
    if (this.globalTemplateForm.invalid) {
      swal({
        title: "Invalid request",
        text: "Some fields need to be filled",
        type: "error"
      });
      return;
    }
    this.loading = true;
    let settingsData = {master_id: this.masterId, data: []};
    this.globalTemplateForm.value.data.forEach((obj, index) => {
      obj.items.forEach((item, index) => {
        settingsData.data.push({
          id: item.id,
          title: item.title,
          description: item.description,
          settings_type: item.settings_type,
          class_name: item.class_name,
          master_id: this.masterId,
          group_id: item.group_id,
          options: (item.options != null ? item.options : []) 
        });
      });
    });

    this.templateGlobalSettingService.postGlobalTemplate(settingsData)
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        swal({
          title: response.message,
          type: (response.success) ? "success" : "error",
          confirmButtonText: "Close"
        });
        this.templateGlobalSettingService.getGlobalTemplate(this.masterId);
        this.loading = false;
      },
      (httpError) => {
        swal({
          title: 'Invalid request data',
          text: 'Please check the input fields',
          type: 'error'
        });
        this.loading = false;
      }
    );
  }

  /* Move of global template row setting */
  moveRowSetting(index, dir) {
    let data = this.globalTemplateForm.get('data') as FormArray;
    let directionIdx = (dir == 0) ? index-1 : index+1;
    const currentData = data.controls[(index)];
    const directionData = data.controls[(directionIdx)];
    data.controls[(index)] = directionData;
    data.controls[(directionIdx)] = currentData;
    data.controls[(index)].patchValue(directionData);
    data.controls[(directionIdx)].patchValue(currentData);  
  }
  //adding of setting group

  /* Get list of Setting group */
  getNewSettingGroup() {
    return this.newSettingGroupForm.get('groups') as FormArray;
  }

  /* Dynamic adding of group items */
  addTemplateGroupFields(): void {
    this.getNewSettingGroup().push(this.fb.group({
      id: null,
      group_name: ['', Validators.required],
      master_template_id: this.masterId
    }));
  }

  /* Remove dynamic new group */
  removeNewGroup(index) {
    this.getNewSettingGroup().removeAt(index);
  }

  /* Edit and Submit template group */
  updateTemplateGroup(data: any) {
    const templateMasterId = this.masterId;
    swal({
      title: `ID# ${data.id} - ${data.group_name}`,
      html: `<input id="swal-input-group-name" class="swal2-input" value="${data.group_name}">`,
      allowOutsideClick: false,
      confirmButtonText: "Update",
      showCancelButton: true,
      preConfirm: () => {
        return new Promise( (resolve) => {
          resolve({
            id: data.id,
            template_id: templateMasterId,
            type: 'master',
            group_name: $('#swal-input-group-name').val()
          })
        })
      }
    }).then((result) => {
      this.templateSettingGroupService.updateGroupInfo(result)
      .takeUntil(this.destroy$)
      .subscribe((response: any) => {
        swal({
          title: response.message,
          type: (response.success) ? 'success' : 'error'
        });
        this.templateSettingGroupService.getList(this.templateType, this.masterId);
      }, (HttpError: any) => {
        swal({
          title: 'Unable to proceed the request',
          text: HttpError.error.message,
          type: 'error'
        })
      });
    }).catch(swal.noop)
  }

  /* Deletion of master template group row data */
  deleteGroupData(id: number) {
    swal({
      title: 'Are you sure you want to delete?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes!'
    }).then((isConfirmed) => {
      if (isConfirmed) {
        this.templateSettingGroupService.deleteGroupRow('master', id)
        .takeUntil(this.destroy$)
        .subscribe((response: any) => {
          swal({
            title: response.success ? "Deleted" : "Unable to delete",
            text: !response.success ? "Some setting using this group" : "",
            type: response.success ? "success" : "error"
          });

          if (response.success) {
            this.templateSettingGroupService.getList(this.templateType, this.masterId);
          }
        });
      }
    });
  }

  /* Changing of settings type of pre setting */
  onChangeNewSettingTypeForm(value) {
    let settingClass = this.newSettingForm.get('setting_class_name');
    if (value == 0) {
      settingClass.disable();
    } else {
      settingClass.enable();
    }
    settingClass.setValue('');
  }

  moveGroupedSettingItem(parentIdx, currentIdx, dir) {
    let data = this.globalTemplateForm.get(`data.${parentIdx}.items`) as FormArray;
    let directionIdx = (dir == 0) ? currentIdx-1 : currentIdx+1;
    const currentData = data.controls[(currentIdx)];
    const directionData = data.controls[(directionIdx)];
    data.controls[(currentIdx)] = directionData;
    data.controls[(directionIdx)] = currentData;
    data.controls[(currentIdx)].patchValue(directionData);
    data.controls[(directionIdx)].patchValue(currentData);
  }
  //end of adding setting group

  modalActionEvent(modalType: string, action: string) {
    let modal = '';

    if (modalType == "add_setting") {
      modal = this.modalAddRowSetting.nativeElement;
    }

    if (modal && action) {
      $(modal).modal(action);
    }
  }

  /* Open create form and submission for new template group */
  createNewFormGroup() {
    const templateMasterId = this.masterId;
    swal({
      title: "New Template Group",
      html: `<input id="swal-input-new-group-name" class="swal2-input" placeholder="Insert group name here">`,
      confirmButtonText: "Create",
      allowOutsideClick: false,
      showCancelButton: true,
      preConfirm: () => {
        return new Promise( (resolve) => {
          resolve({
            id: null,
            master_template_id: templateMasterId,
            type: 'master',
            group_name: $('#swal-input-new-group-name').val()
          })
        })
      }
    }).then((result) => {
      let newGroupData = {
        template_type: 'master',
        id: this.masterId,
        groups: [result]
      };
      this.templateSettingGroupService.storeGroupsInfo(newGroupData)
      .takeUntil(this.destroy$)
      .subscribe((response: any) => {
        swal({
          title: response.message,
          type: (response.success) ? 'success' : 'error'
        });
        this.templateSettingGroupService.getList(this.templateType, this.masterId);
      }, (HttpError: any) => {
        swal({
          title: 'Unable to proceed the request',
          type: 'error'
        })
      });
    }).catch(swal.noop);
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
