<div class="text-start">
    <ng-container *ngIf="selectedSettingType === 'office-details'">
        <h2 class="text-capitalize mt-0 mb-3">
           Account Details
        </h2>
        <p>
            Your account details are synced from VaultRE/EA on each sign in, after making any changes make sure you lock the field to stop syncing.
        </p>
    </ng-container>
    <ng-container *ngIf="selectedSettingType === 'my-details'">
        <h2 class="text-capitalize mt-0 mb-3">
            My Details
        </h2>
        <p>
            Your account details are synced from VaultRE/EA on each sign in, after making any changes make sure you lock the field to stop syncing.
        </p>
    </ng-container>
    <ng-container *ngIf="selectedSettingType === 'manage-users'">
        <h2 class="text-capitalize mt-0 mb-3">
            Manage Users
        </h2>
        <p>
            Give all your account users administrative privilages, this allows them to view all users templates and designs as well as create designs on behalf of other users.
        </p>
    </ng-container>
    <ng-container *ngIf="selectedSettingType === 'manage-designs'">
        <h2 class="text-capitalize mt-0 mb-3">
            Manage Designs
        </h2>
    </ng-container>
    <ng-container *ngIf="selectedSettingType === 'integrations'">
        <h2 class="text-capitalize mt-0 mb-3">
            My Integrations
        </h2>
    </ng-container>
    <ng-container *ngIf="selectedSettingType === 'office-integrations'">
        <h2 class="text-capitalize mt-0 mb-3">
            Office Integrations
        </h2>
    </ng-container>
    <ng-container *ngIf="selectedSettingType === 'subscription'">
        <h2 class="text-capitalize mt-0 mb-3">
            Subscription
        </h2>
    </ng-container>
    <ng-container *ngIf="selectedSettingType === 'billing'">
        <h2 class="text-capitalize mt-0 mb-3">
            Billing
        </h2>
    </ng-container>
    <ng-container *ngIf="selectedSettingType === 'email-preferences'">
        <h2 class="text-capitalize mt-0 mb-3">
            Email preferences
        </h2>
    </ng-container>
</div>

<!-- <a (click)="onFeedback()" id="my_feedback" class="btn-feedback">Send Feedback</a> -->
<div class="row">
    <div class="col-lg-12">
        <div *ngIf="client?.allow_agent_settings === 0 && !authUser.is_admin && !authUser.is_group_admin">
            <h3>You do not have permission to view settings.</h3>
            <p>The requested page requires proper authorization to access.</p>
            <p>We suggest to contact your administrator for help or report the issue via the <a
                    href="/help/support"> support portal</a>.</p>
        </div>
        <ng-container *ngIf="client?.allow_agent_settings === 1 || authUser.is_admin || authUser.is_group_admin">
            <div [hidden]="!isLinksSection(selectedSettingType)"
                *ngIf="isGroupVisible(client.group.setting_menu_link)"
                [ngClass]="{'active': defaultTab === 'link'}" class="tab-pane" id="links" role="tabpanel">
                <form [formGroup]="linksForm" class="form-horizontal">
                    <div [hidden]="selectedSettingType !== 'website-links'">
                        <div class="d-flex form-group">
                            <label for="form_website" class="col-sm-1">Website URL</label>
                            <div class="col-sm-5">
                                <input type="url" formControlName="link_website"
                                    [ngClass]="{ 'invalid-url': url.link_website.status === 'INVALID' }"
                                    class="form-control" id="form_website" autocomplete="off"
                                    value="{{ client?.link_website }}" />
                            </div>
                        </div>
                        <div class="d-flex form-group">
                            <label for="form_short_website" class="col-sm-1">Short Website URL</label>
                            <div class="col-sm-5">
                                <input type="text" formControlName="link_short_website"
                                    [ngClass]="{ 'invalid-url': url.link_short_website.status === 'INVALID' }"
                                    class="form-control" id="form_short_website" autocomplete="off"
                                    value="{{ client?.link_short_website }}" />
                                <span class="shortWebsite">The short website URL field is used to display your
                                    website address in an easier to read format, for example instead of
                                    http://www.mywebsite.com you can use mywebsite.com</span>
                            </div>
                        </div>
                        <div [class.hidden]="authUser?.provider === 'idashboard'">
                            <div class="d-flex form-group">
                                <label for="form_buy" class="col-sm-1">Buy</label>
                                <div class="col-sm-5">
                                    <input type="text" formControlName="link_buy"
                                        [ngClass]="{ 'invalid-url': url.link_buy.status === 'INVALID' }"
                                        class="form-control" id="form_buy" autocomplete="off"
                                        value="{{ client?.link_buy }}" />
                                </div>
                            </div>
                            <div class="d-flex form-group">
                                <label for="form_sell" class="col-sm-1">Sell</label>
                                <div class="col-sm-5">
                                    <input type="text" formControlName="link_sell"
                                        [ngClass]="{ 'invalid-url': url.link_sell.status === 'INVALID' }"
                                        class="form-control" id="form_sell" autocomplete="off"
                                        value="{{ client?.link_sell }}" />
                                </div>
                            </div>
                            <div class="d-flex form-group">
                                <label for="form_rent" class="col-sm-1">Rent</label>
                                <div class="col-sm-5">
                                    <input type="text" formControlName="link_rent"
                                        [ngClass]="{ 'invalid-url': url.link_rent.status === 'INVALID' }"
                                        class="form-control" id="form_rent" autocomplete="off"
                                        value="{{ client?.link_rent }}" />
                                </div>
                            </div>
                            <div class="d-flex form-group">
                                <label for="form_home" class="col-sm-1">Home</label>
                                <div class="col-sm-5">
                                    <input type="text" formControlName="link_home"
                                        [ngClass]="{ 'invalid-url': url.link_home.status === 'INVALID' }"
                                        class="form-control" id="form_home" autocomplete="off"
                                        value="{{ client?.link_home }}" />
                                </div>
                            </div>
                            <div class="d-flex form-group">
                                <label for="form_about" class="col-sm-1">About</label>
                                <div class="col-sm-5">
                                    <input type="text" formControlName="link_about"
                                        [ngClass]="{ 'invalid-url': url.link_about.status === 'INVALID' }"
                                        class="form-control" id="form_about" autocomplete="off"
                                        value="{{ client?.link_about }}" />
                                </div>
                            </div>
                            <div class="d-flex form-group">
                                <label for="form_careers" class="col-sm-1">Careers</label>
                                <div class="col-sm-5">
                                    <input type="text" formControlName="link_careers"
                                        [ngClass]="{ 'invalid-url': url.link_careers.status === 'INVALID' }"
                                        class="form-control" id="form_careers" autocomplete="off"
                                        value="{{ client?.link_careers }}" />
                                </div>
                            </div>
                            <div class="d-flex form-group">
                                <label for="form_blog" class="col-sm-1">Blog</label>
                                <div class="col-sm-5">
                                    <input type="text" formControlName="link_blog"
                                        [ngClass]="{ 'invalid-url': url.link_blog.status === 'INVALID' }"
                                        class="form-control" id="form_blog" autocomplete="off"
                                        value="{{ client?.link_blog }}" />
                                </div>
                            </div>
                            <div class="d-flex form-group">
                                <label for="form_contact" class="col-sm-1">Contact</label>
                                <div class="col-sm-5">
                                    <input type="text" formControlName="link_contact"
                                        [ngClass]="{ 'invalid-url': url.link_contact.status === 'INVALID' }"
                                        class="form-control" id="form_contact" autocomplete="off"
                                        value="{{ client?.link_contact }}" />
                                </div>
                            </div>
                            <div class="d-flex form-group">
                                <label for="form_contact" class="col-sm-1">Testimonials</label>
                                <div class="col-sm-5">
                                    <input type="text" formControlName="link_testimonials"
                                        [ngClass]="{ 'invalid-url': url.link_testimonials.status === 'INVALID' }"
                                        class="form-control" id="form_contact" autocomplete="off"
                                        value="{{ client?.link_testimonials }}" />
                                </div>
                            </div>
                            <hr />
                            <div class="d-flex form-group">
                                <label class="col-sm-1">&nbsp;</label>
                                <div class="col-sm-10">
                                    <button type="submit" class="btn-admin btn-admin-primary"
                                        (click)="saveLinks()">Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div [hidden]="selectedSettingType !== 'social-links'">
                        <div class="d-flex form-group">
                            <label for="form_fb" class="col-sm-1">Facebook</label>
                            <div class="col-sm-5">
                                <input type="text" formControlName="link_facebook"
                                    [ngClass]="{ 'invalid-url': url.link_facebook.status === 'INVALID' }"
                                    class="form-control" id="form_fb" autocomplete="off"
                                    value="{{ client?.link_facebook }}" />
                            </div>
                        </div>
                        <div class="d-flex form-group">
                            <label for="form_tw" class="col-sm-1">Twitter</label>
                            <div class="col-sm-5">
                                <input type="text" formControlName="link_twitter"
                                    [ngClass]="{ 'invalid-url': url.link_twitter.status === 'INVALID' }"
                                    class="form-control" id="form_tw" autocomplete="off"
                                    value="{{ client?.link_twitter }}" />
                            </div>
                        </div>
                        <div class="d-flex form-group">
                            <label for="form_yt" class="col-sm-1">Youtube</label>
                            <div class="col-sm-5">
                                <input type="text" formControlName="link_youtube"
                                    [ngClass]="{ 'invalid-url': url.link_youtube.status === 'INVALID' }"
                                    class="form-control" id="form_yt" autocomplete="off"
                                    value="{{ client?.link_youtube }}" />
                            </div>
                        </div>
                        <div class="d-flex form-group">
                            <label for="form_ig" class="col-sm-1">Instagram</label>
                            <div class="col-sm-5">
                                <input type="text" formControlName="link_instagram"
                                    [ngClass]="{ 'invalid-url': url.link_instagram.status === 'INVALID' }"
                                    class="form-control" id="form_ig" autocomplete="off"
                                    value="{{ client?.link_instagram }}" />
                            </div>
                        </div>
                        <div class="d-flex form-group">
                            <label for="form_ig" class="col-sm-1">LinkedIn</label>
                            <div class="col-sm-5">
                                <input type="text" formControlName="link_linkedin"
                                    [ngClass]="{ 'invalid-url': url.link_linkedin.status === 'INVALID' }"
                                    class="form-control" id="form_linkedin" autocomplete="off"
                                    value="{{ client?.link_linkedin }}" />
                            </div>
                        </div>
                        <div class="d-flex form-group">
                            <label for="form_ig" class="col-sm-1">Pinterest</label>
                            <div class="col-sm-5">
                                <input type="text" formControlName="link_pinterest"
                                    [ngClass]="{ 'invalid-url': url.link_pinterest.status === 'INVALID' }"
                                    class="form-control" id="form_pinterest" autocomplete="off"
                                    value="{{ client?.link_pinterest }}" />
                            </div>
                        </div>
                        <div class="d-flex form-group" [class.hidden]="frontService.authService.isUk()">
                            <label for="form_ig" class="col-sm-1">Rate My Agent</label>
                            <div class="col-sm-5">
                                <input type="text" formControlName="link_ratemyagent"
                                    [ngClass]="{ 'invalid-url': url.link_ratemyagent.status === 'INVALID' }"
                                    class="form-control" id="form_ratemyagent" autocomplete="off"
                                    value="{{ client?.link_ratemyagent }}" />
                            </div>
                        </div>
                        <div class="d-flex form-group">
                            <label for="form_ig" class="col-sm-1">Google Reviews</label>
                            <div class="col-sm-5">
                                <input type="text" formControlName="link_googlereviews"
                                    [ngClass]="{ 'invalid-url': url.link_googlereviews.status === 'INVALID' }"
                                    class="form-control" id="form_googlereviews" autocomplete="off"
                                    value="{{ client?.link_googlereviews }}" />
                            </div>
                        </div>
                        <hr />
                        <div class="d-flex form-group">
                            <label class="col-sm-1">&nbsp;</label>
                            <div class="col-sm-10">
                                <button type="submit" class="btn-admin btn-admin-primary"
                                    (click)="saveSocialLinks()">Save</button>
                            </div>
                        </div>
                    </div>
                    <div [hidden]="selectedSettingType !== 'disclaimers'">
                        <h3>Disclaimer</h3>
                        <div class="d-flex form-group" [class.hidden]="authUser?.provider === 'idashboard'">
                            <label for="form_ig" class="col-sm-1">Email</label>
                            <div class="col-sm-5">
                                <textarea rows="5" formControlName="disclaimer_email" class="form-control"
                                    id="form_disclaimer_email"
                                    autocomplete="off">{{ client?.disclaimer_email }}</textarea>
                            </div>
                        </div>
                        <div class="d-flex form-group">
                            <label for="form_ig" class="col-sm-1">Print</label>
                            <div class="col-sm-5">
                                <textarea rows="5" formControlName="disclaimer_print" class="form-control"
                                    id="form_disclaimer_print"
                                    autocomplete="off">{{ client?.disclaimer_print }}</textarea>
                            </div>
                        </div>
                        <h3>Legal</h3>
                        <div class="d-flex form-group">
                            <label for="form_ig" class="col-sm-1">Legal 1</label>
                            <div class="col-sm-5">
                                <textarea rows="5" formControlName="legal_1" class="form-control"
                                    id="form_legal_1" autocomplete="off">{{ client?.legal_1 }}</textarea>
                            </div>
                        </div>
                        <div class="d-flex form-group">
                            <label for="form_ig" class="col-sm-1">Legal 2</label>
                            <div class="col-sm-5">
                                <textarea rows="5" formControlName="legal_2" class="form-control"
                                    id="form_legal_2" autocomplete="off">{{ client?.legal_2 }}</textarea>
                            </div>
                        </div>
                        <div class="d-flex form-group">
                            <label for="form_ig" class="col-sm-1">Legal 3</label>
                            <div class="col-sm-5">
                                <textarea rows="5" formControlName="legal_3" class="form-control"
                                    id="form_legal_1" autocomplete="off">{{ client?.legal_3 }}</textarea>
                            </div>
                        </div>
                        <hr />
                        <div class="d-flex form-group">
                            <label class="col-sm-1">&nbsp;</label>
                            <div class="col-sm-10">
                                <button type="submit" class="btn-admin btn-admin-primary"
                                    (click)="saveLinks()">Save</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>

            <div [hidden]="selectedSettingType !== 'manage-users'">
                <div class="card border-white mt-3">
                    <div class="card-body">
                        <div class="d-flex justify-content-between mb-3">
                            <div class="searchTable">
                                <input type="text" class="form-control agentInputSearch" placeholder="Search users name or ID" />
                            </div>
                            <div class="buttons">
                                <button class="btn btn-link me-1" (click)="onAdminPriveledge()">Give all users and administrative priveledges</button>
                                <button id="sync_agents" class="btn btn-primary btn-1" (click)="onSyncAgents()">Sync Users</button>
                            </div>
                        </div>
                        <form [formGroup]="agentForm" (ngSubmit)="onAgentSubmit()" class="form-horizontal manage-users" >
                            <table class="table table-agent-access" >
                                <thead>
                                <tr>
                                    <th data-priority="0">User</th>
                                    <th data-priority="1">Name</th>
                                    <th data-priority="3">Email</th>
                                    <th data-priority="2">Agent ID</th>
                                    <th data-priority="4">Access Level</th>
                                    <th data-priority="5">Access Changed By</th>
                                </tr>
                                </thead>
                                <tbody>
                                <!-- <tr *ngFor="let group of groups | stringFilter: filter : 'groups' | paginate: { itemsPerPage: 50, currentPage: page } "> -->
                                <tr *ngFor="let user of users" >
                                    <input type="hidden" value="{{ user.agent_id }}" name="agent_id[]">
                                    <td><span class="user-circle"><svg id="Icon" xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
                                        <g id="Icon_Profile" data-name="Icon/Profile">
                                            <rect id="Base" width="22" height="22" fill="none"/>
                                            <path id="Mask" d="M0,14V12.25c0-2.328,4.664-3.5,7-3.5s7,1.173,7,3.5V14ZM3.5,3.5A3.5,3.5,0,1,1,7,7,3.5,3.5,0,0,1,3.5,3.5Z" transform="translate(4 4)" fill="#cad1e1"/>
                                        </g>
                                        </svg>
                                        </span></td>
                                    <td>{{ user.firstname + " " + user.lastname }}</td>
                                    <td>{{ user.email }}</td>
                                    <td>{{ user.agent_id }} </td>
                                    <td>
                                        <select class="form-control form-select" name="user_access[]" [disabled]="!authUser.user.is_group_admin && user.is_group_admin">
                                            <option *ngIf="authUser.user.is_group_admin || user.is_group_admin" value="2" [selected]="user.is_group_admin && user.is_admin">Group Admin</option>
                                            <option value="1" [selected]="user.is_admin && !user.is_group_admin">Admin</option>
                                            <option value="0" [selected]="!user.is_admin && !user.is_group_admin">User</option>
                                        </select>
                                    </td>
                                    <td><span *ngIf="user?.enabled_access_level_user">
                                        {{ user.enabled_access_level_user.firstname }} {{ user.enabled_access_level_user.lastname }}
                                        </span>
                                        <span *ngIf="!user?.enabled_access_level_user">-</span><br><small style="color: #8DA3B0;"> {{ user?.accesslevel_updated_date && user.accesslevel_updated_date !== '0000-00-00 00:00:00' 
                                            ? (user?.accesslevel_updated_date | date: 'dd/MM/yyyy') 
                                            : '' }}</small>
                                    </td> 
                                </tr>
                                </tbody>
                            </table>
                            <hr />
                            <div class="d-flex form-group">
                                <div class="col-sm-10">
                                    <button type="submit" class="btn-admin btn-admin-primary">Save</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div [hidden]="selectedSettingType !== 'office-details'">
                <form [formGroup]="agencyForm" (ngSubmit)="onAgencySubmit()" class="form-horizontal">
                    <div class="d-flex form-group">
                        <div class="col-sm-8">&nbsp;</div>
                    </div>
                    <div class="d-flex form-group">
                        <label for="franchise_name" class="col-sm-1">Franchise Name</label>
                        <div class="col-sm-5">
                            <input type="text" formControlName="company_name" class="form-control"
                                id="company_name" autocomplete="off" value="{{ client?.company_name }}" />
                        </div>
                        <div class="col-sm-3">
                            <div class="form-check form-switch">
                                <input class="form-check-input" type="checkbox" name="field[]" value="franchise_name" id="_field_franchise_name" checked="{{ isLocked('franchise_name') }}">
                                <label class="form-check-label" for="_field_franchise_name">Locked</label>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex form-group" [class.hidden]="authUser?.provider === 'idashboard'">
                        <label for="company_name" class="col-sm-1">Company Name</label>
                        <div class="col-sm-5">
                            <input type="text" formControlName="franchise_name" class="form-control"
                                id="franchise_name" autocomplete="off" value="{{ client?.franchise_name }}" />
                            
                        </div>
                        <div class="col-sm-3">
                            <div class="form-check form-switch">
                                <input class="form-check-input" type="checkbox" name="field[]" value="company_name" id="_field_company_name" checked="{{ isLocked('company_name') }}">
                                <label class="form-check-label" for="_field_company_name">Locked</label>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex form-group">
                        <label for="name" class="col-sm-1">Office Name</label>
                        <div class="col-sm-5">
                            <input type="text" formControlName="name" class="form-control" id="name"
                                autocomplete="off" value="{{ client?.name }}" />
                        </div>
                        <div class="col-sm-3">
                            <div class="form-check form-switch">
                                <input class="form-check-input" type="checkbox" name="field[]" value="name" id="_field_name" checked="{{ isLocked('name') }}">
                                <label class="form-check-label" for="_field_name">Locked</label>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex form-group" [class.hidden]="authUser?.provider === 'idashboard'">
                        <label for="branch_name" class="col-sm-1">Branch Name</label>
                        <div class="col-sm-5">
                            <input type="text" formControlName="branch_name" class="form-control"
                                id="branch_name" autocomplete="off" value="{{ client?.branch_name }}" />
                        </div>
                        <div class="col-sm-3">
                            <div class="form-check form-switch">
                                <input class="form-check-input" type="checkbox" name="field[]" value="branch_name" id="_field_branch_name" checked="{{ isLocked('branch_name') }}">
                                <label class="form-check-label" for="_field_branch_name">Locked</label>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex form-group">
                        <label for="street" class="col-sm-1">Street</label>
                        <div class="col-sm-5">
                            <input type="text" formControlName="street" class="form-control" id="street"
                                autocomplete="off" value="{{ client?.street }}" />
                        </div>
                        <div class="col-sm-3">
                            <div class="form-check form-switch">
                                <input class="form-check-input" type="checkbox" name="field[]" value="street" id="_field_street" checked="{{ isLocked('street') }}">
                                <label class="form-check-label" for="_field_street">Locked</label>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex form-group">
                        <label for="suburb" class="col-sm-1">Suburb</label>
                        <div class="col-sm-5">
                            <input type="text" formControlName="suburb" class="form-control" id="suburb"
                                autocomplete="off" value="{{ client?.suburb }}" />
                        </div>
                        <div class="col-sm-3">
                            <div class="form-check form-switch">
                                <input class="form-check-input" type="checkbox" name="field[]" value="suburb" id="_field_suburb" checked="{{ isLocked('suburb') }}">
                                <label class="form-check-label" for="_field_suburb">Locked</label>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex form-group">
                        <label for="state" class="col-sm-1">State</label>
                        <div class="col-sm-5">
                            <input type="text" formControlName="state" class="form-control" id="state"
                                autocomplete="off" value="{{ client?.state }}" />
                        </div>
                        <div class="col-sm-3">
                            <div class="form-check form-switch">
                                <input class="form-check-input" type="checkbox" name="field[]" value="state" id="_field_state" checked="{{ isLocked('state') }}">
                                <label class="form-check-label" for="_field_state">Locked</label>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex form-group">
                        <label for="postcode" class="col-sm-1">Postcode</label>
                        <div class="col-sm-5">
                            <input type="text" formControlName="postcode" class="form-control" id="postcode"
                                autocomplete="off" value="{{ client?.postcode }}" />
                        </div>
                        <div class="col-sm-3">
                            <div class="form-check form-switch">
                                <input class="form-check-input" type="checkbox" name="field[]" value="postcode" id="_field_postcode" checked="{{ isLocked('postcode') }}">
                                <label class="form-check-label" for="_field_postcode">Locked</label>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex form-group">
                        <label for="country" class="col-sm-1">Country</label>
                        <div class="col-sm-5">
                            <input type="text" formControlName="country" class="form-control" id="country"
                                autocomplete="off" value="{{ client?.country }}" />
                        </div>
                        <div class="col-sm-3">
                            <div class="form-check form-switch">
                                <input class="form-check-input" type="checkbox" name="field[]" value="country" id="_field_country" checked="{{ isLocked('country') }}">
                                <label class="form-check-label" for="_field_country">Locked</label>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex form-group">
                        <label for="postal_street" class="col-sm-1">Postal Street</label>
                        <div class="col-sm-5">
                            <input type="text" formControlName="postal_street" class="form-control"
                                id="postal_street" autocomplete="off" value="{{ client?.postal_street }}" />
                        </div>
                        <div class="col-sm-3">
                            <div class="form-check form-switch">
                                <input class="form-check-input" type="checkbox" name="field[]" value="postal_street" id="_field_postal_street" checked="{{ isLocked('postal_street') }}">
                                <label class="form-check-label" for="_field_postal_street">Locked</label>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex form-group">
                        <label for="postal_suburb" class="col-sm-1">Postal Suburb</label>
                        <div class="col-sm-5">
                            <input type="text" formControlName="postal_suburb" class="form-control"
                                id="postal_suburb" autocomplete="off" value="{{ client?.postal_suburb }}" />
                        </div>
                        <div class="col-sm-3">
                            <div class="form-check form-switch">
                                <input class="form-check-input" type="checkbox" name="field[]" value="postal_suburb" id="_field_postal_suburb" checked="{{ isLocked('postal_suburb') }}">
                                <label class="form-check-label" for="_field_postal_suburb">Locked</label>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex form-group">
                        <label for="postal_state" class="col-sm-1">Postal State</label>
                        <div class="col-sm-5">
                            <input type="text" formControlName="postal_state" class="form-control"
                                id="postal_state" autocomplete="off" value="{{ client?.postal_state }}" />
                        </div>
                        <div class="col-sm-3">
                            <div class="form-check form-switch">
                                <input class="form-check-input" type="checkbox" name="field[]" value="postal_state" id="_field_postal_state" checked="{{ isLocked('postal_state') }}">
                                <label class="form-check-label" for="_field_postal_state">Locked</label>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex form-group">
                        <label for="postal_postcode" class="col-sm-1">Postal Postcode</label>
                        <div class="col-sm-5">
                            <input type="text" formControlName="postal_postcode" class="form-control"
                                id="postal_postcode" autocomplete="off" value="{{ client?.postal_postcode }}" />
                        </div>
                        <div class="col-sm-3">
                            <div class="form-check form-switch">
                                <input class="form-check-input" type="checkbox" name="field[]" value="postal_postcode" id="_field_postal_postcode" checked="{{ isLocked('postal_postcode') }}">
                                <label class="form-check-label" for="_field_postal_postcode">Locked</label>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex form-group">
                        <label for="phone" class="col-sm-1">Phone</label>
                        <div class="col-sm-5">
                            <input type="text" formControlName="phone" class="form-control" id="phone"
                                autocomplete="off" value="{{ client?.phone }}" />
                        </div>
                        <div class="col-sm-3">
                            <div class="form-check form-switch">
                                <input class="form-check-input" type="checkbox" name="field[]" value="phone" id="_field_phone" checked="{{ isLocked('phone') }}">
                                <label class="form-check-label" for="_field_phone">Locked</label>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex form-group">
                        <label for="fax" class="col-sm-1">Fax</label>
                        <div class="col-sm-5">
                            <input type="text" formControlName="fax" class="form-control" id="fax"
                                autocomplete="off" value="{{ client?.fax }}" />
                        </div>
                        <div class="col-sm-3">
                            <div class="form-check form-switch">
                                <input class="form-check-input" type="checkbox" name="field[]" value="fax" id="_field_fax" checked="{{ isLocked('fax') }}">
                                <label class="form-check-label" for="_field_fax">Locked</label>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex form-group">
                        <label for="abn" class="col-sm-1">ABN</label>
                        <div class="col-sm-5">
                            <input type="text" formControlName="abn" class="form-control" id="abn"
                                autocomplete="off" value="{{ client?.abn }}" />
                        </div>
                        <div class="col-sm-3">
                            <div class="form-check form-switch">
                                <input class="form-check-input" type="checkbox" name="field[]" value="abn" id="_field_abn" checked="{{ isLocked('abn') }}">
                                <label class="form-check-label" for="_field_abn">Locked</label>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex form-group">
                        <label for="email" class="col-sm-1">Email</label>
                        <div class="col-sm-5">
                            <input type="text" formControlName="email" class="form-control" id="email"
                                autocomplete="off" value="{{ client?.email }}" />
                        </div>
                        <div class="col-sm-3">
                            <div class="form-check form-switch">
                                <input class="form-check-input" type="checkbox" name="field[]" value="email" id="_field_email" checked="{{ isLocked('email') }}">
                                <label class="form-check-label" for="_field_email">Locked</label>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex form-group">
                        <div class="col-sm-12">
                            <button type="submit" class="btn-admin btn-admin-primary">Save</button>
                        </div>
                    </div>
                </form>
            </div>

            <div [hidden]="selectedSettingType !== 'manage-designs'">
                <div class="card p-3 border-white mt-3">
                    <div class="card-body">
                        <div class="d-flex justify-content-between align-items-center">
                            <div class="flex-fill">
                                <h4><strong>Delete All Designs</strong></h4>
                                <p class="mb-1">Sometimes you have too many Designs created which can take some time
                                    to delete them all one by one, here you can delete all your Designs at once.</p>
                                <p class="fw-bold">Please note this excludes Templates or Designs shared.</p>
                            </div>
                            <div>
                                <button class="btn btn-primary btn-1" (click)="onDeleteTemplates()">Delete my
                                    Designs</button>
                                <button class="btn btn-primary btn-1 delete-all"
                                    (click)="onDeleteAllTemplates()">Delete all Designs</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div [hidden]="selectedSettingType !== 'manage-designs'">
                <div class="card p-3 border-white mt-3">
                    <div class="card-body">
                        <div class="d-flex justify-content-between align-items-center">
                            <div class="flex-fill">
                                <h4><strong>Sync VaultRE/EA Designs</strong></h4>
                                <p>
                                    Here you can view and manage all your templates saved in your VaultRE/EA account
                                </p>
                            </div>
                            <div>
                                <button class="btn btn-primary btn-1" (click)="onGetVaultTemplates()">View Templates</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div [hidden]="selectedSettingType !== 'manage-designs'">
                <div class="card p-3 border-white mt-3">
                    <div class="card-body">
                        <div class="d-flex justify-content-between align-items-center">
                            <div class="flex-fill">
                                <h4><strong>Toogle Viewing Inactive Users</strong></h4>
                                <p>
                                    Show account's Inactive Users
                                </p>
                            </div>
                            <div>
                                <button class="btn btn-primary btn-1" (click)="onToggleUserDropdownList()">{{buttonTextShowInactiveUsers}}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div [hidden]="selectedSettingType !== 'agent-access'">
                <h3>Agent Access</h3>
                <p class="gray4-color">Allow or disallow all agents admin rights</p>
                <hr>
                <div class="row">
                    <div class="col-sm-12">
                        <form [formGroup]="agentSettingsForm" class="form-horizontal">
                            <div class="d-flex form-group">
                                <label for="email" class="col-sm-2">Allow all agents access to settings and
                                    view all templates within the office</label>
                                <div class="col-sm-5">
                                    <label class="allowSettings"><input type="radio"
                                            formControlName="allow_agent_settings" name="allow_agent_settings"
                                            value="0" [checked]="(client?.allow_agent_settings === 0)"> No
                                        &emsp;</label>
                                    <label><input type="radio" formControlName="allow_agent_settings"
                                            name="allow_agent_settings" value="1"
                                            [checked]="(client?.allow_agent_settings === 1)"> Yes </label>
                                </div>
                            </div>
                            <hr>
                            <div class="form-group">
                                <div class="col-sm-12">
                                    <button type="submit" class="btn-admin btn-admin-primary"
                                        (click)="onAgentSettingsSubmit()">Save</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>

<!-- Modal -->
<div class="modal" id="modalImageCpicker" aria-hidden="true" aria-labelledby="modalImageCpicker" role="dialog"
    tabindex="-1" style="display: none">
    <div class="modal-dialog modal-center modal-large">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close close-outside" data-bs-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
                <h4 class="modal-title">Image Color Picker</h4>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-7">
                        <div class="btn_clearfix">
                            <span id="error_msg_image" style="color: red; display: none">Please upload your own logo in
                                our Logo tab page</span>
                        </div>
                        <canvas #canvasEl id="colorPickercanvas" width="500" height="386"></canvas>
                    </div>
                    <div class="col-md-5 column2">
                        <div>Preview:</div>
                        <div id="preview" style="background-color: rgba(0, 0, 0, 0)"></div>
                        <div>Color:</div>
                        <div class="insertcolor">R: <input type="text" id="rVal" readonly="" class="color" /></div>
                        <div class="insertcolor">G: <input type="text" id="gVal" readonly="" class="color" /></div>
                        <div class="insertcolor">B: <input type="text" id="bVal" readonly="" class="color" /></div>
                        <div class="insertcolor">RGB: <input type="text" id="rgbVal" readonly="" class="color" /></div>
                        <div class="insertcolor">RGBA: <input type="text" id="rgbaVal" readonly="" class="color" />
                        </div>
                        <div class="insertcolor">HEX: <input type="text" id="hexVal" readonly="" class="color" /></div>
                    </div>
                    <div style="clear: both"></div>
                </div>
            </div>
            <div class="modal-footer justify-content-start">
                <button type="button" class="btn btn-1" data-bs-dismiss="modal">Add</button>
            </div>
        </div>
    </div>
</div>

<!-- vault template modal-->
<div id="mdVautTemplatesSearch" class="modal fade edit-box" role="dialog" aria-labelledby="mdVautTemplatesSearchLabel">
    <div class="modal-dialog modal-center" role="document">
        <div class="modal-content p-0">
            <div class="modal-header border-bottom mb-2">
                <h4 class="modal-title m-0" id="mdVautTemplatesSearchLabel">VaultRE/EA Templates</h4>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="form-horizontal">
                <div class="modal-body">
                    <div class="row mb-4">
                        <div class="col-sm-12">
                            <input type="text" class="form-control" autocomplete="off" autofocus
                                placeholder="Search template" [(ngModel)]="vaultTemplateSearchFilter">
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-md-12">
                            <div class="vaultTemplateListScroll">
                                <ul>
                                    <li *ngFor="let vaultTemplate of vaultTemplates | settingsDefaultVaultTemplates: vaultTemplateSearchFilter ">
                                        <div class="d-flex align-items-center">
                                            <div class="flex-grow-1 ms-2 text-truncate">
                                                <h6>{{vaultTemplate?.title}}</h6>
                                                <p class="m-0">Inserted {{ vaultTemplate?.inserted | date:'dd/MM/yyyy' }} &nbsp;&nbsp; Modified {{ vaultTemplate?.modified | date:'dd/MM/yyyy' }}</p>
                                            </div>
                                            <button type="button" class="btn btn-default btn-author-actions" (click)="onDeleteVaultTemplate(vaultTemplate?.id)"
                                            >Remove</button>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-default btn-author-actions" (click)="onDeleteAllVaultTemplate()">Remove All Templates</button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Modal -->
<ngx-loading [show]="loading"></ngx-loading>