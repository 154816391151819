import { Component, OnInit,  ElementRef, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';
import { FrontService } from '../../front.service';
import { ClientService } from '../../../services/client.service';
import { Client } from '../../../models/client.model';
import { FormGroup, FormControl, FormArray, FormBuilder } from '@angular/forms';
import { Auth } from '../../../models/auth.model';
import { Icon } from '../../../models/icon.model';
import { IconService } from '../../../services/icon.service';
import { User } from '../../../models/user.model';
import { UserService } from '../../../services/user.service';
import { TemplateService } from '../../../services/template.service';

import { MyDesktopService } from '../../../services/mydesktop.service';
import { VaultService } from '../../../services/vault.service';
import { Subject } from 'rxjs';
import { ActivatedRoute, Data } from '@angular/router';
import { IdashboardService } from '../../../services/idashboard.service';
import { MalihuScrollbarService } from 'ngx-malihu-scrollbar';
import { GlobalEmitterService } from '../../../services/global-emitter.service';

declare var $: any;
declare var jQuery: any;
declare var introJs: any;
declare var swal: any;
declare var _bugHerd: any;

@Component({
  selector: 'app-settings-default',
  templateUrl: './settings-default.component.html',
  styleUrls: ['./settings-default.component.scss']
})
export class SettingsDefaultComponent implements OnInit, AfterViewInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject;
  loading = false;
  client: Client;
  title = 'Settings';
  logo_link = '';
  private color = '#127bdc';
  private colorMainHeading = '#127bdc';
  private colorSubHeading = '#127bdc';
  private colorIcon = '#127bdc';

  icons: Icon[] = [];

  dataTableHtml: any;

  imagePickerId;

  logoForm: FormGroup;
  formFontColor: FormGroup;
  linksForm: FormGroup;
  agencyForm: FormGroup;
  agentForm: FormGroup;
  agentSettingsForm: FormGroup;

  is_colorpicker_open = false;
  current_picker: string;

  color1 = '';
  color2 = '';
  color4 = '';
  color6 = '';
  color7 = '';
  color9 = '';
  color10 = '';

  agentListArray: FormArray;

  public items: Array<string> = ['Arial', 'Lato'];

  private value: any = {};
  private _disabledV = '0';
  private disabled = false;

  authUser: Auth;
  user = {
    ID: 21,
    client_id: 5,
    name: 'John Doe',
    photo: 'assets/img/user_photo.jpg',
    email: 'johndoe@websiteblue.com',
    mobile: '+6112345678',
    telephone: '1234 5678',
    fax: '9876 5432'
  };

  users: User[];

  defaultTab = '';

  supportLink = '';

  lockFields = [];
  isSetup = false;

  selectedSettingType = '';

  currentTimestamp = Date.now();
  vaultTemplates = [];
  vaultTemplateSearchFilter = '';

  buttonTextShowInactiveUsers:string = "";
  @ViewChild('canvasEl', { static: true }) canvasElem: ElementRef;

  constructor(
    public frontService: FrontService,
    private clientService: ClientService,
    private iconService: IconService,
    private userService: UserService,
    private mydesktopService: MyDesktopService,
    private vaultService: VaultService,
    private route: ActivatedRoute,
    private idashboardService: IdashboardService,
    private templateService: TemplateService,
    private mScrollbarService: MalihuScrollbarService,
    private formBuilder: FormBuilder,
    private globalEmitterService: GlobalEmitterService
  ) { }

  ngOnInit() {
    this.route.params
    .takeUntil(this.destroy$)
    .subscribe(
      (params: any) => {
        this.selectedSettingType = params.type;
        this.title = params.type.replace('-', ' ');
      }
    );

    this.supportLink = this.frontService.supportLink;

    this.authUser = this.frontService.authService.auth;

    this.user = {
      ID: this.authUser.user.agent_id,
      client_id: this.authUser.client.ID,
      name: this.authUser.user.firstname + ' ' + this.authUser.user.firstname,
      photo: this.authUser.user.photo,
      email: this.authUser.user.email,
      mobile: this.authUser.user.mobile,
      telephone: this.authUser.user.telephone,
      fax: this.authUser.user.fax
    };

    if (this.authUser.is_admin || this.authUser.is_master) {
      this.userService.httpGetUsers({'client_id': this.user.client_id, 'is_active': 1 });
    }

    this.userService.usersChanged
    .takeUntil(this.destroy$)
    .subscribe(
      (users: any) => {
        if ( typeof users !== 'undefined' && users.status === 'success' ) {
          this.loading = false;

          if ( users.count > 0 ) {
            this.users = users.data.sort(function(a, b) {
              const x = a.firstname.toLowerCase();
              const y = b.firstname.toLowerCase();
              return x < y ? -1 : x > y ? 1 : 0;
            });
          }

          this.initDT();
        }
      }
    );

    this.userService.usersAccessChanged
    .takeUntil(this.destroy$)
    .subscribe(
      (users: any) => {
        if ( typeof users !== 'undefined' && users.status === 'success' ) {
          this.loading = false;
          swal({
            type: 'success',
            title: 'Changes has been saved',
            showConfirmButton: false,
            timer: 1500,
            allowEscapeKey: false,
            allowOutsideClick: false
          });

          setTimeout(() => {
            location.reload();
          }, 1000);

          this.initDT();
        }
      }
    );

    this.mydesktopService.syncAgents
    .takeUntil(this.destroy$)
    .subscribe(
      (agents: any) => {
        if (typeof agents !== 'undefined' && typeof agents.sync !== 'undefined') {
          swal({
            title: 'Syncing Agent List',
            timer: 2000,
            onOpen: function () {
              swal.showLoading ();
            }
          }).then(
            function () {},
            // handling the promise rejection
            function (dismiss) {
              if (dismiss === 'timer') {
                location.reload();
              }
            }
          );
        }
      }
    );

    this.client = this.authUser.client;

    if (this.client.is_account_master === 1) {
      this.defaultTab = 'logo';
    } else {
      if (this.client.group.setting_menu_link === 1) {
        this.defaultTab = 'link';
      }
      if (this.client.group.setting_menu_icon === 1) {
        this.defaultTab = 'icon';
      }
      if (this.client.group.setting_menu_font === 1) {
        this.defaultTab = 'font';
      }
      if (this.client.group.setting_menu_color === 1) {
        this.defaultTab = 'color';
      }
      if (this.client.group.setting_menu_logo === 1) {
        this.defaultTab = 'logo';
      }
    }


    this.iconService.httpGetIcons();

    this.clientService.clientLink
    .takeUntil(this.destroy$)
    .subscribe(
      (client: Client) => {
        this.loading = false;
        if (typeof client.ID !== 'undefined') {
          this.client = client;
          this.frontService.authService.updateAuthCookieField('client', JSON.stringify(client));
        }
      }
    );

    this.clientService.agentSettings
    .takeUntil(this.destroy$)
    .subscribe(
      (client: Client) => {
        this.loading = false;
        if (typeof client.ID !== 'undefined') {
          this.client = client;
          this.frontService.authService.updateAuthCookieField('client', JSON.stringify(client));
        }
      }
    );

    this.clientService.clientLogo
    .takeUntil(this.destroy$)
    .subscribe(
      (client: Client) => {
        this.loading = false;
        this.logo_link = client.primary_logo;
        // this.displayImageColorPicker(9);
        if (typeof client.ID !== 'undefined') {
          this.client = client;
          this.frontService.authService.updateAuthCookieField('client', JSON.stringify(client));
        }
      }
    );

    this.clientService.clientColor
    .takeUntil(this.destroy$)
    .subscribe(
      (client: Client) => {
        this.loading = false;

        if (typeof client.ID !== 'undefined') {
          this.client = client;
          this.frontService.authService.updateAuthCookieField('client', JSON.stringify(client));
        }
      }
    );

    this.clientService.clientIcon
    .takeUntil(this.destroy$)
    .subscribe(
      (client: Client) => {
        this.loading = false;

        if (typeof client.ID !== 'undefined') {
          this.client = client;
          this.frontService.authService.updateAuthCookieField('client', JSON.stringify(client));
        }
      }
    );

    this.iconService.iconsChanged
    .takeUntil(this.destroy$)
    .subscribe(
      (icons: Icon[]) => {
        this.icons = icons;
      }
    );

    this.clientService.clientLockFields
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if ( typeof response !== 'undefined' && response.status === 'success' ) {
          this.lockFields = response.data;
        }
      }
    );

    this.clientService.clientOperation
    .takeUntil(this.destroy$)
    .subscribe(
      (client: Client) => {
        if ( typeof client.ID !== 'undefined'  ) {
          this.loading = false;

          swal({
            type: 'success',
            title: 'Changes has been saved',
            showConfirmButton: false,
            timer: 1500,
            allowEscapeKey: false,
            allowOutsideClick: false
          });

          setTimeout(() => {
            location.reload();
          }, 1000);
        }
      }
    );

    this.vaultService.onVaultSyncAgent
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if ( typeof response !== 'undefined' && response.status === 'success') {
          swal({
            title: 'Syncing Agent List',
            timer: 2000,
            onOpen: function () {
              swal.showLoading ();
            }
          }).then(
            function () {},
            // handling the promise rejection
            function (dismiss) {
              if (dismiss === 'timer') {
                location.reload();
              }
            }
          );
        }
      }
    );

    this.idashboardService.onSyncAgents
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (typeof response !== 'undefined' && response.status === 'success') {
          swal({
            title: 'Syncing Agent List',
            timer: 2000,
            onOpen: function () {
              swal.showLoading ();
            }
          }).then(
            function () {},
            // handling the promise rejection
            function (dismiss) {
              if (dismiss === 'timer') {
                location.reload();
              }
            }
          );
        }
      }
    );

    this.templateService.templatesDeletion
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (typeof response !== 'undefined' && response.status === 'success') {
          this.loading = false;
          swal(
            'Deleted!',
            'Templates has been deleted.',
            'success'
          );
        }
      }
    );

    this.templateService.vaultTemplates
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (typeof response !== 'undefined' && response.status === 'success') {
          this.vaultTemplates = response.data;
          this.loading = false;
          $('#mdVautTemplatesSearch').modal('show');
          this.vaultTemplateSearchFilter = '';
        } else {
          swal(
            'Error',
            'Try again later',
            'error'
          );
        }
      }
    );

    this.templateService.vaultTemplateDeletion
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (typeof response !== 'undefined' && response.status === 'success') {
          $('#mdVautTemplatesSearch').modal('hide');
          this.loading = false;
          swal({
            title: 'Vault Template removed successfully',
            type: 'success',
          });
        } else {
          $('#mdVautTemplatesSearch').modal('hide');
          this.loading = false;
          swal('Vault Error', response.message, 'error');
        }
      }
    );

    this.templateService.vaultTemplateDeletionAll
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (typeof response !== 'undefined' && response.status === 'success') {
          $('#mdVautTemplatesSearch').modal('hide');
          this.loading = false;
          swal({
            title: 'Vault Templates removed successfully',
            type: 'success',
          });
        } else {
          $('#mdVautTemplatesSearch').modal('hide');
          this.loading = false;
          swal('Vault Error', response.message, 'error');
        }
      }
    );

    this.logo_link = this.client.primary_logo;

    this.color1 = this.client.color_1;
    this.color2 = this.client.color_2;
    this.color4 = this.client.color_4;
    this.color6 = this.client.color_6;
    this.color7 = this.client.color_7;
    this.color9 = this.client.color_9;
    this.color10 = this.client.color_10;

    this.initfontColorForm();
    this.initlinksForm();
    this.initlinksFormEdit();

    this.initAgencyForm();
    this.initAgentForm();
    this.initAgentSettingsForm();
    this.checkLockFields();
    // this.displayImageColorPicker(9);
    this.initShowInactiveUsersSettings();
  }

  initDT() {
    setTimeout(
      () => {
        if ( ! jQuery.fn.DataTable.isDataTable( 'table' ) ) {
          this.dataTableHtml = jQuery('table:not(.static)').DataTable({
            'sDom': 'ltipr',
            paging: true,
            info: true,
            ordering: false,
            responsive: true,
            'bLengthChange': false,
            // 'pagingType': 'full_numbers',
            // 'processing': true,
            'pageLength': 20,
          });

          const dt = $('.table-agent-access').DataTable();
          $('.agentInputSearch').keyup(function(){
            dt.search($(this).val()).draw();
          });
        }
      }, 1000
    );
  }


  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();

    if (jQuery.fn.DataTable.isDataTable(this.dataTableHtml)) {
      this.dataTableHtml.destroy();
    }
  }

  ngAfterViewInit() {

    this.mScrollbarService.initScrollbar('.vaultTemplateListScroll', {
      axis: 'y',
      theme: 'dark-3',
      scrollInertia: 600,
      advanced: { autoExpandHorizontalScroll: true }
    });

    const $this = this;
    this.initializeColorPicker();

    const isSetup = this.route.snapshot.queryParamMap.get('setup');

    if (isSetup === 'true' && this.authUser.is_admin) {
      this.isSetup = true;
      $this.startTour();
    }

    if ( localStorage.getItem('first_settings') === '1' ) {
      this.startTour();

      // set setting tour to 0
      localStorage.setItem('first_settings', '0');
    }
  }

  public selected(value: any): void {}

  public removed(value: any): void {}

  public typed(value: any): void {}

  public refreshValue(value: any): void {
    this.value = value;
  }

  initializeColorPicker() {

    function readURL(input) {
      if (input.files && input.files[0]) {
          const reader = new FileReader();
          reader.onload = function (e) {
            const target: any = e.target;
            const content: string = target.result;

            const image_placement = jQuery(input).attr('data-action');
            jQuery(image_placement).attr('src', content);
          };

          reader.readAsDataURL(input.files[0]);
      }
    }

    jQuery('#image-primary').change(function(){
        readURL(this);
    });

    jQuery('#image-secondary').change(function(){
      readURL(this);
    });
  }

  uploadLogo(event: Event) {
    const target: HTMLInputElement = event.target as HTMLInputElement;

    for (let i = 0; i < target.files.length; i++) {
      this.clientService.httpPutLogo(this.authUser.client.ID, 'primary', target.files[i]);
    }
    this.loading = true;
  }

  uploadSecondaryLogo(event: Event) {
    const target: HTMLInputElement = event.target as HTMLInputElement;
    for (let i = 0; i < target.files.length; i++) {
      this.clientService.httpPutLogo(this.authUser.client.ID, 'secondary', target.files[i]);
    }
    this.loading = true;
  }

  private initfontColorForm() {
    this.formFontColor = new FormGroup({
      'color_1': new FormControl(''),
      'color_2': new FormControl(''),
      'color_3': new FormControl(''),
      'color_4': new FormControl(''),
      'color_5': new FormControl(''),
      'color_6': new FormControl(''),
      'color_7': new FormControl(''),
      'color_8': new FormControl(''),
      'color_9': new FormControl(''),
      'color_10': new FormControl(''),
      'font_family': new FormControl(''),
    });
  }

  private initlinksForm() {
    this.linksForm = new FormGroup({
      'link_website': new FormControl(''),
      'link_short_website': new FormControl(''),
      'link_buy': new FormControl(''),
      'link_sell': new FormControl(''),
      'link_rent': new FormControl(''),
      'link_unsubscribe': new FormControl(''),
      'link_home': new FormControl(''),
      'link_about': new FormControl(''),
      'link_careers': new FormControl(''),
      'link_blog': new FormControl(''),
      'link_facebook': new FormControl(''),
      'link_twitter': new FormControl(''),
      'link_youtube': new FormControl(''),
      'link_instagram': new FormControl(''),
      'link_linkedin': new FormControl(''),
      'link_pinterest': new FormControl(''),
      // 'link_gplus': new FormControl(''),
      'link_ratemyagent': new FormControl(''),
      'link_googlereviews': new FormControl(''),
      'link_contact': new FormControl(''),
      'link_testimonials': new FormControl(''),
      'disclaimer_email': new FormControl(''),
      'disclaimer_print': new FormControl(''),
      'legal_1': new FormControl(''),
      'legal_2': new FormControl(''),
      'legal_3': new FormControl('')
    });
  }

  get url(){
    return this.linksForm.controls;
  }

  private initlinksFormEdit() {
    this.linksForm.patchValue({
      'link_website': this.client.link_website,
      'link_short_website': this.client.link_short_website,
      'link_buy': this.client.link_buy,
      'link_sell': this.client.link_sell,
      'link_rent': this.client.link_rent,
      'link_unsubscribe': this.client.link_unsubscribe,
      'link_home': this.client.link_home,
      'link_about': this.client.link_about,
      'link_careers': this.client.link_careers,
      'link_blog': this.client.link_blog,
      'link_facebook': this.client.link_facebook,
      'link_twitter': this.client.link_twitter,
      'link_youtube': this.client.link_youtube,
      'link_instagram': this.client.link_instagram,
      'link_linkedin': this.client.link_linkedin,
      'link_pinterest': this.client.link_pinterest,
      // 'link_gplus': this.client.link_gplus,
      'link_ratemyagent': this.client.link_ratemyagent,
      'link_googlereviews': this.client.link_googlereviews,
      'link_contact': this.client.link_contact,
      'link_testimonials': this.client.link_testimonials,
      'disclaimer_email': this.client.disclaimer_email,
      'disclaimer_print': this.client.disclaimer_print,
      'legal_1': this.client.legal_1,
      'legal_2': this.client.legal_2,
      'legal_3': this.client.legal_3
    });
  }

  private initAgencyForm() {
    this.agencyForm = new FormGroup({
      'franchise_name': new FormControl(this.client.franchise_name),
      'company_name': new FormControl(this.client.company_name),
      'name': new FormControl(this.client.name),
      'branch_name': new FormControl(this.client.branch_name),
      'street': new FormControl(this.client.street),
      'suburb': new FormControl(this.client.suburb),
      'state': new FormControl(this.client.state),
      'postcode': new FormControl(this.client.postcode),
      'postal_street': new FormControl(this.client.postal_street),
      'postal_suburb': new FormControl(this.client.postal_suburb),
      'postal_state': new FormControl(this.client.postal_state),
      'postal_postcode': new FormControl(this.client.postal_postcode),
      'country': new FormControl(this.client.country),
      'phone': new FormControl(this.client.phone),
      'fax': new FormControl(this.client.fax),
      'abn': new FormControl(this.client.abn),
      'email': new FormControl(this.client.email)
    });
  }

  private initAgentForm() {
    this.agentForm = new FormGroup({
      'client_id': new FormControl(this.user.client_id)
    });
  }

  private initAgentSettingsForm() {
    this.agentSettingsForm = new FormGroup({
      'allow_agent_settings': new FormControl(this.client.allow_agent_settings),
    });
  }

  private checkUrl() {
    const linksValue = this.linksForm.value;

    const linksGroup = [
      linksValue.link_website,
      linksValue.link_buy,
      linksValue.link_sell,
      linksValue.link_rent,
      linksValue.link_unsubscribe,
      linksValue.link_home,
      linksValue.link_about,
      linksValue.link_careers,
      linksValue.link_blog,
      linksValue.link_testimonials,
      linksValue.link_contact,
    ];

    const linksControl = this.linksForm.controls;

    const linksControlGroup = [
      linksControl.link_website,
      linksControl.link_buy,
      linksControl.link_sell,
      linksControl.link_rent,
      linksControl.link_unsubscribe,
      linksControl.link_home,
      linksControl.link_about,
      linksControl.link_careers,
      linksControl.link_blog,
      linksControl.link_testimonials,
      linksControl.link_contact
    ];


    for (let i = 0; i < linksGroup.length ; i++) {
      if (!this.validateUrl(linksGroup[i])) {
        linksControlGroup[i].setErrors({status : 'INVALID'});

        return false;
      }
    }

    return true;
  }

  private checkSocialUrl() {
    const linksValue = this.linksForm.value;

    const linksGroup = [
      linksValue.link_facebook,
      linksValue.link_twitter,
      linksValue.link_youtube,
      linksValue.link_instagram,
      linksValue.link_linkedin,
      linksValue.link_pinterest,
      linksValue.link_ratemyagent,
      linksValue.link_googlereviews,
    ];

    const linksControl = this.linksForm.controls;

    const linksControlGroup = [
      linksControl.link_facebook,
      linksControl.link_twitter,
      linksControl.link_youtube,
      linksControl.link_instagram,
      linksControl.link_linkedin,
      linksControl.link_pinterest,
      linksControl.link_ratemyagent,
      linksControl.link_googlereviews,
    ];


    for (let i = 0; i < linksGroup.length ; i++) {
      if (!this.validateUrl(linksGroup[i])) {
        linksControlGroup[i].setErrors({status : 'INVALID'});

        return false;
      }
    }

    return true;
  }

  private checkShortUrl() {

    if (!this.validateShortUrl(this.linksForm.value.link_short_website)) {
      this.linksForm.controls.link_short_website.setErrors({status : 'INVALID'});
      return false;
    }

    return true;
  }

  private initShowInactiveUsersSettings() {
    let responseFlag = this.globalEmitterService.getShowInactiveFlag();
    if(responseFlag === false) {
      this.buttonTextShowInactiveUsers = "Show Inactive Users";
    } else {
      this.buttonTextShowInactiveUsers = "Hide Inactive Users";
    }
  }

  saveLinks() {
    if (!this.checkUrl()) {
      return false;
    }

    if (!this.checkShortUrl()) {
      return false;
    }

    this.linksForm.patchValue({
      'link_website': this.linksForm.get('link_website').value,
      'link_buy': this.linksForm.get('link_buy').value,
      'link_sell': this.linksForm.get('link_sell').value,
      'link_rent': this.linksForm.get('link_rent').value,
      'link_unsubscribe': this.linksForm.get('link_unsubscribe').value,
      'link_home': this.linksForm.get('link_home').value,
      'link_about': this.linksForm.get('link_about').value,
      'link_careers': this.linksForm.get('link_careers').value,
      'link_blog': this.linksForm.get('link_blog').value,
      'disclaimer_email': this.linksForm.get('disclaimer_email').value,
      'disclaimer_print': this.linksForm.get('disclaimer_print').value,
      'link_contact': this.linksForm.get('link_contact').value,
      'link_testimonials': this.linksForm.get('link_testimonials').value,
    });
    const linksValue = this.linksForm.value;


    this.clientService.httpPutLinks(this.user.client_id, linksValue);
    this.loading = true;
  }

  saveSocialLinks() {
    if (!this.checkSocialUrl()) {
      return false;
    }

    this.linksForm.patchValue({
      'link_facebook': this.linksForm.get('link_facebook').value,
      'link_twitter': this.linksForm.get('link_twitter').value,
      'link_youtube': this.linksForm.get('link_youtube').value,
      'link_instagram': this.linksForm.get('link_instagram').value,
      'link_linkedin': this.linksForm.get('link_linkedin').value,
      'link_pinterest': this.linksForm.get('link_pinterest').value,
      // 'link_gplus': this.linksForm.get('link_gplus').value,
      'link_ratemyagent': this.linksForm.get('link_ratemyagent').value,
      'link_googlereviews': this.linksForm.get('link_googlereviews').value,
    });
    const linksValue = this.linksForm.value;


    this.clientService.httpPutLinks(this.user.client_id, linksValue);
    this.loading = true;
  }

  saveColor() {
    let color_1 = this.color1;
    let color_2 = this.color2;
    let color_4 = this.color4;
    let color_6 = this.color6;
    let color_7 = this.color7;
    let color_9 = this.color9;
    let color_10 = this.color10;

    if (color_1 === '' || color_1 === '#') {
      if (this.client.color_1 === '' || this.client.color_1 === '#') {
        color_1 = '';
      } else {
        color_1 = this.client.color_1;
      }
    } else {
        color_1 = color_1;
    }

    if (color_2 === '' || color_2 === '#') {
      if (this.client.color_2 === '' || this.client.color_2 === '#') {
        color_2 = '';
      }else {
        color_2 = this.client.color_2;
      }
    } else {
        color_2 = color_2;
    }

    if (color_4 === '' || color_4 === '#') {
      if (this.client.color_4 === '' || this.client.color_4 === '#') {
        color_4 = '';
      } else {
        color_4 = this.client.color_4;
      }
    } else {
        color_4 = color_4;
    }

    if (color_6 === '' || color_6 === '#') {
      if (this.client.color_6 === '' || this.client.color_6 === '#') {
        color_6 = '';
      } else {
        color_6 = this.client.color_6;
      }
    } else {
        color_6 = color_6;
    }

    if (color_7 === '' || color_7 === '#') {
      if (this.client.color_7 === '' || this.client.color_7 === '#') {
        color_7 = '';
      } else {
        color_7 = this.client.color_7;
      }
    } else {
        color_7 = color_7;
    }

    if (color_9 === '' || color_9 === '#') {
      if (this.client.color_9 === '' || this.client.color_9 === '#') {
        color_9 = '';
      } else {
        color_9 = this.client.color_9;
      }
    } else {
        color_9 = color_9;
    }

    if (color_10 === '' || color_10 === '#') {
      if (this.client.color_10 === '' || this.client.color_10 === '#') {
        color_10 = '';
      } else {
        color_10 = this.client.color_10;
      }
    } else {
        color_10 = color_10;
    }

    this.formFontColor.patchValue({
      'color_1': (color_1),
      'color_2': (color_2),
      'color_4': (color_4),
      'color_6': (color_6),
      'color_7': (color_7),
      'color_9': (color_9),
      'color_10': (color_10),
    });

    const fontValue = this.formFontColor.value;
    this.clientService.httpPutColor(this.user.client_id, fontValue);

    this.color1 = color_1;
    this.color2 = color_2;
    this.color4 = color_4;
    this.color6 = color_6;
    this.color7 = color_7;
    this.color9 = color_9;
    this.color10 = color_10;

    this.loading = true;

  }

  displayImageColorPicker(id) {
    const image = new Image;

    const $this = this;

    const canvas = this.canvasElem.nativeElement;
    const ctx = canvas.getContext('2d');

    ctx.clearRect(0, 0, canvas.width, canvas.height);

    image.crossOrigin = 'Anonymous';

    image.onload = function() {
      canvas.width = 500;
      canvas.height = 370;

      const w = image.width;
      const h = image.height;

      const sizes = scalePreserveAspectRatio(w, h, canvas.width, canvas.height);

      ctx.drawImage(image, 0, 0, w * sizes, h * sizes);
    };

    const default_logo = this.logo_link;

    if (typeof default_logo === 'undefined' || default_logo === '') {
      jQuery('#error_msg_image').css('display', 'block');
    } else {
      image.src = default_logo;
    }

    function scalePreserveAspectRatio(imgW, imgH, maxW, maxH) {
      return(Math.min((maxW / imgW), (maxH / imgH)));
    }

    jQuery(canvas).unbind('click').click(function(e) {
      const canvasOffset = jQuery(canvas).offset();
      const canvasX = Math.floor(e.pageX - canvasOffset.left);
      const canvasY = Math.floor(e.pageY - canvasOffset.top);

      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      const pixels = imageData.data;
      const pixelRedIndex = ((canvasY - 1) * (imageData.width * 4)) + ((canvasX - 1) * 4);
      // tslint:disable-next-line: max-line-length
      const pixelcolor = 'rgba(' + pixels[pixelRedIndex] + ', ' + pixels[pixelRedIndex + 1] + ', ' + pixels[pixelRedIndex + 2] + ', ' + pixels[pixelRedIndex + 3] + ')';

      jQuery('#rVal').val(pixels[pixelRedIndex]);
      jQuery('#gVal').val(pixels[pixelRedIndex + 1]);
      jQuery('#bVal').val(pixels[pixelRedIndex + 2]);
      jQuery('#rgbVal').val(pixels[pixelRedIndex] + ', ' + pixels[pixelRedIndex + 1] + ', ' + pixels[pixelRedIndex + 2]);
      // tslint:disable-next-line: max-line-length
      jQuery('#rgbaVal').val(pixels[pixelRedIndex] + ', ' + pixels[pixelRedIndex + 1] + ', ' + pixels[pixelRedIndex + 2] + ', ' + pixels[pixelRedIndex + 3]);
      jQuery('#hexVal').val('#' + toHex(pixels[pixelRedIndex]) + toHex(pixels[pixelRedIndex + 1]) + toHex(pixels[pixelRedIndex + 2]));

      jQuery('#preview').css('background', pixelcolor);

      if (id === 1) {
        $this.color1  = '#' + toHex(pixels[pixelRedIndex]) + toHex(pixels[pixelRedIndex + 1]) + toHex(pixels[pixelRedIndex + 2]);
      }

      if (id === 2) {
        $this.color2  = '#' + toHex(pixels[pixelRedIndex]) + toHex(pixels[pixelRedIndex + 1]) + toHex(pixels[pixelRedIndex + 2]);
      }

      if (id === 4) {
        $this.color4  = '#' + toHex(pixels[pixelRedIndex]) + toHex(pixels[pixelRedIndex + 1]) + toHex(pixels[pixelRedIndex + 2]);
      }

      if (id === 6) {
        $this.color6  = '#' + toHex(pixels[pixelRedIndex]) + toHex(pixels[pixelRedIndex + 1]) + toHex(pixels[pixelRedIndex + 2]);
      }

      if (id === 7) {
        $this.color7  = '#' + toHex(pixels[pixelRedIndex]) + toHex(pixels[pixelRedIndex + 1]) + toHex(pixels[pixelRedIndex + 2]);
      }

      if (id === 9) {
        $this.color9  = '#' + toHex(pixels[pixelRedIndex]) + toHex(pixels[pixelRedIndex + 1]) + toHex(pixels[pixelRedIndex + 2]);
      }

      if (id === 10) {
        $this.color10 = '#' + toHex(pixels[pixelRedIndex]) + toHex(pixels[pixelRedIndex + 1]) + toHex(pixels[pixelRedIndex + 2]);
      }

      jQuery('#cp' + id).css('background', pixelcolor);
    });

    function toHex(n) {
      let hex = n.toString(16);
      while (hex.length < 2) {hex = '0' + hex; }
      return hex;
    }
    // if (id !== 9) {
      jQuery('#modalImageCpicker').modal('show');
    // }
  }

  onFeedback() {
    swal({
      title: 'We would love to hear from you about the new MyDesign',
      imageUrl: 'http://mydesign3.wbclients.com/mydesign/assets/images/message-square.svg',
      imageWidth: 300,
      imageHeight: 100,
      showConfirmButton: false,
      html: '<p>Please choose to provide either Feedback or log a Support Ticket</p>' +
      // tslint:disable-next-line: max-line-length
      '<a class="btn btn-success btn-bh-feedback">Send Feedback</a> &nbsp; <a href="' + this.supportLink + '" target="_blank" class="btn btn-info btn-support-log">Log Support Ticket</a>'
      } );

      jQuery('.btn-bh-feedback').click(function() {
        swal.close();
        _bugHerd.win.bugherd.applicationView.anonymousbar.toggleOptions();
      });
  }

  isGroupVisible(fieldData: number = 0) {
    // tslint:disable-next-line: max-line-length
    if ( this.authUser.provider === 'designly'  || this.authUser.is_master || (this.authUser.is_admin && fieldData === 1 || this.authUser.client.allow_agent_settings === 1) ) {
      return true;
    }
    return false;
  }

  validateUrl(url) {
    const regexp = /((https?\:\/\/)|(http\.))(\S+)(\w{2,4})(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/g;

    if (url === null || url === '') {
      return true;
    }

    if (url.match(regexp)) {
      return true;
    } else {
      swal(
        'Invalid URL!',
        'Please check the URL is correct (for example http://www.example.com)',
        'error'
      );
      return false;
    }
  }

  validateShortUrl(url) {
    const regexp = /((https?\:\/\/)|(http\.))(\S+)(\w{2,4})(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/g;

    if (url === '') {
      return true;
    }

    if (url.match(regexp)) {
      swal(
        'Invalid Website URL!',
        'It is recommend not to include HTTP in the Short Website URL field.',
        'error'
      );

      return false;
    } else {
      return true;
    }
  }

  startTour() {
    const intro = introJs();

    intro.setOption('hidePrev', true);
    intro.setOption('hideNext', true);
    intro.setOption('disableInteraction', true);
    intro.setOption('exitOnOverlayClick', false);
    intro.setOption('overlayOpacity', '0.5');

    intro.start();
  }

  onSyncAgents() {
    if (this.authUser._tmp === '1') {
      swal(
        'Temporary Account Access',
        'Such Priviledge is not allowed',
        'error'
      );

      return;
    }

    const $this = this;
    swal({
      title: 'Confirm User Sync?',
      html: 'This will update user information from MRI Vault, including new, removed, and update access levels. Please note that this process may take some time to complete.',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Proceed'
    }).then(function () {
      $this.loading = true;
      if ( $this.authUser.provider === 'mydesktop' ) {
        $this.mydesktopService.httpSyncAgents($this.user.client_id);
      } else if ($this.authUser.provider === 'idashboard') {
        $this.idashboardService.httpSyncAgents();
      } else {
        $this.vaultService.httpSyncAgents($this.user.client_id);
      }
    }).catch(swal.noop);
  }

  onAgencySubmit() {
    const agencyValues = this.agencyForm.value;
    const lockFields = $('[name="field[]"]:checked').map(function () {
      return this.value;
    }).get();

    agencyValues['locked_fields'] = lockFields;
    agencyValues['agent_page'] = 'yes';

    this.clientService.httpPutClient(this.client.ID, agencyValues);
  }

  onAgentSubmit() {
    const agentValues = this.agentForm.value;

    const agent_id = $('[name="agent_id[]"]').map(function () {
      return this.value;
    }).get();

    const user_access = $('[name="user_access[]"]').map(function () {
      return this.value;
    }).get();

    agentValues['agent_id'] = agent_id;
    agentValues['user_access'] = user_access;

    this.userService.httpPutUserAccess(this.client.ID, agentValues);

    this.loading = true;
  }

  onAdminPriveledge() {
    const $this = this;
    swal({
      title: 'Are you sure you want change all the user to admin priviledge?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Proceed'
    }).then(function () {
      $this.userService.httpPutAdminAccess($this.client.ID);
      $this.loading = true;
    }).catch(swal.noop);
  }

  checkLockFields() {
    this.clientService.httpGetClientLockFields(this.client.ID);
  }

  isLocked(fieldName) {
    return ( this.lockFields.includes(fieldName) ? true : null);
  }

  changeIcon(type: string, icon: string, element) {
    const iconValues = {
      [type]: icon
    };

    $(`.${type}`).parent().removeClass('icon-holder-active');

    if ($(element.target).hasClass('icon-holder')) {
      $(element.target).addClass('icon-holder-active');
    } else {
      $(element.target).parent('.icon-holder').addClass('icon-holder-active');
    }

    this.clientService.httpPutIcon(this.user.client_id, iconValues);
    this.loading = true;
  }

  onEnterColor(event) {
    if (event.key === 'Enter') {
      const color_1 = jQuery('.asColorpicker1').val();
      const color_2 = jQuery('.asColorpicker2').val();
      const color_4 = jQuery('.asColorpicker4').val();
      const color_6 = jQuery('.asColorpicker6').val();
      const color_7 = jQuery('.asColorpicker7').val();
      const color_8 = jQuery('.asColorpicker8').val();

      if (color_1.includes('#')) {
        jQuery('.asColorpicker1').val(color_1);
      } else {
        if (color_1 === '') {
          jQuery('.asColorpicker1').val('');
        } else if (color_1.includes('rgb')) {
          jQuery('.asColorpicker1').val(color_1);
        } else {
          jQuery('.asColorpicker1').val('#' + color_1);
        }
      }

      if (color_2.includes('#')) {
        jQuery('.asColorpicker2').val(color_2);
      } else {
        if (color_2 === '') {
          jQuery('.asColorpicker2').val('');
        } else if (color_2.includes('rgb')) {
          jQuery('.asColorpicker2').val(color_2);
        } else {
          jQuery('.asColorpicker2').val('#' + color_2);
        }
      }

      if (color_4.includes('#')) {
        jQuery('.asColorpicker4').val(color_4);
      } else {
        if (color_4 === '') {
          jQuery('.asColorpicker4').val('');
        } else if (color_4.includes('rgb')) {
          jQuery('.asColorpicker4').val(color_4);
        } else {
          jQuery('.asColorpicker4').val('#' + color_4);
        }
      }

      if (color_6.includes('#')) {
        jQuery('.asColorpicker6').val(color_6);
      }  else {
        if (color_6 === '') {
          jQuery('.asColorpicker6').val('');
        } else if (color_6.includes('rgb')) {
          jQuery('.asColorpicker6').val(color_6);
        } else {
          jQuery('.asColorpicker6').val('#' + color_6);
        }
      }

      if (color_7.includes('#')) {
        jQuery('.asColorpicker7').val(color_7);
      } else {
        if (color_7 === '') {
          jQuery('.asColorpicker7').val('');
        } else if (color_7.includes('rgb')) {
          jQuery('.asColorpicker7').val(color_7);
        } else {
          jQuery('.asColorpicker7').val('#' + color_7);
        }
      }

      if (color_8.includes('#')) {
        jQuery('.asColorpicker8').val(color_8);
      } else {
        if (color_8 === '') {
          jQuery('.asColorpicker8').val('');
        } else if (color_8.includes('rgb')) {
          jQuery('.asColorpicker8').val(color_8);
        } else {
          jQuery('.asColorpicker8').val('#' + color_8);
        }
      }

    }
  }

  rgbToHex(color: any) {
    let values = color.replace('rgb(', '').replace(')', '');

    if (color.includes('rgba')) {
      values = color.replace('rgba(', '').replace(')', '');
    }

    const val = values.split(',');

    // tslint:disable-next-line: no-bitwise
    return '#' + ((1 << 24) + (Number(val[0]) << 16) + (Number(val[1]) << 8) + Number(val[2])).toString(16).slice(1);
  }

  onAgentSettingsSubmit() {

    this.agentSettingsForm.patchValue({
      'allow_agent_settings': this.agentSettingsForm.get('allow_agent_settings').value,
    });

    const agentSettingValues = this.agentSettingsForm.value;

    this.clientService.httpPutAgentSettings(this.client.ID, agentSettingValues);

    this.loading = true;
  }

  onDeleteTemplates() {
    const $this = this;
    swal({
      title: 'Are you sure you want to delete all the designs you created?',
      text: 'This will delete all the designs you have created except for the templates and shared designs.',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Proceed'
    }).then(function () {
      $this.loading = true;
      $this.templateService.httpDeleteTemplates('user');
    }).catch(swal.noop);
  }

  onDeleteAllTemplates() {
    const $this = this;
    swal({
      title: 'Are you sure you want to delete all the designs in the office?',
      text: 'This will delete all the designs in your office except for the templates and shared designs.',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Proceed'
    }).then(function () {
      $this.loading = true;
      $this.templateService.httpDeleteTemplates('client');
    }).catch(swal.noop);
  }

  onGetVaultTemplates() {
    this.loading = true;
    this.templateService.httpGetVaultTemplates();
  }

  onDeleteVaultTemplate(id: number) {
    const $this = this;
    if (id > 0) {
      swal({
        title: 'Are you sure you want to remove this template?',
        text: 'You can still send the template again',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Remove',
      }).then(function () {
        $this.templateService.httpDeleteVaultTemplate(id);
        $this.loading = true;
      });
    }
  }

  onDeleteAllVaultTemplate() {
    const $this = this;

    swal({
      title: 'Are you sure you want to remove all templates?',
      text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Remove',
    }).then(function () {
      $this.templateService.httpDeleteAllVaultTemplate();
      $this.loading = true;
    });
  }

  isLinksSection(type: string) {
    return ['website-links', 'social-links', 'disclaimers'].includes(type);
  }
  onToggleUserDropdownList():void {
    let responseFlag = this.globalEmitterService.getShowInactiveFlag();
    this.globalEmitterService.setShowInactiveFlag(!responseFlag);
    if(responseFlag === true) {
      this.buttonTextShowInactiveUsers = "Show Inactive Users";
    } else {
      this.buttonTextShowInactiveUsers = "Hide Inactive Users";
    }
  }
}
