<div class="">
  <div class="heading">
    <h2 class="pull-left">Users - {{ client?.name }}</h2>
  </div>
</div>
<div id="box" class="row">
  <div class="col-md-12">
    <div class="table-box">
      <div>
        <label class="control">
          <input type="text" class="form-control admin-input" placeholder="Search user's name or ID" (keyup)="onFilter()" [(ngModel)]="searchTerm">
        </label>
        <div style="float: right;">
          <button style="cursor: pointer;" class="mr-2 mt-2 btn btn-info"
            (click)="showEnableDesignlyProUsersModal()">
            Configure Designly Pro modules to all users
          </button>
          <button style="cursor: pointer;" class="mr-2 mt-2 btn btn-info"
            (click)="onGiveUsersAdminAccess()">
            Give all users admin administrative privileges
          </button>
        </div>
      </div>
      <table id="datatable-clients" class="datatable-clients table table-striped table-borderless table-hover" cellspacing="0" width="100%" style="width: 100%">
        <thead>
          <tr>
            <th>Agent ID</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Email</th>
            <th>Active</th>
            <th>Admin</th>
            <th>Logged-in before</th>
            <th>Group Admin</th>
            <th>Pro</th>
            <th>Access Level</th>
            <th>Access Changed By</th>
            <th style="text-align: right">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let user of users">
            <td>{{ user?.agent_id }}</td>
            <td><span [ngClass]="!user?.is_active ? 'text-danger' : ''">{{ user?.firstname }}</span></td>
            <td><span [ngClass]="!user?.is_active ? 'text-danger' : ''">{{ user?.lastname }}</span></td>
            <td>{{ user?.email }}</td>
            <td>
              <span *ngIf="user?.is_active" class="label label-success">YES</span>
              <span *ngIf="!user?.is_active" class="label label-danger">NO</span>
            </td>
            <td>
              <span *ngIf="user?.is_admin" class="label label-success">Yes</span>
              <span *ngIf="!user?.is_admin" class="label label-danger">No</span>
            </td>

            <td>
              <span *ngIf="user?.is_first" class="label label-danger">NO</span>
              <span *ngIf="!user?.is_first" class="label label-success">YES</span>
            </td>

            <td>
              <span *ngIf="user?.is_group_admin" class="label label-success">YES</span>
              <span *ngIf="!user?.is_group_admin" class="label label-danger">NO</span>
            </td>

            <td>
              <span *ngIf="user?.enable_designly_pro" class="label label-success">YES</span>
              <span *ngIf="!user?.enable_designly_pro" class="label label-danger">NO</span>
            </td>

            <td>
              <select class="form-control"
                onfocus="this.setAttribute('data-value', this.value);" 
                [(ngModel)]="user.accessLevel" 
                (change)="onChangeAccessLevel($event, user)">
                <option value="user">User</option>
                <option value="group_admin">Group Admin</option>
                <option value="admin">Admin</option>
              </select>
            </td>
            <td>
              {{ user?.enabled_access_level_user?.firstname }} {{ user?.enabled_access_level_user?.lastname }}<span *ngIf="!user?.  enabled_access_level_user">-</span><br><small style="color: #8DA3B0;">{{ user?.accesslevel_updated_date && user.accesslevel_updated_date !== '0000-00-00 00:00:00' ? (user?.accesslevel_updated_date | date: 'dd/MM/yyyy') : '' }}</small>
            </td>

            <td style="text-align: right">
              <button (click)="resetFirstLogin(user?.ID)" type="button" class="btn btn-success btn-sm mr-2">Reset to First Login</button> 
              <!-- <button *ngIf="user?.is_group_admin" (click)="onRemoveGroupAdmin(user?.ID)" type="button" class="btn btn-warning btn-sm mr-2">Remove as Group Admin</button>
              <button *ngIf="!user?.is_group_admin" (click)="onSetGroupAdmin(user?.ID)" type="button" class="btn btn-info btn-sm mr-2">Set as Group Admin</button> -->
              <button *ngIf="!user?.enable_designly_pro" (click)="showEnableDesignlyProUserModal(user)" type="button" class="btn btn-info btn-sm">Enable Designly Pro</button>
              <button *ngIf="user?.enable_designly_pro" (click)="showEnableDesignlyProUserModal(user)" type="button" class="btn btn-warning btn-sm">Disable Designly Pro</button>
            </td>
          </tr>
        </tbody>
      </table>
      <!-- <pagination-controls (pageChange)="page = $event"></pagination-controls> -->
    </div>
  </div>
</div>

<div id="enableDesignlyProModal" class="modal fade edit-box" role="dialog">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ designlyProModalTitle }}</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="form-control border-0 d-flex flex-wrap">
          <div class="checkbox">
            <label><input type="checkbox" [(ngModel)]="isEnableWebbooks"> Webbooks</label>
          </div>
        </div>
        <div class="form-control border-0 d-flex flex-wrap">
          <div class="checkbox">
            <label><input type="checkbox" [(ngModel)]="isEnableProposals"> Proposals</label>
          </div>
        </div>
        <div class="form-control border-0 d-flex flex-wrap">
          <div class="checkbox">
            <label><input type="checkbox" [(ngModel)]="isEnableOwnerReports"> Owner Reports</label>
          </div>
        </div>
      </div>
      <div class="modal-footer d-block">
        <div *ngIf="isAllUser">
          <button type="button" [disabled]="getCheckboxAttribute()" (click)="onUpdateUsersDesignlyPro(1)" class="btn-admin btn-admin-primary me-2">Enable to all user</button>
          <button type="button" [disabled]="getCheckboxAttributeDisable()" (click)="onUpdateUsersDesignlyPro(0)" class="btn btn-warning me-2">Disable to all user</button>
          <button type="button" class="btn-admin" data-bs-dismiss="modal">Cancel</button>
        </div>
        <div *ngIf="!isAllUser">
          <button type="button" [disabled]="getCheckboxAttributeDisable('selected')" (click)="onUpdateUserDesignlyPro(1)" class="btn-admin btn-admin-primary me-2">Enable to this user</button>
          <button type="button" [disabled]="getCheckboxAttributeDisable('selected')" (click)="onUpdateUserDesignlyPro(0)" class="btn btn-warning me-2 me-2">Disable to this user</button>
          <button type="button" class="btn-admin" data-bs-dismiss="modal">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</div>
<ngx-loading [show]="loading"></ngx-loading>
